import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { DeviceThermostat } from '@mui/icons-material'
import { alpha, darken, lighten, useTheme } from '@mui/material/styles'

import {
  getRuuviTagBatteryNow,
  getRuuviTagConditionNow
} from '../services/ruuviTagService'
import {
  RuuviTag,
  RuuviTagBattery,
  RuuviTagCondition,
  Statistic
} from '../types'
import { humanizeTimestamp } from '../utils/date'
import { isConditionOutdated } from '../utils/ruuviTag'
import RuuviTagStatusIndicator from './RuuviTagStatusIndicator'
import RuuviTagBatteryIndicator from './RuuviTagBatteryIndicator'
import RuuviTagSignalIndicator from './RuuviTagSignalIndicator'
import { formatMacAddress } from '../utils/string'
import RuuviTagStatsTooltip from './RuuviTagStatsTooltip'

interface RuuviTagGridItemStatsBoxProps {
  title?: string
  value?: number | string
  unit?: string
  loading?: boolean
}

function RuuviTagGridItemStatsBox({
  title,
  value,
  unit,
  loading = false
}: RuuviTagGridItemStatsBoxProps) {
  const theme = useTheme()

  return (
    <Tooltip title={title ?? ''}>
      <Box>
        {loading ? (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: '1.5rem' }}
            />
            <Skeleton
              variant="text"
              width="24px"
              animation="wave"
              sx={{ fontSize: '1rem' }}
            />
          </>
        ) : (
          <>
            <Typography variant="h4">{value}</Typography>
            <Typography
              variant="h6"
              color={
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.5)'
                  : 'rgba(255, 255, 255, 0.6)'
              }
            >
              {unit}
            </Typography>
          </>
        )}
      </Box>
    </Tooltip>
  )
}

interface RuuviTagGridItemProps {
  ruuviTag: RuuviTag
  statistic?: Statistic
  size?: 'small' | 'large'
}

export default function RuuviTagGridItem({
  ruuviTag,
  statistic = 'avg',
  size = 'large'
}: RuuviTagGridItemProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The latest condition of the ruuvi tag.
   */
  const [condition, setCondition] = useState<RuuviTagCondition | null>(null)

  /**
   * The latest batttery of the ruuvi tag.
   */
  const [battery, setBattery] = useState<RuuviTagBattery | null>(null)

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * Boots the component.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      await Promise.all([
        loadRuuviTagConditionNow(signal),
        loadRuuviTagBattery(signal)
      ])
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Loads the current condition of RuuviTag.
   */
  async function loadRuuviTagConditionNow(signal: AbortSignal): Promise<void> {
    setCondition(await getRuuviTagConditionNow(ruuviTag.id, { signal }))
  }

  /**
   * Loads the latest battery of the ruuvi tag.
   */
  async function loadRuuviTagBattery(signal: AbortSignal): Promise<void> {
    setBattery(await getRuuviTagBatteryNow(ruuviTag.id, { signal }))
  }

  /**
   * Reboots the component when once per minute.
   */
  useEffect(() => {
    const controller = new AbortController()
    const timer = setInterval(async () => {
      boot(controller.signal)
    }, 60000)
    return () => {
      clearInterval(timer)
      controller.abort()
    }
  }, [condition])

  /**
   * Boots the component when mounted.
   */
  useEffect(() => {
    const controller = new AbortController()
    boot(controller.signal)
    return () => controller.abort()
  }, [])

  /**
   * Calculate the temperature.
   *
   * @param condition The condition.
   *
   * @returns The temperature.
   */
  function calculateTemperature(condition: RuuviTagCondition): number {
    switch (statistic) {
      case 'min':
        return condition.temperatureMin
      case 'max':
        return condition.temperatureMax
      case 'avg':
      default:
        return condition.temperatureAvg
    }
  }

  /**
   * Calculate the humidity.
   *
   * @param condition The condition.
   *
   * @returns The humidity.
   */
  function calculateHumidity(condition: RuuviTagCondition): number {
    switch (statistic) {
      case 'min':
        return condition.humidityMin
      case 'max':
        return condition.humidityMax
      case 'avg':
      default:
        return condition.humidityAvg
    }
  }

  /**
   * Calculate the pressure.
   *
   * @returns {number} The pressure.
   */
  function calculatePressure(condition: RuuviTagCondition): number {
    switch (statistic) {
      case 'min':
        return condition.pressureMin
      case 'max':
        return condition.pressureMax
      case 'avg':
      default:
        return condition.pressureAvg
    }
  }

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: lighten(theme.palette.background.paper, 0.05)
        }}
      >
        {size === 'large' && (
          <>
            {condition && !isConditionOutdated(condition) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                  left: '1rem'
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={3}
                >
                  <Stack direction="row" alignItems="center" gap={3}>
                    {battery && (
                      <RuuviTagBatteryIndicator
                        battery={battery}
                        condition={condition}
                        rotationAngle={90}
                        fontSize="medium"
                      />
                    )}

                    <RuuviTagSignalIndicator
                      condition={condition}
                      fontSize="small"
                    />
                  </Stack>

                  <Chip
                    size="small"
                    label={humanizeTimestamp(condition.time)}
                  />
                </Stack>
              </Box>
            )}
          </>
        )}

        {ruuviTag?.asset?.photoUrl ? (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%'
            }}
          >
            <img
              src={ruuviTag.asset.photoUrl}
              alt={ruuviTag.asset.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
            <DeviceThermostat
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '5rem',
                color: 'rgba(255, 255, 255, 0.5)'
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: size === 'large' ? 'flex-start' : 'center',
              paddingTop: size === 'large' ? '5rem' : 0
            }}
          >
            <DeviceThermostat
              sx={{
                fontSize: '5rem',
                color:
                  theme.palette.mode === 'light'
                    ? darken(theme.palette.background.paper, 0.1)
                    : lighten(theme.palette.background.paper, 0.5)
              }}
            />
          </Box>
        )}
      </Box>

      <CardActionArea
        component={NavLink}
        to={`/ruuvi-tags/${ruuviTag.id}`}
        sx={{ height: size === 'small' ? '100%' : 'auto' }}
      >
        <CardContent
          sx={{
            backgroundColor: alpha(theme.palette.background.paper, 0.9),
            height: '100%'
          }}
        >
          <Box mb={2}>
            <Typography variant="h6">
              <Stack direction="row" alignItems="center" gap={2}>
                {!isLoading && <RuuviTagStatusIndicator ruuviTag={ruuviTag} />}
                {ruuviTag?.asset?.name ?? formatMacAddress(ruuviTag.id)}
              </Stack>
            </Typography>
          </Box>

          {size === 'small' && (
            <>
              {condition && !isConditionOutdated(condition) && (
                <Stack
                  direction="row"
                  gap={3}
                  alignItems="center"
                  sx={{ my: 3 }}
                >
                  <Typography fontSize="small">
                    {condition !== null
                      ? humanizeTimestamp(condition.time)
                      : '-'}
                  </Typography>

                  {battery && (
                    <RuuviTagBatteryIndicator
                      battery={battery}
                      condition={condition}
                      rotationAngle={90}
                      fontSize="small"
                    />
                  )}

                  <Typography
                    fontSize="small"
                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <RuuviTagSignalIndicator
                      condition={condition}
                      fontSize="inherit"
                    />
                  </Typography>
                </Stack>
              )}
            </>
          )}

          {!isLoading && condition === null ? (
            <Alert variant="outlined" severity="error" sx={{ border: 0, p: 0 }}>
              {t('conditionIsUnknown')}
            </Alert>
          ) : !isLoading &&
            condition !== null &&
            isConditionOutdated(condition) ? (
            <RuuviTagStatsTooltip
              ruuviTag={ruuviTag}
              condition={condition}
              battery={battery}
              statistic={statistic}
            >
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ border: 0, p: 0 }}
              >
                {!ruuviTag.status
                  ? t('deviceIsOffline')
                  : t('conditionIsOutdated')}
              </Alert>
            </RuuviTagStatsTooltip>
          ) : (
            <Grid container spacing={3}>
              {ruuviTag.has_temperature_sensor && (
                <Grid item xs={4} lg={4}>
                  <RuuviTagGridItemStatsBox
                    title={t('temperature')}
                    value={condition ? calculateTemperature(condition) : '-'}
                    unit="°C"
                    loading={isLoading}
                  />
                </Grid>
              )}

              {ruuviTag.has_humidity_sensor && (
                <Grid item xs={4} lg={4}>
                  <RuuviTagGridItemStatsBox
                    title={t('humidity')}
                    value={condition ? calculateHumidity(condition) : '-'}
                    unit="%"
                    loading={isLoading}
                  />
                </Grid>
              )}

              {ruuviTag.has_pressure_sensor && (
                <Grid item xs={4} lg={4}>
                  <RuuviTagGridItemStatsBox
                    title={t('pressure')}
                    value={condition ? calculatePressure(condition) : '-'}
                    unit="hPa"
                    loading={isLoading}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
