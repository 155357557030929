import { ProjectNewOrOldType } from '../enums/ProjectNewOrOldType'

export default function translateProjectNewOrOldType(
  newOrOld: ProjectNewOrOldType | string
): string {
  switch (newOrOld) {
    case ProjectNewOrOldType.NEW:
      return 'Project.new'
    case ProjectNewOrOldType.OLD:
      return 'Project.renovation'
    default:
      return newOrOld
  }
}
