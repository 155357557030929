import React, { MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import QrCode from 'react-qr-code'

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Stack,
  Typography,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CardHeader,
  IconButton,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Tooltip,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  AccordionActions,
  List,
  ListItem,
  ListItemButton,
  CardContent
} from '@mui/material'
import { spacing, useTheme } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/store'

import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import { getProject, updateProject } from '../../redux/slices/projects'
import {
  Delete,
  Edit,
  BarChart as BarChartIcon,
  AssignmentTurnedIn,
  Share,
  CopyAll,
  ContentCopy,
  MoreVert,
  ShowChart,
  Restore,
  LinkRounded,
  NavigateNext,
  ExpandMore
} from '@mui/icons-material'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import ProjectUsers from './ProjectUsers'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { UserRole } from '../../enums/UserRole'
import { ProjectMembershipRole } from '../../enums/ProjectMembershipRole'
import { getLevelsByProjectId } from '../../redux/slices/levels'
import LevelTable from '../../components/LevelTable'
import LiftTable from '../../components/LiftTable'
import SectorTable from '../../components/SectorTable'
import PowerSupplyHierarchy from '../../components/PowerSupplyHierarchy'
import { getLiftsByProjectId } from '../../redux/slices/lifts'
import { getSectorsByProjectId } from '../../redux/slices/sectors'
import { getPowerSuppliesByProjectId } from '../../redux/slices/powerSupplies'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { MembershipRole } from '../../enums/MembershipRole'
import translateProjectType from '../../utils/translateProjectType'
import translateProjectNewOrOldType from '../../utils/translateProjectNewOrOldType'
import translateProjectWarmingMethod from '../../utils/translateProjectWarmingMethod'
import checkProjectStatus from '../../utils/checkProjectStatus'
import { ProjectStateType } from '../../enums/ProjectStateType'
import ProjectEnergyPriceInfo from '../../components/ProjectEnergyPriceInfo'
import ProjectEmissionFactorInfo from '../../components/ProjectEmissionFactorInfo'
import ProjectEnergyConsumptionStats from '../../components/ProjectEnergyConsumptionStats'
import ProjectEnergyCostStats from '../../components/ProjectEnergyCostStats'
import ProjectEmissionStats from '../../components/ProjectEmissionStats'
import ProjectAlertManagementTable from '../../components/ProjectAlertManagementTable'
import LoadingState from '../../components/LoadingState'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import projectService, { getJoinLink } from '../../services/projectService'
import { createUserNotification } from '../../utils/createUserNotification'
import { ProjectErrors } from '../../enums/ProjectErrors'
import UserAlertManagementTable from '../../components/UserAlertManagementTable'
import {
  Project,
  GeoCoordinate,
  RuuviTag,
  ProjectMembership
} from '../../types'
import { getProjectMembership } from '../../redux/slices/projectMemberships'
import ProjectGanttChart from '../../components/ProjectGanttChart'
import { calculateProgressByDate } from '../../utils/calculateProgressByDate'
import ProjectInput from '../../types/ProjectInput'
import translateProjectState from '../../utils/translateProjectState'
import AssetScroller from '../../components/AssetScroller'
import AssetHierarchyWrapper from '../../components/AssetHierarchyWrapper'
import { getRuuviTags } from '../../services/ruuviTagService'
import PageHeader from '../../components/PageHeader'
import { red } from '@mui/material/colors'
import { M } from 'msw/lib/glossary-dc3fd077'
import ProjectProgressBar from '../../components/ProjectProgressBar'
import { Tool } from 'react-feather'
import CompanyDetails from '../../components/CompanyDetails'
import ProjectDetails from '../../components/ProjectDetails'
import { LoadingButton } from '@mui/lab'

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Paper = styled(MuiPaper)(spacing)
const Button = styled(MuiButton)(spacing)

export enum ProjectTab {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  DEVICES = 'devices',
  RUUVI_TAGS = 'ruuvitags',
  ASSETS = 'assets',
  RUUVI_LOCATIONS = 'ruuvilocations',
  POWER_SUPPLIES = 'powersupplies',
  LEVELS = 'levels',
  LIFTS = 'lifts',
  SECTORS = 'sectors',
  ALERT_MANAGEMENT = 'alertmanagement',
  TIMELINE = 'timeline',
  MONITORING = 'monitoring'
}

// eslint-disable-next-line
function ProjectPage() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [t] = useTranslation('common')

  const { id } = useParams()

  const { currentUser } = useAuth()

  const { project, loading, projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  const { membership } = useSelector(
    (state: RootState) => state.projectMemberships
  )

  const memberId = parseInt(currentUser?.id)

  const [tab, setTab] = useState<ProjectTab>(getInitialTab())

  /**
   * The ruuvi tags of project.
   */
  const [ruuviTags, setRuuviTags] = useState<RuuviTag[]>([])

  /**
   * Indicates if ruuvi tags are loading.
   */
  const [isLoadingRuuviTags, setIsLoadingRuuviTags] = useState<boolean>(true)

  function getInitialTab(): ProjectTab {
    const hash = location.hash.substring(1).split('?')[0]
    switch (hash) {
      case ProjectTab.POWER_SUPPLIES:
        return ProjectTab.POWER_SUPPLIES
      case ProjectTab.LEVELS:
        return ProjectTab.LEVELS
      case ProjectTab.LIFTS:
        return ProjectTab.LIFTS
      case ProjectTab.SECTORS:
        return ProjectTab.SECTORS
      case ProjectTab.USERS:
        return ProjectTab.USERS
      case ProjectTab.DEVICES:
        return ProjectTab.DEVICES
      case ProjectTab.ALERT_MANAGEMENT:
        return ProjectTab.ALERT_MANAGEMENT
      case ProjectTab.TIMELINE:
        return ProjectTab.TIMELINE
      case ProjectTab.ASSETS:
        return ProjectTab.ASSETS
      case ProjectTab.MONITORING:
        return ProjectTab.MONITORING
      default:
        return ProjectTab.DASHBOARD
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    if (currentUser && id) {
      dispatch(getProject(parseInt(id)))
      dispatch(getLevelsByProjectId(parseInt(id)))
      dispatch(getLiftsByProjectId(parseInt(id)))
      dispatch(getSectorsByProjectId(parseInt(id)))
      dispatch(getPowerSuppliesByProjectId(parseInt(id)))
      dispatch(
        getProjectMembership({ memberId: memberId, projectId: parseInt(id) })
      )
      ;(async () => {
        await loadRuuviTagsOfProject(parseInt(id), controller.signal)
      })()
    }

    return () => controller.abort()
  }, [currentUser, id])

  async function loadRuuviTagsOfProject(
    projectId: number,
    signal: AbortSignal
  ): Promise<void> {
    try {
      setIsLoadingRuuviTags(true)
      setRuuviTags(await getRuuviTags({ projectId }, { signal }))
    } finally {
      setIsLoadingRuuviTags(false)
    }
  }

  const handleChange = (event: React.SyntheticEvent, tab: string): void => {
    setTab(tab as ProjectTab)
  }

  const userCanManageProject: boolean =
    currentUser?.role === UserRole.SUPERADMIN ||
    membership?.role === ProjectMembershipRole.ADMIN ||
    membership?.role === ProjectMembershipRole.OWNER

  const canProjectBeEnded: boolean =
    project?.state === ProjectStateType.PLANNED ||
    project?.state === ProjectStateType.INACTIVE ||
    project?.state === ProjectStateType.EXTENDED ||
    project?.state === ProjectStateType.ACTIVE

  const handleDelete = async (id: number) => {
    try {
      await projectService.deleteProject(id)

      handleClose()

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('projectArchiveSuccess')
        )
      )

      navigate('/projects')
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  const [open, setOpen] = useState(false)
  const [projectId, setProjectId] = useState(0)
  const [moveMarker, setMoveMarker] = useState(false)
  const [latlong, setLatlong] = useState<GeoCoordinate>({
    long: 0,
    lat: 0
  })

  useEffect(() => {
    if (project) {
      setLatlong({ long: project.long, lat: project.lat })
    }
  }, [project])

  const handleClickOpen = (id: any) => {
    setProjectId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setProjectId(0)
  }

  const [isUpdatingLocation, setIsUpdatingLocation] = useState<boolean>(false)

  /**
   * Indicates if the current user is superadmin.
   */
  const isSuperadmin = currentUser?.role === UserRole.SUPERADMIN

  /**
   * Indicates if the current user is owner or admin of the project.
   */
  const isOwnerOrAdmin =
    currentUser?.projects?.some(
      (membership) =>
        membership.projectId === parseInt(id as string) &&
        (membership.role === ProjectMembershipRole.OWNER ||
          membership.role === ProjectMembershipRole.ADMIN)
    ) ?? false

  async function handleLocationUpdate() {
    try {
      setIsUpdatingLocation(true)
      if (project) {
        const updatedProject = await projectService.updateProject(project.id, {
          ...project,
          company: project?.company?.id,
          lat: latlong.lat,
          long: latlong.long
        })

        dispatch(updateProject(updatedProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectUpdateSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setMoveMarker(false)
      setIsUpdatingLocation(false)
    }
  }

  const handleLatLong = (e: any) => {
    setLatlong({ long: e.lng, lat: e.lat })
  }

  async function handleChangeProjectStateProject(state: ProjectStateType) {
    try {
      if (project) {
        setIsSubmitting(true)
        //@ts-ignore
        const projectInput: ProjectInput = { ...project }
        projectInput.state = state
        projectInput.company = project?.company?.id

        const updatedProject = await projectService.updateProject(
          project.id,
          projectInput
        )

        dispatch(updateProject(updatedProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectUpdateSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: project ? ProjectErrors.EDIT : ProjectErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setOpenProjectArchiveDialog(false)
      setIsSubmitting(false)
    }
  }
  const [openProjectArchiveDialog, setOpenProjectArchiveDialog] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY

  /**
   * Indicates if the share dialog is open.
   */
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false)

  /**
   * Open the share dialog.
   */
  const openShareDialog = () => {
    setIsShareDialogOpen(true)
  }

  /**
   * Close the share dialog.
   */
  const closeShareDialog = () => {
    setIsShareDialogOpen(false)
  }

  /**
   * The join link.
   */
  const [joinLink, setJoinLink] = useState<string>()

  /**
   * Load the join link.
   */
  const loadJoinLink = async () => {
    if (project?.id) {
      try {
        setJoinLink(await getJoinLink(project.id))
      } catch (error: unknown) {
        // TODO: Handle errors.
      }
    }
  }

  /**
   * Copy the join link to the clipboard.
   */
  const copyJoinLink = async () => {
    if (joinLink !== undefined) {
      await navigator.clipboard.writeText(joinLink)
    }
  }

  /**
   * The anchor element of the device menu.
   */
  const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(
    null
  )

  /**
   * Indicates if the device menu is open.
   */
  const isActionMenuOpen = Boolean(actionMenuAnchor)

  /**
   * Open the action menu.
   */
  const openActionMenu = (event: MouseEvent<HTMLElement>) => {
    setActionMenuAnchor(event.currentTarget)
  }

  /**
   * Close the action menu.
   */
  const closeActionMenu = () => {
    setActionMenuAnchor(null)
  }

  // Loads the join link when the dialog is opened for the first time.
  useEffect(() => {
    if (isShareDialogOpen && joinLink === undefined) {
      loadJoinLink()
    }
  }, [isShareDialogOpen])

  const theme = useTheme()

  return (
    <React.Fragment>
      <PageHeader
        title={project ? project.name : t('project')}
        breadcrumbs={
          <Breadcrumbs separator={<NavigateNext />}>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>
            <Link component={NavLink} to="/projects">
              {t('projects')}
            </Link>
            <Typography>
              {project ? project.name : <Skeleton variant="text" width={100} />}
            </Typography>
          </Breadcrumbs>
        }
        action={
          <Stack direction="row" gap={3}>
            {project && (
              <>
                {(isSuperadmin || isOwnerOrAdmin) && (
                  <Button
                    disabled={project?.state === ProjectStateType.ARCHIVED}
                    variant="contained"
                    endIcon={<PersonAddAlt1Icon />}
                    sx={{
                      display: { xs: 'none', md: 'inline-flex' },
                      textWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                    onClick={() =>
                      navigate(`/projects/${project?.id}/invite-user`)
                    }
                  >
                    {t('Project.inviteUsersBtn')}
                  </Button>
                )}

                {(isSuperadmin || isOwnerOrAdmin) && (
                  <>
                    <IconButton sx={{ flexShrink: 0 }} onClick={openActionMenu}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      open={isActionMenuOpen}
                      onClose={() => closeActionMenu()}
                      anchorEl={actionMenuAnchor}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      PaperProps={{
                        sx: { width: '260px' }
                      }}
                    >
                      <MenuItem
                        disabled={project?.state === ProjectStateType.ARCHIVED}
                        onClick={() =>
                          navigate(`/projects/${project?.id}/invite-user`)
                        }
                      >
                        <ListItemText primary={t('Project.inviteUsersBtn')} />
                        <ListItemSecondaryAction>
                          <PersonAddAlt1Icon />
                        </ListItemSecondaryAction>
                      </MenuItem>

                      <MenuItem
                        component={NavLink}
                        to={`/projects/${project?.id}/stats`}
                      >
                        <ListItemText primary={t('stats')} />
                        <ListItemSecondaryAction>
                          <ShowChart />
                        </ListItemSecondaryAction>
                      </MenuItem>

                      <>
                        <MenuItem onClick={() => openShareDialog()}>
                          <ListItemText primary={t('shareLink')} />
                          <ListItemSecondaryAction>
                            <LinkRounded />
                          </ListItemSecondaryAction>
                        </MenuItem>

                        <Dialog
                          open={isShareDialogOpen}
                          onClose={closeShareDialog}
                          maxWidth="md"
                          fullWidth
                        >
                          <DialogTitle>{t('shareLinkToProject')}</DialogTitle>
                          <DialogContent>
                            {joinLink === undefined ? (
                              <LoadingState />
                            ) : (
                              <Stack direction="column" gap={6}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <QrCode
                                    value={joinLink}
                                    size={512}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '512px',
                                      width: '100%'
                                    }}
                                  />
                                </Box>

                                <TextField
                                  value={joinLink}
                                  label={t('urlAddress')}
                                  disabled={true}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={copyJoinLink}
                                          edge="end"
                                        >
                                          <ContentCopy />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Stack>
                            )}
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closeShareDialog}>
                              {t('close')}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>

                      <MenuItem
                        component={NavLink}
                        to={`/projects/${project?.id}/edit`}
                      >
                        <ListItemText primary={t('edit')} />
                        <ListItemSecondaryAction>
                          <Edit />
                        </ListItemSecondaryAction>
                      </MenuItem>

                      {canProjectBeEnded && (
                        <MenuItem
                          onClick={() =>
                            handleChangeProjectStateProject(
                              ProjectStateType.ENDED
                            )
                          }
                        >
                          <ListItemText primary={t('endProject')} />
                          <ListItemSecondaryAction>
                            <AssignmentTurnedIn />
                          </ListItemSecondaryAction>
                        </MenuItem>
                      )}

                      {project.state === ProjectStateType.ENDED && (
                        <MenuItem
                          sx={{ color: theme.palette.error.main }}
                          onClick={() => setOpenProjectArchiveDialog(true)}
                        >
                          <ListItemText primary={t('archiveProject')} />
                          <ListItemSecondaryAction>
                            <Delete color="error" />
                          </ListItemSecondaryAction>
                        </MenuItem>
                      )}

                      {project.state === ProjectStateType.ARCHIVED && (
                        <MenuItem
                          onClick={() =>
                            handleChangeProjectStateProject(
                              ProjectStateType.ACTIVE
                            )
                          }
                        >
                          <ListItemSecondaryAction>
                            <Restore />
                          </ListItemSecondaryAction>
                          <ListItemText primary={t('recoverProject')} />
                        </MenuItem>
                      )}

                      <Divider />

                      <>
                        <MenuItem
                          onClick={handleClickOpen}
                          sx={{ color: red[500] }}
                        >
                          <ListItemText
                            primary={t('delete')}
                            color="textError"
                          />
                          <ListItemSecondaryAction>
                            <Delete color="error" />
                          </ListItemSecondaryAction>
                        </MenuItem>

                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {t('projectArchiveTitle')}
                          </DialogTitle>

                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {t('projectArchiveInfo')}
                            </DialogContentText>
                          </DialogContent>

                          <DialogActions>
                            <Button onClick={() => handleDelete(projectId)}>
                              {t('Projects.yes')}
                            </Button>
                            <Button onClick={handleClose} autoFocus>
                              {t('Projects.no')}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    </Menu>
                  </>
                )}
              </>
            )}
          </Stack>
        }
      />

      <Dialog
        open={openProjectArchiveDialog}
        onClose={() => setOpenProjectArchiveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isSubmitting ? (
          <DialogContentText width={'500px'} height={'200px'}>
            <LoadingState />
          </DialogContentText>
        ) : (
          <React.Fragment>
            <DialogTitle id="alert-dialog-title">
              {t('archiveProjectTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('archiveProjectInfo')}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() =>
                  handleChangeProjectStateProject(ProjectStateType.ARCHIVED)
                }
                color="error"
              >
                {t('archiveProject')}
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenProjectArchiveDialog(false)}
              >
                {t('cancel')}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={handleChange}
            >
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#dashboard')
                }
                label={t('Project.information')}
                value={ProjectTab.DASHBOARD as string}
              />

              <Tab
                onClick={() => window.history.replaceState({}, '', '#timeline')}
                label={t('timeline')}
                value={ProjectTab.TIMELINE as string}
              />

              <Tab
                onClick={() => window.history.replaceState({}, '', '#assets')}
                label={t('deviceView')}
                value={ProjectTab.ASSETS as string}
              />

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#monitoring')
                }
                label={t('deviceMonitoring')}
                value={ProjectTab.MONITORING as string}
              />

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#levels')}
                  label={t('Project.levels')}
                  value={ProjectTab.LEVELS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#lifts')}
                  label={t('Project.lifts')}
                  value={ProjectTab.LIFTS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() =>
                    window.history.replaceState({}, '', '#sectors')
                  }
                  label={t('Project.sectors')}
                  value={ProjectTab.SECTORS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#users')}
                  label={t('Project.users')}
                  value={ProjectTab.USERS as string}
                />
              )}

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#alertmanagement')
                }
                label={t('alertManagement')}
                value={ProjectTab.ALERT_MANAGEMENT as string}
              />
            </TabList>
          </Box>

          <Box mt={6}>
            <TabPanel value={ProjectTab.DASHBOARD as string}>
              {project && !loading && (
                <Box sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={4}>
                    <Box pb="6px" flexGrow={1}>
                      <ProjectProgressBar project={project} />
                    </Box>

                    {userCanManageProject && (
                      <Tooltip title={t('edit') as string}>
                        <IconButton
                          size="small"
                          sx={{ alignSelf: 'flex-end' }}
                          onClick={() =>
                            navigate(
                              `/projects/${project?.id}/edit?timeline=true`
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
              )}

              {project && !loading && (
                <>
                  <Grid container spacing={6} my={6}>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyConsumptionStats project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyCostStats project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEmissionStats project={project} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={6} mb={6}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyPriceInfo project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEmissionFactorInfo project={project} />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <ProjectDetails project={project} defaultExpanded />
                </Grid>

                <Grid item xs={12}>
                  <CompanyDetails
                    title={t('Project.builderInfo')}
                    company={project?.company}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={t('Project.projectLocation')}
                      action={
                        <>
                          {(isSuperadmin || isOwnerOrAdmin) && (
                            <>
                              {!moveMarker ? (
                                <Tooltip title={t('edit') as string}>
                                  <IconButton
                                    disabled={
                                      project?.state ===
                                      ProjectStateType.ARCHIVED
                                    }
                                    onClick={() => setMoveMarker(true)}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <LoadingButton
                                  loading={isUpdatingLocation}
                                  size="small"
                                  variant="contained"
                                  onClick={() => handleLocationUpdate()}
                                >
                                  {t('save')}
                                </LoadingButton>
                              )}
                            </>
                          )}
                        </>
                      }
                    />

                    <CardContent sx={{ p: '0 !important' }}>
                      {loading || isSubmitting ? (
                        <LoadingState />
                      ) : (
                        <>
                          {project && !isUpdatingLocation ? (
                            <Map
                              mapboxAccessToken={apiKey}
                              initialViewState={{
                                longitude: project?.long,
                                latitude: project?.lat,
                                zoom: 13
                              }}
                              mapStyle="mapbox://styles/mapbox/streets-v9"
                              style={{
                                width: '100%',
                                minHeight: 590,
                                height: '100%'
                              }}
                            >
                              <Marker
                                draggable={moveMarker}
                                longitude={latlong.long}
                                latitude={latlong.lat}
                                onDragEnd={(e) => handleLatLong(e.lngLat)}
                              ></Marker>
                            </Map>
                          ) : (
                            <LoadingState />
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>

            {project && (
              <TabPanel value={ProjectTab.TIMELINE as string}>
                <ProjectGanttChart project={project} />
              </TabPanel>
            )}
            <TabPanel value={ProjectTab.ASSETS as string}>
              <AssetHierarchyWrapper
                project={project as Project}
                ruuviTags={ruuviTags}
                userCanManageProject={userCanManageProject}
              />
            </TabPanel>

            <TabPanel value={ProjectTab.MONITORING as string}>
              {project && (
                <AssetScroller initialQuery={{ projectId: project.id }} />
              )}
            </TabPanel>

            {userCanManageProject && (
              <TabPanel value={ProjectTab.LEVELS as string}>
                <LevelTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.LIFTS as string}>
                <LiftTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.SECTORS as string}>
                <SectorTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.USERS as string}>
                <ProjectUsers />
              </TabPanel>
            )}

            <TabPanel value={ProjectTab.ALERT_MANAGEMENT as string}>
              <ProjectAlertManagementTable
                project={project}
                userCanManageProject={userCanManageProject}
              />
              <Divider mt={6} />
              <UserAlertManagementTable project={project} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </React.Fragment>
  )
}

export default ProjectPage
