import { Tooltip, IconButton, ButtonProps, Button } from '@mui/material'
import { BugReport as BugReportIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { BugReport } from './BugReport'
import { MouseEvent, useState } from 'react'

type BugReportButtonProps = Omit<ButtonProps, 'children'>

export default function BugReportButton({
  onClick,
  ...props
}: BugReportButtonProps) {
  /**
   * The translation function
   */
  const { t } = useTranslation('common')

  /**
   * Indicates if the bug report dialog is open.
   */
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  /**
   * Open the dialog.
   */
  const openDialog = () => {
    setIsDialogOpen(true)
  }

  /**
   * Close the dialog.
   */
  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  /**
   * Handle the click event.
   */
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    openDialog()

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {t('Navbar.bugReport')}
      </Button>

      <BugReport isOpen={isDialogOpen} onClose={closeDialog} />
    </>
  )
}
