import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import {
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  Stack,
  Box,
  CardHeader
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createUser } from '../../redux/slices/users'
import { UserRole } from '../../enums/UserRole'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { THEMES } from '../../constants'
import useTheme from '../../hooks/useTheme'
import { InstructionForNewUser } from '../InstructionsForNewUser'
import {
  createErrorOrSuccessNotification,
  setShowInstructions
} from '../../redux/slices/notifications'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { NotificationType } from '../../enums/NotificationType'
import userService from '../../services/userService'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

type SignUpFormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string | null
  password: string
  confirmPassword: string
  role: UserRole
  language: 'fi' | 'swe' | 'en'
  notificationsByEmail: boolean
  notificationsBySms: boolean
  // FIXME: Rename as "projectMembership"
  token?: string
}

// eslint-disable-next-line
function SignUpGuest() {
  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const { theme } = useTheme()

  /**
   * The user role.
   */
  const [isInstaller, setIsInstaller] = useState(UserRole.GUEST)

  /**
   * The phone number.
   */
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  /**
   * The token.
   */
  const token = new URL(window.location.href).searchParams.get('token')

  /**
   * The initial values.
   */
  const initialValues: SignUpFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    role: UserRole.GUEST,
    language: 'en',
    notificationsByEmail: true,
    notificationsBySms: true,
    token: token ?? undefined
  }

  /**
   * The validation schema.
   */
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .max(255)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        t('strongPassword')
      )
      .required('Required'),
    confirmPassword: Yup.string().when('password', {
      // eslint-disable-next-line
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same'
      )
    }),
    role: Yup.string().required('Required'),
    notificationsByEmail: Yup.boolean().required('Required'),
    notificationsBySms: Yup.boolean().required('Required')
  })

  const parsePhone = (phone: string | null): string | null => {
    if (
      phone === undefined ||
      phone === null ||
      phone === '+' ||
      phone === ''
    ) {
      //if user wants to clear phonenumber on update we need to do this otherwise '+' is saved to database.
      return null
    } else {
      // Add "+"" to phone number since PhoneInput does not provide it.
      return `+${phone}`
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsInstaller(UserRole.INSTALLER)
    } else {
      setIsInstaller(UserRole.GUEST)
    }
  }

  const handleSubmit = async (
    values: SignUpFormValues,
    { resetForm, setStatus, setSubmitting }: FormikHelpers<SignUpFormValues>
  ) => {
    try {
      values.phone = parsePhone(values.phone)

      const newUser = await userService.createUser(values)
      dispatch(createUser(newUser))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('signUpSuccess')
        )
      )
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      navigate('/auth/sign-in')

      // eslint-disable-next-line
    } catch (error: unknown) {
      dispatch(
        setError({
          type: AuthErrors.SIGN_UP_GUEST,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: AuthErrors.SIGN_UP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(AuthErrors.SIGN_UP))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Card>
        <CardHeader
          title={t('signUp')}
          action={
            <>
              <IconButton
                size="small"
                onClick={() => dispatch(setShowInstructions(true))}
              >
                <QuestionMarkIcon fontSize="inherit" />
              </IconButton>
              <InstructionForNewUser />
            </>
          }
        />
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      name="firstName"
                      label={t('Users.firstName')}
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      name="lastName"
                      label={t('Users.lastName')}
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      type="email"
                      name="email"
                      label={t('Users.email')}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <PhoneInput
                      enableSearch={true}
                      specialLabel=""
                      placeholder={t('Users.phone')}
                      value={(values.phone = phoneNumber)}
                      disabled={isSubmitting}
                      onChange={(newValue) => setPhoneNumber(newValue)}
                      country="fi"
                      containerStyle={{
                        width: '100%'
                      }}
                      inputStyle={{
                        width: '100%',
                        background:
                          theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                        color: theme === THEMES.DARK ? 'white' : 'black',
                        fontSize: 13,
                        borderColor: '#777777'
                      }}
                      dropdownStyle={{
                        background:
                          theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                        color: 'gray'
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      type="password"
                      name="password"
                      label={t('Users.password')}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      type="password"
                      name="confirmPassword"
                      label={t('Users.confirm')}
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      name="notificationsByEmail"
                      label={t('Users.emailNotification')}
                      value={values.notificationsByEmail}
                      error={Boolean(
                        touched.notificationsByEmail &&
                          errors.notificationsByEmail
                      )}
                      helperText={
                        touched.notificationsByEmail &&
                        errors.notificationsByEmail
                      }
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                      <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      name="notificationsBySms"
                      label={t('Users.smsNotification')}
                      value={values.notificationsBySms}
                      error={Boolean(
                        touched.notificationsBySms && errors.notificationsBySms
                      )}
                      helperText={
                        touched.notificationsBySms && errors.notificationsBySms
                      }
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                      <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      select
                      name="language"
                      label={t('Users.language')}
                      value={values.language}
                      error={Boolean(touched.language && errors.language)}
                      helperText={touched.language && errors.language}
                      disabled={isSubmitting}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="fi">Finnish</MenuItem>
                      <MenuItem value="swe">Swedish</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheckboxChange} />}
                      name="role"
                      label={t('Users.iAmInstaller') as string}
                      value={(values.role = isInstaller)}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      gap={3}
                    >
                      <Button
                        onClick={() => {
                          navigate('/auth/sign-in')
                        }}
                      >
                        {t('cancel')}
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {t('save')}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Box mt={4}>
        <Button
          size="small"
          onClick={() =>
            navigate(token ? `/auth/sign-in?token=${token}` : '/auth/sign-in')
          }
        >
          {t('alreadyHaveAccount?')}
        </Button>
      </Box>
    </>
  )
}

export default SignUpGuest
