import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Alert, AlertColor, Collapse, CssBaseline } from '@mui/material'

import GlobalStyle from '../components/GlobalStyle'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { createNotification } from '../redux/slices/notifications'

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

const Auth: React.FC = ({ children }) => {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The notification state.
   */
  const { notification } = useSelector(
    (state: RootState) => state.notifications
  )

  /**
   * The alert severity.
   */
  const severity = notification?.type as AlertColor

  const clearNotifications = () => {
    dispatch(
      createNotification({
        show: false,
        type: '',
        message: '',
        timeout: 0
      })
    )
  }

  const handleClose = () => {
    clearNotifications()
  }

  useEffect(() => {
    if (notification?.type == 'success' || notification?.type == 'warning') {
      setTimeout(() => {
        clearNotifications()
      }, notification.timeout)
    } else {
      return
    }
  }, [notification?.show])

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Collapse in={notification?.show}>
        <Alert variant="filled" severity={severity} onClose={handleClose}>
          {notification?.message}
        </Alert>
      </Collapse>
      <Outlet />
    </Root>
  )
}

export default Auth
