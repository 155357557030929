import { MouseEvent } from 'react'
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps
} from '@mui/material'
import { AssetType } from '../enums/AssetType'
import { useTranslation } from 'react-i18next'

export interface AssetTypeToggleProps
  extends Omit<ToggleButtonGroupProps, 'children' | 'exclusive'> {
  value: AssetType
  onChange?: (event: MouseEvent<HTMLElement>, value: AssetType) => void
  onValueChange?: (value: AssetType) => void
}

export default function AssetTypeToggle({
  value,
  onChange,
  onValueChange,
  ...props
}: AssetTypeToggleProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle the on change event.
   */
  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: AssetType | null
  ) => {
    if (newValue && onChange) {
      onChange(event, newValue)
    }

    if (newValue && onValueChange) {
      onValueChange(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      {...props}
      value={value}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value={AssetType.DEVICE}>{t('powerSupplies')}</ToggleButton>
      <ToggleButton value={AssetType.RUUVI_TAG}>
        {t('conditionMonitoring')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
