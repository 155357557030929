import React from 'react'

import { THEMES } from '../constants'

const initialState = {
  theme: THEMES.DARK,
  // eslint-disable-next-line
  setTheme: (theme: string) => {}
}
const ThemeContext = React.createContext(initialState)

type ThemeProviderProps = {
  children: React.ReactNode
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const initialState = () => {
    let storedTheme = localStorage.getItem('theme')

    if (storedTheme === '"LIGHT"') {
      storedTheme = THEMES.LIGHT
      localStorage.setItem('theme', THEMES.LIGHT)
    } else if (storedTheme === '"DARK"') {
      storedTheme = THEMES.DARK
      localStorage.setItem('theme', THEMES.DARK)
    }

    return storedTheme ? storedTheme : THEMES.DARK
  }

  const [theme, _setTheme] = React.useState<string>(initialState())

  const setTheme = (theme: string) => {
    localStorage.setItem('theme', theme)
    _setTheme(theme)
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
