import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import {
  Box,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Select as MuiSelect,
  MenuItem,
  IconButton,
  Stack
} from '@mui/material'
import { spacing } from '@mui/system'
import { getCompanies } from '../../redux/slices/companies'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUser } from '../../redux/slices/users'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { THEMES } from '../../constants'
import useTheme from '../../hooks/useTheme'
import { InstructionForNewUser } from '../InstructionsForNewUser'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import {
  createErrorOrSuccessNotification,
  setShowInstructions
} from '../../redux/slices/notifications'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { NotificationType } from '../../enums/NotificationType'
import userService from '../../services/userService'
import { UserRole } from '../../enums/UserRole'
import { NavLink } from 'react-router-dom'
import { Company, Project, ProjectMembership } from '../../types'
import ProjectMembershipRole from '../../enums/ProjectMembershipRole'
import jwtDecode from 'jwt-decode'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

type SignUpToken = {
  company?: Company
  role?: UserRole
  project?: Project
  projectRole?: ProjectMembershipRole
}

type ProjectMembershipToken = {
  projectId: number
  role: ProjectMembershipRole
}

type SignUpFormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string | null
  password: string
  confirmPassword: string
  role: UserRole
  language: 'fi' | 'en' | 'swe'
  notificationsByEmail: boolean
  notificationsBySms: boolean
  company?: number | null
  activeProject?: number | null
  projectRole?: ProjectMembershipRole
}

// eslint-disable-next-line
function SignUp() {
  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const { theme } = useTheme()

  /**
   * The phone number.
   */
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  useEffect(() => {
    dispatch(setShowInstructions(true))

    // TODO: Can we fetch companies for unauthorized user?
    dispatch(getCompanies())
  }, [dispatch])

  const initialValues: SignUpFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    company: null,
    role: UserRole.GUEST,
    language: 'en',
    notificationsByEmail: true,
    notificationsBySms: true
  }

  const parseSignUpToken = (): SignUpToken | undefined => {
    const url = window.location.pathname
    const token = url.substring(url.lastIndexOf('/') + 1)

    return jwtDecode(token)
  }

  const decoded = parseSignUpToken()

  if (decoded?.company !== undefined && decoded?.role !== undefined) {
    initialValues.company = decoded.company.id
    initialValues.role = decoded.role
  }

  if (decoded?.project !== undefined && decoded?.projectRole !== undefined) {
    initialValues.activeProject = decoded.project.id
    initialValues.projectRole = decoded.projectRole
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .max(255)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        t('strongPassword')
      )
      .required('Required'),
    confirmPassword: Yup.string().when('password', {
      // eslint-disable-next-line
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same'
      )
    }),
    role: Yup.string().required('Required'),
    notificationsByEmail: Yup.boolean().required('Required'),
    notificationsBySms: Yup.boolean().required('Required')
  })

  const handleSubmit = async (
    values: SignUpFormValues,
    { resetForm, setStatus, setSubmitting }: FormikHelpers<SignUpFormValues>
  ) => {
    try {
      if (
        values.phone !== undefined &&
        values.phone !== '+' &&
        values.phone !== '' &&
        values.phone !== null
      ) {
        //Add '+' to phone number since PhoneInput does not provide it
        values.phone = '+' + values.phone
      } else if (values.phone === '+' || values.phone === '') {
        //if user wants to clear phonenumber on update we need to do this otherwise '+' is saved to database.
        values.phone = null
      }

      const newUser = await userService.createUser(values)
      dispatch(createUser(newUser))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('signUpSuccess')
        )
      )
      resetForm()
      navigate('/auth/sign-in')
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
    } catch (error: unknown) {
      dispatch(
        setError({
          type: AuthErrors.SIGN_UP,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: AuthErrors.SIGN_UP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(AuthErrors.SIGN_UP))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Card>
      <InstructionForNewUser />
      <Grid mt={2} ml={2}>
        <IconButton
          size="small"
          onClick={() => dispatch(setShowInstructions(true))}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Grid>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    name="firstName"
                    label={t('Users.firstName')}
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    name="lastName"
                    label={t('Users.lastName')}
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="email"
                    name="email"
                    label={t('Users.email')}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <PhoneInput
                    enableSearch={true}
                    specialLabel=""
                    placeholder={t('Users.phone')}
                    value={(values.phone = phoneNumber)}
                    onChange={(newValue) => setPhoneNumber(newValue)}
                    containerStyle={{
                      width: '100%'
                    }}
                    inputStyle={{
                      width: '100%',
                      background:
                        theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                      color: theme === THEMES.DARK ? 'white' : 'black',
                      fontSize: 13,
                      borderColor: '#777777'
                    }}
                    dropdownStyle={{
                      background:
                        theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                      color: 'gray'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="password"
                    name="password"
                    label={t('Users.password')}
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label={t('Users.confirm')}
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>

              {decoded?.project && (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="activeProject"
                      label={t('Users.project')}
                      value={decoded.project.name}
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="projectRole"
                      label={t('Users.projectRole')}
                      value={decoded.projectRole}
                      variant="outlined"
                      fullWidth
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={6}>
                {decoded?.company && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="company"
                      label={t('Users.company')}
                      value={decoded.company.name}
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    select
                    name="language"
                    label={t('Users.language')}
                    value={values.language}
                    error={Boolean(touched.language && errors.language)}
                    helperText={touched.language && errors.language}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="fi">Finnish</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="swe">Swedish</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} md={6} xl>
                  <TextField
                    name="notificationsByEmail"
                    label={t('Users.emailNotification')}
                    select
                    value={values.notificationsByEmail}
                    error={Boolean(
                      touched.notificationsByEmail &&
                        errors.notificationsByEmail
                    )}
                    fullWidth
                    helperText={
                      touched.notificationsByEmail &&
                      errors.notificationsByEmail
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                    <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    name="notificationsBySms"
                    label={t('Users.smsNotification')}
                    select
                    value={values.notificationsBySms}
                    error={Boolean(
                      touched.notificationsBySms && errors.notificationsBySms
                    )}
                    fullWidth
                    helperText={
                      touched.notificationsBySms && errors.notificationsBySms
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                    <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={3}
              >
                <Button onClick={() => navigate('/auth/sign-in')}>
                  {t('Users.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {t('Users.save')}
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default SignUp
