import { MouseEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField as MuiTextField,
  Autocomplete,
  Stack
} from '@mui/material'
import { Box, spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { getCompanies } from '../redux/slices/companies'
import useAuth from '../hooks/useAuth'
import { UserRole } from '../enums/UserRole'
import Project from '../types/Project'
import { createProject, setShow, updateProject } from '../redux/slices/projects'
import { format } from 'date-fns'
import useProject from '../hooks/useProject'
import CompanySelect from './CompanySelect'
import { MembershipRole } from '../enums/MembershipRole'
import ProjectTypeSelect from './ProjectTypeSelect'
import ProjectWarmingMethodSelect from './ProjectWarmingMethodSelect'
import ProjectNewOrRenovationSelect from './ProjectNewOrRenovationSelect'
import ProjectStateSelect from './ProjectStateSelect'
import { deleteError, setError } from '../redux/slices/errors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import projectService from '../services/projectService'
import { ProjectErrors } from '../enums/ProjectErrors'
import { useNavigate } from 'react-router-dom'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { countryList } from '../lists/countryList'
import { ProjectStateType } from '../enums/ProjectStateType'
import ProjectInput from '../types/ProjectInput'
import LoadingState from './LoadingState'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

interface ProjectFormProps {
  /**
   * Handler for the cancel action.
   */
  onCancel?: () => void
}

export default function ProjectForm({
  onCancel
}: ProjectFormProps): JSX.Element {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The project.
   */
  const project: Project | undefined = useProject()

  const searchParams = new URLSearchParams(window.location.search)

  const foundTimeLine = searchParams.get('timeline') ?? undefined

  const initialValues: ProjectInput =
    project === undefined
      ? {
          company: '',
          name: '',
          address: '',
          zip: '',
          city: '',
          countryCode: null,
          area: '',
          type: '',
          new: '',
          warmingMethod: '',
          state: ProjectStateType.PLANNED,
          hideCosts: false,
          fixedEnergyPrice: '',
          fixedEmissionFactor: '',
          lat: '',
          long: '',
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
          role: MembershipRole.ADMIN,
          customerReference: null,
          customData: {
            blueprintAdminOnly: false
          }
        }
      : {
          name: project.name,
          address: project.address,
          zip: project.zip,
          city: project.city,
          countryCode: project.countryCode,
          area: project.area,
          type: project.type,
          new: project.new,
          warmingMethod: project.warmingMethod,
          hideCosts: project.hideCosts,
          fixedEnergyPrice: project?.fixedEnergyPrice,
          fixedEmissionFactor: project?.fixedEmissionFactor,
          company: project?.companyId,
          lat: project.lat,
          long: project.long,
          state: project.state,
          startDate: format(new Date(project.startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(project.endDate), 'yyyy-MM-dd'),
          customerReference: project.customerReference,
          customData:
            Object.keys(project.customData).length === 0
              ? {
                  blueprintAdminOnly: false
                }
              : project.customData
        }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    area: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    new: Yup.string().required('Required'),
    warmingMethod: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required')
  })

  async function handleSubmit(
    values: any,
    { resetForm, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      if (values.fixedEnergyPrice === '') {
        values.fixedEnergyPrice = null
      }

      if (values.fixedEmissionFactor === '') {
        values.fixedEmissionFactor = null
      }

      if (project === undefined) {
        const createdProject = await projectService.createProject(values)

        dispatch(createProject(createdProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectCreateSuccess')
          )
        )
      } else {
        const updatedProject = await projectService.updateProject(
          project.id,
          values
        )

        dispatch(updateProject(updatedProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectUpdateSuccess')
          )
        )

        navigate(`/projects/${project.id}`)
      }

      resetForm()
      setStatus({ sent: true })
      dispatch(setShow(false))
    } catch (error: unknown) {
      dispatch(
        setError({
          type: project ? ProjectErrors.EDIT : ProjectErrors.CREATE,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: currentUser,
        type: project ? ProjectErrors.EDIT : ProjectErrors.CREATE,
        error: error
      })

      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      // TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(project ? ProjectErrors.EDIT : ProjectErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  /**
   * Handle the cancel action.
   */
  const handleCancel = () => {
    dispatch(setShow(false))

    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.role === UserRole.SUPERADMIN) {
      dispatch(getCompanies())
    }
  }, [currentUser])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        values,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              {foundTimeLine ? (
                <>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      {/* FIXME: Use a date picker */}
                      <TextField
                        type="date"
                        name="startDate"
                        label={t('Projects.startDate')}
                        value={values.startDate}
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* FIXME: Use a date picker */}
                      <TextField
                        name="endDate"
                        type="date"
                        label={t('Projects.endDate')}
                        value={values.endDate}
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Button
                          onClick={() => navigate(`/projects/${project?.id}`)}
                        >
                          {t('cancel')}
                        </Button>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t('save')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label={t('Projects.name')}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      fullWidth
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="address"
                          label={t('Projects.address')}
                          value={values.address}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <TextField
                          name="zip"
                          label={t('Projects.zip')}
                          value={values.zip}
                          error={Boolean(touched.zip && errors.zip)}
                          helperText={touched.zip && errors.zip}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <TextField
                          name="city"
                          label={t('Projects.city')}
                          value={values.city}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          key={values.countryCode}
                          defaultValue={values.countryCode}
                          options={countryList.map((option) => option.code)}
                          autoHighlight
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                alt=""
                              />
                              ({option})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('countryCode')}
                              inputProps={{
                                ...params.inputProps
                              }}
                            />
                          )}
                          onChange={(event: any, newValue: any) => {
                            values.countryCode = newValue
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <ProjectTypeSelect
                          name="type"
                          value={values.type}
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          name="area"
                          label={t('Projects.area')}
                          value={values.area}
                          error={Boolean(touched.area && errors.area)}
                          helperText={touched.area && errors.area}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <ProjectNewOrRenovationSelect
                          name="new"
                          value={values.new}
                          error={Boolean(touched.new && errors.new)}
                          helperText={touched.new && errors.new}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ProjectWarmingMethodSelect
                          name="warmingMethod"
                          value={values.warmingMethod}
                          error={Boolean(
                            touched.warmingMethod && errors.warmingMethod
                          )}
                          helperText={
                            touched.warmingMethod && errors.warmingMethod
                          }
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      {currentUser?.role === UserRole.SUPERADMIN ? (
                        <Grid item xs={12} md={6} lg={4}>
                          <CompanySelect
                            name="company"
                            label={t('Projects.builder')}
                            value={values.company}
                            error={Boolean(touched.company && errors.company)}
                            helperText={touched.company && errors.company}
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            name="company"
                            label={t('Projects.builder')}
                            value={currentUser?.company?.name}
                            key={(values.company = currentUser?.company?.id)}
                            disabled={true}
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          name="customerReference"
                          label={t('customerReference')}
                          value={values.customerReference}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <ProjectStateSelect
                          name="state"
                          value={values.state}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        {/* TODO: Use a date picker */}
                        <TextField
                          name="startDate"
                          type="date"
                          label={t('Projects.startDate')}
                          value={values.startDate}
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {/* TODO: Use a date picker */}
                        <TextField
                          name="endDate"
                          type="date"
                          label={t('Projects.endDate')}
                          value={values.endDate}
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="fixedEnergyPrice"
                          label={t('fixedEnergyPrice')}
                          value={values.fixedEnergyPrice}
                          error={Boolean(
                            touched.fixedEnergyPrice && errors.fixedEnergyPrice
                          )}
                          helperText={t('fixedEnergyPriceHelperText')}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          name="fixedEmissionFactor"
                          label={t('fixedEmissionFactor')}
                          value={values.fixedEmissionFactor}
                          error={Boolean(
                            touched.fixedEmissionFactor &&
                              errors.fixedEmissionFactor
                          )}
                          helperText={t('fixedEmissionFactorHelperText')}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="hideCosts"
                                checked={values.hideCosts}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            }
                            label={t('hideCosts') as string}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                name="customData.blueprintAdminOnly"
                                checked={values?.customData?.blueprintAdminOnly}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            }
                            label={t('showBlueprintsFormAdminsOnly') as string}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={3}
                        >
                          <Button onClick={handleCancel}>{t('cancel')}</Button>

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            {t('save')}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
