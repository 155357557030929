import React from 'react'
import OrderForm from '../../components/manufacturing/OrderForm'
import ManufacturingHeader from '../../components/manufacturing/ManufacturingPageHeader'

export default function OrderCreate() {
  return (
    <React.Fragment>
      <ManufacturingHeader />
      <OrderForm />
    </React.Fragment>
  )
}
