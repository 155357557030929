import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { spacing } from '@mui/system'
import {
  Box,
  Button,
  Divider as MuiDivider,
  Stack,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Divider = styled(MuiDivider)(spacing)

export interface PageTitleProps {
  children: string | JSX.Element
}

export function PageTitle({ children }: PageTitleProps) {
  return (
    <Typography
      variant="h3"
      display="inline"
      sx={{
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </Typography>
  )
}

export interface PageHeaderProps {
  title: string
  heading?: string | JSX.Element
  breadcrumbs?: JSX.Element
  action?: JSX.Element
  hideBackButton?: boolean
  backButton?: JSX.Element
}

export default function PageHeader({
  title,
  heading,
  breadcrumbs,
  action,
  hideBackButton = false,
  backButton
}: PageHeaderProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigation function.
   */
  const navigate = useNavigate()

  return (
    <>
      <Helmet title={title} />

      {!hideBackButton && (
        <>
          {backButton ? (
            <>{backButton}</>
          ) : (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => navigate(-1)}
            >
              {t('back')}
            </Button>
          )}

          <Divider my={6} />
        </>
      )}

      <Stack direction="column">
        {breadcrumbs && <Box mb={6}>{breadcrumbs}</Box>}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={0}
        >
          {heading && typeof heading !== 'string' ? (
            <>{heading}</>
          ) : (
            <PageTitle>{heading ?? title}</PageTitle>
          )}

          {action && <Box>{action}</Box>}
        </Stack>
      </Stack>

      <Divider mt={6} />
    </>
  )
}
