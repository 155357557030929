import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Skeleton,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'

// Hooks
import useAuth from '../../hooks/useAuth'

// Components
import SensorAggregateTable from '../../components/SensorAggregateTable'
import SensorAggregateChart from '../../components/SensorAggregateChart'
import SensorAggregateFilters from '../../components/SensorAggregateFilters'
import LoadingState from '../../components/LoadingState'

// Types
import Sensor from '../../types/Sensor'
import SensorAggregate from '../../types/SensorAggregate'
import SensorAggregateOptions from '../../types/SensorAggregateOptions'
import SensorAggregateQuery from '../../types/SensorAggregateQuery'

// Enums
import SensorAggregateField from '../../enums/SensorAggregateField'
import SensorAggregateStatistic from '../../enums/SensorAggregateStatistic'

// Services
import { resolveSensorAggregateInterval } from '../../utils/sensorAggregate'
import {
  getDevice,
  getSensorAggregates
} from '../../services/sensorAggregateService'
import { getSensors } from '../../services/deviceService'

// Utilities
import { getDifferenceOfDateRangeInHours } from '../../utils/date'
import SensorTable from '../../components/SensorTable'
import { Device } from '../../types'
import useAppSettings from '../../hooks/useAppSettings'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import PageHeader from '../../components/PageHeader'
import DeviceHeading from './DeviceHeading'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

export default function DeviceStats() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The ID parameter.
   */
  const { id } = useParams()

  /**
   * The date range.
   */
  const { dateRange } = useSelector((state: RootState) => state.query)

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  /**
   * The device.
   */
  const [device, setDevice] = useState<Device>()

  /**
   * The sensors.
   */
  const [sensors, setSensors] = useState<Sensor[]>([])

  /**
   * The sensor aggregates.
   */
  const [sensorAggregates, setSensorAggregates] = useState<SensorAggregate[]>(
    []
  )

  /**
   * The sensor aggregate query.
   */
  const [sensorAggregateQuery, setSensorAggregateQuery] =
    useState<SensorAggregateQuery>(getInitialSensorAggregateQuery())

  const [isLoadingDevice, setIsLoadingDevice] = useState<boolean>(true)

  const [isLoadingSensors, setIsLoadingSensors] = useState<boolean>(false)

  const [isLoadingSensorAggregates, setIsLoadingSensorAggregates] =
    useState<boolean>(false)

  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  /**
   * Indicates if sensor aggregates are being used.
   */
  const isUsingSensorAggregates = differenceOfDateRangeInHours > 3

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const dispatch = useDispatch()

  function getInitialSensorAggregateQuery(): SensorAggregateQuery {
    return {
      interval: resolveSensorAggregateInterval(dateRange),
      from: dateRange.from,
      to: dateRange.to
    }
  }

  const [sensorAggregateOptions, setSensorAggregateOptions] =
    useState<SensorAggregateOptions>({
      statistics: getInitialStatistics(),
      fields: getInitialFields()
    })

  function getInitialStatistics(): SensorAggregateStatistic[] {
    const sensorSettings = appSettings.sensorSettings
    const searchParams = new URLSearchParams(window.location.search)
    const foundStatics = searchParams.get('statistics') ?? undefined
    if (foundStatics) {
      return JSON.parse(foundStatics)
    } else {
      searchParams.set(
        'statistics',
        JSON.stringify(
          sensorSettings?.sensorAggregateChartStatistics
            ? sensorSettings.sensorAggregateChartStatistics
            : [SensorAggregateStatistic.AVG]
        )
      )
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)

      return sensorSettings?.sensorAggregateChartStatistics
        ? sensorSettings.sensorAggregateChartStatistics
        : [SensorAggregateStatistic.AVG]
    }
  }

  function getInitialFields(): SensorAggregateField[] {
    const sensorSettings = appSettings.sensorSettings
    const searchParams = new URLSearchParams(window.location.search)
    const foundFields = searchParams.get('fields') ?? undefined
    if (foundFields) {
      return JSON.parse(foundFields)
    } else {
      searchParams.set(
        'fields',
        JSON.stringify(
          sensorSettings?.sensorAggregateChartFields
            ? sensorSettings.sensorAggregateChartFields
            : [
                SensorAggregateField.CURRENT_1,
                SensorAggregateField.CURRENT_2,
                SensorAggregateField.CURRENT_3
              ]
        )
      )
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
      return sensorSettings?.sensorAggregateChartFields
        ? sensorSettings.sensorAggregateChartFields
        : [
            SensorAggregateField.CURRENT_1,
            SensorAggregateField.CURRENT_2,
            SensorAggregateField.CURRENT_3
          ]
    }
  }

  async function loadDevice(id: string) {
    try {
      setIsLoadingDevice(true)
      setDevice(await getDevice(id))
    } finally {
      setIsLoadingDevice(false)
    }
  }

  async function loadSensors(deviceCode: string) {
    try {
      setIsLoadingSensors(true)
      setSensors(
        await getSensors(deviceCode, {
          from: dateRange.from,
          to: dateRange.to
        })
      )
    } finally {
      setIsLoadingSensors(false)
    }
  }

  async function loadSensorAggregates(deviceCode: string) {
    try {
      setIsLoadingSensorAggregates(true)
      setSensorAggregates(
        await getSensorAggregates(deviceCode, {
          interval: resolveSensorAggregateInterval(dateRange),
          from: dateRange.from,
          to: dateRange.to
        })
      )
    } finally {
      setIsFirstRender(false)
      setIsLoadingSensorAggregates(false)
    }
  }

  useEffect(() => {
    if (currentUser && id) {
      loadDevice(id)
      // setSensorAggregateQuery(getInitialSensorAggregateQuery)
    }
  }, [currentUser, id])

  useEffect(() => {
    if (device) {
      if (!isFirstRender) {
        setSensorAggregateQuery({
          interval: resolveSensorAggregateInterval(dateRange),
          from: dateRange.from,
          to: dateRange.to
        })
      }

      if (isUsingSensorAggregates) {
        loadSensorAggregates(device.name)
        setSensors([])
      } else {
        loadSensors(device.name)
        setSensorAggregates([])
      }
    }
  }, [device, dateRange])

  return (
    <>
      {isLoadingDevice || device === undefined ? (
        <LoadingState />
      ) : (
        <>
          <PageHeader
            title={device?.asset?.name ?? device.name}
            heading={<DeviceHeading device={device} />}
            breadcrumbs={
              <Breadcrumbs>
                <Link component={NavLink} to="/" title={t('frontpage')}>
                  {t('frontpage')}
                </Link>

                {device?.asset?.project ? (
                  <Link
                    component={NavLink}
                    to="/projects"
                    title={t('projects')}
                  >
                    {t('projects')}
                  </Link>
                ) : (
                  device && (
                    <Link
                      component={NavLink}
                      to="/devices"
                      title={t('devices')}
                    >
                      {t('devices')}
                    </Link>
                  )
                )}

                {device?.asset?.project && (
                  <Link
                    component={NavLink}
                    to={`/projects/${device?.asset?.project?.id}`}
                    title={device?.asset?.project?.name}
                  >
                    {device?.asset?.project?.name}
                  </Link>
                )}

                {device?.asset?.project && (
                  <Link
                    component={NavLink}
                    to={`/projects/${device?.asset?.project?.id}#monitoring`}
                    title={t('deviceMonitoring')}
                  >
                    {t('deviceMonitoring')}
                  </Link>
                )}

                {device ? (
                  <Link
                    component={NavLink}
                    to={`/devices/${device?.name}`}
                    title={device?.asset?.name ?? device.name}
                  >
                    {device?.asset?.name ?? device.name}
                  </Link>
                ) : (
                  <Skeleton variant="text" width={100} />
                )}

                <Typography>{t('stats')}</Typography>
              </Breadcrumbs>
            }
          />

          <Grid container spacing={6} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <SensorAggregateFilters
                query={sensorAggregateQuery}
                options={sensorAggregateOptions}
                onQueryChange={setSensorAggregateQuery}
                onOptionsChange={setSensorAggregateOptions}
              />
            </Grid>
          </Grid>

          {isLoadingSensors || isLoadingSensorAggregates ? (
            <LoadingState />
          ) : (
            <>
              <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
                <Grid item xs={12}>
                  <SensorAggregateChart
                    dateRange={dateRange}
                    sensors={sensors}
                    sensorAggregates={sensorAggregates}
                    options={sensorAggregateOptions}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                  {isUsingSensorAggregates ? (
                    <SensorAggregateTable
                      device={device}
                      sensorAggregates={sensorAggregates}
                      query={sensorAggregateQuery}
                      options={sensorAggregateOptions}
                    />
                  ) : (
                    <SensorTable
                      sensors={sensors}
                      query={sensorAggregateQuery}
                      options={sensorAggregateOptions}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}
