import React, { useEffect, useState } from 'react'
import { Grid, Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getBlueprintService } from '../../services/uploadService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { EmailErrors } from '../../enums/EmailErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { deleteError, setError } from '../../redux/slices/errors'
import { UploadErrors } from '../../enums/UploadErrors'
import { NotificationType } from '../../enums/NotificationType'
import { useAuth } from '../../hooks'
import { BlueprintCanvas } from '../../components/BlueprintCanvas'
import { PowerSupply, RuuviTag } from '../../types'
import { UploadWithLink } from '../../types/Upload'
import { getProjectMembership } from '../../redux/slices/projectMemberships'
import { getRuuviTags } from '../../services/ruuviTagService'
import LoadingState from '../../components/LoadingState'
import { getPowerSuppliesByProjectId } from '../../services/projectService'

export default function Blueprint() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The route params.
   */
  const { projectId, levelId } = useParams()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the page is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The blueprint of the project.
   */
  const [blueprint, setBlueprint] = useState<UploadWithLink>()

  /**
   * The power supplies of the project.
   */
  const [powerSupplies, setPowerSupplies] = useState<PowerSupply[]>([])

  /**
   * The power supplies of the level.
   */
  const powerSuppliesOfLevel =
    levelId !== 'area'
      ? powerSupplies?.filter(
          (powerSupply) =>
            powerSupply?.device?.asset?.levelId === parseInt(levelId as string)
        )
      : powerSupplies?.filter(
          (powerSupply) => powerSupply?.device?.asset?.levelId === null
        )

  /**
   * The ruuvi tags of the project.
   */
  const [ruuviTags, setRuuviTags] = useState<RuuviTag[]>([])

  /**
   * The ruuvi tags of the level.
   */
  const ruuviTagsOfLevel =
    levelId !== 'area'
      ? ruuviTags?.filter(
          (ruuviTag) => ruuviTag.asset?.levelId === parseInt(levelId as string)
        )
      : ruuviTags?.filter((ruuviTag) => ruuviTag.asset?.levelId === null)

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)

      dispatch(
        getProjectMembership({
          memberId: parseInt(currentUser?.id),
          projectId: parseInt(projectId as string)
        })
      )

      await Promise.all([loadBlueprint(), loadPowerSupplies(), loadRuuviTags()])
    } catch (error: unknown) {
      dispatch(
        setError({
          type: UploadErrors.GET,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.GET,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(EmailErrors.SEND))
    } finally {
      setIsLoading(false)
    }
  }

  async function loadBlueprint(): Promise<void> {
    if (levelId !== 'area') {
      setBlueprint(
        await getBlueprintService({
          project: parseInt(projectId as string),
          level: parseInt(levelId as string)
        })
      )
    } else {
      setBlueprint(
        await getBlueprintService({
          project: parseInt(projectId as string)
        })
      )
    }
  }

  async function loadPowerSupplies(): Promise<void> {
    setPowerSupplies(
      await getPowerSuppliesByProjectId(parseInt(projectId as string))
    )
  }

  async function loadRuuviTags(): Promise<void> {
    setRuuviTags(
      await getRuuviTags({ projectId: parseInt(projectId as string) })
    )
  }

  useEffect(() => {
    const controller = new AbortController()
    if (currentUser) {
      boot(controller.signal)
    }
    return () => controller.abort()
  }, [currentUser])

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {blueprint === undefined || isLoading ? (
            <LoadingState />
          ) : (
            <BlueprintCanvas
              upload={blueprint}
              powerSupplies={powerSuppliesOfLevel}
              ruuviTags={ruuviTagsOfLevel}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
