import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button as MuiButton,
  Table,
  TableRow,
  TableCell,
  Card,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Skeleton
} from '@mui/material'
import { spacing } from '@mui/system'
import { RootState } from '../../redux/store'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import LoadingState from '../../components/LoadingState'
import { getDevice } from '../../redux/slices/devices'
import DeviceMap from '../../components/DeviceMap'
import powerSupplyService from '../../services/powerSupplyService'
import { updatePowerSupply } from '../../redux/slices/powerSupplies'
import PageHeader from '../../components/PageHeader'
import { UserRole } from '../../enums/UserRole'
import { ProductType } from '../../enums/ProductType'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { setStep } from '../../redux/slices/steps'
import { RequestStatus } from '../../enums/RequestStatus'
import { ActionRequest } from '../../types'
import { updateRequestState } from '../../services/requestService'
import { deleteDeviceLocationChangeRequest } from '../../redux/slices/requests'
import { createUserNotification } from '../../utils/createUserNotification'
import { PowerSupplyErrors } from '../../enums/PowerSupplyError'
import { ActionRequestType } from '../../enums/RequestAccessType'
import { RequesterType } from '../../enums/RequesterType'
import { getActionRequestsByProject } from '../../services/projectService'

const Button = styled(MuiButton)(spacing)

export default function DeviceLocation() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { device, loading } = useSelector((state: RootState) => state.devices)

  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  const [deviceRequests, setDeviceRequests] = useState<ActionRequest[]>()
  const [isLoadingRequests, setIsLoadingRequests] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getDevice(id))
    }
  }, [currentUser, id])

  useEffect(() => {
    if (device?.asset?.project) {
      loadRequests()
    }
  }, [device])

  async function loadRequests() {
    try {
      setIsLoadingRequests(true)
      setDeviceRequests(
        //@ts-ignore
        await getActionRequestsByProject(device.asset.project.id, {
          requestableType: ActionRequestType.PROJECT,
          requesterType: RequesterType.DEVICE,
          requesterId: device?.name
        })
      )
    } finally {
      setIsLoadingRequests(false)
    }
  }

  let values: any = {
    name: device?.powerSupply?.name ?? '',
    fuse: device?.powerSupply?.fuse ?? '',
    inputCables: device?.powerSupply?.inputCables ?? 0,
    voltage_1_tp: device?.powerSupply?.voltage_1_tp ?? 0,
    voltage_1_ts: device?.powerSupply?.voltage_1_ts ?? 0,
    isMainDistributionAssembly:
      device?.powerSupply?.isMainDistributionAssembly ?? false,
    project: device?.asset?.project?.id ?? '',
    parent: device?.powerSupply?.parent?.id ?? null,
    level: device?.asset?.level?.id ?? null,
    lift: device?.asset?.lift?.id ?? null,
    transformCurrent: device?.powerSupply?.transformCurrent ?? false,
    customerReference: device?.powerSupply?.customerReference ?? null,
    current_1_offset: device?.powerSupply?.current_1_offset ?? 0,
    current_2_offset: device?.powerSupply?.current_2_offset ?? 0,
    current_3_offset: device?.powerSupply?.current_3_offset ?? 0,
    voltage_1_offset: device?.powerSupply?.voltage_1_offset ?? 0,
    voltage_2_offset: device?.powerSupply?.voltage_2_offset ?? 0,
    voltage_3_offset: device?.powerSupply?.voltage_3_offset ?? 0
  }
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const isProduct = device?.product !== null

  async function handleDetachDeviceFromProject() {
    try {
      // @ts-ignore
      values = {
        ...values,
        project: null,
        lift: null,
        level: null,
        fuse:
          isProduct && device?.product?.type === ProductType.PLUG_AND_PLAY
            ? 0
            : device?.powerSupply?.fuse
      }
      setIsSubmitting(true)
      const updatedPowerSupply = await powerSupplyService.updatePowerSupply(
        device?.powerSupply?.id as number,
        values
      )
      dispatch(updatePowerSupply(updatedPowerSupply))
      if (deviceRequests && deviceRequests.length > 0) {
        await handleRequestsStatusUpdates(RequestStatus.APPROVED)
      }

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('deviceDetachedFromTheProjectSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: PowerSupplyErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage?.key) + t(errorMessage?.message)
        )
      )
    } finally {
      setIsSubmitting(false)
      navigate(`/devices/${device?.name}`)
    }
  }

  async function handleRequestsStatusUpdates(status: RequestStatus) {
    //@ts-ignore
    return deviceRequests.map(async (request: RequestAccess) => {
      const updatedRequest = await updateRequestState(request.id, {
        status: status
      })
      dispatch(deleteDeviceLocationChangeRequest(updatedRequest.id))
    })
  }

  function handleNavigationToInstallation() {
    if (device && device?.powerSupply && device?.asset?.project) {
      dispatch(setStep(1))
      navigate(`/installation/project/${device?.asset?.project.id}`)
    }
  }

  const title = device?.asset?.name
    ? device.asset.name
    : device
    ? device.name
    : ''

  return (
    <React.Fragment>
      <PageHeader
        title={title}
        breadcrumbs={
          <Breadcrumbs>
            {device?.asset?.project && (
              <NavLink to="/projects">{t('projects')}</NavLink>
            )}

            {device?.asset?.project && (
              <NavLink to={`/projects/${device?.asset?.project.id}`}>
                {device?.asset?.project.name}
              </NavLink>
            )}

            {device?.asset?.project && (
              <NavLink to={`/projects/${device?.asset?.project.id}#monitoring`}>
                {device?.asset?.project.name}
              </NavLink>
            )}

            {device && (
              <NavLink to={`/devices/${device?.name}`}>{device?.name}</NavLink>
            )}
          </Breadcrumbs>
        }
      />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading || isSubmitting || isLoadingRequests ? (
            <LoadingState />
          ) : (
            <Grid item xs={12}>
              {device && (
                <React.Fragment>
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t('confirmDeviceDetachTitle')}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t('confirmDeviceDetachText')}
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={() => handleDetachDeviceFromProject()}>
                        {t('yes')}
                      </Button>
                      <Button onClick={() => setOpen(false)} autoFocus>
                        {t('no')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Card>
                    <Table>
                      <TableRow>
                        <TableCell>{t('name')}</TableCell>
                        <TableCell>{t('project')}</TableCell>
                        <TableCell>{t('owner')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {device?.powerSupply?.name} ({device.shortUUID})
                        </TableCell>
                        <TableCell>
                          {device.asset?.project
                            ? device.asset?.project.name
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {device.asset?.company?.name ?? '-'}
                        </TableCell>
                      </TableRow>
                    </Table>
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <Typography align="left" sx={{ mt: 4, ml: 4, mb: 4 }}>
                          <Button
                            color="warning"
                            variant="contained"
                            onClick={() => setOpen(true)}
                          >
                            {t('detachFromProject')} <WarningAmberIcon />
                          </Button>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography align="right" sx={{ mt: 4, mr: 4, mb: 4 }}>
                          <Button
                            variant="contained"
                            onClick={() => handleNavigationToInstallation()}
                          >
                            {t('moveDevice')} <ArrowForwardIcon />
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </React.Fragment>
              )}

              {device && device?.asset?.project && (
                <DeviceMap device={device} project={device?.asset.project} />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
