import React, { useEffect, useState, MouseEvent } from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardProps,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Link,
  TableContainer,
  Tab,
  Breadcrumbs,
  Skeleton,
  Stack,
  Menu,
  IconButton,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListProps,
  ListItem,
  ListItemButton
} from '@mui/material'
import { spacing, SpacingProps } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { deleteUser, getUser, setShow } from '../../redux/slices/users'
import useAuth from '../../hooks/useAuth'
import {
  Delete,
  Edit,
  Lock,
  MoreVert,
  Password,
  Shield,
  ShieldMoon
} from '@mui/icons-material'
import UserForm from '../../components/UserForm'
import ProjectTable from '../../components/ProjectTable'
import { THEMES } from '../../constants'
import { scrollbarForDark, scrollbarForLight } from '../../theme/scrollbar'
import useTheme from '../../hooks/useTheme'
import { useTheme as useMuiTheme } from '@mui/system'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { ProfileTab } from '../../enums/ProfileTabs'
import ProfileAlertManagementTable from '../../components/ProfileAlertManagementTable'
import { Company, Project, User } from '../../types'
import { UserErrors } from '../../enums/UserErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import ProfileRequestManagementTable from '../../components/ProfileRequestManagementTable'
import { NavLink } from 'react-router-dom'
import { MFAType } from '../../enums'
import { UserRole } from '../../enums/UserRole'
import { setActiveProject } from '../../redux/slices/projects'
import PageHeader from '../../components/PageHeader'
import CompanyDetails from '../../components/CompanyDetails'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Grid = styled(MuiGrid)(spacing)

interface TypographyProps extends SpacingProps {
  component?: string
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing)

interface CompanyCardProps extends Omit<CardProps, 'children'> {
  company: Company
}

function CompanyCard({ company, ...props }: CompanyCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Card {...props}>
      <CardHeader title={t('company')} />
      <CardContent>
        {company && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('address')}</TableCell>
                <TableCell>{t('city')}</TableCell>
                <TableCell>{t('zip')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {company.name}
                </TableCell>
                <TableCell>{company.address}</TableCell>
                <TableCell>{company.city}</TableCell>
                <TableCell>{company.zip}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

interface ProjectsCardProps extends Omit<CardProps, 'children'> {
  projects: Project[]
}

function ProjectsCard({ projects, ...props }: ProjectsCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Card {...props}>
      <CardHeader title={t('projects')} />
      <CardContent>
        <ProjectTable projects={projects} />
      </CardContent>
    </Card>
  )
}

interface UserCardProps extends Omit<CardProps, 'children'> {
  user: User
}

function UserCard({ user, ...props }: UserCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the user is shown.
   */
  const show = useSelector((state: RootState) => state.users.show)

  /**
   * Is superadmin.
   */
  const isSuperadmin = currentUser?.role === UserRole.SUPERADMIN

  /**
   * The theme.
   */
  const { theme } = useTheme()

  const [open, setOpen] = useState(false)

  const handleEdit = (id: number) => {
    dispatch(getUser(id))
    dispatch(setShow(true))
  }

  const handleDelete = (id: number) => {
    try {
      dispatch(deleteUser(id))
      navigate('/auth/sign-in')
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  return (
    <>
      <Card {...props}>
        <CardHeader
          title={t('userAccount')}
          action={
            <>
              {(isSuperadmin || currentUser?.id === user.id) && (
                <>
                  {show ? (
                    <Button
                      variant="outlined"
                      onClick={() => dispatch(setShow(false))}
                    >
                      {t('cancel')}
                    </Button>
                  ) : (
                    <UserMenu user={user} />
                  )}
                </>
              )}
            </>
          }
        />
        <CardContent>
          <UserDetailsList user={user} />
        </CardContent>
      </Card>

      <Dialog open={show} onClose={() => dispatch(setShow(false))}>
        <DialogTitle>{t('editUserAccount')}</DialogTitle>
        <DialogContent>
          <UserForm />
        </DialogContent>
      </Dialog>
    </>
  )
}

interface UserMenuProps {
  user: User
}

function UserMenu({ user }: UserMenuProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The theme.
   */
  const theme = useMuiTheme()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The anchor element of the menu.
   */
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>()

  /**
   * Indicates if the delete dialog is open.
   */
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  /**
   * Indicates if the delete is in progress.
   */
  const [isDeleting, setIsDeleting] = useState(false)

  /**
   * Open the delete dialog.
   */
  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  /**
   * Close the delete dialog.
   */
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  /**
   * Indicates if the menu is open.
   */
  const isMenuOpen = Boolean(menuAnchorEl)

  /**
   * Open the menu.
   */
  const openMenu = (even: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(even.currentTarget)
  }

  /**
   * Close the menu.
   */
  const closeMenu = () => {
    setMenuAnchorEl(undefined)
  }

  /**
   * Toggle the menu.
   */
  const toggleMenu = (event: MouseEvent<HTMLElement>) => {
    isMenuOpen ? closeMenu() : openMenu(event)
  }

  /**
   * Handle the edit action.
   */
  const handleEdit = () => {
    dispatch(getUser(user.id))
    dispatch(setShow(true))
  }

  /**
   * Handle the delete action.
   */
  const handleDelete = () => {
    try {
      setIsDeleting(true)
      dispatch(deleteUser(user.id))
      navigate('/auth/sign-in')
    } catch (error: unknown) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.DELETE,
        error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      <IconButton onClick={toggleMenu}>
        <MoreVert />
      </IconButton>

      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: { width: '280px' }
        }}
      >
        <MenuList>
          <MenuItem onClick={handleEdit}>
            <ListItemText primary={t('editUserAccount')} />
            <ListItemSecondaryAction>
              <Edit color="action" />
            </ListItemSecondaryAction>
          </MenuItem>

          <MenuItem component={NavLink} to="/auth/change-password">
            <ListItemText primary={t('Profile.change')} />
            <ListItemSecondaryAction>
              <Lock color="action" />
            </ListItemSecondaryAction>
          </MenuItem>

          <MenuItem component={NavLink} to="/mfa">
            <ListItemText primary={t('mfa')} />
            <ListItemSecondaryAction>
              <ShieldMoon color="action" />
            </ListItemSecondaryAction>
          </MenuItem>

          <Divider />

          <>
            <MenuItem onClick={() => openDeleteDialog()}>
              <ListItemText primary={t('deleteUserAccount')} />
              <ListItemSecondaryAction>
                <Delete color="error" />
              </ListItemSecondaryAction>
            </MenuItem>

            <Dialog
              open={isDeleteDialogOpen}
              onClose={() => closeDeleteDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('Profile.confirm')}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('Profile.warning')}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleDelete}>{t('Profile.yes')}</Button>
                <Button onClick={() => closeDeleteDialog}>
                  {t('Profile.no')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </MenuList>
      </Menu>
    </>
  )
}

interface UserDetailsListProps extends Omit<ListProps, 'children'> {
  user: User
}

function UserDetailsList({
  user,
  ...props
}: UserDetailsListProps): JSX.Element {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  return (
    <List {...props}>
      <ListItemButton>
        <ListItemText
          secondary={t('Users.name')}
          primary={`${user.firstName} ${user.lastName}`}
        />
      </ListItemButton>

      <ListItemButton>
        <ListItemText secondary={t('Users.email')} primary={user.email} />
      </ListItemButton>

      <ListItemButton>
        <ListItemText secondary={t('Users.phone')} primary={user.phone} />
      </ListItemButton>

      <ListItemButton>
        <ListItemText
          secondary={t('mfa')}
          primary={
            <>
              {user.mfaType === MFAType.NONE ? (
                <Chip
                  variant="outlined"
                  color="error"
                  size="small"
                  label={t('no')}
                />
              ) : (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={t('yes')}
                />
              )}
            </>
          }
        />
      </ListItemButton>

      {user?.company && (
        <ListItemButton>
          <ListItemText
            secondary={t('Users.company')}
            primary={
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                component={NavLink}
                to={`/companies/${user.company.id}`}
                label={user.company.name}
                clickable
              />
            }
          />
        </ListItemButton>
      )}

      <ListItemButton>
        <ListItemText
          secondary={t('Users.role')}
          primary={<Chip variant="outlined" size="small" label={user.role} />}
        />
      </ListItemButton>
    </List>
  )
}

export default function Profile(): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The route params.
   */
  const { id } = useParams()

  /**
   * The user state.
   */
  const { user } = useSelector((state: RootState) => state.users)

  /**
   * The projects of user.
   */
  const projects = (user?.projects ?? [])
    .map(({ project }) => project)
    .filter((project) => !!project)

  const handleChange = (event: React.SyntheticEvent, tab: string): void => {
    setTab(tab as ProfileTab)
  }

  const [tab, setTab] = useState<ProfileTab>(getInitialTab())

  function getInitialTab(): ProfileTab {
    const hash: string = location.hash.substring(1).split('?')[0]

    switch (hash) {
      case ProfileTab.ALERT_MANAGEMENT:
        return ProfileTab.ALERT_MANAGEMENT
      case ProfileTab.REQUEST_MANAGEMENT:
        return ProfileTab.REQUEST_MANAGEMENT
      default:
        return ProfileTab.DASHBOARD
    }
  }

  function canViewCompany(): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      currentUser?.email === user?.email
    )
  }

  function canViewProjects(): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      currentUser?.email === user?.email
    )
  }

  useEffect(() => {
    if (id) {
      dispatch(getUser(parseInt(id)))
    }
  }, [id])

  return (
    <React.Fragment>
      <PageHeader
        title={user ? `${user.firstName} ${user.lastName}` : t('loading')}
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>

            <Link component={NavLink} to="/users">
              {t('users')}
            </Link>

            {user ? (
              <Typography>
                {user ? `${user.firstName} ${user.lastName}` : ''}
              </Typography>
            ) : (
              <Skeleton variant="text" width="120px" />
            )}
          </Breadcrumbs>
        }
      />

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={handleChange}
            >
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#dashboard')
                }
                label={t('accountInformation')}
                value={ProfileTab.DASHBOARD as string}
              />

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#alertmanagement')
                }
                label={t('alertManagement')}
                value={ProfileTab.ALERT_MANAGEMENT as string}
              />
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#requestmanagement')
                }
                label={t('requests')}
                value={ProfileTab.REQUEST_MANAGEMENT as string}
              />
            </TabList>
          </Box>

          <Box mt={6}>
            <TabPanel value={ProfileTab.DASHBOARD as string} sx={{ p: 0 }}>
              {user && (
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <UserCard user={user} />

                    {user?.company && canViewCompany() && (
                      <CompanyDetails company={user.company} sx={{ mt: 6 }} />
                    )}

                    {canViewProjects() && (
                      <ProjectsCard projects={projects} sx={{ mt: 6 }} />
                    )}
                  </Grid>
                </Grid>
              )}
            </TabPanel>

            <TabPanel value={ProfileTab.ALERT_MANAGEMENT as string}>
              <ProfileAlertManagementTable />
            </TabPanel>

            <TabPanel value={ProfileTab.REQUEST_MANAGEMENT as string}>
              <ProfileRequestManagementTable />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </React.Fragment>
  )
}
