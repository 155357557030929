import axios from 'axios'
import { CurrentUser, MFAResetLinkInput } from '../types'
import { setSession } from '../utils/jwt'

interface PassworInput {
  newPassword: string
  oldPassword?: string
}

interface EmailInput {
  email: string
}

interface LoginInput {
  email: string
  password: string
  token?: string
}

const api = axios.create({
  baseURL:
    process.env?.NODE_ENV === 'production'
      ? process.env?.REACT_APP_PROD_API_URL
      : process.env?.REACT_APP_DEV_API_URL
})

api.interceptors.request.use(
  (config) => {
    const accessToken: string | null =
      window.localStorage.getItem('accessToken')

    const temporaryToken: string | null =
      window.localStorage.getItem('temporaryToken')

    if (accessToken || temporaryToken) {
      // @ts-ignore
      config.headers['Authorization'] = `Bearer ${
        accessToken ?? temporaryToken
      }`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

//TODO: LoginInput type comes with mfa branch
export async function login(input: LoginInput): Promise<CurrentUser> {
  const response = await api.post('/login', input)

  return response.data
}

export async function sendPasswordResetEmail(
  input: EmailInput
): Promise<unknown> {
  const response = await api.post('/email', input)

  return response.data
}

export async function resetPassword(
  input: PassworInput,
  token: string
): Promise<unknown> {
  const response = await api.post(`/email/password_reset/${token}`, input)

  return response.data
}

//move this to users service?
export async function changePassword(
  id: number,
  input: PassworInput
): Promise<unknown> {
  const response = await api.put(`/users/change_password/${id}`, input)

  return response.data
}

export async function handleRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken')

  try {
    const response = await api.post('/login/refresh-token', {
      refreshToken
    })

    const newTokens = response.data

    if (!newTokens) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    }

    return setSession(newTokens.accessToken, newTokens.refreshToken)
  } catch (error) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export async function sendEmailVerificationMail(): Promise<void> {
  const response = await api.post('/email/verify/user')

  return response.data
}

export async function verifyUserEmailService(token: string): Promise<unknown> {
  const response = await api.post(`/email/verify/${token}`)

  return response.data
}

export async function sendMfaResetLink(
  input: MFAResetLinkInput
): Promise<void> {
  const response = await api.post(`/email/send-mfa-reset-link`, input)

  return response.data
}

export async function resetUserMfa(token: string): Promise<void> {
  const response = await api.post(`/email/reset-mfa/${token}`)

  return response.data
}

export default {
  login,
  // sendEmail,
  resetPassword,
  changePassword,
  sendEmailVerificationMail,
  sendMfaResetLink,
  verifyUserEmailService,
  resetUserMfa,
  sendPasswordResetEmail
}
