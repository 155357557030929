import { useEffect, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  IconButton,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel
} from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import PowerSupplyCard from './PowerSupplyCard'
import { blue } from '@mui/material/colors'
import { Add, ConstructionOutlined } from '@mui/icons-material'
import { StringSchema } from 'yup'
import { getLifts } from '../services/liftService'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useNavigate, useParams } from 'react-router-dom'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import { Blueprint } from '../types/Blueprint'
import { getBlueprintService } from '../services/uploadService'
import LoadingState from '../components/LoadingState'
import React from 'react'
import { ProjectTab } from '../pages/pages/Project'
import {
  ActiveProject,
  Level,
  Lift,
  PowerSupply,
  Project,
  Sector
} from '../types'
import { getLevelsByBlueprints } from '../services/projectService'
import { AlertGroup } from '../types/Alerts'

const TABLE_CELL_HEIGHT = '100%'
const powerLineSize = '8px'
const POWER_LINE_THICKNESS = '2px'

function PowerLineBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: powerLineSize,
        height: '100%'
      }}
    >
      {children}
    </Box>
  )
}

function OutputPowerLineBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: powerLineSize,
        height: powerLineSize
      }}
    >
      {children}
    </Box>
  )
}

function Electron({ animationDelay = '0s' }: { animationDelay?: string }) {
  const Fragment = styled.div`
    @keyframes animateUp {
      from {
        opacity: 1;
        bottom: 0;
      }
      to {
        bottom: ${powerLineSize};
        opacity: 0;
      }
    }

    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    height: ${POWER_LINE_THICKNESS};
    width: ${POWER_LINE_THICKNESS};
    border-radius: ${POWER_LINE_THICKNESS};
    background-color: ${blue[500]};
    animation-name: animateUp;
    animation-delay: ${animationDelay};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 4px 4px rgba(71, 130, 215, 1);
  `

  return <Fragment />
}

function HorizontalElectron({
  animationDelay = '0s',
  animationDirection = 'normal'
}: {
  animationDelay?: string
  animationDirection?: string
}) {
  const Fragment = styled.div`
    @keyframes animateLeft {
      from {
        right: 0;
        opacity: 1;
      }
      to {
        right: ${powerLineSize};
        opacity: 0;
      }
    }

    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: ${POWER_LINE_THICKNESS};
    width: ${POWER_LINE_THICKNESS};
    border-radius: ${POWER_LINE_THICKNESS};
    background-color: ${blue[500]};
    animation-name: animateLeft;
    animation-delay: ${animationDelay};
    animation-duration: 1s;
    animation-direction: ${animationDirection};
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 4px 4px rgba(71, 130, 215, 1);
  `

  return <Fragment />
}

interface PowerLineProps {
  color?: string
  useAnimation?: boolean
  animationDelay?: string
}

function VerticalLevelPowerLine({ color = blue['500'] }: PowerLineProps) {
  const PowerLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: ${POWER_LINE_THICKNESS};
    background-color: ${color};
  `

  return <PowerLine />
}

function HorizontalLevelPowerLine({ color = blue['500'] }: PowerLineProps) {
  const PowerLine = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${POWER_LINE_THICKNESS};
    width: ${powerLineSize};
    background-color: ${color};
  `

  return <PowerLine />
}

function VerticalInputOutputPowerLine({
  color = blue['500'],
  useAnimation = false,
  animationDelay = '0s'
}: PowerLineProps) {
  const PowerLine = styled.div`
    position: absolute;
    top: 0;
    left: '-1px';
    bottom: 0;
    display: block;
    height: '24px';
    width: ${POWER_LINE_THICKNESS};
    background-color: ${color};
  `

  return useAnimation ? (
    <PowerLine>
      <Electron animationDelay={animationDelay} />
    </PowerLine>
  ) : (
    <PowerLine />
  )
}

function HorizontalInputPowerLine({
  color = blue['500'],
  useAnimation = false,
  animationDelay = '0s'
}: PowerLineProps) {
  const PowerLine = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${POWER_LINE_THICKNESS};
    width: '24px';
    background-color: ${color};
  `

  return useAnimation ? (
    <PowerLine>
      <HorizontalElectron
        animationDirection="reverse"
        animationDelay={animationDelay}
      />
    </PowerLine>
  ) : (
    <PowerLine />
  )
}

function HorizontalOutputPowerLine({
  color = blue['500'],
  useAnimation = false,
  animationDelay = '0s'
}: PowerLineProps) {
  const PowerLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${POWER_LINE_THICKNESS};
    width: '24px';
    background-color: ${color};
  `

  return useAnimation ? (
    <PowerLine>
      <HorizontalElectron animationDelay={animationDelay} />
    </PowerLine>
  ) : (
    <PowerLine />
  )
}

function VerticalMainPowerLine({ color = blue['500'] }: PowerLineProps) {
  const PowerLine = styled.div`
    height: ${powerLineSize};
    width: ${POWER_LINE_THICKNESS};
    background-color: ${color};
  `

  return <PowerLine />
}

function HorizontalMainPowerLine({ color = blue['500'] }: PowerLineProps) {
  const PowerLine = styled.div`
    height: ${POWER_LINE_THICKNESS};
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: ${color};
  `

  return <PowerLine />
}

interface PowerSupplyHierarchyProps {
  height?: string
  project: Project | ActiveProject
  loading?: boolean
  userCanManageProject: boolean
  listOfLevelIds: number[]
  sectorIds: number[]
  areaUpload?: Blueprint
  alertRules: AlertGroup[]
}

const PowerSupplyHierarchy = memo(function ({
  height = '60vh',
  project,
  userCanManageProject,
  loading = false,
  listOfLevelIds,
  sectorIds,
  areaUpload,
  alertRules
}: PowerSupplyHierarchyProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false)

  const powerSupplies: PowerSupply[] = useSelector(
    (state: RootState) => state.powerSupplies.powerSupplies
  )

  const powerSuppliesMappedById: Map<number, PowerSupply> =
    mapPowerSuppliesById(powerSupplies)

  const levels: Level[] = useSelector((state: RootState) => state.levels.levels)

  const listOfLifts: Lift[] = useSelector(
    (state: RootState) => state.lifts.lifts
  )

  const lifts = listOfLifts.filter((lift) =>
    sectorIds.length === 0
      ? listOfLifts
      : sectorIds.indexOf(lift?.sector?.id as number) >= 0
  )

  const sectors: Sector[] = useSelector(
    (state: RootState) => state.sectors.sectors
  )

  const levelsFromTopToBottom = [...levels].sort(
    // @ts-ignore
    (a, b) => b.number - a.number
  )

  const levelsFromBottomToTop = [...levels].sort(
    // @ts-ignore
    (a, b) => a.number - b.number
  )

  const powerSuppliesOfUnknownArea = powerSupplies.filter(
    (powerSupply) => powerSupply?.device?.asset?.level === null
  )

  /**
   * The styles of the card component.
   */
  const cardStyle = isFullScreenMode
    ? {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: 0,
        zIndex: 2147483647
      }
    : {}

  // FIXME: src/utils/powerSupply.ts
  function mapPowerSuppliesById(
    powerSupplies: PowerSupply[]
  ): Map<number, PowerSupply> {
    const powerSuppliesMappedById: Map<number, PowerSupply> = new Map()

    powerSupplies.forEach((powerSupply) => {
      powerSuppliesMappedById.set(powerSupply.id, powerSupply)
    })

    return powerSuppliesMappedById
  }

  /**
   * Get the power supplies that locate at the lift.
   */
  function getPowerSuppliesOfLift(lift: Lift): PowerSupply[] {
    return powerSupplies.filter(
      (powerSupply) => powerSupply?.device?.asset?.lift?.id === lift.id
    )
  }

  /**
   * Get the power supplies that locate at the level.
   */
  function getPowerSuppliesOfLevel(level: Level): PowerSupply[] {
    return powerSupplies.filter(
      (powerSupply) => powerSupply?.device?.asset?.level?.id === level.id
    )
  }

  /**
   * Get the power supplies above the given level.
   */
  function getPowerSuppliesAboveTheArea(
    lift: Lift,
    level: Level
  ): PowerSupply[] {
    const levels = getLevelsThatAreAboveOf(level)
    const levelIds = levels.map((l) => l.id)

    return powerSupplies.filter(
      (powerSupply) =>
        powerSupply?.device?.asset?.lift !== null &&
        powerSupply?.device?.asset?.lift !== undefined &&
        powerSupply?.device?.asset?.lift.id === lift.id &&
        powerSupply?.device?.asset?.level !== null &&
        powerSupply?.device?.asset?.level !== undefined &&
        levelIds.indexOf(powerSupply?.device?.asset?.level?.id) >= 0
    )
  }

  /**
   * Get the power supplies below the given level.
   */
  function getPowerSuppliesBelowTheArea(
    lift: Lift,
    level: Level
  ): PowerSupply[] {
    const levels = getLevelsThatAreBelowOf(level)
    const levelIds = levels.map((l) => l.id)

    return powerSupplies.filter(
      (powerSupply) =>
        (powerSupply?.device?.asset?.lift === null ||
          powerSupply?.device?.asset?.lift === undefined ||
          powerSupply?.device?.asset?.lift.id === lift.id) &&
        (powerSupply?.device?.asset?.level === null ||
          powerSupply?.device?.asset?.level === undefined ||
          levelIds.indexOf(powerSupply?.device?.asset?.level?.id) >= 0)
    )
  }

  /**
   * Get the power supplies that locate at the level and the lift.
   */
  function getPowerSuppliesOfArea(lift: Lift, level: Level): PowerSupply[] {
    return powerSupplies.filter(
      (powerSupply) =>
        // @ts-ignore
        powerSupply?.device?.asset?.level?.id === level.id &&
        powerSupply?.device?.asset?.lift?.id === lift.id
    )
  }

  /**
   * Get the power supplies of the unknown area.
   */
  function getPowerSuppliesOfUnknownArea(): PowerSupply[] {
    return powerSuppliesOfUnknownArea
  }

  /**
   * Get the power supplies from the same area than the given power supply.
   */
  function getPowerSuppliesOfTheSameArea(
    powerSupply: PowerSupply
  ): PowerSupply[] {
    return powerSupply?.device?.asset?.level && powerSupply?.device?.asset?.lift
      ? getPowerSuppliesOfArea(
          powerSupply?.device?.asset?.lift as Lift,
          powerSupply?.device?.asset?.level as Level
        )
      : powerSupply?.device?.asset?.lift
      ? getPowerSuppliesOfLift(powerSupply?.device?.asset?.lift as Lift)
      : powerSupply?.device?.asset?.level
      ? getPowerSuppliesOfLevel(powerSupply?.device?.asset?.level as Level)
      : powerSuppliesOfUnknownArea
  }

  /**
   * Get the power supplies that are on the same area but area after the given power supply.
   */
  function getPowerSuppliesOfAreaBeforeOf(
    powerSupply: PowerSupply
  ): PowerSupply[] {
    const powerSupplies = getPowerSuppliesOfTheSameArea(powerSupply)
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    const index = powerSupplyIds.indexOf(powerSupply.id)

    return powerSupplies.filter((p) => {
      return powerSupplyIds.indexOf(p.id) < index
    })
  }

  /**
   * Get the power supplies that are on the same area but are after the given power supply.
   */
  function getPowerSuppliesOfAreaAfterOf(
    powerSupply: PowerSupply
  ): PowerSupply[] {
    const powerSupplies = getPowerSuppliesOfTheSameArea(powerSupply)
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    const index = powerSupplyIds.indexOf(powerSupply.id)

    return powerSupplies.filter((p) => {
      return powerSupplyIds.indexOf(p.id) > index
    })
  }

  /**
   * Get the power supply that the given power supply inputs from.
   */
  function getParentOf(powerSupply: PowerSupply): PowerSupply | undefined {
    return powerSupply?.parent?.id &&
      powerSuppliesMappedById.has(powerSupply?.parent?.id)
      ? powerSuppliesMappedById.get(powerSupply?.parent?.id)
      : undefined
  }

  /**
   * Get the power supplies that the given power supply outputs to.
   */
  function getChildrenOf(powerSupply: PowerSupply): PowerSupply[] {
    return powerSupplies.filter((p) => {
      return p?.parent?.id == powerSupply.id
    })
  }

  /**
   * Get the lifts that are before the given lift.
   */
  function getLiftsThatAreBeforeOf(lift: Lift): Lift[] {
    const keys = lifts.map((l) => l.id)
    const index = keys.indexOf(lift.id)
    return lifts.filter((l, i) => i < index)
  }

  /**
   * Get the lifts that are before the given lift.
   */
  function getLiftsThatAreAfterOf(lift: Lift): Lift[] {
    const keys = lifts.map((l) => l.id)
    const index = keys.indexOf(lift.id)
    return lifts.filter((l, i) => i > index)
  }

  /**
   * Get the levels that are above the given level.
   */
  function getLevelsThatAreAboveOf(level: Level): Level[] {
    const keys: number[] = levelsFromTopToBottom.map((l) => l.id)
    const index: number = keys.indexOf(level.id)
    return levelsFromTopToBottom.filter((l, i) => i < index)
  }

  /**
   * Get the levels that are below the given level.
   */
  function getLevelsThatAreBelowOf(level: Level): Level[] {
    const keys: number[] = levelsFromTopToBottom.map((l) => l.id)
    const index: number = keys.indexOf(level.id)
    return levelsFromTopToBottom.filter((l, i) => i > index)
  }

  /**
   * Checks if the level is above the other level.
   */
  function levelIsAboveTheOther(level: Level, other: Level): boolean {
    const ids: number[] = levelsFromTopToBottom.map((l) => l.id)
    return ids.indexOf(level.id) < ids.indexOf(other.id)
  }

  /**
   * Checks if the level is below the other level.
   */
  function levelIsBelowTheOther(level: Level, other: Level): boolean {
    const ids: number[] = levelsFromTopToBottom.map((l) => l.id)
    return ids.indexOf(level.id) > ids.indexOf(other.id)
  }

  /**
   * Checks if the level is the same as the other level.
   */
  function levelIsSameAsTheOther(level: Level, other: Level): boolean {
    const ids: number[] = levelsFromTopToBottom.map((l) => l.id)
    return ids.indexOf(level.id) === ids.indexOf(other.id)
  }

  /**
   * Checks if the lift has an input connection.
   */
  function liftHasInputConnection(lift: Lift): boolean {
    return somePowerSupplyHasInputConnection(getPowerSuppliesOfLift(lift))
  }

  /**
   * Checks if the lift has an output connection.
   */
  function liftHasOutputConnection(lift: Lift): boolean {
    return somePowerSupplyHasOutputConnection(getPowerSuppliesOfLift(lift))
  }

  /**
   * Checks if the area has an input connection.
   */
  function areaHasInputConnection(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasInputConnection(
      getPowerSuppliesOfArea(lift, level)
    )
  }

  /**
   * Checks if the area has an input connection from the unknown area.
   */
  function areaHasInputConnectionFromUnknownArea(
    lift: Lift,
    level: Level
  ): boolean {
    return somePowerSupplyHasInputConnectionFromUnknownArea(
      getPowerSuppliesOfArea(lift, level)
    )
  }

  /**
   * Checks if the area has an input connection from the level.
   */
  function areaHasInputConnectionFromLevel(
    lift: Lift,
    level: Level,
    from: Level
  ): boolean {
    return somePowerSupplyHasInputConnectionFromLevel(
      getPowerSuppliesOfArea(lift, level),
      from
    )
  }

  /**
   * Checks if the area has an input connection from above.
   */
  function areaHasInputConnectionFromAbove(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasInputConnectionFromAnyLevelAboveOf(
      getPowerSuppliesOfArea(lift, level),
      level
    )
  }

  /**
   * Check if the area has an input connection from a level above the given level.
   */
  function areaHasInputConnectionFromAnyLevelAboveOf(
    lift: Lift,
    level: Level,
    from: Level
  ): boolean {
    return somePowerSupplyHasInputConnectionFromAnyLevelAboveOf(
      getPowerSuppliesOfArea(lift, level),
      from
    )
  }

  /**
   * Checks if the area has an input conection from below.
   */
  function areaHasInputConnectionFromBelow(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasInputConnectionFromAnyLevelBelowOf(
      getPowerSuppliesOfArea(lift, level),
      level
    )
  }

  /**
   * Checks if the area has an input connection from a level below the given level.
   */
  function areaHasInputConnectionFromAnyLevelBelowOf(
    lift: Lift,
    level: Level,
    from: Level
  ): boolean {
    return somePowerSupplyHasInputConnectionFromAnyLevelBelowOf(
      getPowerSuppliesOfArea(lift, level),
      from
    )
  }

  /**
   * Checks if the area has an output connection to anywhere.
   */
  function areaHasOutputConnection(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasOutputConnection(
      getPowerSuppliesOfArea(lift, level)
    )
  }

  /**
   * Checks if the area has an output connection to the unknown area.
   */
  function areaHasOutputConnectionToUnknownArea(
    lift: Lift,
    level: Level
  ): boolean {
    return somePowerSupplyHasOutputConnectionToUnknownArea(
      getPowerSuppliesOfArea(lift, level)
    )
  }

  /**
   * Checks if the area has an output connection to the given level.
   */
  function areaHasOutputConnectionToLevel(
    lift: Lift,
    level: Level,
    to: Level
  ): boolean {
    return somePowerSupplyHasOutputConnectionToLevel(
      getPowerSuppliesOfArea(lift, level),
      to
    )
  }

  /**
   * Checks if the area has an output connection to the levels above.
   */
  function areaHasOutputConnectionToAbove(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasOutputConnectionToSomeLevelAboveOf(
      getPowerSuppliesOfArea(lift, level),
      level
    )
  }

  /**
   * Checks if the area has an output connection to levels above the given level.
   */
  function areaHasOutputConnectionToAboveOf(
    lift: Lift,
    level: Level,
    to: Level
  ): boolean {
    return somePowerSupplyHasOutputConnectionToSomeLevelAboveOf(
      getPowerSuppliesOfArea(lift, level),
      to
    )
  }

  /**
   * Checks if the area has an output connection to below.
   */
  function areaHasOutputConnectionToBelow(lift: Lift, level: Level): boolean {
    return somePowerSupplyHasOutputConnectionToSomeLevelBelowOf(
      getPowerSuppliesOfArea(lift, level),
      level
    )
  }

  /**
   * Checks if the area has an output connection to levels below the given level.
   */
  function areaHasOutputConnectionToBelowOf(
    lift: Lift,
    level: Level,
    to: Level
  ): boolean {
    return somePowerSupplyHasOutputConnectionToSomeLevelBelowOf(
      getPowerSuppliesOfArea(lift, level),
      to
    )
  }

  /**
   * Checks if any of the given power supplies has an input connection from anywhere.
   */
  function somePowerSupplyHasInputConnection(
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnection(powerSupply)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from the given lift.
   */
  function somePowerSupplyHasInputConnectionFromLift(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromLift(powerSupply, lift)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from any lift before the given lift.
   */
  function somePowerSupplyHasInputConnectionFromSomeLiftBeforeOf(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomeLiftBeforeOf(
        powerSupply,
        lift
      )
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from any lift after the given lift.
   */
  function somePowerSupplyHasInputConnectionFromSomeLiftAfterOf(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomeLiftAfterOf(powerSupply, lift)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from any of the given lifts.
   */
  function somePowerSupplyHasInputConnectionFromSomeLift(
    powerSupplies: PowerSupply[],
    lifts: Lift[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomeLift(powerSupply, lifts)
    })
  }

  /**
   * Check if any of the given power supplies has an input connection from the given area.
   */
  function somePowerSupplyHasInputConnectionFromArea(
    powerSupplies: PowerSupply[],
    lift: Lift,
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromArea(powerSupply, lift, level)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from the unknown area.
   */
  function somePowerSupplyHasInputConnectionFromUnknownArea(
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromUnknownArea(powerSupply)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from the given level.
   */
  function somePowerSupplyHasInputConnectionFromLevel(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromLevel(powerSupply, level)
    })
  }

  /**
   * Checks if any of the given power supplies has an input connection from any level above the given level.
   */
  function somePowerSupplyHasInputConnectionFromAnyLevelAboveOf(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomeLevelAboveOf(
        powerSupply,
        level
      )
    })
  }

  /**
   * Check if any of the given power supplies has aninput connection from any level below the given level.
   */
  function somePowerSupplyHasInputConnectionFromAnyLevelBelowOf(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomeLevelBelowOf(
        powerSupply,
        level
      )
    })
  }

  /**
   * Checks if any of the given power supplies have an input connection from any of the other power supplies.
   */
  function somePowerSupplyHasInputConnectionFromSomePowerSupply(
    powerSupplies: PowerSupply[],
    others: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasInputConnectionFromSomePowerSupply(
        powerSupply,
        others
      )
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection.
   */
  function somePowerSupplyHasOutputConnection(
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnection(powerSupply)
    })
  }

  /**
   * Check if any of the given power supplies has an output connection to the given area.
   */
  function somePowerSupplyHasOutputConnectionToArea(
    powerSupplies: PowerSupply[],
    lift: Lift,
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToArea(powerSupply, lift, level)
    })
  }

  /**
   * Check if any of the given power supplies has an output connection to the unknown area.
   */
  function somePowerSupplyHasOutputConnectionToUnknownArea(
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToUnknownArea(powerSupply)
    })
  }

  /**
   * Checks if ane of the given power supplies has an output connection to the lift.
   */
  function somePowerSupplyHasAnOuputConnectionToLift(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToLift(powerSupply, lift)
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to any lift before the given lift.
   */
  function somePowerSupplyHasOutputConnectionToSomeLiftBeforeOf(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomeLiftBeforeOf(powerSupply, lift)
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to any lift after the given lift.
   */
  function somePowerSupplyHasOutputConnectionToSomeLiftAfterOf(
    powerSupplies: PowerSupply[],
    lift: Lift
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomeLiftAfterOf(powerSupply, lift)
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to any lift after the given lift.
   */
  function somePowerSupplyHasOutputConnectionToSomeLift(
    powerSupplies: PowerSupply[],
    lifts: Lift[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomeLift(powerSupply, lifts)
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to the given level.
   */
  function somePowerSupplyHasOutputConnectionToLevel(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToLevel(powerSupply, level)
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to some level above the given level.
   */
  function somePowerSupplyHasOutputConnectionToSomeLevelAboveOf(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomeLevelAboveOf(
        powerSupply,
        level
      )
    })
  }

  /**
   * Checks if any of the given power supplies has an output connection to some level below the given level.
   */
  function somePowerSupplyHasOutputConnectionToSomeLevelBelowOf(
    powerSupplies: PowerSupply[],
    level: Level
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomeLevelBelowOf(
        powerSupply,
        level
      )
    })
  }

  /**
   * Checks if any of the given power supplies have an output connection to any of the other power supplies.
   */
  function somePowerSupplyHasOuputConnectionToSomePowerSupply(
    powerSupplies: PowerSupply[],
    others: PowerSupply[]
  ): boolean {
    return powerSupplies.some((powerSupply) => {
      return powerSupplyHasOutputConnectionToSomePowerSupply(
        powerSupply,
        others
      )
    })
  }

  /**
   * Checks if the power supply has an input connection.
   */
  function powerSupplyHasInputConnection(powerSupply: PowerSupply): boolean {
    const parent = getParentOf(powerSupply)
    return parent !== null && parent !== undefined
  }

  /**
   * Checks if the power supply has an output connection to the given area.
   */
  function powerSupplyHasInputConnectionFromArea(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)

    return (
      parent?.device?.asset?.lift !== null &&
      parent?.device?.asset?.lift !== undefined &&
      parent?.device?.asset?.lift.id === lift.id &&
      parent?.device?.asset?.level !== null &&
      parent?.device?.asset?.level !== undefined &&
      parent?.device?.asset?.level.id === level.id
    )
  }

  /**
   * Checks if the power supply has an output connection to some area above the given level.
   */
  function powerSupplyHasInputConnectionFromAreaAboveOf(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)
    const levels = getLevelsThatAreAboveOf(level)
    const levelIds = levels.map((l) => l.id)

    return (
      parent?.device?.asset?.lift !== null &&
      parent?.device?.asset?.lift !== undefined &&
      parent?.device?.asset?.lift.id === lift.id &&
      parent?.device?.asset?.level !== null &&
      parent?.device?.asset?.level !== undefined &&
      levelIds.includes(parent?.device?.asset?.level?.id)
    )
  }

  /**
   * Checks if the power supply has an output connection to some area below the given level.
   */
  function powerSupplyHasInputConnectionFromAreaBelowOf(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)
    const levels = getLevelsThatAreBelowOf(level)
    const levelIds = levels.map((l) => l.id)

    return (
      parent?.device?.asset?.lift === null ||
      parent?.device?.asset?.lift === undefined ||
      parent?.device?.asset?.lift.id === lift.id ||
      parent?.device?.asset?.level === null ||
      parent?.device?.asset?.level === undefined ||
      levelIds.includes(parent?.device?.asset?.level?.id)
    )
  }

  /**
   * Checks if the power supply has an input connection from the unknown area.
   */
  function powerSupplyHasInputConnectionFromUnknownArea(
    powerSupply: PowerSupply
  ): boolean {
    const parent = getParentOf(powerSupply)
    return parent?.device?.asset?.level === null
  }

  /**
   * Checks if the power supply has an input connection from the given lift.
   */
  function powerSupplyHasInputConnectionFromLift(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    const parent = getParentOf(powerSupply)

    return (
      !!parent?.device?.asset?.lift?.id &&
      parent?.device?.asset?.lift?.id === lift.id
    )
  }

  /**
   * Checks if the power supply has an input connection from any lift before the given lift.
   */
  function powerSupplyHasInputConnectionFromSomeLiftBeforeOf(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    return powerSupplyHasInputConnectionFromSomeLift(
      powerSupply,
      getLiftsThatAreBeforeOf(lift)
    )
  }

  /**
   * Checks if the power supply has an input connection from any lift after the given lift.
   */
  function powerSupplyHasInputConnectionFromSomeLiftAfterOf(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    return powerSupplyHasInputConnectionFromSomeLift(
      powerSupply,
      getLiftsThatAreAfterOf(lift)
    )
  }

  /**
   * Checks if the power supply has an input connection from any of the given lifts.
   */
  function powerSupplyHasInputConnectionFromSomeLift(
    powerSupply: PowerSupply,
    lifts: Lift[]
  ): boolean {
    const parent = getParentOf(powerSupply)
    const liftIds = lifts.map((l) => l.id)

    return (
      !!parent?.device?.asset?.lift?.id &&
      liftIds.includes(parent?.device?.asset?.lift?.id)
    )
  }

  /**
   * Checks if the power supply has an input connection from the given level.
   */
  function powerSupplyHasInputConnectionFromLevel(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)

    return (
      !!parent?.device?.asset?.level?.id &&
      parent?.device?.asset?.level?.id === level.id
    )
  }

  /**
   * Checks if the power supply has an input connection from any level above the given level.
   */
  function powerSupplyHasInputConnectionFromSomeLevelAboveOf(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)
    const levels = getLevelsThatAreAboveOf(level)
    const levelIds = levels.map((l) => l.id)

    return (
      !!parent?.device?.asset?.level?.id &&
      levelIds.includes(parent?.device?.asset?.level?.id)
    )
  }

  /**
   * Checks if the power supply has an input connection from any level below the given level.
   */
  function powerSupplyHasInputConnectionFromSomeLevelBelowOf(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const parent = getParentOf(powerSupply)
    const levels = getLevelsThatAreBelowOf(level)
    const levelIds = levels.map((l) => l.id)

    return (
      parent?.device?.asset?.level === null ||
      (!!parent?.device?.asset?.level?.id &&
        levelIds.includes(parent?.device?.asset?.level?.id))
    )
  }

  /**
   * Checks if the power supply has an input connection from any of the given levels.
   */
  function powerSupplyHasInputConnectionFromSomeLevel(
    powerSupply: PowerSupply,
    levels: Level[]
  ): boolean {
    const parent = getParentOf(powerSupply)
    const levelIds = levels.map((l) => l.id)

    return (
      parent?.device?.asset?.level !== null &&
      parent?.device?.asset?.level !== undefined &&
      levelIds.includes(parent?.device?.asset?.level?.id)
    )
  }

  /**
   * Checks if the power supply has an input connection from the given power supply.
   */
  function powerSupplyHasInputConnectionFromPowerSupply(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const parent = getParentOf(powerSupply)

    return parent !== null && parent !== undefined && parent.id === other.id
  }

  /**
   * Checks if the power supply has an input connection from any of the given power supply.
   */
  function powerSupplyHasInputConnectionFromSomePowerSupply(
    powerSupply: PowerSupply,
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((other) => {
      return powerSupplyHasInputConnectionFromPowerSupply(powerSupply, other)
    })
  }

  /**
   * Checks if the power supply has an output connection.
   */
  function powerSupplyHasOutputConnection(powerSupply: PowerSupply): boolean {
    const children = getChildrenOf(powerSupply)
    return children.length > 0
  }

  /**
   * Checks if the power supply has an output connection to the given area.
   */
  function powerSupplyHasOutputConnectionToArea(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)

    return children.some(
      (child) =>
        child?.device?.asset?.lift !== null &&
        child?.device?.asset?.lift !== undefined &&
        child?.device?.asset?.lift?.id === lift.id &&
        child?.device?.asset?.level !== null &&
        child?.device?.asset?.level !== undefined &&
        child?.device?.asset?.level.id == level.id
    )
  }

  /**
   * Checks if the power supply has an output connection to the unknown area.
   */
  function powerSupplyHasOutputConnectionToUnknownArea(
    powerSupply: PowerSupply
  ): boolean {
    const children = getChildrenOf(powerSupply)

    return children.some((child) => child?.device?.asset?.level === null)
  }

  /**
   * Checks if the power supply has an output connection to some area above the given level.
   */
  function powerSupplyHasOutputConnectionToAreaAboveOf(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const levels = getLevelsThatAreAboveOf(level)
    const levelIds = levels.map((l) => l.id)

    return children.some((child) => {
      return (
        child?.device?.asset?.lift !== null &&
        child?.device?.asset?.lift !== undefined &&
        child?.device?.asset?.lift?.id === lift.id &&
        child?.device?.asset?.level !== null &&
        child?.device?.asset?.level !== undefined &&
        levelIds.includes(child?.device?.asset?.level.id)
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to some area below the given level.
   */
  function powerSupplyHasOutputConnectionToAreaBelowOf(
    powerSupply: PowerSupply,
    lift: Lift,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const levels = getLevelsThatAreBelowOf(level)
    const levelIds = levels.map((l) => l.id)

    return children.some((child) => {
      return (
        child?.device?.asset?.lift === null ||
        child?.device?.asset?.lift === undefined ||
        child?.device?.asset?.lift?.id === lift.id ||
        child?.device?.asset?.level === null ||
        child?.device?.asset?.level === undefined ||
        levelIds.includes(child?.device?.asset?.level.id)
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to the given lift.
   */
  function powerSupplyHasOutputConnectionToLift(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    const children = getChildrenOf(powerSupply)

    return children.some(
      (child) =>
        child?.device?.asset?.lift !== null &&
        child?.device?.asset?.lift !== undefined &&
        child?.device?.asset?.lift?.id === lift.id
    )
  }

  /**
   * Checks if the power supply has an output connection to any lift before the given lift.
   */
  function powerSupplyHasOutputConnectionToSomeLiftBeforeOf(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    return powerSupplyHasOutputConnectionToSomeLift(
      powerSupply,
      getLiftsThatAreBeforeOf(lift)
    )
  }

  /**
   * Checks if the power supply has an output connection to any lift after the given lift.
   */
  function powerSupplyHasOutputConnectionToSomeLiftAfterOf(
    powerSupply: PowerSupply,
    lift: Lift
  ): boolean {
    return powerSupplyHasOutputConnectionToSomeLift(
      powerSupply,
      getLiftsThatAreAfterOf(lift)
    )
  }

  /**
   * Checks if the power supply has an output connection to any lift before the given lift.
   */
  function powerSupplyHasOutputConnectionToSomeLift(
    powerSupply: PowerSupply,
    lifts: Lift[]
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const liftIds = lifts.map((l) => l.id)

    return children.some((child) => {
      return (
        !!child?.device?.asset?.lift?.id &&
        liftIds.includes(child?.device?.asset?.lift.id)
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to the given level.
   */
  function powerSupplyHasOutputConnectionToLevel(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)

    return children.some(
      (child) =>
        child?.device?.asset?.level !== null &&
        child?.device?.asset?.level !== undefined &&
        child?.device?.asset?.level?.id === level.id
    )
  }

  /**
   * Checks if the power supply has an output connection to some level above the given level.
   */
  function powerSupplyHasOutputConnectionToSomeLevelAboveOf(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const levels = getLevelsThatAreAboveOf(level)
    const levelIds = levels.map((l) => l.id)

    return children.some((child) => {
      return (
        !!child?.device?.asset?.level?.id &&
        levelIds.includes(child?.device?.asset?.level.id)
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to some level below the given level.
   */
  function powerSupplyHasOutputConnectionToSomeLevelBelowOf(
    powerSupply: PowerSupply,
    level: Level
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const levels = getLevelsThatAreBelowOf(level)
    const levelIds = levels.map((l) => l.id)

    return children.some((child) => {
      return (
        child?.device?.asset?.level === null ||
        (!!child?.device?.asset?.level?.id &&
          levelIds.includes(child?.device?.asset?.level.id))
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to any of the given level.
   */
  function powerSupplyHasOutputConnectionToSomeLevel(
    powerSupply: PowerSupply,
    levels: Level[]
  ): boolean {
    const children = getChildrenOf(powerSupply)
    const levelIds = levels.map((l) => l.id)

    return children.some((child) => {
      return (
        !!child?.device?.asset?.level?.id &&
        levelIds.includes(child?.device?.asset?.level.id)
      )
    })
  }

  /**
   * Checks if the power supply has an output connection to the given power supply.
   */
  function powerSupplyHasOutputConnectionToPowerSupply(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const children = getChildrenOf(powerSupply)

    return children.some((child) => {
      return child.id === other.id
    })
  }

  /**
   * Checks if the power supply has an output connection to any of the given power supplies.
   */
  function powerSupplyHasOutputConnectionToSomePowerSupply(
    powerSupply: PowerSupply,
    powerSupplies: PowerSupply[]
  ): boolean {
    return powerSupplies.some((other) => {
      return powerSupplyHasOutputConnectionToPowerSupply(powerSupply, other)
    })
  }

  /**
   * Checks if the power supply is the same as the other.
   */
  function powerSupplyIsSameAsTheOther(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    return powerSupply.id === other.id
  }

  /**
   * Check if the given power supply is before the other power supply.
   */
  function powerSupplyIsBeforeTheOther(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    return (
      powerSupplyIds.indexOf(powerSupply.id) < powerSupplyIds.indexOf(other.id)
    )
  }

  /**
   * Checks if the given power supply is right before the other power supply
   */
  function powerSupplyIsRightBeforeTheOther(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    return (
      powerSupplyIds.indexOf(powerSupply.id) + 1 ===
      powerSupplyIds.indexOf(other.id)
    )
  }

  /**
   * Check if the given power supply is after the other power supply.
   */
  function powerSupplyIsAfterTheOther(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    return (
      powerSupplyIds.indexOf(powerSupply.id) > powerSupplyIds.indexOf(other.id)
    )
  }

  /**
   * Checks if the given power supply is right after the other power supply
   */
  function powerSupplyIsRightAfterTheOther(
    powerSupply: PowerSupply,
    other: PowerSupply
  ): boolean {
    const powerSupplyIds = powerSupplies.map((p) => p.id)
    return (
      powerSupplyIds.indexOf(powerSupply.id) - 1 ===
      powerSupplyIds.indexOf(other.id)
    )
  }

  /**
   * Get the gutter range.
   */
  function getGutterRange(lift: Lift, level: Level): any[] {
    const range: any[] = [null]

    const countOfPowerSuppliesOnTheArea = getPowerSuppliesOfArea(
      lift,
      level
    ).length
    const maxCountOfPowerSuppliesForLevel = Math.max(
      ...lifts.map((l) => {
        return getPowerSuppliesOfArea(l, level).length
      })
    )

    for (
      let i = 0;
      i < maxCountOfPowerSuppliesForLevel - countOfPowerSuppliesOnTheArea;
      i++
    ) {
      range.push(null)
    }

    return range
  }
  const { theme } = useTheme()
  const navigate = useNavigate()

  async function handleNavigate(level: number | undefined) {
    level === undefined
      ? navigate(`/blueprint/project/${project.id}/level/area`)
      : navigate(`/blueprint/project/${project.id}/level/${level}`)
  }

  return (
    <React.Fragment>
      {/* prettier-ignore */}
      <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
                      {lifts.map((lift) => (
                        <TableCell
                          key={lift.id}
                          sx={{
                            textAlign: 'center',
                            padding: 0,
                            width: '0px'
                          }}
                        >
                          <Box
                            height="24px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography>{lift.name}</Typography>
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/** Levels */}
                    {levelsFromTopToBottom.map((level) => (
                      <TableRow key={level.id}>
                        <TableCell sx={{
                          textAlign: 'center',
                          padding: 0,
                          height: '100%',
                          width: '100%'
                        }}>
                            <Box position="relative" width="24px" height="100%">
                              <Typography
                               color={listOfLevelIds.includes(level.id) === true ? '#376fd0' : theme === THEMES.DARK ? 'white' : 'black'} 
                               onClick={() => listOfLevelIds.includes(level.id) === true ? handleNavigate(level.id) : false}
                               sx={{                        
                                 position: 'absolute',
                                 transform: 'rotate(-90deg) translateX(-50%)',
                                 transformOrigin: 'left top',
                                 width: '168px',
                                 cursor:  listOfLevelIds.includes(level.id) === true ?'pointer' : 'default'
                               }}
                              >
                                {level.name}
                              </Typography>
                            </Box> 
                        </TableCell>
                        {lifts.map((lift) => (
                          <TableCell
                            key={lift.id}
                            sx={{
                              position: 'relative',
                              padding: 0,
                              width: '0px',
                              border: '1px solid rgba(81, 81, 81, 1)'
                            }}
                          >
                            <Stack
                              direction="row"
                              height="100%"
                              width="100%"
                            >
                              <Stack
                                direction="row"
                                height="100%"
                              >
                                {/**
                                 * Connections from level to levels.
                                 */}
                                {levelsFromTopToBottom.map((levelOfPowerLine, index) => (
                                  <Stack direction="column" key={levelOfPowerLine.id}>
                                    <Stack
                                      direction="column"
                                      flexGrow={0}
                                    >
                                      {/** The top gutter connections */}
                                      {getGutterRange(lift, level).map((__, index) => (
                                        <Box
                                          position="relative"
                                          width={powerLineSize}
                                          height={powerLineSize}
                                          key={index}
                                        >
                                          {(
                                            (
                                              levelIsSameAsTheOther(levelOfPowerLine, level) &&
                                              areaHasInputConnectionFromAbove(lift, levelOfPowerLine)
                                            ) || (
                                              levelIsAboveTheOther(levelOfPowerLine, level) && (
                                                areaHasInputConnectionFromLevel(lift, levelOfPowerLine, level) ||
                                                areaHasInputConnectionFromAnyLevelBelowOf(lift, levelOfPowerLine, level)
                                              )
                                            ) || (
                                              levelIsBelowTheOther(levelOfPowerLine, level) &&
                                              areaHasInputConnectionFromAnyLevelAboveOf(lift, levelOfPowerLine, level)
                                            )
                                          ) && (
                                            <VerticalLevelPowerLine />
                                          )}
                                        </Box>
                                      ))}

                                      {[...getPowerSuppliesOfArea(lift, level)].reverse().map((powerSupply) => (
                                        <OutputPowerLineBox key={powerSupply.id}>
                                          {(
                                            powerSupplyHasOutputConnectionToLevel(powerSupply, levelOfPowerLine) ||
                                            powerSupplyHasOutputConnectionToSomeLevelAboveOf(powerSupply, levelOfPowerLine)
                                          ) && (
                                            <HorizontalOutputPowerLine
                                              animationDelay={`${index * 0.5}s`}
                                            />
                                          )}
                                          {(
                                            levelIsAboveTheOther(levelOfPowerLine, level) && (
                                              somePowerSupplyHasOutputConnectionToArea(
                                                [
                                                  ...getPowerSuppliesOfAreaBeforeOf(powerSupply),
                                                  ...getPowerSuppliesBelowTheArea(lift, level)
                                                ],
                                                lift,
                                                levelOfPowerLine
                                              )
                                            ) || (
                                              levelIsBelowTheOther(levelOfPowerLine, level) && (
                                                powerSupplyHasOutputConnectionToArea(powerSupply, lift, levelOfPowerLine) ||
                                                somePowerSupplyHasOutputConnectionToArea(
                                                  [
                                                    ...getPowerSuppliesOfAreaAfterOf(powerSupply),
                                                    ...getPowerSuppliesAboveTheArea(lift, level)
                                                  ],
                                                  lift,
                                                  levelOfPowerLine
                                                )
                                              )
                                            ) || (
                                              levelIsSameAsTheOther(levelOfPowerLine, level) && (
                                                powerSupplyHasOutputConnectionToArea(powerSupply, lift, levelOfPowerLine) ||
                                                somePowerSupplyHasOutputConnectionToArea(
                                                  [
                                                    ...getPowerSuppliesOfAreaAfterOf(powerSupply),
                                                    ...getPowerSuppliesAboveTheArea(lift, level)
                                                  ],
                                                  lift,
                                                  levelOfPowerLine
                                                )
                                              )
                                            )
                                          ) && (
                                            <VerticalLevelPowerLine />
                                          )}
                                        </OutputPowerLineBox>
                                      ))}
                                    </Stack>

                                    <Stack
                                      direction="column"
                                      spacing={0}
                                      flexGrow={0}
                                      width="100%"
                                      height="168px"
                                    >
                                      <PowerLineBox>
                                        {(
                                          levelIsAboveTheOther(levelOfPowerLine, level) && (
                                            areaHasInputConnectionFromAnyLevelBelowOf(lift, levelOfPowerLine, level)
                                          ) ||
                                          (
                                            (
                                              levelIsBelowTheOther(levelOfPowerLine, level) ||
                                              levelIsSameAsTheOther(levelOfPowerLine, level)
                                            ) && (
                                              areaHasInputConnectionFromLevel(lift, levelOfPowerLine, level) ||
                                              areaHasInputConnectionFromAnyLevelAboveOf(lift, levelOfPowerLine, level)
                                            )
                                          )
                                        ) && (
                                          <VerticalLevelPowerLine />
                                        )}
                                      </PowerLineBox>
                                    </Stack>

                                    <Stack
                                      direction="column"
                                      flexGrow={0}
                                    >
                                      {[...getPowerSuppliesOfArea(lift, level)].map((powerSupply) => (
                                        <OutputPowerLineBox key={powerSupply.id}>
                                          {(
                                            (
                                              levelIsSameAsTheOther(level, levelOfPowerLine) ||
                                              levelIsAboveTheOther(level, levelOfPowerLine)
                                            ) && areaHasInputConnection(lift, level)
                                          ) && (
                                            <HorizontalInputPowerLine
                                              animationDelay={`${index * 0.5}s`}
                                            />
                                          )}

                                          {(
                                            (
                                              levelIsAboveTheOther(levelOfPowerLine, level) && (
                                                areaHasInputConnectionFromAnyLevelBelowOf(
                                                  lift,
                                                  levelOfPowerLine,
                                                  level
                                                ) ||
                                                somePowerSupplyHasOutputConnectionToLevel(
                                                  getPowerSuppliesOfAreaBeforeOf(powerSupply),
                                                  levelOfPowerLine
                                                )
                                              )
                                            ) || (
                                              levelIsBelowTheOther(levelOfPowerLine, level) && (
                                                powerSupplyHasOutputConnectionToLevel(
                                                  powerSupply,
                                                  levelOfPowerLine
                                                ) ||
                                                somePowerSupplyHasOutputConnectionToLevel(
                                                  getPowerSuppliesOfAreaBeforeOf(powerSupply),
                                                  levelOfPowerLine
                                                ) ||
                                                areaHasInputConnectionFromAnyLevelAboveOf(
                                                  lift,
                                                  levelOfPowerLine,
                                                  level
                                                )
                                              )
                                            ) || (
                                              levelIsSameAsTheOther(levelOfPowerLine, level) && (
                                                somePowerSupplyHasInputConnectionFromSomePowerSupply(
                                                  [
                                                    powerSupply,
                                                    ...getPowerSuppliesOfAreaAfterOf(powerSupply),
                                                  ],
                                                  [
                                                    ...getPowerSuppliesOfTheSameArea(powerSupply),
                                                    ...getPowerSuppliesAboveTheArea(lift, level)
                                                  ]
                                                ) ||
                                                somePowerSupplyHasInputConnectionFromSomePowerSupply(
                                                  [
                                                    ...getPowerSuppliesOfAreaBeforeOf(powerSupply),
                                                  ],
                                                  [
                                                    ...getPowerSuppliesBelowTheArea(lift, level)
                                                  ]
                                                )
                                              )
                                            )
                                          ) && (
                                            <VerticalLevelPowerLine />
                                          )}
                                        </OutputPowerLineBox>
                                      ))}

                                      {/** The bottom gutter connections */}
                                      {getGutterRange(lift, level).map((__, index) => (
                                        <Box
                                          key={index}
                                          position="relative"
                                          width={powerLineSize}
                                          height={powerLineSize}
                                        >
                                          {(
                                            (
                                              levelIsSameAsTheOther(levelOfPowerLine, level) &&
                                              areaHasInputConnectionFromBelow(lift, levelOfPowerLine)
                                            ) || (
                                              levelIsAboveTheOther(levelOfPowerLine, level) &&
                                              areaHasInputConnectionFromAnyLevelBelowOf(lift, levelOfPowerLine, level)
                                            ) || (
                                              levelIsBelowTheOther(levelOfPowerLine, level) &&
                                              areaHasInputConnectionFromAbove(lift, levelOfPowerLine)
                                            )
                                          ) && (
                                            <VerticalLevelPowerLine />
                                          )}
                                        </Box>
                                      ))}
                                    </Stack>
                                  </Stack>
                                ))}

                                {/* TODO: Connections from level to lifts */}

                                {/**
                                 * Connections from level to unknown area
                                 */ }
                                <Stack direction="column">
                                  <Stack
                                    direction="column"
                                    flexGrow={0}
                                  >
                                    {/** The top gutter */}
                                    {getGutterRange(lift, level).map((__, index) => (
                                      <Box
                                        key={index}
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {(
                                          somePowerSupplyHasOutputConnectionToUnknownArea(
                                            getPowerSuppliesAboveTheArea(lift, level)
                                          )
                                        ) && (
                                          <VerticalLevelPowerLine />
                                        )}
                                      </Box>
                                    ))}

                                    {[...getPowerSuppliesOfArea(lift, level)].reverse().map((powerSupply) => (
                                      <Box
                                        key={powerSupply.id}
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {powerSupplyHasOutputConnection(powerSupply) && (
                                          <HorizontalOutputPowerLine
                                            animationDelay={`${(levels.length - 1) * 0.5 + 0.5}s`}
                                          />
                                        )}

                                        {(
                                          somePowerSupplyHasOutputConnectionToUnknownArea(
                                            [
                                              powerSupply,
                                              ...getPowerSuppliesOfAreaAfterOf(powerSupply),
                                              ...getPowerSuppliesAboveTheArea(lift, level)
                                            ]
                                          )
                                        ) && (
                                          <VerticalInputOutputPowerLine />
                                        )}
                                      </Box>
                                    ))}
                                  </Stack>

                                  <Stack
                                    direction="column"
                                    flexGrow={1}
                                  >
                                    <Box
                                      position="relative"
                                      width={powerLineSize}
                                      height="100%"
                                    >
                                      {(
                                        somePowerSupplyHasOutputConnectionToUnknownArea(
                                          [
                                            ...getPowerSuppliesOfArea(lift, level),
                                            ...getPowerSuppliesAboveTheArea(lift, level)
                                          ]
                                        )
                                      ) && (
                                        <VerticalInputOutputPowerLine />
                                      )}
                                    </Box>
                                  </Stack>

                                  <Stack
                                    direction="column"
                                    flexGrow={0}
                                  >
                                    {[...getPowerSuppliesOfArea(lift, level)].map((inputPowerSupply) => (
                                      <Box
                                        key={inputPowerSupply.id}
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {powerSupplyHasInputConnection(inputPowerSupply) && (
                                          <HorizontalInputPowerLine
                                            animationDelay={`${(levels.length - 1) * 0.5 + 0.5}s`}
                                          />
                                        )}

                                        {somePowerSupplyHasOutputConnectionToUnknownArea([
                                          ...getPowerSuppliesOfArea(lift, level),
                                          ...getPowerSuppliesAboveTheArea(lift, level)
                                        ]) && (
                                          <VerticalInputOutputPowerLine />
                                        )}
                                      </Box>
                                    ))}

                                    {/** The bottom gutter */}
                                    {getGutterRange(lift, level).map((__, index) => (
                                      <Box
                                        key={index}
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {(
                                          somePowerSupplyHasOutputConnectionToUnknownArea(
                                            [
                                              ...getPowerSuppliesOfArea(lift, level),
                                              ...getPowerSuppliesAboveTheArea(lift, level)
                                            ]
                                          )
                                        ) && (
                                          <VerticalLevelPowerLine />
                                        )}
                                      </Box>
                                    ))}
                                  </Stack>
                                </Stack>
                              </Stack>

                              <Stack
                                direction="row"
                                spacing={0}
                                sx={{ height: '100%', width: '100%' }}
                              >
                                {getPowerSuppliesOfArea(lift, level).map(
                                  (powerSupply) => (
                                    <>
                                      <Stack
                                        direction="column"
                                        spacing={0}
                                      >
                                        {/**
                                          * Render outputs lines for the device.
                                          * The number of line stacks should equal to devices on level and lift.
                                          */}
                                          <Stack
                                            direction="column"
                                            spacing={0}
                                          >
                                            {/** The top gutter */}
                                            {getGutterRange(lift, level).map((__, index) => (
                                              <Box
                                                key={index}
                                                display="flex"
                                                width="100%"
                                                height={powerLineSize}
                                              ></Box>
                                            ))}

                                            {/**
                                              * Get the power supplies of level and lift in a reverse order, so
                                              * that the last power supply on level gets the top line and
                                              * the first the bottom line.
                                              */}
                                            {getPowerSuppliesOfArea(lift, level).reverse().map(
                                              (outputPowerSupply, index) => (
                                                <Stack
                                                  key={outputPowerSupply.id}
                                                  direction="row"
                                                  spacing={0}
                                                  height={powerLineSize}
                                                >
                                                  <Box
                                                    display="flex"
                                                    width="50%"
                                                    height={powerLineSize}
                                                    position="relative"
                                                  >
                                                    {/**
                                                      * Draw a horizontal power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasOutputConnection(outputPowerSupply) && (
                                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply) ||
                                                        powerSupplyIsSameAsTheOther(outputPowerSupply, powerSupply)
                                                      )
                                                    ) && (
                                                      <HorizontalOutputPowerLine />
                                                    )}
                                                  </Box>
                                                  <Box
                                                    display="flex"
                                                    width="50%"
                                                    height={powerLineSize}
                                                    position="relative"
                                                  >
                                                    {/**
                                                      * Draw a horizontal power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasOutputConnection(outputPowerSupply) && (
                                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply) &&
                                                        !powerSupplyIsSameAsTheOther(outputPowerSupply, powerSupply)
                                                      )
                                                    ) && (
                                                      <HorizontalOutputPowerLine />
                                                    )}

                                                    {/**
                                                      * Draw a verical power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasOutputConnection(powerSupply) && (
                                                        powerSupplyIsAfterTheOther(powerSupply, outputPowerSupply) ||
                                                        powerSupplyIsSameAsTheOther(powerSupply, outputPowerSupply)
                                                      )
                                                    ) && (
                                                      <VerticalInputOutputPowerLine
                                                        useAnimation={powerSupply.device?.status ?? false}
                                                        animationDelay={`${index * 1}s`}
                                                      />
                                                    )}
                                                  </Box>
                                                </Stack>
                                              )
                                            )}
                                          </Stack>

                                          {/**
                                            * The box for the power supply.
                                            */}
                                          <Box
                                            display="flex"
                                            flexGrow="0"
                                            justifyContent="center"
                                            alignItems="center"
                                            px={3}
                                          >
                                            <PowerSupplyCard
                                              powerSupply={powerSupply}
                                              alertRules={alertRules}
                                            />
                                          </Box>

                                          {/**
                                          * Render outputs lines for the device.
                                          * The number of line stacks should equal to devices on level and lift.
                                          */}
                                          <Stack
                                            direction="column"
                                            spacing={0}
                                          >
                                            {getPowerSuppliesOfArea(lift, level).map(
                                              (outputPowerSupply, index) => (
                                                <Stack
                                                  key={outputPowerSupply.id}
                                                  direction="row"
                                                  spacing={0}
                                                  height={powerLineSize}
                                                >
                                                  <Box
                                                    display="flex"
                                                    width="50%"
                                                    height={powerLineSize}
                                                    position="relative"
                                                  >
                                                    {/**
                                                      * Draw a horizontal power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasInputConnection(outputPowerSupply) && (
                                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply) ||
                                                        powerSupplyIsSameAsTheOther(outputPowerSupply, powerSupply)
                                                      )
                                                    ) && (
                                                      <HorizontalInputPowerLine />
                                                    )}
                                                  </Box>
                                                  <Box
                                                    display="flex"
                                                    width="50%"
                                                    height={powerLineSize}
                                                    position="relative"
                                                  >
                                                    {/**
                                                      * Draw a horizontal power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasInputConnection(outputPowerSupply) && (
                                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply) &&
                                                        !powerSupplyIsSameAsTheOther(outputPowerSupply, powerSupply)
                                                      )
                                                    ) && (
                                                      <HorizontalInputPowerLine />
                                                    )}

                                                    {/**
                                                      * Draw a verical power line.
                                                      */}
                                                    {(
                                                      powerSupplyHasInputConnection(powerSupply) && (
                                                        powerSupplyIsAfterTheOther(powerSupply, outputPowerSupply) ||
                                                        powerSupplyIsSameAsTheOther(powerSupply, outputPowerSupply)
                                                      )
                                                    ) && (
                                                      <VerticalInputOutputPowerLine
                                                        useAnimation={powerSupply.device?.status ?? false}
                                                        animationDelay={`${-1 * index * 0.5}s`}
                                                      />
                                                    )}
                                                  </Box>
                                                </Stack>
                                              )
                                            )}

                                            {/** The bottom gutter */}
                                            {getGutterRange(lift, level).map((__, index) => (
                                              <Box
                                                key={index}
                                                display="flex"
                                                width="100%"
                                                height={powerLineSize}
                                              ></Box>
                                            ))}
                                          </Stack>
                                      </Stack>
                                    </>
                                  )
                                )}
                              </Stack>
                            </Stack>
                          </TableCell>
                        ))}
                        <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
                      </TableRow>
                    ))}

                    {/* Unknown Area */}
                    <TableRow>
                      <TableCell sx={{
                        textAlign: 'center',
                        padding: 0,
                        height: '100%',
                        width: '100%'
                      }}>
                        <Box position="relative" width="24px" height="100%" borderBottom={'1px'}>
                          <Typography
                            color={areaUpload ? '#376fd0' : theme === THEMES.DARK ? 'white' : 'black'} 
                            onClick={() => areaUpload ? handleNavigate(undefined) : false}
                            sx={{                        
                              position: 'absolute',
                              transform: 'rotate(-90deg) translateX(-50%)',
                              transformOrigin: 'left top',
                              width: '168px',
                              cursor: areaUpload ? 'pointer' : 'default'
                            }}
                          >
                            {t('areaLevel')}
                          </Typography>
                        </Box>
                      </TableCell>

                      {lifts.map((lift) => (
                        <TableCell
                          key={lift.id}
                          sx={{
                            padding: 0,
                            width: '0px',
                            height: '100%'
                          }}
                        >
                          <Stack direction="row" height="100%" sx={{ marginLeft: '0.5px'}}>
                            <Stack direction="column" height="100%">
                              {/**
                               * The top connections of uknown area.
                               */}
                              <Stack direction="column"flexGrow={0}>
                                {/** The top gutter. */}
                                <Stack
                                  direction="row"
                                  flexGrow={0}
                                  spacing={0}
                                >
                                  {levelsFromTopToBottom.map((levelOfPowerLine) => (
                                    <Box
                                      key={levelOfPowerLine.id}
                                      position="relative"
                                      width={powerLineSize}
                                      height={powerLineSize}
                                    >
                                      {(
                                        areaHasInputConnectionFromUnknownArea(lift, levelOfPowerLine)
                                      ) && (
                                        <VerticalLevelPowerLine />
                                      )}
                                    </Box>
                                  ))}

                                  {/** TODO: Connections to lifts. */}

                                  <Box
                                    position="relative"
                                    width={powerLineSize}
                                    height={powerLineSize}
                                  >
                                    {(
                                      somePowerSupplyHasOutputConnectionToUnknownArea(
                                        getPowerSuppliesOfLift(lift)
                                      )
                                    ) && (
                                      <VerticalLevelPowerLine />
                                    )}
                                  </Box>
                                </Stack>

                                {[...getPowerSuppliesOfUnknownArea()].reverse().map(
                                  (outputPowerSupply) => (
                                    <Stack
                                      direction="row"
                                      flexGrow={0}
                                      spacing={0}
                                      key={outputPowerSupply.id}
                                    >
                                      {levelsFromTopToBottom.map((levelOfPowerLine) => (
                                        <Box
                                          key={levelOfPowerLine.id}
                                          position="relative"
                                          width={powerLineSize}
                                          height={powerLineSize}
                                        >
                                          {(
                                            powerSupplyHasOutputConnectionToArea(outputPowerSupply, lift, levelOfPowerLine) ||
                                            powerSupplyHasOutputConnectionToAreaAboveOf(outputPowerSupply, lift, levelOfPowerLine) ||
                                            powerSupplyHasOutputConnectionToSomeLiftBeforeOf(
                                              outputPowerSupply,
                                              lift
                                            )
                                          ) && (
                                            <HorizontalOutputPowerLine />
                                          )}

                                          {(
                                            somePowerSupplyHasOutputConnectionToArea(
                                              getPowerSuppliesOfAreaBeforeOf(outputPowerSupply),
                                              lift,
                                              levelOfPowerLine
                                            )
                                          ) && (
                                            <VerticalLevelPowerLine />
                                          )}
                                        </Box>
                                      ))}

                                      {/** TODO: Connections to lifts. */}

                                      <Box
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {(
                                          powerSupplyHasOutputConnectionToLift(outputPowerSupply, lift) ||
                                          powerSupplyHasOutputConnectionToSomeLiftBeforeOf(
                                            outputPowerSupply,
                                            lift
                                          )
                                        ) && (
                                          <HorizontalOutputPowerLine />
                                        )}

                                        {somePowerSupplyHasOutputConnectionToUnknownArea(
                                          getPowerSuppliesOfLift(lift)
                                        ) && (
                                          <VerticalLevelPowerLine />
                                        )}
                                      </Box>
                                    </Stack>
                                  )
                                )}
                              </Stack>

                              {/**
                                * The middle connections of unknown area.
                                */}
                              <Stack direction="row" height="168px" flexGrow={1}>
                                {levels.map((level) => (
                                  <Box
                                    position="relative"
                                    width={powerLineSize}
                                    height="100%"
                                    key={level.id}
                                  >
                                  </Box>
                                ))}

                                {/** TODO: Connections to lifts. */}

                                <Box
                                  position="relative"
                                  width={powerLineSize}
                                  height="100%"
                                >
                                  {somePowerSupplyHasOutputConnectionToUnknownArea(
                                    getPowerSuppliesOfLift(lift)
                                  ) && (
                                    <VerticalLevelPowerLine />
                                  )}
                                </Box>
                              </Stack>

                              {/**
                                * The bottom connections of unknown area.
                                */}
                              <Stack direction="column" flexGrow={0}>
                                {[...getPowerSuppliesOfUnknownArea()].map((powerSupply) => (
                                  <Stack
                                    key={powerSupply.id}
                                    direction="row"
                                  >
                                    {levelsFromTopToBottom.map((level) => (
                                      <Box
                                        key={level.id}
                                        position="relative"
                                        width={powerLineSize}
                                        height={powerLineSize}
                                      >
                                        {(
                                          powerSupplyHasInputConnectionFromSomeLiftBeforeOf(
                                            powerSupply,
                                            lift
                                          )
                                        ) && (
                                          <HorizontalInputPowerLine />
                                        )}
                                      </Box>
                                    ))}

                                    {/** TODO: Connections to lifts. */}

                                    <Box
                                      position="relative"
                                      width={powerLineSize}
                                      height={powerLineSize}
                                    >
                                      {(
                                        powerSupplyHasInputConnectionFromLift(powerSupply, lift) ||
                                        powerSupplyHasInputConnectionFromSomeLiftBeforeOf(
                                          powerSupply,
                                          lift
                                        )
                                      ) && (
                                        <HorizontalInputPowerLine />
                                      )}

                                      {(
                                        powerSupplyHasInputConnectionFromLift(powerSupply, lift) ||
                                        somePowerSupplyHasInputConnectionFromLift(
                                          getPowerSuppliesOfAreaAfterOf(powerSupply),
                                          lift
                                        )
                                      )&& (
                                        <VerticalLevelPowerLine />
                                      )}
                                    </Box>
                                  </Stack>
                                ))}

                                {/** The bottom gutter. */}
                                <Stack
                                  direction="row"
                                  flexGrow={0}
                                  spacing={0}
                                >
                                  {levelsFromTopToBottom.map((levelOfPowerLine) => (
                                    <Box
                                      key={levelOfPowerLine.id}
                                      position="relative"
                                      width={powerLineSize}
                                      height={powerLineSize}
                                    ></Box>
                                  ))}

                                  {/** TODO: Connections to lifts. */}

                                  <Box
                                    position="relative"
                                    width={powerLineSize}
                                    height={powerLineSize}
                                  ></Box>
                                </Stack>
                              </Stack>
                            </Stack>

                            <Stack direction="column" height="100%" width="100%">
                              {/* Output connections */}
                              <Stack direction="column" flexGrow={0}>
                                {/** The top gutter */}
                                <Box
                                  display="flex"
                                  width="100%"
                                  height={powerLineSize}
                                ></Box>

                                {[...getPowerSuppliesOfUnknownArea()].reverse().map(
                                  (outputPowerSupply) => (
                                    <Box
                                      key={outputPowerSupply.id}
                                      position="relative"
                                      width="100%"
                                      height={powerLineSize}
                                    >
                                      {(
                                        powerSupplyHasOutputConnectionToLift(outputPowerSupply, lift) ||
                                        powerSupplyHasOutputConnectionToSomeLiftBeforeOf(
                                          outputPowerSupply,
                                          lift
                                        )
                                      ) && (
                                        <HorizontalOutputPowerLine />
                                      )}
                                    </Box>
                                  )
                                )}
                              </Stack>

                              <Stack
                                direction="row"
                                height="100%"
                                minHeight="168px"
                                flexGrow={1}
                              >
                                {/** Nothing should be here  */}
                              </Stack>

                              {/* Input connection */}
                              <Stack direction="column" flexGrow={0}>
                                {[...getPowerSuppliesOfUnknownArea()].map((inputPowerSupply) => (
                                  <Box
                                    key={inputPowerSupply.id}
                                    position="relative"
                                    width="100%"
                                    height={powerLineSize}
                                  >
                                    {(
                                      powerSupplyHasInputConnectionFromLift(inputPowerSupply, lift) ||
                                      powerSupplyHasInputConnectionFromSomeLiftBeforeOf(
                                        inputPowerSupply,
                                        lift
                                      )
                                    ) && (
                                      <HorizontalInputPowerLine />
                                    )}
                                  </Box>
                                ))}

                                {/** The bottom gutter */}
                                <Box
                                  display="flex"
                                  width="100%"
                                  height={powerLineSize}
                                ></Box>
                              </Stack>
                            </Stack>
                          </Stack>
                        </TableCell>
                      ))}

                      <TableCell sx={{ padding: 0, width: '0px' }}>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          height="100%"
                        >
                          {/**
                           * Connections from unknown area to itself
                           */}
                          <Stack direction="column">
                            {/** The top gutter */}
                            <Box
                              display="flex"
                              width={powerLineSize}
                              height={powerLineSize}
                            >
                            </Box>

                            {/** Output connections from unknown area */}
                            {[...getPowerSuppliesOfUnknownArea()].reverse().map((powerSupply) => (
                              <Box
                                key={powerSupply.id}
                                position="relative"
                                width={powerLineSize}
                                height={powerLineSize}
                              >
                                {powerSupplyHasOutputConnection(powerSupply) && (
                                  <HorizontalOutputPowerLine />
                                )}

                                {(
                                  powerSupplyHasOutputConnectionToUnknownArea(powerSupply) ||
                                  somePowerSupplyHasOutputConnectionToUnknownArea(
                                    getPowerSuppliesOfAreaAfterOf(powerSupply)
                                  )
                                ) && (
                                  <VerticalLevelPowerLine />
                                )}
                              </Box>
                            ))}

                            {/** Middle connections from unknown area to unknown area */}
                            <Box
                              position="relative"
                              width={powerLineSize}
                              height="168px"
                            >
                              {somePowerSupplyHasInputConnectionFromUnknownArea(
                                getPowerSuppliesOfUnknownArea()
                              ) && (
                                <VerticalLevelPowerLine />
                              )}
                            </Box>

                            {/** Input connections from unknown area to unknown area */}
                            {[...getPowerSuppliesOfUnknownArea()].map((powerSupply) => (
                              <Box
                                key={powerSupply.id}
                                position="relative"
                                width={powerLineSize}
                                height={powerLineSize}
                              >
                                {powerSupplyHasInputConnection(powerSupply) && (
                                  <HorizontalInputPowerLine />
                                )}

                                {(
                                  powerSupplyHasInputConnectionFromUnknownArea(powerSupply) ||
                                  somePowerSupplyHasInputConnectionFromUnknownArea(
                                    getPowerSuppliesOfAreaAfterOf(powerSupply)
                                  )
                                ) && (
                                  <VerticalLevelPowerLine />
                                )}
                              </Box>
                            ))}

                            {/** The bottom gutter */}
                            <Box
                              display="flex"
                              width={powerLineSize}
                              height={powerLineSize}
                            ></Box>
                          </Stack>

                          {[...getPowerSuppliesOfUnknownArea()].map((powerSupply) => (
                            <Stack direction="column" spacing={0} key={powerSupply.id}>
                              {/** The top gutter */}
                              <Box
                                display="flex"
                                width="100%"
                                height={powerLineSize}
                              ></Box>

                              {/* Output connections */}
                              {[...getPowerSuppliesOfUnknownArea()].reverse().map((outputPowerSupply, index) => (
                                <Stack
                                  direction="row"
                                  spacing={0}
                                  height={powerLineSize}
                                  key={outputPowerSupply.id}
                                >
                                  <Box
                                    display="flex"
                                    width="50%"
                                    height={powerLineSize}
                                    position="relative"
                                  >
                                    {(
                                      powerSupplyHasOutputConnection(outputPowerSupply) && (
                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply) ||
                                        powerSupplyIsSameAsTheOther(outputPowerSupply, powerSupply)
                                      )
                                    ) && (
                                      <HorizontalOutputPowerLine />
                                    )}
                                  </Box>
                                  <Box
                                    display="flex"
                                    width="50%"
                                    height={powerLineSize}
                                    position="relative"
                                  >
                                    {(
                                      powerSupplyHasOutputConnection(outputPowerSupply) && (
                                        powerSupplyIsAfterTheOther(outputPowerSupply, powerSupply)
                                      )
                                    ) && (
                                      <HorizontalOutputPowerLine />
                                    )}

                                    {(
                                      powerSupplyHasOutputConnection(powerSupply) && (
                                        powerSupplyIsAfterTheOther(powerSupply, outputPowerSupply) ||
                                        powerSupplyIsSameAsTheOther(powerSupply, outputPowerSupply)
                                      )
                                    ) && (
                                      <VerticalInputOutputPowerLine
                                        useAnimation={powerSupply.device?.status ?? false}
                                        animationDelay={`${index * 0.5}s`}
                                      />
                                    )}
                                  </Box>
                                </Stack>
                              ))}

                              <Box flexGrow="1" paddingX={3}>
                                <PowerSupplyCard key={powerSupply.id} powerSupply={powerSupply} alertRules={alertRules} />
                              </Box>

                              {/* Input connections */}
                              {[...getPowerSuppliesOfUnknownArea()].map((inputPowerSupply, index) => (
                                <Stack
                                  direction="row"
                                  spacing={0}
                                  height={powerLineSize}
                                  key={inputPowerSupply.id}
                                >
                                  <Box
                                    display="flex"
                                    width="50%"
                                    height={powerLineSize}
                                    position="relative"
                                  >
                                    {(
                                      powerSupplyHasInputConnection(inputPowerSupply) && (
                                        powerSupplyIsAfterTheOther(inputPowerSupply, powerSupply) ||
                                        powerSupplyIsSameAsTheOther(inputPowerSupply, powerSupply)
                                      )
                                    ) && (
                                      <HorizontalInputPowerLine />
                                    )}
                                  </Box>
                                  <Box
                                    display="flex"
                                    width="50%"
                                    height={powerLineSize}
                                    position="relative"
                                  >
                                    {(
                                      powerSupplyHasInputConnection(inputPowerSupply) && (
                                        powerSupplyIsAfterTheOther(inputPowerSupply, powerSupply)
                                      )
                                    ) && (
                                      <HorizontalInputPowerLine />
                                    )}

                                    {(
                                      powerSupplyHasInputConnection(powerSupply) && (
                                        powerSupplyIsAfterTheOther(powerSupply, inputPowerSupply) ||
                                        powerSupplyIsSameAsTheOther(powerSupply, inputPowerSupply)
                                      )
                                    ) && (
                                      <VerticalInputOutputPowerLine
                                        useAnimation={powerSupply.device?.status ?? false}
                                        animationDelay={`${-1 * index * 0.5}s`}
                                      />
                                    )}
                                  </Box>
                                </Stack>
                              ))}

                              {/** The bottom gutter  */}
                              <Box
                                display="flex"
                                width="100%"
                                height={powerLineSize}
                              ></Box>
                            </Stack>
                          ))}    
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
    </React.Fragment>
  )
})

export default PowerSupplyHierarchy
