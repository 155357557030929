import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Outlet } from 'react-router-dom'

import {
  Alert,
  AlertColor,
  Box,
  Collapse,
  CssBaseline,
  Paper as MuiPaper
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { spacing } from '@mui/system'

import GlobalStyle from '../components/GlobalStyle'
import Navbar from '../components/navbar/Navbar'
import dashboardItems from '../components/sidebar/dashboardItems'
import Sidebar from '../components/sidebar/Sidebar'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { createNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'

const drawerWidth = 258

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

const Dashboard: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const dispatch = useDispatch()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const { notification } = useSelector(
    (state: RootState) => state.notifications
  )

  const navbarHeight = 64

  const handleClear = () => {
    dispatch(
      createNotification({
        show: false,
        type: '',
        message: '',
        timeout: 0
      })
    )
  }

  useEffect(() => {
    if (
      notification?.type === NotificationType.SUCCESS ||
      notification?.type === NotificationType.WARNING ||
      notification?.type === NotificationType.INFO
    ) {
      setTimeout(() => {
        handleClear()
      }, notification.timeout)
    } else {
      return
    }
  }, [notification?.show])

  const sev = notification?.type as AlertColor
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Root style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
      <CssBaseline />
      <GlobalStyle />

      <>
        {isMobile ? (
          <Sidebar
            key={location.pathname}
            variant="temporary"
            PaperProps={{ style: { width: drawerWidth } }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        ) : (
          <Sidebar
            variant="permanent"
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        )}
      </>

      <AppContent
        style={{
          maxHeight: '100vh',
          overflowY: 'hidden',
          marginLeft: isMobile ? 0 : `${drawerWidth}px`
        }}
      >
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent
          sx={{
            p: isLgUp ? 12 : 5,
            borderRadius: 0,
            maxHeight: `calc(100vh - ${navbarHeight}px)`,
            overflowY: 'auto'
          }}
        >
          <Collapse in={notification?.show}>
            <Alert
              variant={'filled'}
              severity={sev}
              onClose={() => handleClear()}
            >
              {notification?.message}
            </Alert>
          </Collapse>

          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  )
}

export default Dashboard
