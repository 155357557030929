import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  MenuItem,
  Stack,
  Box
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { UserRole } from '../enums/UserRole'
import { getCompanies } from '../redux/slices/companies'
import CompanySelect from './CompanySelect'
import { createUser, setShow, setUser, updateUser } from '../redux/slices/users'
import useAuth from '../hooks/useAuth'
import { useUser } from '../hooks'
import UserRoleSelect from './UserRoleSelect'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import userService from '../services/userService'
import { deleteError, setError } from '../redux/slices/errors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { UserErrors } from '../enums/UserErrors'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import UserInput from '../types/UserInput'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function UserForm() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { theme } = useTheme()
  const { currentUser } = useAuth()
  const user = useUser()

  const initialValues: UserInput =
    user === undefined
      ? {
          role: UserRole.USER,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: null,
          language: 'en',
          notificationsByEmail: false,
          notificationsBySms: false
        }
      : {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          role: user.role,
          // FIXME: company: user.companyId
          company: user.company?.id,
          language: user.language,
          notificationsByEmail: user.notificationsByEmail,
          notificationsBySms: user.notificationsBySms
        }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    role: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    notificationsByEmail: Yup.boolean().required('Required'),
    notificationsBySms: Yup.boolean().required('Required')
  })

  useEffect(() => {
    if (currentUser?.role === UserRole.SUPERADMIN) {
      dispatch(getCompanies())
    }
  }, [])

  useEffect(() => {
    return function cleanup() {
      dispatch(setShow(false))
    }
  }, [dispatch])
  const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone as string)

  async function handleSubmit(
    values: UserInput,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      if (
        values.phone !== undefined &&
        values.phone !== '+' &&
        values.phone !== '' &&
        values.phone !== null
      ) {
        //Add '+' to phone number since PhoneInput does not provide it
        values.phone.charAt(0) === '+'
          ? values.phone
          : (values.phone = '+' + values.phone)
      } else if (values.phone === '+' || values.phone === '') {
        //if user wants to clear phonenumber on update we need to do this otherwise '+' is saved to database.
        values.phone = null
      }
      if (user === undefined) {
        const createdUser = await userService.createUser(values)
        dispatch(createUser(createdUser))

        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('userCreateSuccess')
          )
        )
      } else {
        const updatedUser = await userService.updateUser(user.id, values)
        dispatch(updateUser(updatedUser))
        dispatch(setUser(updatedUser))
        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('userUpdateSuccess')
          )
        )
      }

      resetForm()
      dispatch(setShow(false))
    } catch (error: unknown) {
      dispatch(
        setError({
          type: user ? UserErrors.EDIT : UserErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: user ? UserErrors.EDIT : UserErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(user ? UserErrors.EDIT : UserErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Formik
        initialValues={
          user === undefined
            ? initialValues
            : {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                role: user.role,
                company: user.company?.id,
                language: user.language,
                notificationsByEmail: user.notificationsByEmail,
                notificationsBySms: user.notificationsBySms
              }
        }
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="firstName"
                  label={t('Users.firstName')}
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="lastName"
                  label={t('Users.lastName')}
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={user && currentUser?.role !== UserRole.SUPERADMIN}
                  name="email"
                  label={t('Users.email')}
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <PhoneInput
                  enableSearch={true}
                  specialLabel=""
                  placeholder={t('Users.phone')}
                  value={(values.phone = phoneNumber)}
                  onChange={(newValue) => setPhoneNumber(newValue)}
                  containerStyle={{
                    width: '100%'
                  }}
                  inputStyle={{
                    width: '100%',
                    background: theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                    color: theme === THEMES.DARK ? 'white' : 'black',
                    fontSize: 13,
                    borderColor: '#777777'
                  }}
                  dropdownStyle={{
                    background: theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                    color: 'gray'
                  }}
                />
              </Grid>

              {currentUser && currentUser.role === UserRole.SUPERADMIN && (
                <>
                  <Grid item xs={12} md={6}>
                    <UserRoleSelect
                      name="role"
                      select
                      value={values.role}
                      error={Boolean(touched.role && errors.role)}
                      fullWidth
                      helperText={touched.role && errors.role}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CompanySelect
                      name="company"
                      label={t('Users.company')}
                      select
                      required
                      value={values.company}
                      error={Boolean(touched.company && errors.company)}
                      fullWidth
                      helperText={touched.company && errors.company}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ mt: { md: 2 } }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={4}>
                <TextField
                  name="language"
                  label={t('Users.language')}
                  select
                  value={values.language}
                  error={Boolean(touched.language && errors.language)}
                  fullWidth
                  helperText={touched.language && errors.language}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'fi'}>Finnish</MenuItem>
                  <MenuItem value={'swe'}>Swedish</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  name="notificationsByEmail"
                  label={t('Users.emailNotification')}
                  select
                  value={values.notificationsByEmail}
                  error={Boolean(
                    touched.notificationsByEmail && errors.notificationsByEmail
                  )}
                  fullWidth
                  helperText={
                    touched.notificationsByEmail && errors.notificationsByEmail
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                  <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  name="notificationsBySms"
                  label={t('Users.smsNotification')}
                  select
                  value={values.notificationsBySms}
                  error={Boolean(
                    touched.notificationsBySms && errors.notificationsBySms
                  )}
                  fullWidth
                  helperText={
                    touched.notificationsBySms && errors.notificationsBySms
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                  <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  gap={3}
                  sx={{ mt: 3 }}
                >
                  <Button onClick={() => dispatch(setShow(false))}>
                    {t('cancel')}
                  </Button>

                  <Button type="submit" variant="contained" color="primary">
                    {t('save')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  )
}
