import {
  LinearProgressProps as MuiLinearProgressProps,
  LinearProgress as MuiLinearProgress,
  Stack,
  Typography
} from '@mui/material'
import { Project } from '../types'
import { calculateProjectProgress } from '../utils/projectProgress'
import { styled, useTheme } from '@mui/system'
import { humanizeDate } from '../utils/date'
import { useTranslation } from 'react-i18next'

const LinearProgress = styled(MuiLinearProgress)<MuiLinearProgressProps>(
  ({ theme }) => `
  height: 16px;
  width: 100%;
  border-radius: 2px;
  background: ${theme.palette.grey[200]};
`
)

interface ProjectProgressBarProps {
  project: Project
}

export default function ProjectProgressBar({
  project
}: ProjectProgressBarProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The progress as a percentage.
   */
  const progress = calculateProjectProgress(project.startDate, project.endDate)

  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t('progress')}
      </Typography>

      <Stack direction="row" alignItems="center" spacing={6}>
        <Stack direction="column" spacing={1} flexGrow={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              {humanizeDate(project.startDate)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {humanizeDate(project.endDate)}
            </Typography>
          </Stack>

          <LinearProgress
            color="success"
            variant="determinate"
            value={progress}
          />
        </Stack>
        <Typography variant="h6" sx={{ alignSelf: 'flex-end' }}>
          {progress}%
        </Typography>
      </Stack>
    </>
  )
}
