import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { ProjectMembershipQuery, ProjectMembership } from '../../types'
import projectMembershipService from '../../services/projectMembershipService'

interface ProjectMembershipsState {
  memberships: ProjectMembership[]
  membership: ProjectMembership | undefined
  updateMembership: ProjectMembership | undefined
  show: boolean
  loading: boolean
}

const initialState: ProjectMembershipsState = {
  memberships: [],
  membership: undefined,
  updateMembership: undefined,
  show: false,
  loading: false
}

const projectMembershipSlice = createSlice({
  name: 'projectMemberships',
  initialState,
  reducers: {
    setMemberships(state, action) {
      state.memberships = action.payload
    },
    setMembership(state, action) {
      state.membership = action.payload
    },
    setUpdateMembership(state, action) {
      state.updateMembership = action.payload
    },
    updateMembershipState(state, action) {
      state.memberships = state.memberships
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    showMembership(state, action) {
      state.updateMembership = action.payload
      state.show = true
    },
    hideMembership(state) {
      state.updateMembership = undefined
      state.show = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const {
  setMemberships,
  setMembership,
  setUpdateMembership,
  updateMembershipState,
  showMembership,
  hideMembership
} = projectMembershipSlice.actions

export function getProjectMembership(query: ProjectMembershipQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectMembershipSlice.actions.setLoading(true))
      const membership = await projectMembershipService.getProjectMembership(
        query
      )
      dispatch(projectMembershipSlice.actions.setMembership(membership))
    } catch (error: unknown) {
      console.error(error)
    } finally {
      dispatch(projectMembershipSlice.actions.setLoading(false))
    }
  }
}

export default projectMembershipSlice.reducer
