import { createContext, useContext, useEffect, useState } from 'react'
import { useApi } from '../hooks'
import { ConfluencePage } from '../types'

/**
 * The user manual context.
 */
const UserManualContext = createContext<ConfluencePage | null>(null)

/**
 * The user manual provider.
 */
export function UserManualProvider({
  pageId,
  children
}: {
  pageId: string
  children: React.ReactNode
}) {
  /**
   * The user manual.
   */
  const [page, setPage] = useState<ConfluencePage | null>(null)

  /**
   * The axios client.
   */
  const axios = useApi('v2')

  /**
   * Loads the user manual content.
   */
  async function loadUserManual(signal: AbortSignal): Promise<void> {
    try {
      const response = await axios.get(`/user-manual/pages/${pageId}`, {
        params: {
          'body-format': 'view'
        },
        signal
      })

      setPage(response.data)
    } catch (err: any) {
      console.error(err)
    }
  }

  /**
   * Runs when the component mounts.
   */
  useEffect(() => {
    const controller = new AbortController()

    loadUserManual(controller.signal)

    return () => controller.abort()
  }, [pageId])

  return (
    <UserManualContext.Provider value={page}>
      {children}
    </UserManualContext.Provider>
  )
}

/**
 * The user manual hook.
 */
export function useUserManual(): ConfluencePage | null {
  return useContext(UserManualContext)
}
