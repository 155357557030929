import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ChipPropsColorOverrides,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Device } from '../../types'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import CodeInput from '../../components/DeviceCodeInput'
import { getDevice, replaceDevice } from '../../services/deviceService'
import { ArrowForward, PublishedWithChanges, QrCode } from '@mui/icons-material'
import LoadingState from '../../components/LoadingState'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/system'
import { useDispatch } from 'react-redux'
import { createNotification } from '../../redux/slices/notifications'
import { Helmet } from 'react-helmet-async'
import { Field } from 'formik'
import axios from 'axios'

interface DeviceReplacementDetailsProps {
  device: Device
}

function DeviceReplacementDetails({ device }: DeviceReplacementDetailsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  return (
    <Grid container spacing={6} flexDirection="column">
      <Grid item xs={12}>
        <List>
          {device.asset?.name !== device.name && (
            <ListItemButton key="assetName">
              <ListItemText
                primary={device.asset?.name ?? '-'}
                secondary={t('name')}
              />
            </ListItemButton>
          )}

          <ListItemButton key="name">
            <ListItemText primary={device.name} secondary={t('serialNumber')} />
          </ListItemButton>

          <ListItemButton key="shortUUID">
            <ListItemText
              primary={device.shortUUID}
              secondary={t('deviceCode')}
            />
          </ListItemButton>

          <ListItemButton key="fuse">
            <ListItemText
              primary={device?.powerSupply?.fuse ?? '-'}
              secondary={t('fuse')}
            />
          </ListItemButton>

          <ListItemButton key="inputCables">
            <ListItemText
              primary={device?.powerSupply?.inputCables ?? '-'}
              secondary={t('inputCables')}
            />
          </ListItemButton>

          <ListItemButton key="company">
            <ListItemText
              primary={device.asset?.company?.name ?? '-'}
              secondary={t('company')}
            />
          </ListItemButton>

          <ListItemButton key="project">
            <ListItemText
              primary={device.asset?.project?.name ?? '-'}
              secondary={t('project')}
            />
          </ListItemButton>

          <ListItemButton key="level">
            <ListItemText
              primary={device.asset?.level?.name ?? '-'}
              secondary={t('level')}
            />
          </ListItemButton>

          <ListItemButton key="lift">
            <ListItemText
              primary={device.asset?.lift?.name ?? '-'}
              secondary={t('lift')}
            />
          </ListItemButton>
        </List>
      </Grid>
    </Grid>
  )
}

interface DeviceScannerProps {
  onChange?: (device: Device) => void
  onError?: (error: unknown) => void
}

function DeviceScanner({ onChange, onError }: DeviceScannerProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The device code.
   */
  const [deviceCode, setDeviceCode] = useState<string>('')

  /**
   * Indicates if device is being loaded.
   */
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Indicates if access was denied.
   */
  const [accessWasDenied, setAccessWasDenied] = useState<boolean>(false)

  /**
   * Handle the change event.
   */
  const handleChange = (deviceCode: string) => {
    setDeviceCode(deviceCode)
  }

  /**
   * Load the device.
   */
  const loadDevice = async (signal: AbortSignal) => {
    try {
      setAccessWasDenied(false)
      setIsLoading(true)

      const device = await getDevice(deviceCode, { signal })

      if (onChange) {
        onChange(device)
      }
    } catch (error: unknown) {
      if (onError) {
        onError(error)
      }

      if (axios.isAxiosError(error) && error?.response?.status === 403) {
        setAccessWasDenied(true)
      }

      dispatch(
        createNotification({
          show: true,
          type: 'warning',
          message: t('deviceWasNotFound'),
          timeout: 5000
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Load the device when the device code changes.
   */
  useEffect(() => {
    const controller = new AbortController()

    // Check if the device code is valid.
    if (deviceCode.length === 6) {
      loadDevice(controller.signal)
    }

    return () => controller.abort()
  }, [deviceCode])

  return (
    <>
      {isLoading ? (
        <LoadingState />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Typography fontWeight={500} sx={{ mb: 3 }}>
              {t('scanQrCode')}
            </Typography>

            {accessWasDenied && (
              <Alert severity="info" sx={{ mb: 3 }}>
                {t('accessWasDenied')}
              </Alert>
            )}

            <QrReader
              scanDelay={500}
              constraints={{ facingMode: 'environment' }}
              onResult={(result) => {
                if (result) {
                  const regex = new RegExp(
                    /^https:\/\/qr\.spine-electric\.io\/[0-9]{6}$/
                  )
                  if (regex.test(result.getText())) {
                    handleChange(result.getText().substring(29))
                  }
                }
              }}
              videoContainerStyle={{
                backgroundColor: 'black'
              }}
            />

            <CodeInput onChange={handleChange} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

interface DeviceReplacementPreviewProps {
  replaceable: Device
  replacement: Device
}

interface FieldComparisonProps {
  values: [
    string | number | null | undefined,
    string | number | null | undefined
  ]
}

function FieldComparison({ values }: FieldComparisonProps) {
  /**
   * The replaced and replacement values.
   */
  const [replaced, replacement] = values

  /**
   * The theme.
   */
  const theme = useTheme()

  return (
    <>
      {replaced === replacement ? (
        <Typography>{replaced}</Typography>
      ) : (
        <Stack direction="row" alignItems="center" spacing={3}>
          {/*<Chip
            variant="outlined"
            color="error"
            size="small"
            label={replaced ?? '-'}
            sx={{
              textDecoration: 'line-through'
            }}
          />*/}
          <Typography
            color={theme.palette.text.disabled}
            sx={{ textDecoration: 'line-through' }}
          >
            {replaced ?? '-'}
          </Typography>
          <ArrowForward fontSize="inherit" />
          <Chip
            variant="filled"
            color="success"
            size="small"
            label={replacement ?? '-'}
          />
        </Stack>
      )}
    </>
  )
}

interface StepAccordionProps extends Omit<AccordionProps, 'children'> {
  label: string
  device: Device
  color: 'success' | 'error'
}

function StepAccordion({ label, device, color, ...props }: StepAccordionProps) {
  return (
    <Accordion
      {...props}
      disableGutters={true}
      sx={{ boxShadow: 'none', mb: 1 }}
    >
      <AccordionSummary>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{ width: '100%', px: 2 }}
        >
          <Typography variant="h6">{label}</Typography>
          <Stack direction="row" alignItems="center" gap={3}>
            <Typography>{device?.asset?.name ?? device.name}</Typography>
            <Chip
              variant="outlined"
              color={color}
              size="small"
              icon={<QrCode fontSize="inherit" />}
              label={device.shortUUID}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <DeviceReplacementDetails device={device} />
      </AccordionDetails>
    </Accordion>
  )
}

function DeviceReplacementPreview({
  replaceable,
  replacement
}: DeviceReplacementPreviewProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  return (
    <Grid container spacing={6} flexDirection="column">
      <Grid item xs={12}>
        <List>
          {(replaceable.asset?.name || replacement.asset?.name) && (
            <ListItemButton key="name">
              <ListItemText
                primary={
                  <>
                    {replaceable.asset?.name !== replaceable.name ? (
                      <FieldComparison
                        values={[
                          replacement.asset?.name,
                          replaceable.asset?.name
                        ]}
                      />
                    ) : replacement.asset?.name !== replacement.name ? (
                      replacement.asset?.name
                    ) : null}
                  </>
                }
                secondary={t('name')}
              />
            </ListItemButton>
          )}

          <ListItemButton key="name">
            <ListItemText
              primary={replacement.name}
              secondary={t('serialNumber')}
            />
          </ListItemButton>

          <ListItemButton key="shortUUID">
            <ListItemText
              primary={replacement.shortUUID}
              secondary={t('deviceCode')}
            />
          </ListItemButton>

          <ListItemButton key="fuse">
            <ListItemText
              primary={
                <FieldComparison
                  values={[
                    replacement?.powerSupply?.fuse,
                    replaceable?.powerSupply?.fuse
                  ]}
                />
              }
              secondary={t('fuse')}
            />
          </ListItemButton>
          <ListItemButton key="inputCables">
            <ListItemText
              primary={
                <FieldComparison
                  values={[
                    replacement?.powerSupply?.inputCables,
                    replaceable?.powerSupply?.inputCables
                  ]}
                />
              }
              secondary={t('inputCables')}
            />
          </ListItemButton>

          {(replaceable.asset?.company || replacement.asset?.company) && (
            <ListItemButton key="company">
              <ListItemText
                primary={
                  <>
                    {replacement.asset?.company === null ? (
                      <FieldComparison
                        values={[undefined, replaceable.asset?.company?.name]}
                      />
                    ) : (
                      replacement.asset?.company?.name
                    )}
                  </>
                }
                secondary={t('company')}
              />
            </ListItemButton>
          )}

          {(replaceable.asset?.project || replacement.asset?.project) && (
            <ListItemButton key="project">
              <ListItemText
                primary={
                  <FieldComparison
                    values={[
                      replacement.asset?.project?.name,
                      replaceable.asset?.project?.name
                    ]}
                  />
                }
                secondary={t('project')}
              />
            </ListItemButton>
          )}

          {(replaceable.asset?.level || replacement.asset?.level) && (
            <ListItemButton key="level">
              <ListItemText
                primary={
                  <FieldComparison
                    values={[
                      replacement.asset?.level?.name,
                      replaceable.asset?.level?.name
                    ]}
                  />
                }
                secondary={t('level')}
              />
            </ListItemButton>
          )}

          {(replaceable.asset?.lift || replacement.asset?.lift) && (
            <ListItemButton key="lift">
              <ListItemText
                primary={
                  <FieldComparison
                    values={[
                      replacement.asset?.lift?.name,
                      replaceable.asset?.lift?.name
                    ]}
                  />
                }
                secondary={t('lift')}
              />
            </ListItemButton>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

export default function DeviceReplace() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The active step.
   */
  const [activeStep, setActiveStep] = useState(0)

  /**
   * The device to be replaced.
   */
  const [replaceableDevice, setReplaceableDevice] = useState<
    Device | undefined
  >()

  /**
   * The replacement device.
   */
  const [replacementDevice, setReplacementDevice] = useState<
    Device | undefined
  >()

  /**
   * Indicates if device is being loaded.
   */

  /**
   * Indicates if replacing is in progress.
   */
  const [isReplacing, setIsReplacing] = useState(false)

  /**
   * Indicate if replacing is completed.
   */
  const [isReplaced, setIsReplaced] = useState(false)

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  /**
   * Handle the change of replaceable device.
   */
  function handleReplaceableDevice(device: Device) {
    if (device?.powerSupply === null || device?.powerSupply === undefined) {
      dispatch(
        createNotification({
          show: true,
          type: 'warning',
          message: t('cannotReplaceDeviceWithoutPowerSupply'),
          timeout: 5000
        })
      )
    } else {
      setReplaceableDevice(device)
    }
  }

  /**
   * Handle the change of replacement device.
   */
  function handleReplacementDeviceChange(device: Device) {
    if (device.name === replaceableDevice?.name) {
      dispatch(
        createNotification({
          show: true,
          type: 'warning',
          message: t('cannotReplaceDeviceWithSameDevice'),
          timeout: 5000
        })
      )
    } else {
      setReplacementDevice(device)
    }
  }

  /**
   * Handle the replace event.
   */
  async function handleReplace(): Promise<void> {
    try {
      if (replaceableDevice !== undefined && replacementDevice !== undefined) {
        setIsReplacing(true)
        await replaceDevice(replaceableDevice.name, replacementDevice.name)
        setIsReplaced(true)
      }
    } catch (error: unknown) {
      // TODO: Handle errors.
      console.error(error)
    } finally {
      setIsReplacing(false)
    }
  }

  return (
    <>
      <Helmet title={t('replaceDevice')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('replaceDevice')}
      </Typography>

      <Divider sx={{ my: 6 }} />

      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        sx={{ mb: 6 }}
      >
        <Step
          key={0}
          completed={activeStep > 0 && replaceableDevice !== undefined}
        >
          <StepButton color="inherit">
            {t('selectReplaceableDevice')}
          </StepButton>
        </Step>
        <Step
          key={1}
          completed={activeStep > 1 && replacementDevice !== undefined}
        >
          <StepButton color="inherit">
            {t('selectReplacementDevice')}
          </StepButton>
        </Step>
        <Step key={2} completed={isReplaced}>
          <StepButton color="inherit">{t('confirmReplacement')}</StepButton>
        </Step>
      </Stepper>

      {replaceableDevice && activeStep > 0 && (
        <StepAccordion
          label={t('replaceableDevice')}
          device={replaceableDevice}
          color="error"
        />
      )}

      {replacementDevice && activeStep > 1 && (
        <StepAccordion
          label={t('replacementDevice')}
          device={replacementDevice}
          color="success"
        />
      )}

      {activeStep === 0 ? (
        <Card>
          <CardHeader title={t('selectReplaceableDevice')} />
          <CardContent>
            {replaceableDevice ? (
              <DeviceReplacementDetails device={replaceableDevice} />
            ) : (
              <DeviceScanner onChange={handleReplaceableDevice} />
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                if (replaceDevice !== undefined) {
                  setReplaceableDevice(undefined)
                } else {
                  navigate('/')
                }
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={replaceableDevice === undefined}
              onClick={() => setActiveStep(1)}
            >
              {t('next')}
            </Button>
          </CardActions>
        </Card>
      ) : activeStep === 1 ? (
        <Card>
          <CardHeader title={t('selectReplacementDevice')} />
          <CardContent>
            {replacementDevice ? (
              <DeviceReplacementDetails device={replacementDevice} />
            ) : (
              <DeviceScanner onChange={handleReplacementDeviceChange} />
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {replacementDevice === undefined ? (
              <Button
                onClick={() => {
                  setActiveStep(0)
                  setReplaceableDevice(undefined)
                }}
              >
                {t('previous')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setReplacementDevice(undefined)
                }}
              >
                {t('cancel')}
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              disabled={replacementDevice === undefined}
              onClick={() => setActiveStep(2)}
            >
              {t('next')}
            </Button>
          </CardActions>
        </Card>
      ) : activeStep === 2 ? (
        <Card>
          <CardHeader title={t('confirmReplacement')} />
          <CardContent>
            {isReplacing ? (
              <LoadingState />
            ) : isReplaced ? (
              <Alert severity="success">{t('deviceReplaced')}</Alert>
            ) : replaceableDevice && replacementDevice ? (
              <DeviceReplacementPreview
                replaceable={replaceableDevice}
                replacement={replacementDevice}
              />
            ) : null}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {isReplaced && replacementDevice ? (
              <Button
                variant="contained"
                color="success"
                component={NavLink}
                to={`/devices/${replacementDevice.name}`}
              >
                {t('showDevice')}
              </Button>
            ) : !isReplacing ? (
              <>
                <Button
                  onClick={() => {
                    setActiveStep(0)
                    setReplacementDevice(undefined)
                    setReplaceableDevice(undefined)
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReplace}
                >
                  {t('confirm')}
                </Button>
              </>
            ) : null}
          </CardActions>
        </Card>
      ) : null}
    </>
  )
}
