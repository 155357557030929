import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Skeleton,
  Link
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getProjects,
  getProjectsByCompanyId
} from '../../redux/slices/projects'
import { getDevice } from '../../redux/slices/devices'
import DeviceForm from '../../components/DeviceForm'
import { useAuth, useDevice } from '../../hooks'
import PageHeader from '../../components/PageHeader'
import { RootState } from '../../redux/store'
import LoadingState from '../../components/LoadingState'
import { UserRole } from '../../enums/UserRole'
import device_groupService from '../../services/device_groupService'
import { NavLink } from 'react-router-dom'
import DeviceHeading from './DeviceHeading'

const Card = styled(MuiCard)(spacing)
const CardHeader = styled(MuiCardHeader)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

export default function EditDevice() {
  const [t] = useTranslation('common')
  const { id } = useParams()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const device = useDevice()
  const navigate = useNavigate()

  const isLoadingDevice = useSelector(
    (state: RootState) => state.devices.loading
  )

  const title = device?.asset?.name
    ? device.asset.name
    : device
    ? device.name
    : ''

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getDevice(id))
    }
  }, [currentUser, id])

  if (isLoadingDevice || device === undefined) {
    return <LoadingState />
  }

  return (
    <>
      <PageHeader
        title={device?.asset?.name ?? device?.name ?? t('loading')}
        heading={<DeviceHeading device={device} />}
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>

            {device?.asset?.project ? (
              <Link component={NavLink} to="/projects">
                {t('projects')}
              </Link>
            ) : (
              device && (
                <Link component={NavLink} to="/devices">
                  {t('devices')}
                </Link>
              )
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project.id}`}
              >
                {device?.asset?.project.name}
              </Link>
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project.id}#monitoring`}
              >
                {t('deviceMonitoring')}
              </Link>
            )}

            {device ? (
              <Link component={NavLink} to={`/devices/${device?.name}`}>
                {device?.asset?.name ?? device?.name}
              </Link>
            ) : (
              <Skeleton variant="text" width="100px" />
            )}

            <Typography>{t('edit')}</Typography>
          </Breadcrumbs>
        }
      />

      <Card sx={{ mt: 6 }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CardHeader title={t('editDevice')} />
          </Grid>
          {device.asset && device.asset.project && (
            <Grid item xs={6}>
              <Typography align="right" sx={{ mr: 2, mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/devices/${device.name}/location`)}
                >
                  {t('moveOrDetach')}
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>
        <CardContent>
          <DeviceForm />
        </CardContent>
      </Card>
    </>
  )
}
