import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  Divider as MuiDivider,
  Typography,
  Skeleton,
  Box,
  Breadcrumbs
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../../redux/store'
import { getDevicesByCompanyId, fetchDevices } from '../../redux/slices/devices'
import useAuth from '../../hooks/useAuth'
import { UserRole } from '../../enums/UserRole'
import DeviceManager from '../../components/DeviceManager'
import PageHeader, { PageTitle } from '../../components/PageHeader'
import { NavLink } from 'react-router-dom'

const Divider = styled(MuiDivider)(spacing)

export default function Devices() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The devices state.
   */
  const { devices, loading } = useSelector((state: RootState) => state.devices)

  /**
   * Loads devices when the current user changes.
   */
  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(fetchDevices())
      } else if (currentUser?.role === UserRole.ADMIN && currentUser?.company) {
        dispatch(getDevicesByCompanyId(currentUser.company.id))
      }
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <PageHeader
        title={t('Devices.devices')}
        heading={
          <>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                sx={{ fontSize: '1.5rem' }}
              />
            ) : (
              <PageTitle>{t('Devices.devices')}</PageTitle>
            )}
          </>
        }
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>
            <Typography>{t('Devices.devices')}</Typography>
          </Breadcrumbs>
        }
      />
      <Box mt={6}>
        <DeviceManager devices={devices} loading={loading} />
      </Box>
    </React.Fragment>
  )
}
