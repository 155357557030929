import React, { LegacyRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  AppBar,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  useMediaQuery,
  Chip,
  Tooltip
} from '@mui/material'
import { useTheme } from '@mui/system'
import { THEMES } from '../constants'
import { RootState } from '../redux/store'
import DateRangeSelect from './DateRangeSelect'
import { setDateRange } from '../redux/slices/query'
import { humanizeDateRange } from '../utils/date'
import useAppSettings from '../hooks/useAppSettings'
import TimePeriod from '../enums/TimePeriod'
import { subDays, subHours } from 'date-fns'

/**
 * The props of the auto-refresh switch.
 */
interface AutoRefreshSwitchProps {
  /**
   * The value that indicates if the auto refresh is enabled.
   */
  value: boolean

  /**
   * Indicates if the auto-refresh switch is disabled.
   */
  disabled?: boolean

  /**
   * Handler for the change event.
   */
  onChange: {
    (event: React.ChangeEvent<HTMLInputElement>): void
  }
}

/**
 * A auto-refresh switch.
 */
function AutoRefreshSwitch({
  value,
  disabled = false,
  onChange
}: AutoRefreshSwitchProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <FormControlLabel
      control={
        <Switch
          color="success"
          size="small"
          disabled={disabled}
          checked={value}
          onChange={onChange}
        />
      }
      label={
        <>
          {value ? (
            <Tooltip title={t('dataIsRefreshedOncePerMinue') as string}>
              <Chip color="success" size="small" label="LIVE" sx={{ mr: 1 }} />
            </Tooltip>
          ) : (
            <Chip size="small" label="LIVE" sx={{ mr: 1 }} />
          )}
        </>
      }
      labelPlacement="start"
      sx={{ ml: 0 }}
    />
  )
}

interface DateRangeBarProps {
  includeOneHourOption?: boolean
  showDateRange?: boolean
  showAutoRefreshToggle?: boolean
  onAutoRefreshChange?: {
    (autoRefresh: boolean): void
  }
}

export default function DateRangeBar({
  includeOneHourOption = false,
  showDateRange = true,
  showAutoRefreshToggle = false,
  onAutoRefreshChange
}: DateRangeBarProps) {
  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  /**
   * The date range and time period.
   */
  const { dateRange, timePeriod } = useSelector(
    (state: RootState) => state.query
  )

  /**
   * Indicates if the auto refresh is enabled.
   */
  const [autoRefresh, setAutoRefresh] = useState<boolean>(
    appSettings?.sensorSettings?.autoRefreshData ?? true
  )

  /**
   * Indicates if the auto refresh is disabled.
   */
  const [isAutoRefreshDisabled, setIsAutoRefreshDisabled] = useState<boolean>(
    timePeriod === TimePeriod.CUSTOM
  )

  /**
   * Indicates if the bar should be fixed.
   */
  const [isFixed, setIsFixed] = useState<boolean>(false)

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  /**
   * The reference to the date range bar.
   */
  const dateRangeBar = useRef<HTMLDivElement>()

  /**
   * Handle the change of the auto-refresh switch.
   */
  function handleAutoRefreshSwitchChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setAutoRefresh(event.target.checked)

    if (onAutoRefreshChange) {
      onAutoRefreshChange(event.target.checked)
    }
  }

  /**
   * Get the width of the scroll bar.
   *
   * @see https://stackoverflow.com/a/13382873
   */
  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // forcing scrollbar to appear
    // outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps

    document.body.appendChild(outer)

    // Creating inner element and placing it in the container
    const inner = document.createElement('div')
    outer.appendChild(inner)

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

    if (outer.parentNode !== null) {
      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer)
    }

    return scrollbarWidth
  }

  /**
   * Handle scroll to the root element.
   *
   * @param event The scroll event.
   */
  function handleScroll(event: Event): void {
    // Check if the date range bar is out of bounds.
    const isDateRangeBarOutOfBounds =
      (dateRangeBar?.current?.getBoundingClientRect()?.top ?? 0) < 0

    setIsFixed(isDateRangeBarOutOfBounds)
  }

  // Runs when the component mounts.
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.addEventListener('scroll', handleScroll)
      // Check if the date range bar is out of bounds.
      const isDateRangeBarOutOfBounds =
        (dateRangeBar?.current?.getBoundingClientRect()?.top ?? 0) < 0

      if (isDateRangeBarOutOfBounds) {
        setIsFixed(true)
      }
      return () => root.removeEventListener('scroll', handleScroll)
    }
  }, [])

  /**
   * Disable auto refresh when using a custom time period.
   */
  useEffect(() => {
    if (
      timePeriod === TimePeriod.CUSTOM ||
      timePeriod === TimePeriod.DAY ||
      timePeriod === TimePeriod.WEEK ||
      timePeriod === TimePeriod.MONTH
    ) {
      setAutoRefresh(false)
      if (onAutoRefreshChange) {
        onAutoRefreshChange(false)
      }
      setIsAutoRefreshDisabled(true)
    } else {
      setIsAutoRefreshDisabled(false)
    }
  }, [timePeriod])

  /**
   * Update date range every minute if auto refresh is enabled.
   */
  useEffect(() => {
    const timer = setInterval(() => {
      if (autoRefresh) {
        const now = new Date()

        switch (timePeriod) {
          case TimePeriod.ONE_HOUR:
            dispatch(
              setDateRange({
                from: subHours(now, 1),
                to: now
              })
            )
            break

          case TimePeriod.TWENTYFOUR_HOURS:
            dispatch(
              setDateRange({
                from: subHours(now, 24),
                to: now
              })
            )
            break

          case TimePeriod.THREE_DAYS:
            dispatch(
              setDateRange({
                from: subDays(now, 3),
                to: now
              })
            )
            break

          case TimePeriod.SEVEN_DAYS:
            dispatch(
              setDateRange({
                from: subDays(now, 7),
                to: now
              })
            )
            break

          case TimePeriod.THIRTY_DAYS:
            dispatch(
              setDateRange({
                from: subDays(now, 30),
                to: now
              })
            )
            break

          default:
            break
        }
      } else {
        clearInterval(timer)
      }
    }, 60000)

    return () => clearInterval(timer)
  }, [autoRefresh])

  return (
    <>
      {(isMobile || isFixed) && (
        <AppBar
          position="fixed"
          sx={{
            top: isMobile ? 'auto' : '64px',
            bottom: isMobile ? 0 : 'auto',
            left: { xs: 0, md: '258px' },
            right: `${getScrollbarWidth()}px`,
            padding: '0.75rem 1.5rem 0.75rem 0.25rem',
            overflowX: 'auto',
            width: 'auto',
            boxShadow: 'none',
            // borderBottom: `2px solid ${theme.palette}?,
            background:
              theme.palette.mode === THEMES.DARK.toLocaleLowerCase()
                ? '#1B2635'
                : '#FFFFFFF2'
          }}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
            px={3}
          >
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              alignSelf={'center'}
            >
              <DateRangeSelect
                value={dateRange}
                includeOneHourOption={includeOneHourOption}
                marginTop="0 !important"
                size="small"
              />
            </Stack>

            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={'center'}
              gap={3}
            >
              {showDateRange && (
                <Typography
                  fontWeight={500}
                  color={theme.palette.text.primary}
                  display={{ xs: 'none', xl: 'block' }}
                  sx={{ marginTop: '0 !important' }}
                >
                  {humanizeDateRange(dateRange.from, dateRange.to)}
                </Typography>
              )}

              {showAutoRefreshToggle && (
                <AutoRefreshSwitch
                  value={autoRefresh}
                  disabled={isAutoRefreshDisabled}
                  onChange={handleAutoRefreshSwitchChange}
                />
              )}
            </Stack>
          </Stack>
        </AppBar>
      )}

      {!isMobile && (
        <>
          <div ref={dateRangeBar as LegacyRef<HTMLDivElement>}>
            <Stack
              ref={dateRangeBar}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={6}
              sx={{ pr: 2 }}
            >
              <DateRangeSelect
                value={dateRange}
                onChange={(dateRange) => dispatch(setDateRange(dateRange))}
                includeOneHourOption={includeOneHourOption}
                marginTop="0 !important"
                size="small"
              />

              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={3}
              >
                {showDateRange && (
                  <Typography
                    fontWeight={500}
                    color={theme.palette.text.primary}
                    display={{ xs: 'none', xl: 'block' }}
                    sx={{ marginTop: '0 !important' }}
                  >
                    {humanizeDateRange(dateRange.from, dateRange.to)}
                  </Typography>
                )}

                {showAutoRefreshToggle && (
                  <AutoRefreshSwitch
                    value={autoRefresh}
                    disabled={isAutoRefreshDisabled}
                    onChange={handleAutoRefreshSwitchChange}
                  />
                )}
              </Stack>
            </Stack>
          </div>
        </>
      )}
    </>
  )
}
