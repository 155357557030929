import React, { MouseEvent, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Stats from '../dashboards/Default/Stats'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
  Stack,
  Switch,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Chip,
  Skeleton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Alert,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  IconButton
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import DevicePowerStats from '../../components/DevicePowerStats'
import DeviceCurrentStats from '../../components/DeviceCurrentStats'
import DeviceEmissionStats from '../../components/DeviceEmissionStats'
import DeviceEnergyCostStats from '../../components/DeviceEnergyCostStats'
import LoadingState from '../../components/LoadingState'
import Sensor from '../../types/Sensor'
import { UserRole } from '../../enums/UserRole'
import {
  getSensors,
  getLastSensor,
  invokeAction,
  getStatus,
  getDevice
} from '../../services/deviceService'
import { addMinutes, startOfMinute, subDays, subHours } from 'date-fns'
import DeviceVoltageStats from '../../components/DeviceVoltageStats'
import SensorAggregate from '../../types/SensorAggregate'
import { getSensorAggregates } from '../../services/sensorAggregateService'
import { resolveSensorAggregateInterval } from '../../utils/sensorAggregate'
import {
  getDifferenceOfDateRangeInHours,
  humanizeTimestamp,
  moreThanDaysAgo
} from '../../utils/date'
import DeviceEnergyConsumptionStats from '../../components/DeviceEnergyConsumptionStats'
import useTheme from '../../hooks/useTheme'
import ProjectMembershipRole from '../../enums/ProjectMembershipRole'
import { Device, StatusOutput } from '../../types'
import DeviceMap from '../../components/DeviceMap'
import TimePeriod from '../../enums/TimePeriod'
import { setDateRange } from '../../redux/slices/query'
import useAppSettings from '../../hooks/useAppSettings'
import DateRangeBar from '../../components/DateRangeBar'
import DeviceDetails from '../../components/DeviceDetails'
import DeviceRuuviTagGrid from '../../components/DeviceRuuviTagGrid'
import {
  PowerSettingsNew,
  Settings,
  ShowChart,
  Warning,
  Construction,
  Edit,
  Tune,
  Notifications,
  MoreVert,
  ArrowBack
} from '@mui/icons-material'
import DeviceStatusIndicator from '../../components/DeviceStatusIndicator'
import { isSensorNow } from '../../utils/sensor'
import PageHeader from '../../components/PageHeader'
import DeviceHeading from './DeviceHeading'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `
}

/**
 * Blinks the component three times.
 */
const BlinkingComponent = styled.div`
  animation: ${blinkingEffect} 1s linear 3;
`

export default function DevicePage() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The theme.
   */
  const { theme } = useTheme()

  /**
   * The ID (name) of the device.
   */
  const { id } = useParams()

  /**
   * The date range and time period.
   */
  const { dateRange, timePeriod } = useSelector(
    (state: RootState) => state.query
  )

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()
  const sensorSettings = appSettings.sensorSettings

  /**
   * The device.
   */
  const [device, setDevice] = useState<Device>()

  /**
   * Indicates if the device is being loaded.
   */
  const [isLoadingDevice, setIsLoadingDevice] = useState<boolean>(true)

  /**
   * The latest sensor.
   */
  const [sensor, setSensor] = useState<Sensor>()

  /**
   * The sensors.
   */
  const [sensors, setSensors] = useState<Sensor[]>([])

  /**
   * The sensor aggregates.
   */
  const [sensorAggregates, setSensorAggregates] = useState<SensorAggregate[]>(
    []
  )

  /**
   * The ruuvi tag of device.
   */

  /**
   * Indicates if the sensor is being loaded.
   */
  const [isLoadingSensor, setIsLoadingSensor] = useState<boolean>(true)

  /**
   * Indicates if sensors are being loaded.
   */
  const [isLoadingSensors, setIsLoadingSensors] = useState<boolean>(false)

  /**
   * The difference of date range in hours.
   */
  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  /**
   * Indicates if sensor aggregates are being used.
   */
  const isUsingSensorAggregates = differenceOfDateRangeInHours > 3

  /**
   * The project of the device.
   */
  const project = device?.asset?.project

  /**
   * Indicates if the current user is admin of the project.
   */
  const currentUserIsAdminOfProject = currentUser?.projects
    ? currentUser?.projects.some(
        // @ts-ignore
        (projectMembership) => {
          return (
            projectMembership.projectId === project?.id &&
            projectMembership.role === ProjectMembershipRole.ADMIN
          )
        }
      )
    : false

  /**
   * Indicates if the current user can see energy costss.
   */
  const currentUserCanSeeEnergyCosts =
    !project?.hideCosts ||
    currentUser?.role === UserRole.SUPERADMIN ||
    currentUserIsAdminOfProject

  /**
   * The width of the device stats card.
   */
  const deviceStatsCardWidths = currentUserCanSeeEnergyCosts
    ? { md: 6, lg: 4 }
    : { md: 12, lg: 4 }

  /**
   * Indicates if user wants app to automatically refresh data.
   */
  const [autoRefreshData, setAutoRefreshData] = useState<boolean>(
    sensorSettings ? sensorSettings.autoRefreshData : false
  )

  /**
   * Indicates if settings drawer is open.
   */
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] =
    useState<boolean>(false)

  /**
   * The anchor element of the device menu.
   */
  const [deviceMenuAnchor, setDeviceMenuAnchor] = useState<HTMLElement | null>(
    null
  )

  /**
   * Indicates if the device menu is open.
   */
  const isDeviceMenuOpen = Boolean(deviceMenuAnchor)

  /**
   * The status on a device.
   */
  const [status, setStatus] = useState<StatusOutput>()

  /**
   * Indicates if the status is being polled.
   */
  const [isPollingStatus, setIsPollingStatus] = useState<boolean>(true)

  /**
   * Indicates if the reboot dialog is open.
   */
  const [isRebootDialogOpen, setIsRebootDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the device is rebooting.
   */
  const [isRebooting, setIsRebooting] = useState<boolean>(false)

  /**
   * The humanzied name of the device.
   */
  const humanizedName =
    device?.asset?.name ??
    device?.powerSupply?.name ??
    device?.name ??
    t('device')

  /**
   * Indicates if the sensor is outdated.
   */
  const isSensorOutdated =
    !isLoadingSensor && sensor && moreThanDaysAgo(new Date(sensor.time), 30)

  /**
   * Indicates if the time period is absolute.
   */
  const isAbsoluteTimePeriod =
    timePeriod === TimePeriod.DAY ||
    timePeriod === TimePeriod.WEEK ||
    timePeriod === TimePeriod.MONTH

  /**
   * Load the device.
   *
   * @param name - The name of the device
   * @param signal - The abort signal
   */
  async function loadDevice(name: string, signal?: AbortSignal): Promise<void> {
    try {
      setIsLoadingDevice(true)
      setDevice(
        await getDevice(name, {
          signal
        })
      )
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsLoadingDevice(false)
    }
  }

  function restartStatusPolling() {
    setIsPollingStatus(false)
    setIsPollingStatus(true)
  }

  /**
   * Load sensor data.
   *
   * @param name - The name of the device
   * @param signal - The abort signal
   */
  async function loadSensorData(
    name: string,
    signal?: AbortSignal
  ): Promise<void> {
    await Promise.all([loadSensor(name, signal), loadSensors(name, signal)])
  }

  /**
   * Load the latest sensor.
   *
   * @param name - The name of the device
   * @param signal - The abort signal
   */
  async function loadSensor(name: string, signal?: AbortSignal): Promise<void> {
    try {
      setIsLoadingSensor(true)
      setSensor(
        await getLastSensor(name, {
          signal
        })
      )
    } catch (error: unknown) {
      setSensor(undefined)
    } finally {
      setIsLoadingSensor(false)
    }
  }

  /**
   * Load sensors or sensor aggregates.
   *
   * @param name - The name of the device
   * @param signal - The abort signal
   */
  async function loadSensors(
    name: string,
    signal?: AbortSignal
  ): Promise<void> {
    try {
      setIsLoadingSensors(true)

      if (isUsingSensorAggregates) {
        setSensors([])
        setSensorAggregates(
          await getSensorAggregates(
            name,
            {
              interval: resolveSensorAggregateInterval(dateRange),
              from: dateRange.from,
              to: dateRange.to
            },
            {
              signal
            }
          )
        )
      } else {
        setSensors(
          await getSensors(
            name,
            {
              from: dateRange.from,
              to: dateRange.to
            },
            {
              signal
            }
          )
        )
        setSensorAggregates([])
      }
    } finally {
      setIsLoadingSensors(false)
    }
  }

  /**
   * Load the device status.
   */
  async function loadStatus(name: string): Promise<void> {
    try {
      setStatus(await getStatus(name))
      setIsPollingStatus(false)
    } catch (err: any) {
      // Do nothing.
    }
  }

  /**
   * Poll for the status on a device.
   *
   * @param name - The name of the device
   */
  async function pollStatus(name: string): Promise<void> {
    try {
      await invokeAction(name, { get_status: 1 })
      restartStatusPolling()
    } catch (err: any) {
      // TODO: Handle errors.
    }
  }

  /**
   * Reboot the device.
   *
   * @param name - The name of the device
   */
  async function rebootDevice(name: string): Promise<void> {
    try {
      setIsRebooting(true)

      await invokeAction(name, { reboot: 1 })

      closeRebootDialog()
    } catch (err: any) {
      console.error(err)
    } finally {
      setIsRebooting(false)
    }
  }

  /**
   * Update the relay state on a device.
   */
  async function toggleRelayState(name: string, relay: 0 | 1): Promise<void> {
    try {
      // Update the relay state.
      await invokeAction(name, { relay })

      // Start polling the status to get the updated relay state.
      await pollStatus(name)
    } catch (err: any) {
      console.error(err)
      // TODO: Handle errors.
    }
  }

  /**
   * Handle change to auto refresh data.
   */
  function handleAutoRefreshChange(autoRefreshData: boolean): void {
    if (!autoRefreshData) {
      setAutoRefreshData(false)
    } else {
      const now = startOfMinute(new Date())
      switch (timePeriod) {
        case TimePeriod.ONE_HOUR:
          dispatch(
            setDateRange({
              from: subHours(now, 1),
              to: now
            })
          )
          break
        case TimePeriod.TWENTYFOUR_HOURS:
          dispatch(
            setDateRange({
              from: subHours(now, 24),
              to: now
            })
          )
          break
        case TimePeriod.SEVEN_DAYS:
          dispatch(
            setDateRange({
              from: subDays(now, 7),
              to: now
            })
          )
          break
        case TimePeriod.THIRTY_DAYS:
          dispatch(
            setDateRange({
              from: subDays(now, 30),
              to: now
            })
          )
          break
      }

      setAutoRefreshData(true)
    }

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          autoRefreshData: false
        }
      })
    )
  }

  /**
   * Open the device menu.
   */
  function openDeviceMenu(event: MouseEvent<HTMLElement>): void {
    setDeviceMenuAnchor(event.currentTarget)
  }

  /**
   * Close the device menu.
   */
  function closeDeviceMenu(): void {
    setDeviceMenuAnchor(null)
  }

  /**
   * Open the settings drawer.
   */
  function openSettingsDrawer(): void {
    setIsSettingsDrawerOpen(true)
  }

  /**
   * Open the reboot dialog.
   */
  function openRebootDialog(): void {
    setIsRebootDialogOpen(true)
  }

  /**
   * Close the reboot dialog.
   */
  function closeRebootDialog(): void {
    setIsRebootDialogOpen(false)
  }

  // Runs when the ID changes.
  useEffect(() => {
    const controller = new AbortController()

    if (id) {
      loadDevice(id)
    }

    return () => controller.abort()
  }, [id])

  // Runs when the device or date range changes.
  useEffect(() => {
    const controller = new AbortController()

    if (device) {
      loadSensorData(device.name, controller.signal)
    }

    return () => controller.abort()
  }, [device, dateRange])

  // Runs when settings drawer is opened.
  useEffect(() => {
    if (device && isSettingsDrawerOpen) {
      pollStatus(device.name)
    }
  }, [device, isSettingsDrawerOpen])

  // Runs when polling status changes.
  useEffect(() => {
    if (isPollingStatus) {
      const interval = setInterval(() => {
        if (device && isPollingStatus) {
          loadStatus(device.name)
        } else {
          clearInterval(interval)
          setIsPollingStatus(false)
        }
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [isPollingStatus])

  // Load sensor data when device or date range changes.
  useEffect(() => {
    const controller = new AbortController()

    if (device) {
      loadSensorData(device.name, controller.signal)
    }

    return () => controller.abort()
  }, [device, dateRange])

  if (!isLoadingDevice && device === undefined) {
    return <LoadingState />
  } else {
    return (
      <React.Fragment>
        <PageHeader
          title={device?.asset?.name ?? device?.name ?? t('device')}
          breadcrumbs={
            <Breadcrumbs>
              <Link component={NavLink} to="/" title={t('frontpage')}>
                {t('frontpage')}
              </Link>

              {device?.asset?.project ? (
                <Link component={NavLink} to="/projects" title={t('projects')}>
                  {t('projects')}
                </Link>
              ) : (
                device && (
                  <Link component={NavLink} to="/devices" title={t('devices')}>
                    {t('devices')}
                  </Link>
                )
              )}

              {device?.asset?.project && (
                <Link
                  component={NavLink}
                  to={`/projects/${device?.asset?.projectId}`}
                  title={device?.asset?.project?.name}
                >
                  {device?.asset?.project?.name}
                </Link>
              )}

              {device?.asset?.project && (
                <Link
                  component={NavLink}
                  to={`/projects/${device?.asset?.projectId}#monitoring`}
                  title={t('deviceMonitoring')}
                >
                  {t('deviceMonitoring')}
                </Link>
              )}

              {device ? (
                <Typography title={device?.asset?.name ?? device.name}>
                  {device?.asset?.name ?? device.name}
                </Typography>
              ) : (
                <Skeleton variant="text" width={100} />
              )}
            </Breadcrumbs>
          }
          heading={<DeviceHeading device={device} />}
          action={
            <Stack direction="row" spacing={3}>
              {device && (
                <>
                  <Tooltip title={t('settings') as string}>
                    <IconButton onClick={openDeviceMenu}>
                      <MoreVert fontSize="inherit" />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    open={isDeviceMenuOpen}
                    onClose={() => closeDeviceMenu()}
                    anchorEl={deviceMenuAnchor}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    PaperProps={{
                      sx: { width: '320px' }
                    }}
                  >
                    <MenuList>
                      <MenuItem
                        component={NavLink}
                        to={`/devices/${device?.name}/sensor-aggregates`}
                      >
                        <ListItemIcon>
                          <ShowChart />
                        </ListItemIcon>
                        <ListItemText primary={t('sensorReadings')} />
                      </MenuItem>

                      {currentUser?.role === UserRole.SUPERADMIN && (
                        <MenuItem
                          component={NavLink}
                          to={`/devices/${device?.name}/sensor-errors`}
                        >
                          <ListItemIcon>
                            <Warning />
                          </ListItemIcon>
                          <ListItemText primary={t('sensorErrors')} />
                        </MenuItem>
                      )}

                      <Divider />

                      {(currentUser?.role === UserRole.SUPERADMIN ||
                        currentUser?.role === UserRole.ADMIN ||
                        currentUser?.role === UserRole.INSTALLER) && (
                        <>
                          <MenuItem
                            component={NavLink}
                            to={`/devices/${device?.name}/edit`}
                          >
                            <ListItemIcon>
                              <Edit />
                            </ListItemIcon>
                            <ListItemText>{t('edit')}</ListItemText>
                          </MenuItem>
                        </>
                      )}

                      <MenuItem
                        component={NavLink}
                        to={`/devices/${device?.name}/alert-management`}
                      >
                        <ListItemIcon>
                          <Notifications />
                        </ListItemIcon>
                        <ListItemText>{t('alertManagement')}</ListItemText>
                      </MenuItem>

                      <Divider />

                      {currentUser?.role === UserRole.SUPERADMIN && (
                        <MenuItem
                          onClick={() => {
                            closeDeviceMenu()
                            openSettingsDrawer()
                          }}
                        >
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <ListItemText primary={t('configuration')} />
                        </MenuItem>
                      )}

                      {currentUser?.role === UserRole.SUPERADMIN && (
                        <MenuItem
                          component={NavLink}
                          to={`/devices/${device?.name}/troubleshoot`}
                        >
                          <ListItemIcon>
                            <Construction />
                          </ListItemIcon>
                          <ListItemText>
                            {t('troubleshoot.troubleshoot')}
                          </ListItemText>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </>
              )}
            </Stack>
          }
        />

        <Box my={6}>
          <DateRangeBar
            includeOneHourOption={true}
            onAutoRefreshChange={handleAutoRefreshChange}
            showAutoRefreshToggle={true}
          />
        </Box>

        <Box mb={6}>
          {isLoadingSensor ? (
            <Skeleton variant="rectangular" height="48px" />
          ) : sensor ? (
            <Alert severity={isSensorNow(sensor) ? 'info' : 'warning'}>
              {`${t('Device.latest')}: ${humanizeTimestamp(
                new Date(sensor.time as string)
              )}`}
            </Alert>
          ) : (
            <Alert severity="error">{t('noSensorData')}</Alert>
          )}
        </Box>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            {device ? (
              <DeviceDetails
                device={device}
                sensor={sensor}
                onUpdate={(device) => {
                  setDevice(device)
                }}
              />
            ) : (
              <Skeleton variant="rectangular" height="48px" />
            )}

            <Divider />
          </Grid>

          <Grid item xs={12}>
            {device ? (
              <DevicePowerStats
                device={device}
                dateRange={dateRange}
                sensor={sensor}
                sensors={sensors}
                sensorAggregates={sensorAggregates}
                loading={isLoadingSensors}
              />
            ) : (
              <Skeleton variant="rectangular" height="489px" />
            )}
          </Grid>

          <Grid item xs={12}>
            {device ? (
              <DeviceVoltageStats
                device={device}
                dateRange={dateRange}
                sensor={sensor}
                sensors={sensors}
                sensorAggregates={sensorAggregates}
                loading={isLoadingSensors}
              />
            ) : (
              <Skeleton variant="rectangular" height="455px" />
            )}
          </Grid>

          <Grid item xs={12}>
            {device ? (
              <DeviceCurrentStats
                device={device}
                dateRange={dateRange}
                sensor={sensor}
                sensors={sensors}
                sensorAggregates={sensorAggregates}
                loading={isLoadingSensors}
              />
            ) : (
              <Skeleton variant="rectangular" height="488px" />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                {device ? (
                  <DeviceEnergyConsumptionStats
                    device={device}
                    dateRange={dateRange}
                    project={
                      device.asset && device.asset.project
                        ? device.asset.project
                        : undefined
                    }
                  />
                ) : (
                  <Skeleton variant="rectangular" height="433px" />
                )}
              </Grid>

              {currentUserCanSeeEnergyCosts && (
                <Grid item xs={12} md={4}>
                  {device ? (
                    <DeviceEnergyCostStats
                      device={device}
                      dateRange={dateRange}
                      project={
                        device.asset && device.asset.project
                          ? device.asset.project
                          : undefined
                      }
                    />
                  ) : (
                    <Skeleton variant="rectangular" height="433px" />
                  )}
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                {device ? (
                  <DeviceEmissionStats device={device} dateRange={dateRange} />
                ) : (
                  <Skeleton variant="rectangular" height="433px" />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {device ? (
              <DeviceMap device={device} project={project} />
            ) : (
              <Skeleton variant="rectangular" height="433px" />
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 4 }}>
              {t('conditionMonitoring')}
            </Typography>

            {currentUser?.role === UserRole.SUPERADMIN && (
              <>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={4}>
                    {device && sensor ? (
                      <Stats
                        title={t('temperature')}
                        amount={Math.round(sensor.temp) + ' °C'}
                        chip={t('Device.now')}
                        percentageAltText={t('Device.last')}
                      />
                    ) : (
                      <Skeleton variant="rectangular" height="100px" />
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {device && sensor ? (
                      <Stats
                        title={t('humidity')}
                        amount={Math.round(sensor.rh) + ' %'}
                        chip={t('Device.now')}
                        percentageAltText={t('Device.last')}
                      />
                    ) : (
                      <Skeleton variant="rectangular" height="100px" />
                    )}
                  </Grid>
                </Grid>

                <Divider my={6} />
              </>
            )}

            {device && <DeviceRuuviTagGrid device={device} />}
          </Grid>
        </Grid>

        {device && (
          <Drawer
            anchor="right"
            open={isSettingsDrawerOpen}
            onClose={() => setIsSettingsDrawerOpen(false)}
          >
            <Box sx={{ py: 2 }}>
              <List
                sx={{
                  width: '100%',
                  minWidth: '320px',
                  bgcolor: 'background.paper'
                }}
                subheader={<ListSubheader>{t('configuration')}</ListSubheader>}
              >
                <ListItem
                  secondaryAction={<Typography>{device?.name}</Typography>}
                >
                  <ListItemText primary={t('serialNumber')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    <Chip
                      color={device?.status ? 'success' : 'error'}
                      size="small"
                      variant="filled"
                      label={device?.status ? t('online') : t('offline')}
                    />
                  }
                >
                  <ListItemText primary={t('status')} />
                </ListItem>

                <ListItem>
                  <ListItemText primary={t('relayState')} />
                  {status === undefined || isPollingStatus ? (
                    <CircularProgress size={16} sx={{ my: 2 }} />
                  ) : (
                    <Switch
                      edge="end"
                      disabled={isPollingStatus}
                      onChange={() =>
                        toggleRelayState(
                          device.name,
                          status.relay === 1 ? 0 : 1
                        )
                      }
                      checked={status.relay === 1}
                    />
                  )}
                </ListItem>

                <ListItem
                  secondaryAction={
                    status === undefined || isPollingStatus ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '1rem' }}
                      />
                    ) : (
                      <Typography>{status.sim_id}</Typography>
                    )
                  }
                >
                  <ListItemText primary={t('simCard')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    status === undefined || isPollingStatus ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '1rem' }}
                      />
                    ) : (
                      <Typography>{status.input_cables}</Typography>
                    )
                  }
                >
                  <ListItemText primary={t('inputCables')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    status === undefined || isPollingStatus ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '2rem' }}
                      />
                    ) : (
                      <Typography>{status.clamp_ratio}</Typography>
                    )
                  }
                >
                  <ListItemText primary={t('clampRatio')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    status === undefined || isPollingStatus ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '2rem' }}
                      />
                    ) : (
                      <Typography>{status.nw_info}</Typography>
                    )
                  }
                >
                  <ListItemText primary={t('network')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    <Typography>{device?.hardwareVersion ?? '-'}</Typography>
                  }
                >
                  <ListItemText primary={t('hardwareVersion')} />
                </ListItem>

                <ListItem
                  secondaryAction={
                    <Typography>{device?.firmwareVersion ?? '-'}</Typography>
                  }
                >
                  <ListItemText primary={t('firmwareVersion')} />
                </ListItem>
              </List>

              <Box sx={{ px: 4, py: 2 }}>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={isRebooting}
                  startIcon={<PowerSettingsNew />}
                  onClick={openRebootDialog}
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  {isRebooting ? <CircularProgress size={24} /> : t('reboot')}
                </Button>
              </Box>

              <Dialog open={isRebootDialogOpen} onClose={closeRebootDialog}>
                <DialogTitle>{t('rebootDevice')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('rebootDeviceConfirmation')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeRebootDialog}>{t('cancel')}</Button>
                  <Button onClick={() => rebootDevice(device.name)} autoFocus>
                    {t('confirm')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Drawer>
        )}
      </React.Fragment>
    )
  }
}
