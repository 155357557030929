import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  IconButtonProps,
  MenuList,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography,
  Box,
  ListItemIcon,
  Button,
  Dialog,
  List,
  ListItemButton,
  DialogTitle,
  DialogContent,
  Stack,
  BoxProps
} from '@mui/material'
import { ArrowForward, Check, Edit, Logout, Person } from '@mui/icons-material'
import useAuth from '../../hooks/useAuth'
import { getUser } from '../../redux/slices/users'
import { useTheme as useMuiTheme } from '@mui/system'
import Flag from '../Flag'
import BugReportButton from '../BugReportButton'
import { THEMES } from '../../constants'
import useTheme from '../../hooks/useTheme'
import Language from '../../types/Language'

type LanguageOption = {
  label: string
  value: Language
}

type UserAvatarProps = Omit<BoxProps, 'children'>

function UserAvatar({ sx, ...props }: UserAvatarProps) {
  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The theme.
   */
  const theme = useMuiTheme()

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '38px',
        height: '38px',
        border: `2px solid ${theme.palette.success.main}`,
        borderRadius: '50%',
        ...sx
      }}
      {...props}
    >
      <Typography fontSize="20px" lineHeight={1} fontWeight={500}>
        {currentUser?.firstName?.toUpperCase()?.charAt(0)}
      </Typography>
    </Box>
  )
}

const ProfileMenuItem = () => {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const { currentUser } = useAuth()

  /**
   * Handle the navigate.
   */
  const handleNavigate = () => {
    if (currentUser) {
      dispatch(getUser(parseInt(currentUser.id.toString())))
      navigate(`/users/${currentUser.id}`)
    }
  }

  return (
    <MenuItem onClick={handleNavigate}>
      <ListItemText primary={t('ownProfile')} />
      <ListItemSecondaryAction>
        <ArrowForward fontSize="small" color="disabled" />
      </ListItemSecondaryAction>
    </MenuItem>
  )
}

const SignOutMenuItem = () => {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useMuiTheme()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The sign out function.
   */
  const { signOut } = useAuth()

  const handleSignOut = async () => {
    localStorage.removeItem('closedAnnouncements')
    await signOut()
    navigate('/auth/sign-in')
  }

  return (
    <MenuItem onClick={handleSignOut}>
      <ListItemText primary={t('signOut')} />
      <ListItemSecondaryAction>
        <Logout fontSize="small" color="disabled" />
      </ListItemSecondaryAction>
    </MenuItem>
  )
}

const LanguageMenuItem = () => {
  /**
   * The translate function.
   */
  const { t, i18n } = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useMuiTheme()

  /**
   * The current language.
   */
  const currentLanguage = i18n.language as Language

  /**
   * Indicates if the dialog is open.
   */
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  /**
   * Open the dialog.
   */
  function openDialog() {
    setIsDialogOpen(true)
  }

  /**
   * Close the dialog.
   */
  function closeDialog() {
    setIsDialogOpen(false)
  }

  /**
   * The languge options.
   */
  const options: LanguageOption[] = [
    {
      label: 'Suomi',
      value: 'fi'
    },
    {
      label: 'Svenska',
      value: 'swe'
    },
    {
      label: 'English',
      value: 'en'
    }
  ]

  /**
   * The label of the current language.
   */
  const currentLanguageLabel = options.find(
    (option) => option.value === currentLanguage
  )?.label

  /**
   * Change the language.
   */
  const changeLanguage = (language: string) => {
    closeDialog()
    i18n.changeLanguage(language)
  }

  return (
    <>
      <MenuItem onClick={() => openDialog()}>
        <ListItemText primary={t('language')} />
        <ListItemSecondaryAction>
          {/*<Flag language={currentLanguage} />*/}
          <Typography fontSize="small" color={theme.palette.text.secondary}>
            {currentLanguageLabel}
          </Typography>
        </ListItemSecondaryAction>
      </MenuItem>

      <Dialog
        open={isDialogOpen}
        onClose={() => closeDialog()}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{t('selectLanguage')}</DialogTitle>
        <DialogContent>
          <List>
            {options.map((option) => (
              <ListItemButton
                key={option.value}
                selected={currentLanguage === option.value}
                onClick={() => changeLanguage(option.value)}
              >
                <ListItemIcon>
                  <Flag language={option.value} />
                </ListItemIcon>
                <ListItemText primary={option.label} />
                <ListItemSecondaryAction>
                  {currentLanguage === option.value && (
                    <Check color="success" />
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

const ThemeMenuItem = () => {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The theme.
   */
  const { theme, setTheme } = useTheme()

  /**
   * Handle theme change.
   */
  function handleColorChange(): void {
    const newTheme = theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT
    setTheme(newTheme)
    // window.location.reload()
  }

  return (
    <MenuItem>
      <ListItemText primary={t('darkMode')} />
      <ListItemSecondaryAction>
        <Switch
          checked={theme === THEMES.DARK}
          onChange={handleColorChange}
          size="small"
        />
      </ListItemSecondaryAction>
    </MenuItem>
  )
}

export type NavbarProfileProps = Omit<IconButtonProps, 'children' | 'onClick'>

export default function NavbarProfile(props: NavbarProfileProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The theme.
   */
  const theme = useMuiTheme()

  /**
   * The anchor element of the menu.
   */
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  /**
   * Indicates if the menu is open.
   */
  const isMenuOpen = Boolean(anchorEl)

  /**
   * Open the menu.
   */
  const openMenu = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu.
   */
  const closeMenu = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <Tooltip title={t('userMenu') as string}>
        <IconButton color="inherit" sx={{ p: 0 }} {...props} onClick={openMenu}>
          <UserAvatar />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        PaperProps={{ sx: { width: 230 } }}
        onClose={() => closeMenu()}
      >
        <MenuList>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            px={3}
            pb={3}
            gap={2}
          >
            {/*<UserAvatar sx={{ flexShrink: 0 }} />*/}

            <Stack direction="column" sx={{ overflow: 'hidden' }}>
              <Typography
                fontWeight={500}
                lineHeight={1}
                sx={{
                  textWrap: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {currentUser?.firstName} {currentUser?.lastName}
              </Typography>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  textWrap: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {currentUser?.email}
              </Typography>
            </Stack>
          </Stack>

          <Divider />

          {currentUser && <ProfileMenuItem />}

          <Divider />

          <LanguageMenuItem />
          <ThemeMenuItem />

          <Divider />

          <SignOutMenuItem />

          <Divider />

          <Box pt={3} px={3}>
            <BugReportButton
              variant="contained"
              color="primary"
              size="small"
              fullWidth
            />
          </Box>
        </MenuList>
      </Menu>
    </>
  )
}
