import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Typography,
  Divider as MuiDivider,
  CardContent,
  CardHeader,
  Card,
  Chip,
  Button,
  MenuItem,
  Stack,
  Alert,
  Box,
  CardActions,
  Grid,
  TextField,
  List,
  ListItemText,
  ListItem,
  Stepper,
  useMediaQuery,
  Step,
  StepButton,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { spacing, useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { QrCode, Thermostat } from '@mui/icons-material'
import LoadingState from '../../components/LoadingState'
import {
  getDevice,
  getPairedRuuviTag,
  invokeAction
} from '../../services/deviceService'
import { Device, ProjectMembership, RuuviTag, RuuviTagInput } from '../../types'
import { getDevicesOfProject, getProject } from '../../services/projectService'
import { RuuviTagForm } from '../../components/RuuviTagForm'
import { formatMacAddress } from '../../utils/string'
import { createOrUpdateRuuviTag } from '../../services/ruuviTagService'
import { QrReader } from 'react-qr-reader'
import { uploadAssetPhoto } from '../../services/assetService'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
  createErrorOrSuccessNotification,
  createWarningNotification
} from '../../redux/slices/notifications'
import { getProjects } from '../../redux/slices/projects'
import { NotificationType } from '../../enums/NotificationType'
import { useAuth } from '../../hooks'
import { UserRole } from '../../enums'
import DeviceCodeInput from '../../components/DeviceCodeInput'

const Divider = styled(MuiDivider)(spacing)

interface SelectProjectStepProps {
  onChange: (projectId: number | undefined) => void
  onNext: () => void
  onCancel?: () => void
}

function SelectProjectStep({ onChange, onNext }: SelectProjectStepProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The projects.
   */
  const { projects } = useSelector((state: RootState) => state.projects)

  /**
   * The active projects.
   */
  const activeProjects = useSelector(
    (state: RootState) => state.projects.activeProjects
  )

  /**
   * The ID of the selected project.
   */
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >()

  /**
   * Indicates if the checkbox is checked.
   */
  const [isChecked, setIsChecked] = useState(false)

  /**
   * Indicates if the current user is a superadmin.
   */
  const isSuperadmin = currentUser?.role === UserRole.SUPERADMIN

  /**
   * Indicates if the current user is memeber of the selected project.
   */
  const isMemberOfSelectedProject: boolean = currentUser?.projects?.some(
    (membership: ProjectMembership) =>
      membership?.projectId === selectedProjectId
  )

  /**
   * Indicates if the current user has access to the project.
   */
  const hasAccessToProject = isSuperadmin || isMemberOfSelectedProject

  /**
   * The ID of the active project.
   */
  const activeProjectId = currentUser?.activeProjectMembership?.projectId

  /**
   * Handle the location change.
   */
  const handleLocationChange = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          dispatch(
            getProjects({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
              showDeleted: false
            })
          )
        },
        () => {
          dispatch(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t('Manufacturing.locationNotFound')
            )
          )
        }
      )
    } else {
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t('Manufacturing.notSupported')
        )
      )
    }
  }

  /**
   * Handle the checkbox change.
   */
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    setSelectedProjectId(undefined)

    if (event.target.checked) {
      handleLocationChange()
    }
  }

  /**
   * Handle the select change.
   */
  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectId(parseInt(event.target.value.toString()))
  }

  /**
   * Handle the next action.
   */
  const handleNext = () => {
    onNext()
  }

  /**
   * Handle the cancel action.
   */
  const handleCancel = () => {
    navigate('/')
  }

  // Emits the change event when the selected project changes.
  useEffect(() => {
    onChange(selectedProjectId)
  }, [selectedProjectId])

  // Set the active project as the selected project.
  useEffect(() => {
    if (selectedProjectId === undefined && activeProjectId !== undefined) {
      setSelectedProjectId(activeProjectId)
    }
  }, [activeProjectId])

  return (
    <Card>
      <CardHeader title={t('selectProject')} />
      <CardContent>
        {currentUser === undefined ? (
          <LoadingState />
        ) : (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {selectedProjectId !== undefined && !hasAccessToProject && (
                <Alert severity="warning" variant="outlined" sx={{ mb: 6 }}>
                  {t('noAccessToProject')}
                </Alert>
              )}

              {isChecked ? (
                <>
                  {projects.length > 0 ? (
                    <TextField
                      select
                      name="project"
                      label={t('project')}
                      value={selectedProjectId}
                      defaultValue={activeProjectId}
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={handleSelectChange}
                    >
                      {projects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Alert severity="warning" variant="outlined">
                      {t('noProjectsNearby')}
                    </Alert>
                  )}
                </>
              ) : (
                <TextField
                  select
                  name="project"
                  label={t('project')}
                  value={selectedProjectId}
                  defaultValue={activeProjectId}
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleSelectChange}
                >
                  {activeProjects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange} />}
                label={t('showOnlyNearbyProjects') as string}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
        <>
          <Button onClick={handleCancel}>{t('cancel')}</Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={selectedProjectId === undefined || !hasAccessToProject}
            onClick={handleNext}
          >
            {t('next')}
          </Button>
        </>
      </CardActions>
    </Card>
  )
}
interface ScanQrCodeOrEnterDeviceCodeStepProps {
  onChange: (deviceName: string) => void
  onCancel: () => void
  onNext: () => void
}

function ScanQrCodeOrEnterDeviceCodeStep({
  onChange,
  onCancel,
  onNext
}: ScanQrCodeOrEnterDeviceCodeStepProps) {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The short UUID of the device.
   */
  const [shortUUID, setShortUUID] = useState<string>('')

  /**
   * The error after loading the device.
   */
  const [deviceError, setDeviceError] = useState<Error>()

  /**
   * The selected device.
   */
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>()

  /**
   * Indicates if the device loaded with an error.
   */
  const isDeviceError = deviceError !== undefined

  /**
   * Load the device by short UUID.
   */
  const loadDevice = async (shortUUID: string) => {
    try {
      setDeviceError(undefined)

      const device = await getDevice(shortUUID)

      setSelectedDevice(device)
      onChange(device.name)
    } catch (error: unknown) {
      setDeviceError(error as Error)
    }
  }

  const handleCancel = () => {
    setDeviceError(undefined)
    setSelectedDevice(undefined)
    setShortUUID('')
    onCancel()
  }

  const handleNext = () => {
    onNext()
  }

  /**
   * Load the device when short UUID changes.
   */
  useEffect(() => {
    if (shortUUID) {
      loadDevice(shortUUID)
    }
  }, [shortUUID])

  return (
    <Card>
      <CardHeader title={t('scanQrCodeOrEnterDeviceCode')} />
      <CardContent>
        <Grid container spacing={6}>
          {selectedDevice === undefined ? (
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center'
              }}
            >
              {isDeviceError && (
                <Alert severity="error" variant="outlined" sx={{ mb: 6 }}>
                  {t('deviceNotFound')}
                </Alert>
              )}

              <Box sx={{ backgroundColor: 'black' }}>
                <Box
                  sx={{
                    margin: 'auto',
                    maxWidth: '800px'
                  }}
                >
                  <QrReader
                    scanDelay={500}
                    constraints={{ facingMode: 'environment' }}
                    onResult={(result) => {
                      if (result) {
                        const regex = new RegExp(
                          /^https:\/\/qr\.spine-electric\.io\/[0-9]{6}$/
                        )
                        if (regex.test(result.getText())) {
                          setShortUUID(result.getText().substring(29))
                        }
                      }
                    }}
                    videoContainerStyle={{
                      backgroundColor: 'black'
                    }}
                  />
                </Box>
              </Box>

              <DeviceCodeInput
                onChange={(deviceCode) => {
                  if (deviceCode.length === 6) {
                    setShortUUID(deviceCode)
                  }
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Alert severity="success" variant="outlined" sx={{ mb: 6 }}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  {t('deviceIdentified')}
                  <Chip
                    icon={<QrCode fontSize="inherit" />}
                    label={selectedDevice.shortUUID}
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{ ml: 2 }}
                  />
                </Stack>
              </Alert>

              <List>
                {selectedDevice?.asset?.name && (
                  <ListItem>
                    <ListItemText
                      primary={selectedDevice.asset.name}
                      secondary={t('name')}
                    />
                  </ListItem>
                )}

                <ListItem>
                  <ListItemText
                    primary={selectedDevice.name}
                    secondary={t('serialNumber')}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={selectedDevice.shortUUID}
                    secondary={t('deviceCode')}
                  />
                </ListItem>

                {selectedDevice?.asset?.company?.name && (
                  <ListItem>
                    <ListItemText
                      primary={selectedDevice.asset.company.name}
                      secondary={t('company')}
                    />
                  </ListItem>
                )}

                {selectedDevice?.asset?.project?.name && (
                  <ListItem>
                    <ListItemText
                      primary={selectedDevice.asset.project.name}
                      secondary={t('project')}
                    />
                  </ListItem>
                )}

                {selectedDevice?.asset?.level?.name && (
                  <ListItem>
                    <ListItemText
                      primary={selectedDevice.asset.level.name}
                      secondary={t('level')}
                    />
                  </ListItem>
                )}

                {selectedDevice?.asset?.lift?.name && (
                  <ListItem>
                    <ListItemText
                      primary={selectedDevice.asset.lift.name}
                      secondary={t('lift')}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleCancel}>{t('cancel')}</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {selectedDevice !== undefined ? t('next') : t('skip')}
        </Button>
      </CardActions>
    </Card>
  )
}

interface ActivateTagStepProps {
  onCancel: () => void
  onNext: () => void
}

function ActivateTagStep({ onCancel, onNext }: ActivateTagStepProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Card>
      <CardHeader title={t('activateTag')} />
      <CardContent>
        <InstallationInstructionAlert />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onNext}>
          {t('next')}
        </Button>
      </CardActions>
    </Card>
  )
}

interface SearchingTagsStepProps {
  projectId: number
  deviceName: string | undefined
  onChange: (ruuviTag: RuuviTag) => void
  onNext: () => void
  onCancel: () => void
}

function SearchingTagsStep({
  projectId,
  deviceName,
  onChange,
  onNext,
  onCancel
}: SearchingTagsStepProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the pairing is in progress.
   */
  const [isPairing, setIsPairing] = useState<boolean>(false)

  /**
   * Indicates if the ruuvi tag is paired.
   */
  const [isPaired, setIsPaired] = useState<boolean>(false)

  /**
   * The paired ruuvi tag.
   */
  const [ruuviTag, setRuuviTag] = useState<RuuviTag | undefined>()

  /**
   * Load the paired ruuvi tag.
   */
  const loadPairedRuuviTagOrFail = async (deviceName: string) => {
    setIsPaired(false)
    setRuuviTag(undefined)

    const ruuviTag = await getPairedRuuviTag(deviceName)

    setRuuviTag(ruuviTag)
    setIsPaired(true)
    onChange(ruuviTag)
  }

  /**
   * Handle the next action.
   */
  const handleNext = () => {
    onNext()
  }

  /**
   * Handle the cancel action.
   */
  const handleCancel = () => {
    onCancel()
  }

  /**
   * Handle the pair event.
   */
  const handlePair = async () => {
    setIsPairing(true)

    let devices: Device[] = []

    if (deviceName) {
      // Invoke action for the selected devices.
      await invokeAction(deviceName, {
        ruuvi_pairing: 60
      })
    } else {
      devices = await getDevicesOfProject(projectId)

      // Invoke action for all devices of the project.
      await Promise.all(
        devices.map((device) => {
          return invokeAction(device.name, {
            ruuvi_pairing: 60
          })
        })
      )
    }

    // Poll for the paired ruuvi tag every 5 seconds.
    const interval = setInterval(async () => {
      if (!isPaired) {
        try {
          if (deviceName) {
            await loadPairedRuuviTagOrFail(deviceName)
          } else {
            for (const device of devices) {
              try {
                await loadPairedRuuviTagOrFail(device.name)
                break
              } catch (error: unknown) {
                continue
              }
            }
          }

          setIsPairing(false)
          clearInterval(interval)
        } catch (error: unknown) {
          // Do nothing if failed.
        }
      }
    }, 5000)

    // Clear the interval after 60 seconds.
    setTimeout(() => {
      setIsPairing(false)
      clearInterval(interval)
    }, 60000)
  }

  useEffect(() => {
    handlePair()
  }, [])

  return (
    <Card>
      <CardHeader title={t('searchingTags')} />
      {isPairing ? (
        <>
          <CardContent>
            <LoadingState />

            <Box mt={6}>
              <InstallationInstructionAlert />
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-start' }}>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </CardActions>
        </>
      ) : isPaired && ruuviTag !== undefined ? (
        <>
          <CardContent>
            <Alert color="success" variant="outlined" sx={{ mb: 6 }}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography>{t('tagFound')}</Typography>
                <Chip
                  color="success"
                  variant="outlined"
                  size="small"
                  icon={<Thermostat fontSize="inherit" />}
                  label={
                    ruuviTag?.asset?.name ?? ruuviTag
                      ? formatMacAddress(ruuviTag.id)
                      : '-'
                  }
                />
              </Stack>
            </Alert>

            <List>
              {ruuviTag?.asset?.name && (
                <ListItem>
                  <ListItemText
                    primary={ruuviTag.asset.name}
                    secondary={t('name')}
                  />
                </ListItem>
              )}

              <ListItem>
                <ListItemText
                  primary={formatMacAddress(ruuviTag.id)}
                  secondary={t('macAddress')}
                />
              </ListItem>
            </List>

            {ruuviTag?.asset?.company?.name && (
              <ListItem>
                <ListItemText
                  primary={ruuviTag?.asset?.company?.name}
                  secondary={t('company')}
                />
              </ListItem>
            )}

            {ruuviTag?.asset?.project?.name && (
              <ListItem>
                <ListItemText
                  primary={ruuviTag?.asset?.project?.name}
                  secondary={t('project')}
                />
              </ListItem>
            )}

            {ruuviTag?.asset?.level?.name && (
              <ListItem>
                <ListItemText
                  primary={ruuviTag?.asset?.level?.name}
                  secondary={t('level')}
                />
              </ListItem>
            )}

            {ruuviTag?.asset?.lift?.name && (
              <ListItem>
                <ListItemText
                  primary={ruuviTag?.asset?.lift?.name}
                  secondary={t('lift')}
                />
              </ListItem>
            )}
          </CardContent>
          <CardActions
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Button onClick={handleCancel}>{t('cancel')}</Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {t('next')}
            </Button>
          </CardActions>
        </>
      ) : (
        <>
          <CardContent>
            <Alert color="error">
              <Typography>{t('tagsNotFound')}</Typography>
            </Alert>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
            <Button variant="contained" color="primary" onClick={handlePair}>
              {t('retry')}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  )
}

interface AddDetailsStepProps {
  values: RuuviTagInput
  onCancel: () => void
  onSubmit: (values: RuuviTagInput) => Promise<void>
}

function AddDetailsStep({ values, onCancel, onSubmit }: AddDetailsStepProps) {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle the submit event.
   */
  const handleSubmit = async (values: RuuviTagInput) => {
    await onSubmit(values)
  }

  /**
   * Handle the cancel event.
   */
  const handleCancel = () => {
    onCancel()
  }

  return (
    <Card>
      <CardHeader title={t('addDetails')} />
      <CardContent>
        <RuuviTagForm
          values={values}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </CardContent>
    </Card>
  )
}

interface InstallationCompletedStepProps {
  ruuviTag: RuuviTag
  onInstallNew: () => void
}

function InstallationCompletedStep({
  ruuviTag,
  onInstallNew
}: InstallationCompletedStepProps) {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * Handle the install new action.
   */
  const handleInstallNew = () => {
    onInstallNew()
  }

  return (
    <Card>
      <CardHeader title={t('installationCompleted')} />
      <CardContent>
        <Alert variant="outlined" color="success" sx={{ mb: 6 }}>
          <Typography>{t('tagInstalled')}</Typography>
        </Alert>

        <List>
          {ruuviTag?.asset?.name && (
            <ListItem>
              <ListItemText
                primary={ruuviTag.asset.name}
                secondary={t('name')}
              />
            </ListItem>
          )}

          <ListItem>
            <ListItemText
              primary={formatMacAddress(ruuviTag.id)}
              secondary={t('macAddress')}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={ruuviTag.deviceName}
              secondary={t('hostDevice')}
            />
          </ListItem>

          {ruuviTag?.asset?.company && (
            <ListItem>
              <ListItemText
                primary={ruuviTag.asset.company.name}
                secondary={t('company')}
              />
            </ListItem>
          )}

          {ruuviTag?.asset?.project && (
            <ListItem>
              <ListItemText
                primary={ruuviTag.asset.project.name}
                secondary={t('project')}
              />
            </ListItem>
          )}

          {ruuviTag?.asset?.level && (
            <ListItem>
              <ListItemText
                primary={ruuviTag.asset.level.name}
                secondary={t('level')}
              />
            </ListItem>
          )}

          {ruuviTag?.asset?.lift && (
            <ListItem>
              <ListItemText
                primary={ruuviTag.asset.lift.name}
                secondary={t('lift')}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button onClick={handleInstallNew}>{t('installNew')}</Button>

        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to={`/ruuvi-tags/${ruuviTag.id}`}
        >
          {t('completed')}
        </Button>
      </CardActions>
    </Card>
  )
}

function InstallationInstructionAlert() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Alert severity="info">
      <Typography fontWeight={500}>
        {t('pleaseFollowTheInstructionsBelow')}
      </Typography>
      <ol style={{ lineHeight: '2em', paddingLeft: '1em' }}>
        <li>{t('makeSureThatTheTagIsTurnedOn')}</li>
        <li>{t('takeTheTagAsCloseAsPossibleToThePowerSupply')}</li>
        <li>
          {t('flipTheTagUpsideDownAndKeepItThereUntilItHasBeenIdentified')}
        </li>
      </ol>
    </Alert>
  )
}

enum Steps {
  SELECT_PROJECT = 0,
  SCAN_QR_CODE_OR_ENTER_DEVICE_CODE = 1,
  ACTIVATE_TAG = 2,
  SEARCHING_TAGS = 3,
  ADD_DETAILS = 4,
  INSTALLATION_COMPLETED = 5
}

export default function RuuviTagInstall() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current step.
   */
  const [step, setStep] = useState(Steps.SELECT_PROJECT)

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The ID of the selected company.
   */
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  )

  /**
   * The ID of the selected project.
   */
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >()

  /**
   * The name of the selected device.
   */
  const [selectedDeviceName, setSelectedDeviceName] = useState<
    string | undefined
  >()

  /**
   * The ruuvi tag being installed.
   */
  const [ruuviTag, setRuuviTag] = useState<RuuviTag | undefined>()

  /**
   * The form input.
   */
  const [input, setInput] = useState<RuuviTagInput>({
    id: '',
    device: null,
    asset: {
      name: '',
      description: '',
      project: null,
      company: null,
      level: null,
      lift: null
    }
  })

  /**
   * Load the selected company by project ID.
   */
  const loadSelectedCompany = async (projectId: number) => {
    const project = await getProject(projectId)
    setSelectedCompanyId(project.companyId)
  }

  /**
   * Handle next event.
   */
  const handleNext = () => {
    setStep(step + 1)
  }

  /**
   * Handle the cancel event.
   */
  const handleCancel = () => {
    setStep(0)
    setSelectedProjectId(undefined)
    setSelectedDeviceName(undefined)
    setSelectedCompanyId(null)
    setRuuviTag(undefined)
  }

  /**
   * Handle the submit event.
   */
  const handleSubmit = async (values: RuuviTagInput) => {
    try {
      // Create or update the ruuvi tag.
      const ruuviTag = await createOrUpdateRuuviTag(values)

      // Now, if photo was provided, we should upload it.
      if (ruuviTag.assetId !== null && values?.asset?.photo) {
        const asset = await uploadAssetPhoto(
          ruuviTag.assetId,
          values.asset.photo
        )

        // Omit nested device and ruuvi tag from asset.
        delete asset.device
        delete asset.ruuviTag

        ruuviTag.asset = asset
      }

      setRuuviTag(ruuviTag)

      setStep(Steps.INSTALLATION_COMPLETED)
    } catch (error: unknown) {
      dispatch(createWarningNotification(t('tagInstallationFailed')))
    }
  }

  /**
   * Update the input when the selected project changes.
   */
  useEffect(() => {
    if (selectedProjectId) {
      setInput({
        ...input,
        asset: {
          name: input?.asset?.name ?? '',
          description: input?.asset?.description ?? '',
          company: input?.asset?.company ?? null,
          project: selectedProjectId,
          level: input?.asset?.level ?? null,
          lift: input?.asset?.lift ?? null
        }
      })

      loadSelectedCompany(selectedProjectId)
    }
  }, [selectedProjectId])

  /**
   * Update the input when the selected device changes.
   */
  useEffect(() => {
    if (selectedDeviceName) {
      setInput({
        ...input,
        device: selectedDeviceName
      })
    }
  }, [selectedDeviceName])

  /**
   * Update the input when the selected company changes.
   */
  useEffect(() => {
    if (selectedCompanyId) {
      setInput({
        ...input,
        asset: {
          name: input?.asset?.name ?? '',
          description: input?.asset?.description ?? '',
          company: selectedCompanyId,
          project: input?.asset?.project ?? null,
          level: input?.asset?.level ?? null,
          lift: input?.asset?.lift ?? null
        }
      })
    }
  }, [selectedCompanyId])

  /**
   * Update the input when the paired ruuvi tag changes.
   */
  useEffect(() => {
    if (ruuviTag) {
      setInput({
        ...input,
        id: ruuviTag.id,
        device: ruuviTag.deviceName
      })
    }
  }, [ruuviTag])

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        {t('installRuuviTag')}
      </Typography>

      <Divider my={6} />

      <Stepper
        nonLinear
        activeStep={step}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        sx={{ mb: 12 }}
      >
        <Step
          key={Steps.SELECT_PROJECT}
          completed={step > Steps.SELECT_PROJECT}
          color={step > Steps.SELECT_PROJECT ? 'success' : 'primary'}
        >
          <StepButton color="inherit">{t('selectProject')}</StepButton>
        </Step>
        <Step
          key={Steps.SCAN_QR_CODE_OR_ENTER_DEVICE_CODE}
          completed={step > Steps.SCAN_QR_CODE_OR_ENTER_DEVICE_CODE}
        >
          <StepButton color="inherit">
            {t('scanQrCodeOrEnterDeviceCode')}
          </StepButton>
        </Step>
        <Step key={Steps.ACTIVATE_TAG} completed={step > Steps.ACTIVATE_TAG}>
          <StepButton color="inherit">{t('activateTag')}</StepButton>
        </Step>
        <Step
          key={Steps.SEARCHING_TAGS}
          completed={step > Steps.SEARCHING_TAGS}
        >
          <StepButton color="inherit">{t('searchingTags')}</StepButton>
        </Step>
        <Step key={Steps.ADD_DETAILS} completed={step > Steps.ADD_DETAILS}>
          <StepButton color="inherit">{t('addDetails')}</StepButton>
        </Step>
        <Step
          key={Steps.INSTALLATION_COMPLETED}
          completed={step === Steps.INSTALLATION_COMPLETED}
        >
          <StepButton color="inherit">{t('installationCompleted')}</StepButton>
        </Step>
      </Stepper>

      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Box maxWidth="md" m="auto">
            {step === Steps.SELECT_PROJECT ? (
              <SelectProjectStep
                onChange={setSelectedProjectId}
                onNext={handleNext}
              />
            ) : step === Steps.SCAN_QR_CODE_OR_ENTER_DEVICE_CODE ? (
              <ScanQrCodeOrEnterDeviceCodeStep
                onChange={setSelectedDeviceName}
                onCancel={handleCancel}
                onNext={handleNext}
              />
            ) : step === Steps.ACTIVATE_TAG ? (
              <ActivateTagStep onCancel={handleCancel} onNext={handleNext} />
            ) : step === Steps.SEARCHING_TAGS ? (
              <>
                {selectedProjectId !== undefined ? (
                  <SearchingTagsStep
                    projectId={selectedProjectId}
                    deviceName={selectedDeviceName}
                    onChange={setRuuviTag}
                    onCancel={handleCancel}
                    onNext={handleNext}
                  />
                ) : (
                  <Card>
                    <CardHeader title={t('searchingTags')} />
                    <CardContent>
                      <Alert severity="error" variant="outlined">
                        {t('somethingWentWrong')}
                      </Alert>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button onClick={handleCancel}>{t('cancel')}</Button>
                    </CardActions>
                  </Card>
                )}
              </>
            ) : step === Steps.ADD_DETAILS ? (
              <AddDetailsStep
                values={input}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
              />
            ) : step === Steps.INSTALLATION_COMPLETED ? (
              <>
                {ruuviTag !== undefined ? (
                  <InstallationCompletedStep
                    ruuviTag={ruuviTag}
                    onInstallNew={handleCancel}
                  />
                ) : (
                  <Card>
                    <CardHeader title={t('installationCompleted')} />
                    <CardContent>
                      <Alert severity="error" variant="outlined">
                        {t('somethingWentWrong')}
                      </Alert>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button onClick={handleCancel}>{t('cancel')}</Button>
                    </CardActions>
                  </Card>
                )}
              </>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
