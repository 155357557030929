import { useEffect, memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControlLabel,
  Switch,
  useMediaQuery,
  CardHeader,
  CardActions,
  ToggleButtonGroup,
  ToggleButton,
  SelectProps,
  ToggleButtonGroupProps
} from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { Add } from '@mui/icons-material'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useNavigate } from 'react-router-dom'
import { Blueprint } from '../types/Blueprint'
import { getBlueprintService } from '../services/uploadService'
import LoadingState from '../components/LoadingState'
import React from 'react'
import { ProjectTab } from '../pages/pages/Project'
import { ActiveProject, Project, RuuviTag, Sector } from '../types'
import {
  getLevelsByBlueprints,
  getProjectAlertRules
} from '../services/projectService'
import PowerSupplyHierarchy from './PowerSupplyHierarchy'
import RuuviTagHierarchy from './RuuviTagHierarchy'
import Carousel from 'react-material-ui-carousel'
import { BlueprintAssetType } from './BlueprintCanvas'
import useAppSettings from '../hooks/useAppSettings'
import { AlertGroup } from '../types/Alerts'
import { useTheme } from '@mui/system'
import { AssetType } from '../enums/AssetType'
import { UserRole } from '../enums'
import ProjectMembershipRole from '../enums/ProjectMembershipRole'
import { useAuth } from '../hooks'
import AssetTypeToggle from './AssetTypeToggle'

interface SectorSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
  value: number[]
  onChange?: (event: SelectChangeEvent<number[]>) => void
  onValueChange?: (value: number[]) => void
}

function SectorSelect({ value, onChange, onValueChange }: SectorSelectProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The sectors.
   */
  const sectors = useSelector((state: RootState) => state.sectors.sectors)

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  /**
   * Handle the change event.
   */
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    if (onChange) {
      onChange(event)
    }

    if (onValueChange) {
      const value =
        event.target.value === '' ? [] : (event.target.value as number[])

      onValueChange(value)
    }
  }

  const renderValue = (selected: number[]) =>
    sectors
      .filter((sector) => selected.includes(sector.id))
      .map((sector) => sector.id)
      .join(', ')

  return (
    <FormControl sx={{ m: 1, width: isMobile ? 200 : 300 }} size="small">
      <InputLabel>{t('sectors')}</InputLabel>
      <Select
        multiple
        value={value}
        input={<OutlinedInput label={t('sectors')} />}
        renderValue={renderValue}
        onChange={handleChange}
      >
        {sectors.map((sector) => (
          <MenuItem key={sector.id} value={sector.id}>
            <Checkbox checked={value.indexOf(sector.id) > -1} />
            <ListItemText primary={sector.id} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export interface AssetHierarchyProps {
  project: Project | ActiveProject
  ruuviTags: RuuviTag[]
  type?: AssetType
  sectorIds?: number[]
  isFullScreenMode?: boolean

  loading?: boolean
  height?: string
}

export function AssetHierarchy({
  project,
  ruuviTags,
  type = AssetType.DEVICE,
  sectorIds = [],
  isFullScreenMode = false,

  loading,
  height
}: AssetHierarchyProps) {
  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the current user is superadmin.
   */
  const isSuperadmin = currentUser?.role === UserRole.SUPERADMIN

  /**
   * Indicates if the current user is owner or admin of the project.
   */
  const isOwnerOrAdmin =
    currentUser?.projects?.some(
      (membership) =>
        membership.projectId === project?.id &&
        (membership.role === ProjectMembershipRole.OWNER ||
          membership.role === ProjectMembershipRole.ADMIN)
    ) ?? false

  /**
   * The app settings.
   */
  const appSettings = useAppSettings()

  /**
   * Indicats if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * The alert rules.
   */
  const [alertRules, setAlertRules] = useState<AlertGroup[]>([])

  /**
   * A list of level IDs.
   */
  const [listOfLevelIds, setListOfLevelIds] = useState<number[]>([])

  /**
   * The area upload.
   */
  const [areaUpload, setAreaUpload] = useState<Blueprint | undefined>(undefined)

  /**
   * Boot the component.
   */
  async function boot() {
    try {
      setIsBooting(true)

      const promises: Promise<unknown>[] = []

      promises.push(loadAlertRules(project.id))

      if (
        isSuperadmin ||
        isOwnerOrAdmin ||
        !project.customData?.blueprintAdminOnly ||
        Object.keys(project.customData).length === 0
      ) {
        promises.push(loadLevelBlueprints(project.id))
        promises.push(loadAreaBlueprint(project.id))
      }

      await Promise.all(promises)
    } catch (error: unknown) {
      // TODO: Handle errors.
    } finally {
      setIsBooting(false)
    }
  }

  async function loadAlertRules(projectId: number): Promise<void> {
    setAlertRules(await getProjectAlertRules(projectId))
  }

  async function loadLevelBlueprints(projectId: number): Promise<void> {
    setListOfLevelIds(await getLevelsByBlueprints(projectId))
  }

  async function loadAreaBlueprint(projectId: number): Promise<void> {
    setAreaUpload(await getBlueprintService({ project: projectId }))
  }

  /**
   * Handle the zoom change action.
   */
  const handleChangeZoom = (zoom: number) => {
    const hierarchySettings = appSettings.hierarchySettings

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        hierarchySettings: {
          ...hierarchySettings,
          hierarchyZoom: zoom
        }
      })
    )
  }

  useEffect(() => {
    boot()
  }, [project])

  return (
    <>
      {loading || isBooting ? (
        <LoadingState />
      ) : (
        <ContentWrapper height={height} isFullScreenMode={isFullScreenMode}>
          <TransformWrapper
            initialScale={appSettings.hierarchySettings?.hierarchyZoom ?? 1}
            minScale={0.25}
            centerZoomedOut={true}
            disablePadding={true}
            limitToBounds={true}
            onWheelStop={(e) => handleChangeZoom(e.state.scale)}
          >
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
                position: 'absolute'
              }}
            >
              {type === AssetType.DEVICE && (
                <PowerSupplyHierarchy
                  project={project}
                  userCanManageProject={isSuperadmin || isOwnerOrAdmin}
                  listOfLevelIds={listOfLevelIds}
                  areaUpload={areaUpload}
                  sectorIds={sectorIds}
                  alertRules={alertRules}
                />
              )}

              {type == AssetType.RUUVI_TAG && (
                <RuuviTagHierarchy
                  project={project}
                  ruuviTags={ruuviTags}
                  listOfLevelIds={listOfLevelIds}
                  areaUpload={areaUpload}
                  sectorIds={sectorIds}
                />
              )}
            </TransformComponent>
          </TransformWrapper>
        </ContentWrapper>
      )}
    </>
  )
}

interface ContentWrapperProps {
  height?: string | number
  isFullScreenMode?: boolean
  children: JSX.Element
}

function ContentWrapper({
  isFullScreenMode,
  height = '60vh',
  children
}: ContentWrapperProps) {
  const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    oveflow-y: auto;
    width: 100%;
    height: ${isFullScreenMode ? 'calc(100vh - 80px)' : height};
    padding: 0;
    position: relative;
  `

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid rgba(81, 81, 81, 1);
    border-radius: 4px;
  `

  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  )
}

interface AssetHierarchyWrapperProps {
  project: Project | ActiveProject
  userCanManageProject: boolean
  ruuviTags: RuuviTag[]
  loading?: boolean
  height?: string
}

const AssetHierarchyWrapper = memo(function ({
  project,
  userCanManageProject,
  ruuviTags,
  loading = false,
  height = '60vh'
}: AssetHierarchyWrapperProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * Indicates if the full screen mode is enabled.
   */
  const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false)

  /**
   * The styles of the card component.
   */
  const cardStyle = isFullScreenMode
    ? {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: 0,
        zIndex: 2147483647
      }
    : {}

  const hash: string = location.hash.substr(1)

  const [assetType, setAssetType] = useState<AssetType>(AssetType.DEVICE)

  /**
   * The selected sectors.
   */
  const [selectedSectors, setSelectedSectors] = useState<number[]>([])

  /**
   * Handle the sector selection change.
   */
  const handleSectorSelectChange = (event: SelectChangeEvent<number[]>) => {
    const value =
      typeof event.target.value === 'string'
        ? event.target.value.split(',').map((id) => parseInt(id))
        : event.target.value

    setSelectedSectors(value)
  }

  return (
    <Card sx={cardStyle}>
      <CardHeader
        title={
          assetType === AssetType.DEVICE
            ? t('powerSupplies')
            : t('conditionMonitoring')
        }
        action={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
          >
            <SectorSelect
              value={selectedSectors}
              onChange={handleSectorSelectChange}
            />

            {userCanManageProject && hash !== ProjectTab.POWER_SUPPLIES && (
              <Button
                size="small"
                variant="contained"
                onClick={() => navigate(`/projects/${project.id}/#levels`)}
                endIcon={<Add />}
              >
                {t('addFloorPlan')}
              </Button>
            )}

            {isFullScreenMode ? (
              <IconButton
                size="small"
                onClick={(event) => setIsFullScreenMode(false)}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={(event) => setIsFullScreenMode(true)}
              >
                <FullscreenIcon />
              </IconButton>
            )}
          </Stack>
        }
      />
      <CardContent>
        <AssetHierarchy
          type={assetType}
          sectorIds={selectedSectors}
          isFullScreenMode={isFullScreenMode}
          project={project}
          ruuviTags={ruuviTags}
          loading={loading}
          height={height}
        />
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          pt: 0
        }}
      >
        <AssetTypeToggle
          size="small"
          color="primary"
          value={assetType}
          onValueChange={setAssetType}
        />
      </CardActions>
    </Card>
  )
})

export default AssetHierarchyWrapper
