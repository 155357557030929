import { ProjectWarmingMethodType } from '../enums/ProjectWarmingMethodType'

export default function translateProjectWarmingMethod(
  warmingMethod: ProjectWarmingMethodType | string
): string {
  switch (warmingMethod) {
    case ProjectWarmingMethodType.WOOD:
      return 'Project.wood'
    case ProjectWarmingMethodType.OIL:
      return 'Project.oil'
    case ProjectWarmingMethodType.GAS:
      return 'Project.gas'
    case ProjectWarmingMethodType.WATER:
      return 'Project.district'
    case ProjectWarmingMethodType.ELECTRIC:
      return 'Project.electric'
    case ProjectWarmingMethodType.OTHER:
      return 'Project.other'
    default:
      return warmingMethod
  }
}
