import { Skeleton, Stack, Typography } from '@mui/material'
import { Device } from '../../types'
import DeviceStatusIndicator from '../../components/DeviceStatusIndicator'

export interface DeviceHeadingProps {
  device?: Device
}

export default function DeviceHeading({ device }: DeviceHeadingProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      flexGrow={0}
      flexShrink={0}
      gap={3}
      sx={{ width: '100%', maxWidth: 'calc(100% - 40px)' }}
    >
      {device ? (
        <DeviceStatusIndicator device={device} fontSize="medium" />
      ) : (
        <Skeleton variant="circular" width="1.5em" height="1.5em" />
      )}

      {device ? (
        <Typography
          variant="h3"
          display="inline"
          sx={{
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {device?.asset?.name ?? device.name}
        </Typography>
      ) : (
        <Skeleton variant="text" width="200px" sx={{ fontSize: '2em' }} />
      )}
    </Stack>
  )
}
