import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import {
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Stack,
  Grid
} from '@mui/material'
import { spacing } from '@mui/system'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'

type SignInFormValues = {
  email: string
  password: string
}

// eslint-disable-next-line
function SignIn() {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The auth state.
   */
  const { signIn } = useAuth()

  /**
   * The token.
   */
  const token =
    new URL(window.location.href).searchParams.get('token') ?? undefined

  /**
   * The initial values.
   */
  const initialValues: SignInFormValues = {
    email: '',
    password: ''
  }

  /**
   * The validation schema.
   */
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required')
  })

  const handleSubmit = async (
    values: SignInFormValues,
    { setSubmitting }: FormikHelpers<SignInFormValues>
  ) => {
    try {
      setSubmitting(true)

      await signIn(values.email, values.password, token)
    } catch (error: unknown) {
      dispatch(
        setError({
          type: AuthErrors.SIGN_IN,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: null,
        type: AuthErrors.SIGN_IN,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      // TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(AuthErrors.SIGN_IN))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>

            {/*<Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            </Grid>*/}

            <Grid item xs={12}>
              <Stack direction="column" gap={4}>
                <Button
                  data-cy="submit"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>

                <Stack direction="column" gap={1}>
                  <Button
                    component={Link}
                    to="/auth/reset-password"
                    fullWidth
                    color="primary"
                  >
                    Forgot password
                  </Button>

                  <Button
                    component={Link}
                    to={
                      token ? `/auth/sign-up?token=${token}` : '/auth/sign-up'
                    }
                    fullWidth
                    color="primary"
                  >
                    New user? Click here to create an account
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default SignIn
