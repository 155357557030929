import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { MoreVertical } from 'react-feather'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  IconButton,
  Paper,
  CircularProgress,
  Box,
  Chip,
  Button,
  ListItemButton,
  ListItemText,
  List,
  CardContent,
  Tooltip
} from '@mui/material'
import { spacing } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'

import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../redux/store'
import { ActiveProject, Project } from '../../../types'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import checkProjectStatus from '../../../utils/checkProjectStatus'
import { ProjectStateType } from '../../../enums/ProjectStateType'
import translateProjectState from '../../../utils/translateProjectState'
import ProjectDetails, {
  ProjectDetailsList
} from '../../../components/ProjectDetails'
import { ArrowForward, Edit } from '@mui/icons-material'

const Card = styled(MuiCard)(spacing)
interface ProjectInfoProps {
  project: Project | ActiveProject
  userCanManageProject: boolean
}

// eslint-disable-next-line
function ProjectInfo({ project, userCanManageProject }: ProjectInfoProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The mapbox api key.
   */
  const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader
            title={t('Project.projectInfo')}
            action={
              <>
                {userCanManageProject && (
                  <Tooltip title={t('edit') as string}>
                    <IconButton
                      onClick={() => navigate(`/projects/${project.id}/edit`)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            }
          />

          <CardContent>
            <ProjectDetailsList project={project as Project} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader
            action={
              <IconButton onClick={() => navigate(`/projects/${project.id}`)}>
                <ArrowForward />
              </IconButton>
            }
            title={t('Project.projectLocation')}
          />

          {project && (
            <Map
              mapboxAccessToken={mapboxApiKey}
              longitude={project?.long}
              latitude={project?.lat}
              initialViewState={{
                zoom: 13
              }}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              style={{ width: '100%', height: '100%', minHeight: '320px' }}
            >
              <Marker
                longitude={project?.long}
                latitude={project?.lat}
              ></Marker>
            </Map>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}
export default ProjectInfo
