import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums'
import AuthLayout from '../layouts/Auth'
import ChangePassword from '../pages/auth/ChangePassword'
import CompanyArchivedError from '../pages/auth/CompanyArchivedError'
import EmailVerification from '../pages/auth/EmailVerification'
import EmailVerificationLandingIndex from '../pages/auth/EmailVerificationLandingIndex'
import MFA from '../pages/auth/MFA'
import MFAReset from '../pages/auth/MFAReset'
import NewPassword from '../pages/auth/NewPassword'
import Page404 from '../pages/auth/Page404'
import Page500 from '../pages/auth/Page500'
import ResetPassword from '../pages/auth/ResetPassword'
import SignIn from '../pages/auth/SignIn'
import SignUp from '../pages/auth/SignUp'
import SignUpGuest from '../pages/auth/SignUpGuest'

export default [
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />
      },
      {
        path: 'sign-up',
        element: <SignUpGuest />
      },
      {
        path: 'sign-up/:token',
        element: <SignUp />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'reset-password/:token',
        element: <NewPassword />
      },
      {
        path: 'change-password',
        element: <ChangePassword />
      },
      {
        path: 'mfa/user',
        element: <MFA />
      },
      {
        path: 'reset-mfa',
        element: <MFAReset />
      },
      {
        path: 'verify-email',
        element: <EmailVerification />
      },
      {
        path: 'verify-email/:token',
        element: <EmailVerificationLandingIndex />
      },
      {
        path: '404',
        element: <Page404 />
      },
      {
        path: '500',
        element: <Page500 />
      },
      {
        path: 'company-archived',
        element: <CompanyArchivedError />
      }
    ]
  },
  {
    path: '/auth/change-password',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.GUEST,
          UserRole.INSTALLER
        ]}
      >
        <AuthLayout>
          <ChangePassword />
        </AuthLayout>
      </AuthGuard>
    )
  }
]
