import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import {
  Circle,
  NotificationsNone,
  NotificationsPaused
} from '@mui/icons-material'
import BoltIcon from '@mui/icons-material/Bolt'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { alpha, darken, lighten, useTheme } from '@mui/system'
import { amber, red } from '@mui/material/colors'

import styled, { keyframes } from 'styled-components/macro'
import { DateRange, Device, Sensor } from '../types'
import { AlertGroup } from '../types/Alerts'
import {
  getLastSensor,
  getSensorNow,
  getSensorsOfTheLastHour,
  getSensors
} from '../services/deviceService'
import { AlertRuleType, isDeviceAlerting } from '../utils/alert'
import {
  calculateMaxKwh,
  calculateAverageCurrent,
  calculateMinimumCurrent,
  calculateMaximumCurrent,
  calculateMaximumVoltage,
  calculateMinimumVoltage,
  calculateAverageVoltage,
  calculatePowerUsageAsPercentage,
  isSensorNow
} from '../utils/sensor'
import { humanizeTimestamp } from '../utils/date'
import { startOfMinute, subHours } from 'date-fns'
import SensorAggregateChart from './SensorAggregateChart'
import { ChartOptions } from 'chart.js'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { SensorAggregateField, SensorAggregateStatistic } from '../enums'
import { NavLink } from 'react-router-dom'
import DevicePowerBar from './DevicePowerBar'
import DeviceStatusIndicator from './DeviceStatusIndicator'
import { DeviceSignal } from '../enums/DeviceSignal'
import DeviceSignalIndicator from './DeviceSignalIndicator'
import DeviceAlertIndicator from './DeviceAlertIndicator'
import DeviceStatsTooltip from './DeviceStatsTooltip'

/**
 * Get the alert color for the alert rule type.
 */
function getAlertColor(type: AlertRuleType) {
  switch (type) {
    case AlertRuleType.SHORT_CIRCUIT:
      return alpha(red[500], 0.4)
    case AlertRuleType.HIGH_CURRENT:
      return alpha(amber[500], 0.4)
    case AlertRuleType.OVERLOADED_CURRENT:
      return alpha(red[500], 0.4)
    case AlertRuleType.HIGH_HUMIDITY:
      return alpha(amber[500], 0.4)
    case AlertRuleType.LOW_SIGNAL:
      return alpha(amber[500], 0.4)
    case AlertRuleType.HIGH_TEMPERATURE:
      return alpha(amber[500], 0.4)
    case AlertRuleType.HIGH_VOLTAGE:
      return alpha(red[500], 0.4)
    case AlertRuleType.LOW_VOLTAGE:
      return alpha(red[500], 0.4)
    default:
      return ''
  }
}

enum AvgType {
  VOLTAGE = 'voltage',
  CURRENT = 'current'
}

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `
}

const BlinkingComponent = styled.div`
  animation: ${blinkingEffect} 1s linear infinite;
`

/**
 * The size of the grid item.
 */
type DeviceGridItemSize = 'small' | 'large'

interface DeviceGridItemStatsBoxProps {
  title?: string
  value?: number | string
  unit?: string
  loading?: boolean
  photo?: string
}

function DeviceGridItemStatsBox({
  title,
  value,
  unit,
  loading = false,
  photo
}: DeviceGridItemStatsBoxProps) {
  const theme = useTheme()

  return (
    <Tooltip title={title ?? ''}>
      <Box>
        {loading ? (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: '2rem' }}
            />
            <Skeleton
              variant="text"
              width="24px"
              animation="wave"
              sx={{ fontSize: '1rem' }}
            />
          </>
        ) : (
          <>
            <Typography variant="h4">{value}</Typography>
            <Typography
              variant="h6"
              color={
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.5)'
                  : 'rgba(255, 255, 255, 0.6)'
              }
            >
              {unit}
            </Typography>
            {photo ? (
              <Paper elevation={6}>
                <img
                  src={photo}
                  style={{
                    display: 'block',
                    objectFit: 'cover',
                    width: '64px',
                    height: '64px',
                    borderRadius: '4px'
                  }}
                />
              </Paper>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Tooltip>
  )
}

interface DeviceGridItemContentProps {
  device: Device
  sensor?: Sensor | null
  sensors?: Sensor[]
  alertGroups: AlertGroup[]
  dateRange: DateRange
  statistic?: SensorAggregateStatistic
  size?: DeviceGridItemSize
  loading?: boolean
}

function DeviceGridItemContent({
  device,
  sensor = null,
  sensors = [],
  alertGroups = [],
  dateRange,
  statistic = SensorAggregateStatistic.AVG,
  size = 'large',
  loading = false
}: DeviceGridItemContentProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The alert rule type.
   */
  const alertRuleType = sensor ? isDeviceAlerting(sensor, alertGroups) : null

  /**
   * The alert color.
   */
  const alertColor = alertRuleType ? getAlertColor(alertRuleType) : ''

  /**
   * The background color.
   */
  const backgroundColor = alertRuleType
    ? alertColor
    : alpha(theme.palette.background.paper, 0.9)

  /**
   * The options of for the sensor chart..
   */
  const options: SensorAggregateOptions = {
    fields: [SensorAggregateField.KW],
    statistics: [statistic]
  }

  /**
   * The chart options.
   */
  const chartOptions: ChartOptions = {
    plugins: {
      zoom: undefined,
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        display: false,
        min: 0,
        suggestedMax: calculateMaxKwh(device?.powerSupply?.fuse ?? 0),
        grid: {
          display: false
        }
      }
    }
  }

  /**
   * Calculate the power.
   */
  function calculatePower(sensor: Sensor): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return sensor?.max_kw ?? sensor.kw
      case SensorAggregateStatistic.MIN:
        return sensor?.min_kw ?? sensor.kw
      case SensorAggregateStatistic.AVG:
      default:
        return sensor.kw
    }
  }

  /**
   * Calculate the voltage.
   */
  function calculateVoltage(sensor: Sensor): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return calculateMaximumVoltage(sensor)
      case SensorAggregateStatistic.MIN:
        return calculateMinimumVoltage(sensor)
      case SensorAggregateStatistic.AVG:
      default:
        return calculateAverageVoltage(sensor)
    }
  }

  /**
   * Calculate the current.
   */
  function calculateCurrent(sensor: Sensor): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return calculateMaximumCurrent(sensor)
      case SensorAggregateStatistic.MIN:
        return calculateMinimumCurrent(sensor)
      case SensorAggregateStatistic.AVG:
      default:
        return calculateAverageCurrent(sensor)
    }
  }

  return (
    <CardContent sx={{ backgroundColor: backgroundColor, height: '100%' }}>
      {size === 'large' && (
        <>
          {sensor && isSensorNow(sensor) && (
            <>
              <Box sx={{ mx: -4, mb: 2 }}>
                <SensorAggregateChart
                  dateRange={dateRange}
                  sensors={sensors}
                  loading={loading}
                  options={options}
                  chartOptions={chartOptions}
                  chartWrapperHeight="64px"
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Tooltip title={t('powerUsage') as string}>
                  <DevicePowerBar
                    device={device}
                    sensor={sensor}
                    size="small"
                  />
                </Tooltip>
              </Box>
            </>
          )}
        </>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6">
          <Stack direction="row" alignItems="center" gap={2}>
            {!loading && <DeviceStatusIndicator device={device} />}
            {device?.asset?.name ?? device?.name}
          </Stack>
        </Typography>
      </Stack>

      {size === 'small' && sensor && isSensorNow(sensor) && (
        <Stack direction="row" gap={3} alignItems="center" sx={{ my: 3 }}>
          <Typography fontSize="small">
            {sensor ? humanizeTimestamp(sensor.time) : '-'}
          </Typography>

          {sensor && isSensorNow(sensor) && (
            <Typography
              fontSize="small"
              sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <DeviceSignalIndicator device={device} sensor={sensor} />
            </Typography>
          )}
        </Stack>
      )}

      {!loading && (sensor === null || !isSensorNow(sensor)) ? (
        <>
          {sensor === null ? (
            <Alert variant="outlined" severity="error" sx={{ border: 0, p: 0 }}>
              {t('noSensorData')}
            </Alert>
          ) : (
            !isSensorNow(sensor) && (
              <DeviceStatsTooltip
                device={device}
                sensor={sensor}
                statistic={statistic}
              >
                <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{ border: 0, p: 0 }}
                >
                  {!device.status
                    ? t('deviceIsOffline')
                    : t('sensorIsOutdated')}
                </Alert>
              </DeviceStatsTooltip>
            )
          )}
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <DeviceGridItemStatsBox
              title={t('power')}
              value={sensor ? Math.round(calculatePower(sensor)) : 0}
              unit="kW"
              loading={loading}
            />
          </Grid>

          <Grid item xs={4}>
            <DeviceGridItemStatsBox
              title={t('voltage')}
              value={sensor ? Math.round(calculateVoltage(sensor)) : 0}
              unit="V"
              loading={loading}
            />
          </Grid>

          <Grid item xs={4}>
            <DeviceGridItemStatsBox
              title={t('current')}
              value={
                sensor
                  ? `${Math.round(calculateCurrent(sensor))}/${
                      device?.powerSupply?.fuse ?? '-'
                    }`
                  : `0/${device?.powerSupply?.fuse ?? '-'}`
              }
              unit="A"
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </CardContent>
  )
}

interface DeviceGridItemProps {
  device: Device
  alertRules?: AlertGroup[]
  statistic?: SensorAggregateStatistic
  size?: DeviceGridItemSize
}

export default function DeviceGridItem({
  device,
  alertRules = [],
  statistic = SensorAggregateStatistic.AVG,
  size = 'large'
}: DeviceGridItemProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The date range of the last hour.
   */
  const [dateRange, setDateRange] = useState<DateRange>({
    from: subHours(startOfMinute(new Date()), 1),
    to: startOfMinute(new Date())
  })

  /**
   * The current condition of RuuviTag.
   */
  const [sensor, setSensor] = useState<Sensor | null>(null)

  /**
   * The sensors of the last hour.
   */
  const [sensors, setSensors] = useState<Sensor[]>([])

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The alert rule type.
   */
  const alertRuleType = sensor ? isDeviceAlerting(sensor, alertRules) : null

  /**
   * The alert color.
   */
  const alertColor = alertRuleType ? getAlertColor(alertRuleType) : ''

  // Runs when when the sensor is set.
  useEffect(() => {
    const timer = setInterval(async () => {
      // Load sensors at the beginning of each minute.
      if (new Date().getSeconds() === 0) {
        boot()
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    boot(controller.signal)
    return () => controller.abort()
  }, [])

  /**
   * Boots the component.
   *
   * @param signal The abort signal
   */
  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      await loadSensorNow(signal)
      await loadSensors(signal)
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Loads the latest sensor.
   *
   * @param signal The abort signal
   */
  async function loadSensorNow(signal?: AbortSignal): Promise<void> {
    setSensor(await getLastSensor(device.name, { signal }))
  }

  /**
   * Loads the sensors of the last hour.
   */
  async function loadSensors(signal?: AbortSignal): Promise<void> {
    const dateRange = {
      from: subHours(new Date(), 1),
      to: new Date()
    }

    setDateRange(dateRange)

    setSensors(
      await getSensorsOfTheLastHour(device.name, {
        signal
      })
    )
  }

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <Box
        hidden={size !== 'large'}
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: alertRuleType
            ? alertColor
            : lighten(theme.palette.background.paper, 0.05)
        }}
      >
        {sensor && size === 'large' && (
          <Box
            sx={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              left: '1rem'
            }}
          >
            <Stack direction="row" justifyContent="space-between" gap={3}>
              {!isLoading && sensor && (
                <Stack direction="row" gap={3} alignItems="center">
                  <DeviceAlertIndicator
                    device={device}
                    sensor={sensor}
                    alertGroups={alertRules}
                    fontSize="medium"
                  />

                  {isSensorNow(sensor) && (
                    <DeviceSignalIndicator
                      device={device}
                      sensor={sensor}
                      fontSize="small"
                    />
                  )}
                </Stack>
              )}

              {isSensorNow(sensor) && (
                <Chip size="small" label={humanizeTimestamp(sensor.time)} />
              )}
            </Stack>
          </Box>
        )}

        {device?.asset?.photoUrl ? (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%'
            }}
          >
            <img
              src={device.asset.photoUrl}
              alt={device.asset.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
            <BoltIcon
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '5rem',
                color: 'rgba(255, 255, 255, 0.5)'
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: size === 'large' ? 'flex-start' : 'center',
              paddingTop: size === 'large' ? '5rem' : 0
            }}
          >
            <BoltIcon
              sx={{
                fontSize: '5rem',
                color:
                  theme.palette.mode === 'light'
                    ? darken(theme.palette.background.paper, 0.1)
                    : lighten(theme.palette.background.paper, 0.5)
              }}
            />
          </Box>
        )}
      </Box>

      <CardActionArea
        component={NavLink}
        to={`/devices/${device.name}`}
        sx={{ height: size === 'large' ? 'auto' : '100%' }}
      >
        {alertRuleType ? (
          <BlinkingComponent>
            <DeviceGridItemContent
              device={device}
              sensor={sensor}
              sensors={sensors}
              alertGroups={alertRules}
              dateRange={dateRange}
              statistic={statistic}
              size={size}
              loading={isLoading}
            />
          </BlinkingComponent>
        ) : (
          <DeviceGridItemContent
            device={device}
            sensor={sensor}
            sensors={sensors}
            alertGroups={alertRules}
            dateRange={dateRange}
            statistic={statistic}
            size={size}
            loading={isLoading}
          />
        )}
      </CardActionArea>
    </Card>
  )
}
