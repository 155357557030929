import { useTranslation } from 'react-i18next'
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider as MuiDivider,
  useMediaQuery,
  Tooltip,
  IconButton
} from '@mui/material'
import { spacing, useTheme } from '@mui/system'
import styled from 'styled-components/macro'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { CatalogProduct } from '../../types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  deleteCatalogProductState,
  getCatalogProducts,
  hideCatalogProduct,
  showCatalogProduct
} from '../../redux/slices/catalog'
import LoadingState from '../LoadingState'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material'
import CatalogForm from './CatalogForm'
import { deleteCatalogProduct } from '../../services/catalogProductService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { CatalogProductErrors } from '../../enums/CatalogProductErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { CatalogProductType } from '../../enums/CatalogProductType'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

export default function CatalogTable() {
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const { catalogProducts, catalogProduct, loading, show } = useSelector(
    (state: RootState) => state.catalogProducts
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCatalogProducts())
  }, [])

  const [filter, setFilter] = useState<string>('')

  const filteredCatalogProducts = catalogProducts?.filter(
    (product: CatalogProduct) => {
      const regex = new RegExp(filter, 'i')
      return (
        filter.length === 0 ||
        Object.values(product).some((value) => {
          return regex.test(value as string)
        })
      )
    }
  )

  const title = !show
    ? t('catalogProducts')
    : catalogProduct === undefined
    ? t('newCatalogProduct')
    : t('editCatalogProduct')

  async function handleDelete(id: string) {
    try {
      await deleteCatalogProduct(id)
      dispatch(deleteCatalogProductState(id))
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('catalogProductDeleteSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: CatalogProductErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  const [open, setOpen] = useState(false)
  const [catalogProductId, setCatalogProductId] = useState('')

  const handleClickOpen = (id: string) => {
    setCatalogProductId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCatalogProductId('')
  }

  const navigate = useNavigate()

  function translateProductType(type: CatalogProductType) {
    switch (type) {
      case CatalogProductType.SPINE_MODULE:
        return t('spineModule')
      case CatalogProductType.ELECTRICAL_CABINET:
        return t('electricalCabinet')
    }
  }

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <React.Fragment>
      <Button
        size="small"
        variant="contained"
        onClick={() => navigate('/manufacturing')}
      >
        <ArrowBackIcon />
        {t('Manufacturing.back')}
      </Button>
      <Divider my={6} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('confirmCatalogProductDeleteTitle')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('confirmDeleteInfo')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleDelete(catalogProductId)}>
            {t('yes')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('no')}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Typography
          variant="h3"
          my={2}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {title}
        </Typography>

        {show ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(hideCatalogProduct())}
          >
            {t('cancel')}
          </Button>
        ) : (
          <>
            {isMobile ? (
              <Tooltip title={t('newCatalogProduct') as string}>
                <IconButton
                  onClick={() => dispatch(showCatalogProduct(undefined))}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(showCatalogProduct(undefined))}
                endIcon={<AddIcon />}
              >
                {t('newCatalogProduct')}
              </Button>
            )}
          </>
        )}
      </Stack>

      {loading ? (
        <LoadingState />
      ) : show ? (
        <CatalogForm />
      ) : catalogProducts ? (
        <Card>
          <Grid item xs={6} md={3} ml={3} mt={2}>
            <TextField
              label={t('search')}
              fullWidth
              name="search"
              size="small"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
            />
          </Grid>
          <CardContent>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('type')}</TableCell>
                    <TableCell>{t('name')}</TableCell>
                    <TableCell>{t('manufacturer')}</TableCell>
                    <TableCell>{t('manufacturerProductCode')}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCatalogProducts?.map((product: CatalogProduct) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        {translateProductType(product.type)}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.manufacturer}</TableCell>
                      <TableCell>{product.manufacturerProductCode}</TableCell>
                      <TableCell align="right">
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => dispatch(showCatalogProduct(product))}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <DeleteIcon
                          color="error"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleClickOpen(product.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      ) : (
        <Typography>No catalog products</Typography>
      )}
    </React.Fragment>
  )
}
