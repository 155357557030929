import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Button,
  DialogContentText
} from '@mui/material'
import IssueType from '../enums/IssueType'
import { sendIssue } from '../services/jiraService'
import JiraProject from '../enums/JiraProject'
import { useDispatch } from 'react-redux'
import { createNotification } from '../redux/slices/notifications'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'

type TextIssueType = 'Bug' | 'New Feature'

interface BugReportProps {
  isOpen: boolean
  onClose: () => void
}

export const BugReport: React.FC<BugReportProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const handleIssueType = (type: TextIssueType): IssueType => {
    switch (type) {
      case 'Bug':
        return IssueType.BUG
      case 'New Feature':
        return IssueType.NEW_FEATURE
    }
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(
      t('isRequired', {
        field: t('BugReport.title')
      })
    ),
    description: Yup.string().required(
      t('isRequired', {
        field: t('BugReport.description')
      })
    )
  })

  const formik = useFormik({
    initialValues: {
      issueType: 'Bug' as TextIssueType,
      title: '',
      description: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await sendIssue(
          {
            issueType: handleIssueType(values.issueType),
            title: values.title,
            description: values.description
          },
          JiraProject.BUG_REPORT
        )

        dispatch(
          createNotification({
            show: true,
            type: 'info',
            message: t('BugReport.successMessage'),
            timeout: 5000
          })
        )
      } catch (error) {
        dispatch(
          createNotification({
            show: true,
            type: 'warning',
            message: t('BugReport.failureMessage'),
            timeout: 5000
          })
        )
      }
      closeBugReport()
    }
  })

  const closeBugReport = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeBugReport}
      aria-labelledby="bug-report-dialog-title"
      aria-describedby="bug-report-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="bug-report-dialog-title">
        {t('BugReport.titleText')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="bug-report-dialog-description">
          {t('BugReport.descriptionText')}
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label={t('BugReport.title')}
            name="title"
            value={formik.values.title}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            margin="normal"
          />
          <TextField
            select
            label={t('BugReport.issueType')}
            name="issueType"
            value={formik.values.issueType}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Bug">{t('BugReport.bug')}</MenuItem>
            <MenuItem value="New Feature">{t('BugReport.newFeature')}</MenuItem>
          </TextField>
          <TextField
            label={t('BugReport.description')}
            name="description"
            value={formik.values.description}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            rows={4}
            fullWidth
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            margin="normal"
          />
          <DialogActions>
            <Button onClick={closeBugReport} color="primary">
              {t('BugReport.cancel')}
            </Button>
            <LoadingButton
              loading={formik.isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('BugReport.submit')}
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}
