import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { RootState } from '../redux/store'

import { useNavigate } from 'react-router-dom'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import { Blueprint } from '../types/Blueprint'

import React from 'react'
import { ActiveProject, Level, Lift, Project, RuuviTag } from '../types'

import RuuviTagCard from './RuuviTagCard'

const lineSize = '8px'

interface RuuviTagHierarchyProps {
  ruuviTags: RuuviTag[]
  loading?: boolean
  project: Project | ActiveProject
  height?: string
  listOfLevelIds: number[]
  areaUpload?: Blueprint
  sectorIds: number[]
}

export default function RuuviTagHierarchy({
  ruuviTags,
  loading,
  project,
  listOfLevelIds,
  areaUpload,
  sectorIds
}: RuuviTagHierarchyProps) {
  const [t] = useTranslation('common')

  const levels: Level[] = useSelector((state: RootState) => state.levels.levels)

  const listOfLifts: Lift[] = useSelector(
    (state: RootState) => state.lifts.lifts
  )

  const lifts = listOfLifts.filter((lift) =>
    sectorIds.length === 0
      ? listOfLifts
      : sectorIds.indexOf(lift?.sector?.id as number) >= 0
  )

  const levelsFromTopToBottom = [...levels].sort(
    // @ts-ignore
    (a, b) => b.number - a.number
  )

  const ruuviTagsOfUnknownArea = ruuviTags.filter(
    (ruuviTag) => ruuviTag.asset?.levelId === null
  )

  /**
   * Get the ruuvitags that locate at the level and the lift.
   */
  function getRuuviTagsOfArea(lift: Lift, level: Level): RuuviTag[] {
    return ruuviTags.filter(
      (ruuviTag) =>
        // @ts-ignore
        ruuviTag.asset?.levelId === level.id &&
        ruuviTag.asset?.liftId === lift.id
    )
  }

  /**
   * Get the ruuvitags of the unknown area.
   */
  function getRuuviTagsOfUnknownArea(): RuuviTag[] {
    return ruuviTagsOfUnknownArea
  }

  /**
   * Get the gutter range.
   */
  function getGutterRange(lift: Lift, level: Level): any[] {
    const range: any[] = [null]

    const countOfPowerSuppliesOnTheArea = getRuuviTagsOfArea(lift, level).length
    const maxCountOfPowerSuppliesForLevel = Math.max(
      ...lifts.map((l) => {
        return getRuuviTagsOfArea(l, level).length
      })
    )

    for (
      let i = 0;
      i < maxCountOfPowerSuppliesForLevel - countOfPowerSuppliesOnTheArea;
      i++
    ) {
      range.push(null)
    }

    return range
  }
  const { theme } = useTheme()
  const navigate = useNavigate()

  async function handleNavigate(level: number | undefined) {
    level === undefined
      ? navigate(`/blueprint/project/${project.id}/level/area`)
      : navigate(`/blueprint/project/${project.id}/level/${level}`)
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
            {lifts.map((lift) => (
              <TableCell
                key={lift.id}
                sx={{
                  textAlign: 'center',
                  padding: 0,
                  width: '0px'
                }}
              >
                <Box
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography>{lift.name}</Typography>
                </Box>
              </TableCell>
            ))}
            <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/** Levels */}
          {levelsFromTopToBottom.map((level) => (
            <TableRow key={level.id}>
              <TableCell
                sx={{
                  textAlign: 'center',
                  padding: 0,
                  height: '100%',
                  width: '100%'
                }}
              >
                <Box position="relative" width="24px" height="100%">
                  <Typography
                    color={
                      listOfLevelIds.includes(level.id) === true
                        ? '#376fd0'
                        : theme === THEMES.DARK
                        ? 'white'
                        : 'black'
                    }
                    onClick={() =>
                      listOfLevelIds.includes(level.id) === true
                        ? handleNavigate(level.id)
                        : false
                    }
                    sx={{
                      position: 'absolute',
                      transform: 'rotate(-90deg) translateX(-50%)',
                      transformOrigin: 'left top',
                      width: '168px',
                      cursor:
                        listOfLevelIds.includes(level.id) === true
                          ? 'pointer'
                          : 'default'
                    }}
                  >
                    {level.name}
                  </Typography>
                </Box>
              </TableCell>
              {lifts.map((lift) => (
                <TableCell
                  key={lift.id}
                  sx={{
                    position: 'relative',
                    padding: 0,
                    width: '0px',
                    border: '1px solid rgba(81, 81, 81, 1)'
                  }}
                >
                  <Stack direction="row" height="100%" width="100%">
                    <Stack direction="row" height="100%">
                      <Stack
                        direction="column"
                        spacing={0}
                        flexGrow={0}
                        width="100%"
                        height="168px"
                      ></Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ height: '100%', width: '100%' }}
                    >
                      {getRuuviTagsOfArea(lift, level).map((ruuviTag) => (
                        <>
                          <Stack direction="column" spacing={0}>
                            <Stack direction="column" spacing={0}>
                              {/** The top gutter */}
                              {getGutterRange(lift, level).map((__, index) => (
                                <Box
                                  key={index}
                                  display="flex"
                                  width="100%"
                                  height={lineSize}
                                ></Box>
                              ))}
                            </Stack>

                            <Box
                              display="flex"
                              flexGrow="0"
                              justifyContent="center"
                              alignItems="center"
                              px={3}
                            >
                              <RuuviTagCard ruuviTag={ruuviTag} />
                            </Box>

                            <Stack direction="column" spacing={0}>
                              <Box
                                display="flex"
                                width="50%"
                                height={lineSize}
                                position="relative"
                              ></Box>
                            </Stack>
                          </Stack>
                        </>
                      ))}
                    </Stack>
                  </Stack>
                </TableCell>
              ))}
              <TableCell sx={{ padding: 0, width: '0px' }}></TableCell>
            </TableRow>
          ))}

          {/* Unknown Area */}

          <TableRow>
            <TableCell
              sx={{
                textAlign: 'center',
                padding: 0,
                height: '100%',
                width: '100%'
              }}
            >
              <Box
                position="relative"
                width="24px"
                height="100%"
                borderBottom={'1px'}
              >
                <Typography
                  color={
                    areaUpload
                      ? '#376fd0'
                      : theme === THEMES.DARK
                      ? 'white'
                      : 'black'
                  }
                  onClick={() =>
                    areaUpload ? handleNavigate(undefined) : false
                  }
                  sx={{
                    position: 'absolute',
                    transform: 'rotate(-90deg) translateX(-50%)',
                    transformOrigin: 'left top',
                    width: '168px',
                    cursor: areaUpload ? 'pointer' : 'default'
                  }}
                >
                  {t('areaLevel')}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ padding: 0, width: '0px' }}>
              <Stack
                direction="row"
                height="100%"
                minHeight="168px"
                flexGrow={1}
              >
                {/** Nothing should be here  */}
              </Stack>
            </TableCell>

            <TableCell sx={{ padding: 0, width: '0px' }}>
              <Stack direction="row" justifyContent="flex-start" height="100%">
                {[...getRuuviTagsOfUnknownArea()].map((ruuviTag) => (
                  <Stack direction="column" spacing={0} key={ruuviTag.id}>
                    {/** The top gutter */}
                    <Box display="flex" width="100%" height={lineSize}></Box>

                    <Box
                      display="flex"
                      width="50%"
                      height={lineSize}
                      position="relative"
                    ></Box>

                    <Box flexGrow="1" paddingX={3}>
                      <RuuviTagCard ruuviTag={ruuviTag} />
                    </Box>

                    {/** The bottom gutter  */}
                    <Box display="flex" width="100%" height={lineSize}></Box>
                  </Stack>
                ))}
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
