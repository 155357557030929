import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button as MuiButton,
  Typography,
  Stack,
  Skeleton
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LoadingState from '../../components/LoadingState'
import DeviceAlertTable from '../../components/DeviceAlertTable'
import { DeviceAlertType } from '../../enums/DeivceAlertType'
import { getAlerts } from '../../services/alertService'
import { Project, PrometheusAlert, Sensor } from '../../types'
import { getDevice } from '../../redux/slices/devices'
import { RootState } from '../../redux/store'
import CircleIcon from '@mui/icons-material/Circle'
import { getLastSensor } from '../../services/deviceService'
import { humanizeTimestamp } from '../../utils/date'
import ProjectAlertManagementTable from '../../components/ProjectAlertManagementTable'
import { getProjectAlertRules } from '../../redux/slices/projects'
import PageHeader from '../../components/PageHeader'
import DeviceHeading from './DeviceHeading'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Button = styled(MuiButton)(spacing)

// eslint-disable-next-line
function DeviceAlerts() {
  /**
   * The dispatch function
   */
  const dispatch = useDispatch()
  /**
   * The url params
   */
  const { name } = useParams()

  /**
   * The translation function
   */
  const [t] = useTranslation('common')

  /**
   * The current user
   */
  const { device } = useSelector((state: RootState) => state.devices)

  /**
   * The device's active alerts
   */
  const [deviceActiveAlerts, setDeviceActiveAlerts] = useState<
    PrometheusAlert[]
  >([])

  /**
   * Is loading alerts
   */
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   * Indicates if the sensor is being loaded.
   */
  const [isLoadingSensor, setIsLoadingSensor] = useState<boolean>(true)

  /**
   * The last sensor.
   */
  const [sensor, setSensor] = useState<Sensor | undefined>()

  /**
   * The navigate function
   */
  const navigate = useNavigate()

  const { projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  useEffect(() => {
    if (name) {
      dispatch(getDevice(name))
      loadAlertRules()
      loadSensor()
    }
  }, [name])

  useEffect(() => {
    if (device) {
      dispatch(getProjectAlertRules(device?.asset?.project?.id as number))
    }
  }, [device])

  async function loadSensor(): Promise<void> {
    try {
      setIsLoadingSensor(true)
      setSensor(await getLastSensor(name as string))
    } catch (error) {
      setSensor(undefined)
    } finally {
      setIsLoadingSensor(false)
    }
  }

  async function loadAlertRules() {
    try {
      setIsLoading(true)
      setDeviceActiveAlerts(await getAlerts({ device: name }))
      //TODO: Load device specific alertrules here
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * The device status color
   */
  const deviceStatusColor: 'success' | 'error' = device?.status
    ? 'success'
    : 'error'

  return (
    <React.Fragment>
      <PageHeader
        title={device?.asset?.name ?? device?.name ?? t('loading')}
        heading={<DeviceHeading device={device} />}
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/" title={t('frontpage')}>
              {t('frontpage')}
            </Link>

            {device?.asset?.project ? (
              <Link component={NavLink} to="/projects" title={t('projects')}>
                {t('projects')}
              </Link>
            ) : (
              device && (
                <Link component={NavLink} to="/devices" title={t('devices')}>
                  {t('devices')}
                </Link>
              )
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project?.id}`}
                title={device?.asset?.project?.name}
              >
                {device?.asset?.project?.name}
              </Link>
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project?.id}#monitoring`}
                title={t('deviceMonitoring')}
              >
                {t('deviceMonitoring')}
              </Link>
            )}

            {device ? (
              <Link
                component={NavLink}
                to={`/devices/${device?.name}`}
                title={device?.asset?.name ?? device.name}
              >
                {device?.asset?.name ?? device.name}
              </Link>
            ) : (
              <Skeleton variant="text" width={100} />
            )}

            <Typography>{t('alertManagement')}</Typography>
          </Breadcrumbs>
        }
      />

      <Grid container spacing={6} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          {isLoading ? (
            <LoadingState />
          ) : (
            <React.Fragment>
              <DeviceAlertTable
                type={DeviceAlertType.ALERTING}
                activeAlerts={deviceActiveAlerts}
                projectAlertRules={projectAlertRules}
              />

              <Divider my={6} />

              {/**We don't allow rule modifying here on purpose
               * This component will change in the future to device specific alertrules insted of project alert rules
               */}
              <Stack direction="row" alignItems="center" gap={3} mb={2}>
                <Typography mb={2} variant="h4">
                  {t('projectAlerts')}
                </Typography>
                <Typography mb={2} variant="h4" align="right">
                  <Button
                    variant="outlined"
                    onClick={() =>
                      navigate(
                        `/projects/${device?.asset?.project?.id}#alertmanagement`
                      )
                    }
                  >
                    {t('projectAlertManagement')}
                  </Button>
                </Typography>
              </Stack>
              <ProjectAlertManagementTable
                project={device?.asset?.project as Project}
                userCanManageProject={false}
              />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default DeviceAlerts
