import {
  Card,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Button as MuiButton,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Stack
} from '@mui/material'

import { Project, ProjectPhase } from '../types'
import { Task, ViewMode, Gantt } from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import LoadingState from './LoadingState'
import { calculateProgressByDate } from '../utils/calculateProgressByDate'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {
  deleteProjectPhase,
  updateProjectPhase
} from '../services/projectPhaseService'
import {
  deletePhase,
  getProjectPhases,
  hidePhase,
  setPhase,
  showPhase,
  updatePhaseState
} from '../redux/slices/phases'
import ProjectPhaseForm from './ProjectPhaseForm'
import { createUserNotification } from '../utils/createUserNotification'
import { ProjectPhaseErrors } from '../enums/ProjectPhaseErrors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { useAuth } from '../hooks'
import { useNavigate } from 'react-router-dom'
import { ProjectStateType } from '../enums/ProjectStateType'
import { UserRole } from '../enums'
import ProjectMembershipRole from '../enums/ProjectMembershipRole'

const Wrapper = styled.div`
  overflow-y: auto;
`

export interface ProjectGanttChartProps {
  project: Project
}

export default function ProjectGanttChart({ project }: ProjectGanttChartProps) {
  /**
   * The phases state.
   */
  const { phases, phase, show, loading } = useSelector(
    (state: RootState) => state.phases
  )

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The translation function.
   */
  const { i18n } = useTranslation()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the current user is superadmin.
   */
  const isSuperadmin = currentUser?.role === UserRole.SUPERADMIN

  /**
   * Indicates if the current user is owner or admin of the project.
   */
  const isOwnerOrAdmin =
    currentUser?.projects?.some(
      (membership) =>
        membership.projectId === project.id &&
        (membership.role === ProjectMembershipRole.OWNER ||
          membership.role === ProjectMembershipRole.ADMIN)
    ) ?? false

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  /**
   * Indicates if submitting.
   */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  /**
   * The view mode.
   */
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day)

  const initTasks: Task[] = []

  if (project) {
    initTasks.push({
      start: new Date(project?.startDate),
      end: new Date(project?.endDate),
      name: project.name,
      id: project.id.toString(),
      progress: calculateProgressByDate(project.startDate, project.endDate),
      type: 'project',
      hideChildren: false,
      isDisabled: true,
      displayOrder: 1,
      styles: {
        progressColor: '#00c04b',
        progressSelectedColor: '#00ab41',
        backgroundColor: '#6495ED',
        backgroundSelectedColor: '#6082B6'
      }
    })
  }

  /**
   * The tasks.
   */
  const [tasks, setTasks] = useState<Task[]>(initTasks)

  let columnWidth = 65
  if (view === ViewMode.Month) {
    columnWidth = 300
  } else if (view === ViewMode.Week) {
    columnWidth = 250
  }

  useEffect(() => {
    dispatch(getProjectPhases(project.id))
  }, [])

  const handleTaskChange = async (task: Task) => {
    try {
      setIsSubmitting(true)
      const updatedPhase = await updateProjectPhase(task.id, {
        name: task.name,
        startDate: task.start,
        endDate: task.end
      })

      dispatch(updatePhaseState(updatedPhase))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: phase ? ProjectPhaseErrors.EDIT : ProjectPhaseErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const [isProject, setIsProject] = useState<boolean>(false)

  const handleDblClick = (task: Task) => {
    task.type === 'project'
      ? setIsProject(true)
      : dispatch(
          setPhase({
            id: task.id,
            name: task.name,
            startDate: task.start,
            endDate: task.end
          })
        )
    setOpenDialog(true)
  }

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)))
  }

  useEffect(() => {
    phases.map((phase: ProjectPhase) => {
      initTasks.push({
        start: new Date(phase.startDate),
        end: new Date(phase.endDate),
        name: phase.name,
        id: phase.id,
        type: 'task',
        isDisabled: !isSuperadmin || !isOwnerOrAdmin,
        progress: calculateProgressByDate(phase.startDate, phase.endDate),
        project: project?.id.toString(),
        styles: {
          progressColor:
            new Date(phase.startDate) < new Date(project.startDate)
              ? 'orange'
              : '#00c04b',

          progressSelectedColor:
            new Date(phase.startDate) < new Date(project.startDate)
              ? '#F28C28'
              : '#00ab41'
        }
      })
    })
    setTasks(initTasks)
  }, [phases])

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  function handleOpenForm() {
    isProject
      ? navigate(`/projects/${project.id}/edit?timeline=true`)
      : dispatch(showPhase(phase))
    setOpenDialog(false)
  }

  function handleCloseDialog() {
    setIsProject(false)
    setPhase(undefined)
    setOpenDialog(false)
  }

  async function handleDeletePhase() {
    try {
      if (phase) {
        setIsSubmitting(true)
        await deleteProjectPhase(phase.id)
        dispatch(deletePhase(phase.id))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,

            t('projectPhaseDeleteSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectPhaseErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
      handleCloseDialog()
    }
  }

  const [showListOfPhases, setShowListOfPhases] = useState<boolean>(false)

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setShowListOfPhases(false)
    } else if (event.target.checked === true) {
      setShowListOfPhases(true)
    }
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: ViewMode
  ) => {
    setView(value)
  }

  const windowWidth = window.innerWidth

  const sidebarWidth = 258

  const gutterWidth = isMobile ? 40 + 24 : 96 + 24

  const wrapperWidth = isMobile
    ? windowWidth - gutterWidth
    : windowWidth - sidebarWidth - gutterWidth

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={() => handleCloseDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isProject ? (
          <DialogTitle id="alert-dialog-title">
            {project.name ?? '-'}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">
            {phase ? phase.name : '-'}
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isProject ? (
              <Table>
                <TableRow>
                  {t('starts')}:{' '}
                  {new Date(project.startDate).toLocaleString() ?? '-'}
                </TableRow>
                <TableRow>
                  {t('ends')}:{' '}
                  {new Date(project.endDate).toLocaleString() ?? '-'}
                </TableRow>
              </Table>
            ) : (
              <Table>
                <TableRow>
                  {t('starts')}:{' '}
                  {phase ? new Date(phase.startDate).toLocaleString() : '-'}
                </TableRow>
                <TableRow>
                  {t('ends')}:{' '}
                  {phase ? new Date(phase.endDate).toLocaleString() : '-'}
                </TableRow>
              </Table>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {(isSuperadmin || isOwnerOrAdmin) && (
            <React.Fragment>
              {isProject === false && (
                <Button
                  variant="contained"
                  onClick={() => handleDeletePhase()}
                  autoFocus
                  color="error"
                >
                  {t('delete')}
                </Button>
              )}

              <Button
                disabled={project?.state === ProjectStateType.ARCHIVED}
                variant="contained"
                onClick={() => handleOpenForm()}
                autoFocus
              >
                {t('edit')}
              </Button>
            </React.Fragment>
          )}

          <Button
            variant="contained"
            onClick={() => handleCloseDialog()}
            autoFocus
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container gap={6}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <FormControlLabel
              control={<Switch onChange={handleSwitchChange} />}
              label={t('showListOfPhases') as string}
            />

            <ToggleButtonGroup
              color="primary"
              size="small"
              value={view}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value={ViewMode.Hour}>{t('hour')}</ToggleButton>
              <ToggleButton value={ViewMode.Day}>{t('day')}</ToggleButton>
              <ToggleButton value={ViewMode.Week}>{t('week')}</ToggleButton>
              <ToggleButton value={ViewMode.Month}>{t('month')}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {loading || isSubmitting ? (
            <LoadingState />
          ) : (
            <React.Fragment>
              {show ? (
                <ProjectPhaseForm project={project} />
              ) : (
                <Wrapper
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    width: '100%',
                    maxWidth: `${wrapperWidth}px`
                  }}
                >
                  <Gantt
                    locale={i18n.language ?? 'en'}
                    tasks={tasks}
                    listCellWidth={showListOfPhases ? '155px' : ''}
                    columnWidth={columnWidth}
                    viewMode={view}
                    onDateChange={handleTaskChange}
                    onDoubleClick={handleDblClick}
                    onExpanderClick={handleExpanderClick}
                    todayColor="#ADD8E6"
                  />
                </Wrapper>
              )}
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12}>
          {!show && (
            <>
              {(isSuperadmin || isOwnerOrAdmin) && (
                <Button
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                  variant="contained"
                  sx={{ mb: 4 }}
                  onClick={() => dispatch(showPhase(undefined))}
                >
                  {t('addPhase')}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
