import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import 'mapbox-gl/dist/mapbox-gl.css'

// MUI
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Button as MuiButton,
  Typography,
  Box,
  Chip,
  LinearProgress as MuiLinearProgress,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from '@mui/material'
import { spacing } from '@mui/system'
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbar
} from '@mui/x-data-grid'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import {
  Add,
  BarChart as BarChartIcon,
  Edit,
  Visibility
} from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import InfoIcon from '@mui/icons-material/Info'

import styled from 'styled-components/macro'

import { RootState } from '../redux/store'
import {
  getProject,
  getProjects,
  setShow,
  setProject
} from '../redux/slices/projects'

// Hooks.
import useAuth from '../hooks/useAuth'

import { useTranslation } from 'react-i18next'

import { UserRole } from '../enums/UserRole'
import { ProjectStateType } from '../enums/ProjectStateType'
import ProjectMembershipRole from '../enums/ProjectMembershipRole'

import { Project, ProjectSettings } from '../types'

import { getProjectsByCompanyId } from '../services/projectService'

import translateProjectType from '../utils/translateProjectType'
import { calculateProjectProgress } from '../utils/projectProgress'
import checkProjectStatus from '../utils/checkProjectStatus'
import translateProjectState from '../utils/translateProjectState'

import ProjectArchiveTable from './ProjectArchiveTable'
import ProjectsMap from './ProjectsMap'
import LoadingState from './LoadingState'
import ProjectForm from './ProjectForm'
import { humanizeDateRange, humanizeDayRange } from '../utils/date'
import { userIsAdminOfProject, userIsOwnerOfProject } from '../utils/auth'
import { dispatch } from 'd3'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Paper = styled(MuiPaper)(spacing)
const Button = styled(MuiButton)(spacing)
const LinearProgress = styled(MuiLinearProgress)`
  height: 10px;
  width: 100%;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`

interface ProjectDataGridProps {
  /**
   * The projects.
   */
  projects: Project[]

  /**
   * The option to view the data per square meters.
   */
  perSquareMeters?: boolean
}

export default function ProjectDataGrid({
  projects,
  perSquareMeters = false
}: ProjectDataGridProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The energy consumption unit.
   */
  const energyConsumptionUnit = perSquareMeters ? 'kWh/m²' : 'kWh'

  /**
   * The energy cost unit.
   */
  const energyCostUnit = perSquareMeters ? '€/m²' : '€'

  /**
   * The energy emission unit.
   */
  const energyEmissionUnit = perSquareMeters ? 'kgCO₂/m²' : 'kgCO₂'

  /**
   * Calculate the emission for the project.
   *
   * @param project - The project.
   *
   * @returns The emission.
   */
  function calculateEmission(project: Project): number {
    const totalEmission = (project.totalEmission ?? 0) / 1000

    const emissionPerSquareMeter = project.area
      ? totalEmission / project.area
      : 0

    return perSquareMeters
      ? Math.round(emissionPerSquareMeter)
      : Math.round(totalEmission)
  }

  /**
   * Calculate the energy cost for the project.
   *
   * @param project - The project.
   *
   * @returns The energy cost.
   */
  function calculateEnergyCost(project: Project): number {
    const totalEnergyCost = !project.fixedEnergyPrice
      ? project.totalEnergyCostForFixedPrice ?? 0
      : project.totalEnergyCostForSpotPrice ?? 0

    const energyCostPerSquareMeter = project.area
      ? totalEnergyCost / project.area
      : 0

    return perSquareMeters
      ? Math.round(energyCostPerSquareMeter)
      : Math.round(totalEnergyCost)
  }

  /**
   * Calculate the energy consumption for the project.
   *
   * @param project - The project.
   *
   * @returns The energy consumption.
   */
  function calculateEnergyConsumption(project: Project): number {
    const totalEnergyConsumption = project.totalEnergyConsumption ?? 0

    const energyConsumptionPerSquareMeter = project.area
      ? totalEnergyConsumption / project.area
      : 0

    return perSquareMeters
      ? Math.round(energyConsumptionPerSquareMeter)
      : Math.round(totalEnergyConsumption)
  }

  /**
   * Checks if the current user can see the energy cost.
   *
   * @param project - The project.
   *
   * @returns True if the current user can see the energy cost, false otherwise.
   */
  function canCurrentUserSeeEnergyCosts(project: Project): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      userIsAdminOfProject(currentUser, project) ||
      userIsOwnerOfProject(currentUser, project) ||
      !project?.hideCosts
    )
  }

  /**
   * Handle the edit action.
   *
   * @param project - The project.
   */
  function handleEdit(project: Project): void {
    dispatch(setProject(project))
    dispatch(setShow(true))
  }

  const columns: GridColumns<Project> = [
    {
      field: 'state',
      headerName: t('status'),
      minWidth: 100,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Chip
            size="small"
            label={t(translateProjectState(row.state))}
            color={checkProjectStatus(row.state)}
          />
        )
      }
    },
    {
      field: 'timeline',
      headerName: t('timeline'),
      minWidth: 150,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={`${humanizeDayRange(row.startDate, row.endDate)}`}>
            <LinearProgress
              color="success"
              variant="determinate"
              value={calculateProjectProgress(row.startDate, row.endDate)}
            />
          </Tooltip>
        )
      }
    },
    {
      field: 'name',
      headerName: t('name'),
      minWidth: 300,
      editable: false,
      renderCell: ({ row }) => (
        <Link component={NavLink} to={`/projects/${row.id}`}>
          {row.name}
        </Link>
      )
    },
    {
      field: 'address',
      headerName: t('address'),
      type: 'string',
      minWidth: 300,
      editable: false,
      valueGetter: ({ row }) => `${row.address}, ${row.zip} ${row.city}`
    },
    {
      field: 'company.name',
      headerName: t('company'),
      type: 'string',
      minWidth: 150,
      editable: false,
      valueGetter: ({ row }) => row?.company?.name ?? '-'
    },
    {
      field: 'city',
      headerName: t('city'),
      type: 'string',
      minWidth: 150,
      editable: false
    },
    {
      field: 'type',
      headerName: t('type'),
      type: 'string',
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) =>
        t(translateProjectType(params.row.type) as string)
    },
    {
      field: 'area',
      headerName: `${t('area')} (m²)`,
      type: 'number',
      minWidth: 150,
      editable: false
    },
    {
      field: 'energyConsumption',
      headerName: `${t('energyConsumption')} (${energyConsumptionUnit})`,
      type: 'number',
      minWidth: 250,
      editable: false,
      valueGetter: ({ row }) => calculateEnergyConsumption(row)
    },
    {
      field: 'energyCost',
      headerName: `${t('energyCost')} (${energyCostUnit})`,
      type: 'number',
      minWidth: 250,
      editable: false,
      valueGetter: ({ row }) => calculateEnergyCost(row),
      renderCell: (params) =>
        canCurrentUserSeeEnergyCosts(params.row) && params.value
    },
    {
      field: 'co2Emissions',
      headerName: `${t('co2Emissions')} (${energyEmissionUnit})`,
      type: 'number',
      minWidth: 250,
      editable: false,
      valueGetter: (params) => calculateEmission(params.row)
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'right',
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              size="small"
              component={NavLink}
              to={`/projects/${params.row.id}`}
            >
              <Visibility fontSize="inherit" />
            </IconButton>

            <IconButton size="small" onClick={() => handleEdit(params.row)}>
              <Edit fontSize="inherit" />
            </IconButton>

            <IconButton
              size="small"
              component={NavLink}
              to={`/projects/${params.row.id}#alertmanagement`}
            >
              <EditNotificationsIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              size="small"
              component={NavLink}
              to={`/projects/${params.row.id}/stats`}
            >
              <BarChartIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <DataGrid
      rows={projects}
      columns={columns}
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      disableSelectionOnClick
      components={{
        Toolbar: GridToolbar
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 250 }
        }
      }}
      sx={{
        border: 0
      }}
    />
  )
}
