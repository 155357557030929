import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import { IconButton, Paper, Tooltip, Typography } from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import SignInComponent from '../../components/auth/SignIn'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks'
import jwtDecode from 'jwt-decode'
import { ProjectMembership } from '../../types'
import { joinProjectByToken } from '../../services/projectService'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function SignIn() {
  const { theme } = useTheme()

  const [t] = useTranslation('common')

  /**
   * The auth state.
   */
  const { currentUser, refreshUser, redirectUser } = useAuth()

  const savedTheme = localStorage.getItem('theme')

  const handleColorChange = () => {
    if (savedTheme === THEMES.LIGHT) {
      localStorage.setItem('theme', THEMES.DARK)
      window.location.reload()
    } else {
      localStorage.setItem('theme', THEMES.LIGHT)
      window.location.reload()
    }
  }

  const joinProjectByTokenAndRedirectUser = async (token: string) => {
    const decoded = jwtDecode<{ projectId: number }>(token)
    const membershipExists = currentUser?.projects?.some(
      (membership: ProjectMembership) =>
        membership.projectId === decoded.projectId
    )

    if (!membershipExists) {
      await joinProjectByToken(token)
      await refreshUser()
    }

    redirectUser(currentUser?.role)
  }

  useEffect(() => {
    refreshUser()
  }, [])

  useEffect(() => {
    if (currentUser !== undefined && currentUser !== null) {
      const searchParams = new URL(window.location.href).searchParams

      if (searchParams.has('token')) {
        const token = searchParams.get('token') as string
        joinProjectByTokenAndRedirectUser(token)
      } else {
        redirectUser(currentUser?.role)
      }
    }
  }, [currentUser])

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}

      <Wrapper>
        <Helmet title="Sign In" />
        <Typography>
          <Tooltip title={t('Navbar.switchTheme') as string}>
            <IconButton
              sx={{ ml: 1 }}
              onClick={handleColorChange}
              color="inherit"
            >
              {theme === THEMES.DARK ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  )
}

export default SignIn
