import React, { useEffect, useState, ChangeEvent, useRef } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Breadcrumbs,
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  CircularProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as MuiCard,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  Chip,
  Button,
  Skeleton,
  Tooltip,
  IconButton,
  Menu,
  MenuList,
  ListItemText,
  ToggleButton,
  MenuItem,
  ToggleButtonGroup,
  ListItemSecondaryAction,
  ListItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'
import { spacing } from '@mui/system'
import { green } from '@mui/material/colors'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../vendor/spine-white-logo-text.svg'
import Stats from './Stats'
import useAuth from '../../../hooks/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { getProjectDevices } from '../../../redux/slices/devices'
import ProjectInfo from './projectInfo'
import { createTheme } from '@mui/material'
import RequestTable from '../../../components/RequestTable'
import {
  BarChart as BarChartIcon,
  FilterList,
  MoreVert
} from '@mui/icons-material'
import CompanyForm from '../../../components/CompanyForm'
import { UserRole } from '../../../enums/UserRole'
import ProjectMembershipRole from '../../../enums/ProjectMembershipRole'
import { getPowerSuppliesByProjectId } from '../../../redux/slices/powerSupplies'
import { getLiftsByProjectId } from '../../../redux/slices/lifts'
import { getLevelsByProjectId } from '../../../redux/slices/levels'
import { getSectorsByProjectId } from '../../../redux/slices/sectors'
import useActiveProject from '../../../hooks/useActiveProject'
import ProjectEnergyPriceStats from '../../../components/ProjectEnergyPriceStats'
import ProjectEnergyCostStats from '../../../components/ProjectEnergyCostStats'
import ProjectEnergyConsumptionStats from '../../../components/ProjectEnergyConsumptionStats'
import { startOfDay, addDays, endOfDay, subDays } from 'date-fns'
import DateRangeSelect from '../../../components/DateRangeSelect'
import ProjectEmissionStats from '../../../components/ProjectEmissionStats'
import { usePowerSupplies } from '../../../hooks'
import PowerSupplyConsumptionStats from '../../../components/PowerSupplyConsumptionStats'
import Sensor from '../../../types/Sensor'
import { getSensorNow } from '../../../services/deviceService'
import { subHours } from 'date-fns/esm'
import { useTheme } from '@mui/system'
import { getSensorAggregates } from '../../../services/sensorAggregateService'
import { SensorAggregateInterval } from '../../../enums'
import { Device, RuuviTag, SensorAggregate } from '../../../types'
import useAppSettings from '../../../hooks/useAppSettings'
import TimePeriod from '../../../enums/TimePeriod'
import { setDateRange, setTimePeriod } from '../../../redux/slices/query'
import DateRangeBar from '../../../components/DateRangeBar'
import { createErrorOrSuccessNotification } from '../../../redux/slices/notifications'
import { NotificationType } from '../../../enums/NotificationType'
import { ActionRequestType } from '../../../enums/RequestAccessType'
import { RequesterType } from '../../../enums/RequesterType'
import { getActionRequestsByProject } from '../../../redux/slices/requests'
import AssetHierarchyWrapper, {
  AssetHierarchy
} from '../../../components/AssetHierarchyWrapper'
import { getRuuviTags } from '../../../services/ruuviTagService'
import { RequestStatus } from '../../../enums/RequestStatus'
import AssetScroller from '../../../components/AssetScroller'
import PageHeader from '../../../components/PageHeader'
import { AssetType } from '../../../enums/AssetType'
import AssetTypeToggle from '../../../components/AssetTypeToggle'

const Divider = styled(MuiDivider)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Typography = styled(MuiTypography)(spacing)
const Paper = styled(MuiPaper)(spacing)
const Card = styled(MuiCard)(spacing)

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 500px;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
`

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'normal'].join(',')
  }
})

function WeatherStats() {
  const [t] = useTranslation('common')

  const activeProject = useActiveProject()

  return (
    <>
      {activeProject && (
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Stats
              title={t('FrontPage.temperature') + ' ' + activeProject.city}
              amount={
                activeProject.weatherTemperature !== null
                  ? activeProject.weatherTemperature.toString() + ' °C'
                  : '0 °C'
              }
              chip={t('FrontPage.now')}
              percentagetext={new Date(activeProject.updatedAt).toLocaleString(
                'fin'
              )}
              percentageAltText={t('FrontPage.last')}
              percentagecolor={green[500]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stats
              title={t('FrontPage.wind') + ' ' + activeProject.city}
              amount={
                activeProject.weatherWind !== null
                  ? activeProject.weatherWind.toString() + ' m/s'
                  : '0 m/s'
              }
              chip={t('FrontPage.now')}
              percentagetext={new Date(activeProject.updatedAt).toLocaleString(
                'fi'
              )}
              percentageAltText={t('FrontPage.last')}
              percentagecolor={green[500]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stats
              title={t('FrontPage.humidity') + ' ' + activeProject.city}
              amount={
                activeProject.weatherHumidity !== null
                  ? activeProject.weatherHumidity.toString() + ' %'
                  : '0 %'
              }
              chip={t('FrontPage.now')}
              percentagetext={new Date(activeProject.updatedAt).toLocaleString(
                'fin'
              )}
              percentageAltText={t('FrontPage.last')}
              percentagecolor={green[500]}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

function DeviceTable(props: any) {
  const dispatch = useDispatch()
  const { devices, device, show } = props
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const checkpowerSupply = (device: any) => {
    if (device?.powerSupply != null && device?.powerSupply !== undefined) {
      return device?.powerSupply.name
    } else return
  }
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t('Devices.devices')}
        </Typography>
      </CardContent>
      {devices && (
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Devices.status')}</TableCell>
                  <TableCell align="right">{t('Devices.name')} (id)</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Chip
                        label={row.status ? 'Online' : 'Offline'}
                        color={row.status ? 'success' : 'error'}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Link component={NavLink} to={`/devices/${row.name}`}>
                        {checkpowerSupply(row)} ({row.name})
                      </Link>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      )}
    </Card>
  )
}

function DeviceList() {
  const dispatch = useDispatch()
  const { devices, device, show, loading } = useSelector(
    (state: RootState) => state.devices
  )
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser && currentUser.activeProjectMembership) {
      dispatch(
        getProjectDevices(currentUser?.activeProjectMembership.projectId)
      )
    } else {
      //dispatch(getDevicesByUser(currentUser?.activeProject.id))
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh'
              }}
            >
              <CircularProgress size="5vh" />
            </Box>
          ) : (
            <DeviceTable devices={devices} show={show} device={device} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

type AssetView = 'scroller' | 'hierarchy'

function Default() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The active project.
   */
  const activeProject = useActiveProject()

  /**
   * The application settings of the user.
   */
  const appSettings = useAppSettings()

  /**
   * The settings of the project.
   */
  const projectSettings =
    activeProject &&
    appSettings?.projects &&
    appSettings?.projects.has(activeProject.id)
      ? appSettings.projects.get(activeProject.id)
      : undefined

  /**
   * The date range.
   */
  const { dateRange } = useSelector((state: RootState) => state.query)

  const {
    projectRequests,
    deviceLocationChangeRequests,
    companyRequests,
    systemRequests,
    loading
  } = useSelector((state: RootState) => state.requests)

  /**
   * Indicates if there are project requests or not.
   */
  const hasProjectRequests: boolean = projectRequests.length > 0

  /**
   * Indicates if there are system requests or not.
   */
  const hasSystemRequests = systemRequests.length > 0

  /**
   * The power supplies of the project.
   */
  const powerSupplies = usePowerSupplies()

  /**
   * The ID of the selected power supply.
   */
  const [selectedPowerSupplyId, setSelectedPowerSupplyId] = useState<
    number | undefined
  >()

  /**
   * The ID of the previously selected power supply.
   */
  const [previouslySelectedPowerSupplyId, setPreviouslySelectedPowerSupplyId] =
    useState<number | undefined>()

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if the component has booted.
   */
  const [isBooted, setIsBooted] = useState<boolean>(false)

  /**
   * Indicates if power supplies are being loaded.
   */
  const isLoadingPowerSupplies = useSelector(
    (state: RootState) => state.powerSupplies.loading
  )

  /**
   * Indicates if levels are being loaded.
   */
  const isLoadingLevels = useSelector(
    (state: RootState) => state.levels.loading
  )

  /**
   * Indicates if lifts are being loaded.
   */
  const isLoadingLifts = useSelector((state: RootState) => state.lifts.loading)

  /**
   * Indicates if sectors are being loaded.
   */
  const isLoadingSectors = useSelector(
    (state: RootState) => state.sectors.loading
  )

  /**
   * The main power supplies of the project.
   */
  const mainPowerSupplies = powerSupplies.filter((powerSupply) => {
    return powerSupply.isMainDistributionAssembly
  })

  /**
   * The selected power supply.
   */
  const selectedPowerSupply = powerSupplies.find(
    (powerSupply) => powerSupply.id === selectedPowerSupplyId
  )

  /**
   * Indicates if the current user can see energy costss.
   */
  const currentUserCanSeeEnergyCosts =
    // !activeProject?.hideCosts ||
    currentUser?.role === UserRole.SUPERADMIN ||
    currentUser?.activeProjectMembership?.role ===
      ProjectMembershipRole.OWNER ||
    currentUser?.activeProjectMembership?.role === ProjectMembershipRole.ADMIN

  /**
   * Indicates if the current user can manage project.
   */
  const currentUserCanManageProject: boolean =
    currentUser?.role === UserRole.SUPERADMIN ||
    currentUser?.activeProjectMembership?.role ===
      ProjectMembershipRole.OWNER ||
    currentUser?.activeProjectMembership?.role === ProjectMembershipRole.ADMIN

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      if (currentUser && currentUser.activeProjectMembership) {
        setIsBooting(true)
        if (currentUserCanManageProject) {
          dispatch(
            getActionRequestsByProject(
              currentUser.activeProjectMembership.projectId,
              {
                requesterType: RequesterType.USER,
                status: RequestStatus.REQUESTED
              }
            )
          )

          dispatch(
            getActionRequestsByProject(
              currentUser.activeProjectMembership.projectId,
              {
                requesterType: RequesterType.DEVICE,
                status: RequestStatus.REQUESTED
              }
            )
          )

          dispatch(
            getActionRequestsByProject(
              currentUser.activeProjectMembership.projectId,
              {
                requesterType: RequesterType.SYSTEM,
                status: RequestStatus.REQUESTED
              }
            )
          )
        }

        dispatch(
          getPowerSuppliesByProjectId(
            currentUser.activeProjectMembership.projectId
          )
        )

        dispatch(
          getLevelsByProjectId(currentUser.activeProjectMembership.projectId)
        )

        dispatch(
          getLiftsByProjectId(currentUser.activeProjectMembership.projectId)
        )

        dispatch(
          getSectorsByProjectId(currentUser.activeProjectMembership.projectId)
        )

        await loadRuuviTagsOfProject(
          parseInt(currentUser.activeProjectMembership.projectId)
        )

        setIsBooted(true)
      }
    } finally {
      setIsBooting(false)
    }
  }

  const [isNotificationShown, setIsNotificationShown] = useState<boolean>(false)

  /**
   * The ruuvi tags of project.
   */
  const [ruuviTags, setRuuviTags] = useState<RuuviTag[]>([])

  /**
   * Indicates if ruuvi tags are loading.
   */
  const [isLoadingRuuviTags, setIsLoadingRuuviTags] = useState<boolean>(true)

  async function loadRuuviTagsOfProject(projectId: number): Promise<void> {
    try {
      setIsLoadingRuuviTags(true)
      setRuuviTags(await getRuuviTags({ projectId }))
    } finally {
      setIsLoadingRuuviTags(false)
    }
  }

  function handleSelectedPowerSupplyChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    changePowerSupplyId(parseInt(event.target.value))
  }

  function changePowerSupplyId(id: number | undefined): void {
    setPreviouslySelectedPowerSupplyId(selectedPowerSupplyId)
    setSelectedPowerSupplyId(id)

    if (activeProject) {
      const newProjectSettings = {
        ...projectSettings,
        selectedPowerSupplyId: id
      }

      const appSettingsForProjects = appSettings?.projects
        ? Object.fromEntries(appSettings.projects)
        : {}

      window.localStorage.setItem(
        'appSettings',
        JSON.stringify({
          ...appSettings,
          projects: {
            ...appSettingsForProjects,
            [activeProject.id]: newProjectSettings
          }
        })
      )
    }
  }

  useEffect(() => {
    const requests = [
      deviceLocationChangeRequests,
      projectRequests,
      companyRequests,
      systemRequests
    ]
    if (!isNotificationShown && requests.some((values) => values.length > 0)) {
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.INFO,
          //TODO: Translate
          t('notificationNoticeMessage')
        )
      )
      setIsNotificationShown(true)
    }
  }, [
    deviceLocationChangeRequests,
    projectRequests,
    companyRequests,
    systemRequests
  ])

  useEffect(() => {
    const controller = new AbortController()
    if (currentUser && currentUser.activeProjectMembership && !isBooted) {
      ;(async () => {
        await boot(controller.signal)
      })()
    }
    return () => controller.abort()
  }, [currentUser])

  useEffect(() => {
    if (mainPowerSupplies.length > 0 && selectedPowerSupplyId === undefined) {
      const powerSupplyIds = mainPowerSupplies.map(
        (powerSupply) => powerSupply.id
      )

      changePowerSupplyId(
        projectSettings?.selectedPowerSupplyId &&
          powerSupplyIds.includes(projectSettings.selectedPowerSupplyId)
          ? projectSettings.selectedPowerSupplyId
          : mainPowerSupplies[0].id
      )
    }
  }, [mainPowerSupplies])

  /**
   * The asset view.
   */
  const [assetView, setAssetView] = useState<AssetView>('scroller')

  /**
   * The asset type.
   */
  const [assetType, setAssetType] = useState<AssetType>(AssetType.DEVICE)

  /**
   * The asset menu anchor element.
   */
  const [assetMenuAchorEl, setAssetMenuAchorEl] = useState<HTMLElement>()

  /**
   * Indicates if the asset menu is open.
   */
  const isAssetMenuOpen = Boolean(assetMenuAchorEl)

  /**
   * Opens the asset menu.
   */
  const openAssetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAssetMenuAchorEl(event.currentTarget)
  }

  /**
   * Close the asset menu.
   */
  const closeAssetMenu = () => {
    setAssetMenuAchorEl(undefined)
  }

  return (
    <React.Fragment>
      <PageHeader
        hideBackButton={true}
        title={t('frontpage')}
        heading={
          <>
            {activeProject ? (
              <Typography variant="h3" display="inline">
                {activeProject?.name}
              </Typography>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                width="120px"
                sx={{ fontSize: '1.5rem' }}
              />
            )}
          </>
        }
        action={<DateRangeBar showAutoRefreshToggle={true} />}
      />

      <Box mt={6}>
        {currentUser &&
          !currentUser.company &&
          currentUser.role === UserRole.GUEST && (
            <Grid container>
              <Grid item xs={12}>
                <CompanyForm />
              </Grid>
            </Grid>
          )}

        {activeProject && (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <WeatherStats />
              </Grid>

              {(hasProjectRequests || hasSystemRequests) && (
                <Grid item xs={12}>
                  <RequestTable
                    requests={[...projectRequests, ...systemRequests]}
                    loading={loading}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ pl: 4, pr: 0, mb: 3 }}
                  gap={1}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {t('Devices.devices')}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    {assetView === 'scroller' && (
                      <Button
                        component={NavLink}
                        to={`/projects/${activeProject.id}#monitoring`}
                        variant="outlined"
                        size="small"
                      >
                        {t('showAll')}
                      </Button>
                    )}

                    {assetView === 'hierarchy' && (
                      <Button
                        component={NavLink}
                        to={`/projects/${activeProject.id}#assets`}
                        variant="outlined"
                        size="small"
                      >
                        {t('showAll')}
                      </Button>
                      // <AssetTypeToggle
                      //   size="small"
                      //   color="primary"
                      //   value={assetType}
                      //   onValueChange={setAssetType}
                      // />
                    )}

                    <>
                      <Tooltip title={t('settings') as string}>
                        <IconButton onClick={openAssetMenu}>
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        open={isAssetMenuOpen}
                        anchorEl={assetMenuAchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        PaperProps={{
                          sx: {
                            width: '320px'
                          }
                        }}
                        onClose={closeAssetMenu}
                      >
                        <MenuList>
                          <ListItem>
                            <FormControl>
                              <FormLabel>{t('viewMode')}</FormLabel>
                              <RadioGroup
                                defaultValue="scroller"
                                value={assetView}
                                onChange={(event, value) => {
                                  setAssetView(value as AssetView)
                                  closeAssetMenu()
                                }}
                              >
                                <FormControlLabel
                                  label={t('list') as string}
                                  value="scroller"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  label={t('hierarchy') as string}
                                  value="hierarchy"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </FormControl>
                          </ListItem>
                        </MenuList>
                      </Menu>
                    </>
                  </Stack>
                </Stack>

                {assetView === 'scroller' ? (
                  <AssetScroller
                    initialViewMode="small"
                    initialQuery={{ projectId: activeProject.id }}
                    showFilters={false}
                    // filtersProps={{
                    //   forceAccordion: true,
                    //   expandIcon: <FilterList />,
                    //   title: t('devices')
                    // }}
                  />
                ) : (
                  assetView === 'hierarchy' && (
                    <AssetHierarchy
                      type={assetType}
                      ruuviTags={ruuviTags}
                      project={activeProject}
                      height="40vh"
                      loading={
                        !isBooted &&
                        (isBooting ||
                          isLoadingPowerSupplies ||
                          isLoadingLevels ||
                          isLoadingLifts ||
                          isLoadingSectors ||
                          isLoadingRuuviTags)
                      }
                    />
                  )
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={6}>
                  {currentUserCanSeeEnergyCosts && (
                    <>
                      <Grid item xs={12} md={6}>
                        <ProjectEnergyPriceStats
                          project={activeProject}
                          dateRange={dateRange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ProjectEnergyCostStats
                          project={activeProject}
                          dateRange={dateRange}
                          loading={isBooting}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6}>
                    <ProjectEnergyConsumptionStats
                      project={activeProject}
                      dateRange={dateRange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ProjectEmissionStats
                      project={activeProject}
                      dateRange={dateRange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ProjectInfo
                      project={activeProject}
                      userCanManageProject={currentUserCanManageProject}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <PowerSupplyConsumptionStats
                      isLoadingPowerSupplies={isLoadingPowerSupplies}
                      powerSupplies={mainPowerSupplies}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </React.Fragment>
  )
}

export default Default
