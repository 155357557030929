import { NavigateNext } from '@mui/icons-material'
import { ListItemSecondaryAction } from '@mui/material'
import { max } from 'date-fns'
import { ro } from 'date-fns/locale'
import { position } from 'polished'

const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover'
    }
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6'
      }
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px'
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        boxShadow:
          'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none'
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: '300'
      }
    }
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: '64px'
      }
    }
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: '6px'
      }
    }
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: 'transparent',
        '& > *': {
          backgroundColor: 'transparent'
        }
      },
      switchHeader: {
        marginTop: '2px',
        marginBottom: '4px'
      }
    }
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`
      }
    }
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: '32px',
        height: '32px'
      }
    }
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400
        }
      },
      timeHeader: {
        '& h3': {
          fontSize: '3rem',
          fontWeight: 400
        }
      }
    }
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        '& h2': {
          fontSize: '3.75rem',
          fontWeight: 300
        }
      }
    }
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px'
      }
    }
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        '&.MuiListItemText-multiline': {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          overflow: 'hidden',
          paddingRight: '48px'
        },
        '& .MuiListItemText-primary': {
          order: 2
        },
        '& .MuiListItemText-secondary': {
          order: 1
        }
      }
    }
  },
  MuiListItemSecondaryAction: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  },
  MuiBreadcrumbs: {
    defaultProps: {
      maxItems: 3,
      itemsBeforeCollapse: 0,
      itemsAfterCollapse: 3,
      separator: <NavigateNext />
    },
    styleOverrides: {
      ol: {
        flexWrap: 'nowrap',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflowX: 'auto',
        width: '100%'
      },
      li: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '250px',
        '> p': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        position: 'relative',
        '> .MuiTabScrollButton-root:last-child': {
          right: 0,
          left: 'auto'
        }
      }
    }
  },
  MuiTabScrollButton: {
    styleOverrides: {
      root: {
        position: 'absolute',
        height: '100%'
      }
    }
  },
  MuiTabsScroller: {
    styleOverrides: {
      '+ MuiTabScrollButton-root': {
        right: 0,
        left: 'auto'
      }
    }
  }
}

export default components
