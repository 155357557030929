import { Helmet } from 'react-helmet-async'
import LoadingState from '../../components/LoadingState'
import {
  Breadcrumbs,
  Divider,
  Typography,
  Link,
  Box,
  Skeleton
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Device, SensorError } from '../../types'
import { useTranslation } from 'react-i18next'
import { useParams, NavLink } from 'react-router-dom'
import { getDevice, getSensorErrors } from '../../services/deviceService'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'
import { createNotification } from '../../redux/slices/notifications'
import PageHeader from '../../components/PageHeader'
import DeviceHeading from './DeviceHeading'

export default function DeviceSensorErrors() {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const { name } = useParams<{ name: string }>()

  const [device, setDevice] = useState<Device>()

  const [sensorErrors, setSensorErrors] = useState<SensorError[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const columns: GridColumns<SensorError> = [
    { key: 'error_code' },
    { key: 'timestamp', minWidth: 200 },
    { key: 'error_str', minWidth: 400 },
    { key: 'ver' },
    { key: 'ts', minWidth: 200 },
    { key: 'sim_id', minWidth: 200 },
    { key: 'fw' },
    { key: 'hw' },
    // 'c1',
    { key: 'min_c1' },
    { key: 'max_c1' },
    { key: 'avg_c1' },
    { key: 'med_c1' },
    // 'c2',
    { key: 'min_c2' },
    { key: 'max_c2' },
    { key: 'avg_c2' },
    { key: 'med_c2' },
    // 'c3',
    { key: 'min_c3' },
    { key: 'max_c3' },
    { key: 'avg_c3' },
    { key: 'med_c3' },
    // 'v1',
    { key: 'min_v1' },
    { key: 'max_v1' },
    { key: 'avg_v1' },
    { key: 'med_v1' },
    // 'v2',
    { key: 'min_v2' },
    { key: 'max_v2' },
    { key: 'avg_v2' },
    { key: 'med_v2' },
    // 'v3',
    { key: 'min_v3' },
    { key: 'max_v3' },
    { key: 'avg_v3' },
    { key: 'med_v3' },
    { key: 'temp' },
    // 'min_temp',
    // 'max_temp',
    // 'avg_temp',
    // 'med_temp',
    { key: 'rh' },
    // 'min_rh',
    // 'max_rh',
    // 'avg_rh',
    // 'med_rh',
    { key: 'rc' },
    // 'min_rc',
    // 'max_rc',
    // 'avg_rc',
    // 'med_rc',
    { key: 'rssi' },
    // 'min_rssi',
    // 'max_rssi',
    // 'avg_rssi',
    // 'med_rssi',
    { key: 'energy_counter' }
  ].map((column) => ({
    field: column.key,
    headerName: column.key,
    editable: false,
    minWidth: column.minWidth ?? 100
  }))

  const rows = sensorErrors.map((sensorError) => ({
    id: sensorError.ts,
    ...sensorError
  }))

  async function loadDevice(signal: AbortSignal): Promise<void> {
    if (!name) {
      return
    }
    try {
      setDevice(await getDevice(name))
    } catch (error) {
      dispatch(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Failed to load the device!',
          timeout: 5000
        })
      )
    }
  }

  async function loadSensorErrors(signal: AbortSignal): Promise<void> {
    if (!name) {
      return
    }
    try {
      const errors = await getSensorErrors(name)
      if (errors.length === 0) {
        dispatch(
          createNotification({
            show: true,
            type: 'info',
            message: 'No sensor errors found!',
            timeout: 5000
          })
        )
      }
      setSensorErrors(errors)
    } catch (error) {
      dispatch(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Failed to load sensor errors!',
          timeout: 5000
        })
      )
    }
  }

  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      await loadDevice(signal)
      await loadSensorErrors(signal)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    boot(controller.signal)

    return () => controller.abort()
  }, [])

  return (
    <>
      {isLoading || device === undefined ? (
        <LoadingState />
      ) : (
        <>
          <PageHeader
            title={device?.asset?.name ?? device?.name ?? t('loading')}
            heading={<DeviceHeading device={device} />}
            breadcrumbs={
              <Breadcrumbs>
                <Link component={NavLink} to="/" title={t('frontpage')}>
                  {t('frontpage')}
                </Link>

                {device?.asset?.project ? (
                  <Link
                    component={NavLink}
                    to="/projects"
                    title={t('projects')}
                  >
                    {t('projects')}
                  </Link>
                ) : (
                  device && (
                    <Link
                      component={NavLink}
                      to="/devices"
                      title={t('devices')}
                    >
                      {t('devices')}
                    </Link>
                  )
                )}

                {device?.asset?.project && (
                  <Link
                    component={NavLink}
                    to={`/projects/${device?.asset?.project?.id}`}
                    title={device?.asset?.project?.name}
                  >
                    {device?.asset?.project?.name}
                  </Link>
                )}

                {device?.asset?.project && (
                  <Link
                    component={NavLink}
                    to={`/projects/${device?.asset?.project?.id}#monitoring`}
                    title={t('deviceMonitoring')}
                  >
                    {t('deviceMonitoring')}
                  </Link>
                )}

                {device ? (
                  <Link
                    component={NavLink}
                    to={`/devices/${device?.name}`}
                    title={device?.asset?.name ?? device.name}
                  >
                    {device?.asset?.name ?? device.name}
                  </Link>
                ) : (
                  <Skeleton variant="text" width={100} />
                )}

                <Typography>{t('sensorErrors')}</Typography>
              </Breadcrumbs>
            }
          />

          <Box sx={{ mt: 3 }}>
            <DataGrid
              columns={columns}
              rows={rows}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}
