import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import AxiosStatic from 'axios'
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  MenuItem,
  InputAdornment,
  CardActions,
  Stack,
  Tooltip
} from '@mui/material'
import * as Yup from 'yup'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { getCompanies } from '../../redux/slices/companies'
import { format } from 'date-fns'
import { setOrder, updateOrder } from '../../redux/slices/orders'
import { OrderStatus } from '../../enums/OrderStatus'
import CompanySelect from '../CompanySelect'
import orderService from '../../services/orderService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { OrderErrors } from '../../enums/OrderErrors'
import useOrder from '../../hooks/useOrder'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { OrderType } from '../../enums/OrderType'
import { Order, OrderInput } from '../../types'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import LoadingState from '../LoadingState'
import { ArrowBack } from '@mui/icons-material'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const Button = styled(MuiButton)(spacing)

const Card = styled(MuiCard)(spacing)

const Grid = styled(MuiGrid)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

export default function OrderForm() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The order.
   */
  const order = useOrder()

  /**
   * The initial form values.
   */
  const initialValues: OrderInput =
    order === undefined
      ? {
          name: '',
          status: OrderStatus.PENDING,
          dueDate: format(new Date(), 'yyyy-MM-dd'),
          company: null,
          type: OrderType.INBOUND
        }
      : {
          name: order.name,
          status: order.status,
          dueDate: order.dueDate,
          company: order.company?.id,
          type: order.type
        }

  /**
   * The validation schema.
   */
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('isRequired', {
        field: 'reference'
      })
    )
  })

  /**
   * Handle the submit event.
   */
  const handleSubmit = async (
    values: OrderInput,
    {
      resetForm,
      setErrors,
      setStatus,
      setSubmitting
    }: FormikHelpers<OrderInput>
  ) => {
    try {
      values.company === null
        ? (values.type = OrderType.INBOUND)
        : (values.type = OrderType.OUTBOUND)

      if (order === undefined) {
        const createdOrder: Order = await orderService.createOrder(values)

        dispatch(setOrder(createdOrder))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('orderCreateSuccess')
          )
        )
        navigate(`/manufacturing/products/${createdOrder.id}/create`)
      } else {
        const updatedOrder = await orderService.updateOrder(order.id, values)

        dispatch(updateOrder(updatedOrder))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('orderUpdateSuccess')
          )
        )

        navigate('/manufacturing/orders')
      }

      resetForm()
      setStatus({ sent: true })
    } catch (error: unknown) {
      setStatus({ sent: false })

      if (AxiosStatic.isAxiosError(error) && error?.response?.status === 422) {
        if (error?.response?.data?.validation?.name) {
          setErrors({
            name: error.response.data.validation.name.map((rule: string) => {
              return t(rule, { field: 'reference' })
            })
          })
        }
      }

      dispatch(
        setError({
          type: order ? OrderErrors.CREATE : OrderErrors.EDIT,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: currentUser,
        type: order ? OrderErrors.CREATE : OrderErrors.EDIT,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(order ? OrderErrors.CREATE : OrderErrors.EDIT))
    } finally {
      setSubmitting(false)
    }
  }

  /**
   * Load companies when the component mounts.
   */
  useEffect(() => {
    dispatch(getCompanies())
  }, [])

  return (
    <>
      <Button
        size="small"
        startIcon={<ArrowBack fontSize="inherit" />}
        sx={{ mb: 6 }}
        onClick={() => navigate('/manufacturing')}
      >
        {t('back')}
      </Button>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setValues,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting ? (
              <LoadingState />
            ) : (
              <Card>
                <CardHeader
                  title={t('Manufacturing.instructionsForOrder')}
                  align="center"
                  titleTypographyProps={{ variant: 'h4' }}
                />
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <CompanySelect
                        name="company"
                        label={t('Manufacturing.customer')}
                        value={values.company}
                        error={Boolean(touched.company && errors.company)}
                        helperText={touched.company && errors.company}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <>
                              {values.company !== '' &&
                                values.company !== null && (
                                  <Tooltip title={t('clear') as string}>
                                    <InputAdornment position="end">
                                      <IconButton
                                        sx={{ p: '10px', mr: 3 }}
                                        aria-label="menu"
                                        size="small"
                                        onClick={() =>
                                          setValues({
                                            ...values,
                                            company: null
                                          })
                                        }
                                      >
                                        <ClearIcon fontSize="inherit" />
                                      </IconButton>
                                    </InputAdornment>
                                  </Tooltip>
                                )}
                            </>
                          )
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <CardHeader
                  title={t('Manufacturing.orderReference')}
                  align="center"
                  titleTypographyProps={{ variant: 'h4' }}
                />
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={order ? 4 : 6}>
                      <TextField
                        type="text"
                        name="name"
                        label={t('Manufacturing.orderReference')}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    {order && (
                      <Grid item xs={12} md={4}>
                        <TextField
                          select
                          name="status"
                          label={t('Manufacturing.status')}
                          value={values.status}
                          error={Boolean(touched.status && errors.status)}
                          helperText={touched.status && errors.status}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value={OrderStatus.PENDING}>
                            {t('Manufacturing.pending')}
                          </MenuItem>
                          <MenuItem value={OrderStatus.ON_HOLD}>
                            {t('Manufacturing.onHold')}
                          </MenuItem>
                          <MenuItem value={OrderStatus.IN_PROGRESS}>
                            {t('Manufacturing.inProgress')}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    )}

                    <Grid item xs={12} md={order ? 4 : 6}>
                      {/** TODO: Use <DatePicker> */}
                      <TextField
                        type="date"
                        name="dueDate"
                        label={t('Manufacturing.dueDate')}
                        value={values.dueDate}
                        error={Boolean(touched.dueDate && errors.dueDate)}
                        helperText={touched.dueDate && errors.dueDate}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                    mt={6}
                  >
                    <Button
                      onClick={() => {
                        navigate('/manufacturing')
                      }}
                    >
                      {t('Manufacturing.cancel')}
                    </Button>

                    <Button type="submit" variant="contained" color="primary">
                      {t('Manufacturing.save')}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            )}
          </form>
        )}
      </Formik>
    </>
  )
}
