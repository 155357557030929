import {
  Box,
  Button as MuiButton,
  Typography,
  Stack,
  ButtonProps
} from '@mui/material'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { setOrder, setOrders } from '../../redux/slices/orders'
import { useDispatch } from 'react-redux'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import useAuth from '../../hooks/useAuth'
import { UserRole } from '../../enums/UserRole'
import { setDevice } from '../../redux/slices/devices'
import { setData, setStep } from '../../redux/slices/steps'
import { QRData } from '../../enums/QRData'
import { setProduct } from '../../redux/slices/products'

const Button = styled(MuiButton)(spacing)

const HomeNavigationButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      style={{
        minHeight: '96px',
        fontSize: '24px',
        borderWidth: '3px'
      }}
      variant="outlined"
      fullWidth
    >
      {children}
    </Button>
  )
}

function HomeNavigation() {
  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  useEffect(() => {
    dispatch(setOrder(undefined))
    dispatch(setOrders(undefined))
    dispatch(setDevice(undefined))
    dispatch(setData(QRData.NOT_SCANNED))
    dispatch(setProduct(undefined))
    dispatch(setStep(0))
  }, [])

  return (
    <React.Fragment>
      <Stack direction="column" spacing={6}>
        {currentUser && currentUser.role === UserRole.SUPERADMIN && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              endIcon={<MailOutlineIcon />}
              onClick={() => navigate('/manufacturing/invite')}
            >
              {t('Manufacturing.invite')}
            </Button>
          </Box>
        )}

        <Typography variant="h3" align="center">
          {t('Manufacturing.selectAction')}
        </Typography>

        <Stack direction="column" spacing={2}>
          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/orders/create')}
          >
            {t('Manufacturing.newOrder')}
          </HomeNavigationButton>

          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/orders')}
          >
            {t('Manufacturing.orders')}
          </HomeNavigationButton>

          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/orders/shipped')}
          >
            {t('Manufacturing.inspectShipped')}
          </HomeNavigationButton>

          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/upload')}
          >
            {t('Manufacturing.instructionsAndPictures')}
          </HomeNavigationButton>

          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/catalog')}
          >
            {t('manageCatalog')}
          </HomeNavigationButton>

          <HomeNavigationButton
            onClick={() => navigate('/manufacturing/storage')}
          >
            {t('storage')}
          </HomeNavigationButton>
        </Stack>
      </Stack>
    </React.Fragment>
  )
}
export default HomeNavigation
