// Packages
import { useEffect, useState } from 'react'
import { format, subDays, subHours } from 'date-fns'
import { useTranslation } from 'react-i18next'

// Router
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'

// Types
import { DateRange, StatisticsAggregate } from '../../types'

// Enums
import { EnergyPriceDateRangeMode } from '../../enums'

// Hooks
import { useAuth, useProject } from '../../hooks'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { getProject } from '../../redux/slices/projects'

// Components
import { Helmet } from 'react-helmet-async'
import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Stack,
  Skeleton
} from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid'

import ProjectEnergyPriceStats from '../../components/ProjectEnergyPriceStats'
import ProjectEnergyConsumptionStats from '../../components/ProjectEnergyConsumptionStats'
import ProjectEnergyCostStats from '../../components/ProjectEnergyCostStats'
import ProjectEmissionStats from '../../components/ProjectEmissionStats'
import DateRangeSelect from '../../components/DateRangeSelect'

// Services
import { getStatisticsAggregatesOfProject } from '../../services/projectService'
import { setDateRange } from '../../redux/slices/query'
import DateRangeBar from '../../components/DateRangeBar'
import PageHeader from '../../components/PageHeader'

type ProjectStatsDataGridRow = {
  id: number
  time: string
  energyConsumption: string
  energyCost: string
  energyPrice: string
  co2Emissions: string
  co2EmissionFactor: string
}

function ProjectStatsDataGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default function ProjectStats() {
  const [t] = useTranslation('common')

  const { id } = useParams()

  const { currentUser } = useAuth()

  const project = useProject()

  const isLoading = useSelector((state: RootState) => state.projects.loading)

  const dispatch = useDispatch()

  const { dateRange } = useSelector((state: RootState) => ({
    dateRange: state.query.dateRange
  }))

  const [statisticsAggregates, setStatisticsAggregates] = useState<
    StatisticsAggregate[]
  >([])

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getProject(parseInt(id)))
    }
  }, [currentUser, id])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      await loadStatisticsAggregates(controller.signal)
    })()

    return () => controller.abort()
  }, [project, dateRange])

  async function loadStatisticsAggregates(signal: AbortSignal): Promise<void> {
    if (project) {
      setStatisticsAggregates(
        await getStatisticsAggregatesOfProject(project.id, dateRange)
      )
    }
  }

  const dataGridColumns = makeDataGridColumns()

  const dataGridRows = makeDataGridRows()

  function makeDataGridColumns(): any[] {
    const columns = [
      {
        field: 'time',
        headerName: t('date'),
        flex: 1
      },
      {
        field: 'energyConsumption',
        headerName: `${t('energyConsumption')} (kWh)`,
        flex: 1
      },
      {
        field: 'energyCost',
        headerName: `${t('energyCost')} (€)`,
        flex: 1
      },
      {
        field: 'energyPrice',
        headerName: `${t('energyPrice')} (c/kWh)`,
        flex: 1
      },
      {
        field: 'co2Emissions',
        headerName: `${t('co2Emissions')} (gCO2)`,
        flex: 1
      },
      {
        field: 'co2EmissionFactor',
        headerName: `${t('emissionFactor')} (gCO2/kWh)`,
        flex: 1
      }
    ]

    return columns
  }

  function makeDataGridRows(): any[] {
    return statisticsAggregates.reverse().map((statisticsAggregate) => ({
      id: statisticsAggregate.id,
      time: format(new Date(statisticsAggregate.timeBucket), 'd.M.yyyy HH:mm'),
      energyConsumption: humanizeValue(statisticsAggregate.energyConsumption),
      energyCost:
        statisticsAggregate.fixedEnergyPrice !== null
          ? humanizeValue(statisticsAggregate.energyCostForFixedPrice)
          : humanizeValue(statisticsAggregate.energyCostForSpotPrice),
      energyPrice:
        statisticsAggregate.fixedEnergyPrice !== null
          ? humanizeValue(statisticsAggregate.fixedEnergyPrice as number)
          : humanizeValue(statisticsAggregate.spotEnergyPrice),
      co2Emissions: humanizeValue(statisticsAggregate.co2Emissions),
      co2EmissionFactor:
        statisticsAggregate.fixedEmissionFactor !== null
          ? humanizeValue(statisticsAggregate.fixedEmissionFactor)
          : humanizeValue(statisticsAggregate.realtimeEmissionFactor)
    }))
  }

  function humanizeValue(value: number | null): string {
    return value !== null ? value.toFixed(2) : '-'
  }

  return (
    <>
      <PageHeader
        title={project ? project.name : t('loading')}
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>

            <Link component={NavLink} to="/projects">
              {t('projects')}
            </Link>

            {project ? (
              <Link component={NavLink} to={`/projects/${project.id}`}>
                {project.name}
              </Link>
            ) : (
              <Skeleton variant="text" width="120px" />
            )}
            <Typography>{t('stats')}</Typography>
          </Breadcrumbs>
        }
      />

      <DateRangeBar showAutoRefreshToggle={false} />

      {project && (
        <>
          <Grid container spacing={6} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6} xl={3}>
              <ProjectEnergyPriceStats
                project={project}
                dateRange={dateRange}
                initialSettings={{
                  dateRangeMode: EnergyPriceDateRangeMode.SELECTED_DATE_RANGE
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <ProjectEnergyConsumptionStats
                project={project}
                dateRange={dateRange}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <ProjectEnergyCostStats
                project={project}
                dateRange={dateRange}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <ProjectEmissionStats
                project={project}
                dateRange={dateRange}
                loading={isLoading}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <DataGrid
                autoHeight
                columns={dataGridColumns}
                rows={dataGridRows}
                components={{ Toolbar: ProjectStatsDataGridToolbar }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
