import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Chip,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Project } from '../types'
import { ProjectStateType } from '../enums/ProjectStateType'
import checkProjectStatus from '../utils/checkProjectStatus'
import translateProjectState from '../utils/translateProjectState'
import translateProjectType from '../utils/translateProjectType'
import translateProjectNewOrOldType from '../utils/translateProjectNewOrOldType'
import translateProjectWarmingMethod from '../utils/translateProjectWarmingMethod'

interface TextOrSkeletonProps {
  value: string | number | null | undefined
}

function TextOrSkeleton({ value }: TextOrSkeletonProps) {
  return value !== undefined ? (
    <Typography>{value ?? '-'}</Typography>
  ) : (
    <Skeleton variant="text" width={100} />
  )
}

export interface ProjectDetailsListProps {
  project: Project | undefined
}

export function ProjectDetailsList({ project }: ProjectDetailsListProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  /**
   * The area of the project.
   */
  const area = project?.area !== undefined ? `${project.area} m²` : undefined

  /**
   * The type of the project.
   */
  const type = project?.type
    ? (t(translateProjectType(project.type) as string) as string)
    : undefined

  /**
   * The newOrOld method of the project.
   */
  const newOrOld = project?.new
    ? (t(translateProjectNewOrOldType(project.new) as string) as string)
    : undefined

  /**
   * The warming method of the project.
   */
  const warmingMethod = project?.warmingMethod
    ? (t(
        translateProjectWarmingMethod(project.warmingMethod) as string
      ) as string)
    : undefined

  return (
    <List>
      <ListItemButton key="status">
        <ListItemText
          primary={
            <>
              {project ? (
                <Chip
                  size="small"
                  label={t(translateProjectState(project.state) as string)}
                  color={checkProjectStatus(project.state as ProjectStateType)}
                />
              ) : (
                <Skeleton variant="text" width={100} />
              )}
            </>
          }
          secondary={t('status')}
        />
      </ListItemButton>

      <ListItemButton key="name">
        <ListItemText
          primary={<TextOrSkeleton value={project?.name} />}
          secondary={t('Project.name')}
        />
      </ListItemButton>

      <ListItemButton key="address">
        <ListItemText
          primary={<TextOrSkeleton value={project?.address} />}
          secondary={t('Project.address')}
        />
      </ListItemButton>

      <ListItemButton key="city">
        <ListItemText
          primary={<TextOrSkeleton value={project?.city} />}
          secondary={t('Project.city')}
        />
      </ListItemButton>

      <ListItemButton key="zip">
        <ListItemText primary={project?.zip} secondary={t('Project.zip')} />
      </ListItemButton>

      {project?.countryCode && (
        <ListItemButton key="countryCode">
          <ListItemText
            primary={project.countryCode}
            secondary={t('countryCode')}
          />
        </ListItemButton>
      )}

      <ListItemButton key="area">
        <ListItemText
          primary={<TextOrSkeleton value={area} />}
          secondary={t('Project.area')}
        />
      </ListItemButton>

      <ListItemButton key="type">
        <ListItemText
          primary={<TextOrSkeleton value={type} />}
          secondary={t('Project.type')}
        />
      </ListItemButton>

      <ListItemButton key="newOrOld">
        <ListItemText
          primary={<TextOrSkeleton value={newOrOld} />}
          secondary={`${t('Project.new')} / ${t('Project.renovation')}`}
        />
      </ListItemButton>

      <ListItemButton key="warming">
        <ListItemText
          primary={<TextOrSkeleton value={warmingMethod} />}
          secondary={t('Project.warming')}
        />
      </ListItemButton>
    </List>
  )
}

export interface ProjectDetailsProps extends Omit<AccordionProps, 'children'> {
  project: Project | undefined
}

export default function ProjectDetails({
  project,
  ...props
}: ProjectDetailsProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  return (
    <Accordion sx={{ boxShadow: 'none' }} {...props}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ pl: 4 }}>
        <Typography variant="h6">{t('Project.projectInfo')}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ProjectDetailsList project={project} />
      </AccordionDetails>
    </Accordion>
  )
}
