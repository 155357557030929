import '@mui/lab/themeAugmentation'

import { createTheme as createMuiTheme } from '@mui/material/styles'
import variants from './variants'
import typography from './typography'
import breakpoints from './breakpoints'
import components from './components.tsx'
import shadows from './shadows'

// eslint-disable-next-line
const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name)

  if (!themeConfig) {
    // eslint-disable-next-line
    console.warn(new Error(`The theme ${name} is not valid`))
    themeConfig = variants[0]
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      components: components,
      typography: typography,
      // shadows: shadows,
      palette: themeConfig.palette
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar
    }
  )
}

export default createTheme
