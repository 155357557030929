import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Company } from '../types'
import { ExpandMore } from '@mui/icons-material'

interface CompanyDetailsListProps {
  company?: Company | undefined
}

function CompanyDetailsList({ company }: CompanyDetailsListProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  return (
    <List>
      <ListItemButton key="name">
        <ListItemText primary={company?.name} secondary={t('name')} />
      </ListItemButton>

      <ListItemButton key="address">
        <ListItemText primary={company?.address} secondary={t('address')} />
      </ListItemButton>

      <ListItemButton key="city">
        <ListItemText primary={company?.city} secondary={t('city')} />
      </ListItemButton>

      <ListItemButton key="zip">
        <ListItemText primary={company?.zip} secondary={t('zip')} />
      </ListItemButton>
    </List>
  )
}

export interface CompanyDetailsProps extends Omit<AccordionProps, 'children'> {
  title?: string
  company?: Company | undefined
}

export default function CompanyDetails({
  title,
  company,
  sx,
  ...props
}: CompanyDetailsProps) {
  /**
   * The translate function.
   */
  const { t } = useTranslation('common')

  return (
    <Accordion {...props} sx={{ ...sx, boxShadow: 'none' }}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ pl: 4 }}>
        <Typography variant="h6">
          {title ? title : t('companyDetails')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CompanyDetailsList company={company} />
      </AccordionDetails>
    </Accordion>
  )
}
