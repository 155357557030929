import styled from 'styled-components/macro'
import Language from '../types/Language'

const FlagIcon = styled.img`
  border-radius: 50%;
  width: 18.56px;
  height: 18.56px;
`

/**
 * The flags.
 */
const flags = {
  fi: {
    icon: '/static/img/flags/fi.png',
    name: 'Suomi'
  },
  en: {
    icon: '/static/img/flags/us.png',
    name: 'English'
  },
  swe: {
    icon: '/static/img/flags/se.png',
    name: 'Svenska'
  }
}

interface FlapProps {
  language: Language
  size?: 'small' | 'medium' | 'large'
}

export default function Flag({ language, size }: FlapProps) {
  /**
   * The flag of country.
   */
  const flag = flags[language]

  return <FlagIcon src={flag.icon} alt={flag.name} />
}
