import {
  PowerSupply,
  Project,
  SensorAggregate,
  SensorAggregateMap,
  SensorAggregateQuery
} from '../types'
import { getSensorAggregates } from '../services/sensorAggregateService'
import { SensorAggregateField, SensorAggregateStatistic } from '../enums'

/**
 * Check if the project is active.
 */
export const isProjectActive = (project: Project): boolean => {
  return project.deletedAt === null
}

/**
 * Check if the project is active.
 */
export const isProjectDeleted = (project: Project): boolean => {
  return project.deletedAt !== null
}

/**
 * Get sensor aggregates of project mapped by device.
 */
export async function getSensorAggregatesOfProjectByDevice(
  deviceNames: string[],
  query: SensorAggregateQuery,
  signal?: AbortSignal
): Promise<SensorAggregateMap> {
  const sensorAggregatesByDevice: SensorAggregateMap = {}

  for (const deviceName of deviceNames) {
    const sensorAggregates = await getSensorAggregates(
      deviceName,
      {
        interval: query.interval,
        from: query.from,
        to: query.to
      },
      {
        signal
      }
    )

    sensorAggregatesByDevice[deviceName] = sensorAggregates
  }

  return sensorAggregatesByDevice
}

/**
 * Get sensor aggregates of project mapped by date.
 */
export async function getSensorAggregatesOfProjectByDate(
  deviceNames: string[],
  query: SensorAggregateQuery,
  signal?: AbortSignal
): Promise<SensorAggregateMap> {
  const sensorAggregatesByDate: SensorAggregateMap = {}

  for (const deviceName of deviceNames) {
    const sensorAggregates = await getSensorAggregates(
      deviceName,
      {
        interval: query.interval,
        from: query.from,
        to: query.to
      },
      {
        signal
      }
    )

    sensorAggregates.forEach((sensorAggregate) => {
      if (!sensorAggregatesByDate[sensorAggregate.time_bucket]) {
        sensorAggregatesByDate[sensorAggregate.time_bucket] = []
      }

      sensorAggregatesByDate[sensorAggregate.time_bucket] = [
        ...sensorAggregatesByDate[sensorAggregate.time_bucket],
        ...sensorAggregates
      ]
    })
  }

  return sensorAggregatesByDate
}

export function makeSensorAggregates(
  sensorAggregatesByDevice: SensorAggregateMap
): SensorAggregate[] {
  // Intialize map of sensor aggregates where the time bucket is the key.
  const sensorAggregatesByDate: Map<string, SensorAggregate> = new Map()

  const deviceNames = Object.keys(sensorAggregatesByDevice)
  for (const deviceName of deviceNames) {
    const sensorAggregates = sensorAggregatesByDevice[deviceName]

    // @ts-ignore
    for (const sensorAggregate of sensorAggregates) {
      const addedSensorAggregate = sensorAggregatesByDate.get(
        sensorAggregate.time_bucket
      )

      if (addedSensorAggregate) {
        sensorAggregatesByDate.set(sensorAggregate.time_bucket, {
          ...mergeSensorAggregates(addedSensorAggregate, sensorAggregate)
        })
      } else {
        sensorAggregatesByDate.set(sensorAggregate.time_bucket, {
          ...sensorAggregate
        })
      }
    }
  }

  return Array.from(sensorAggregatesByDate.values())
}

function mergeSensorAggregates(
  sensorAggregate: SensorAggregate,
  otherSensorAggregate: SensorAggregate
): SensorAggregate {
  const newSensorAggregate = { ...sensorAggregate }

  newSensorAggregate.device_code = `${sensorAggregate.device_code},${otherSensorAggregate.device_code}`

  // The statistics.
  const statistics: SensorAggregateStatistic[] = [
    SensorAggregateStatistic.MIN,
    SensorAggregateStatistic.MAX,
    SensorAggregateStatistic.AVG
  ]

  // The fields that should be summed.
  const summableField: SensorAggregateField[] = [
    SensorAggregateField.CURRENT_1,
    SensorAggregateField.CURRENT_2,
    SensorAggregateField.CURRENT_3,
    SensorAggregateField.KW,
    SensorAggregateField.RATED_CURRENT
  ]

  for (const statistic of statistics) {
    for (const field of summableField) {
      const key = `${statistic}_${field}` as keyof SensorAggregate
      const currentValue = sensorAggregate[key] as number
      const addableValue = otherSensorAggregate[key] as number
      const sum = currentValue + addableValue
      // @ts-ignore
      newSensorAggregate[key] = sum
    }
  }

  // The fields that should be aggregated.
  const aggregatableFields: SensorAggregateField[] = [
    SensorAggregateField.VOLTAGE_1,
    SensorAggregateField.VOLTAGE_2,
    SensorAggregateField.VOLTAGE_3,
    SensorAggregateField.TEMP,
    SensorAggregateField.RH,
    SensorAggregateField.RSSI
  ]

  for (const statistic of statistics) {
    for (const field of aggregatableFields) {
      const key = `${statistic}_${field}` as keyof SensorAggregate

      const value = sensorAggregate[key] as number
      const newValue = otherSensorAggregate[key] as number

      switch (statistic) {
        case SensorAggregateStatistic.MIN:
          // @ts-ignore
          newSensorAggregate[key] = value < newValue ? value : newValue
          break
        case SensorAggregateStatistic.MAX:
          // @ts-ignore
          newSensorAggregate[key] = value > newValue ? value : newValue
          break
        case SensorAggregateStatistic.AVG:
          // TODO: Count the average <- This is impossible to do here.
          break
      }
    }
  }

  return newSensorAggregate
}
