import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import styled, { withTheme } from 'styled-components/macro'

import {
  Box,
  Button,
  Divider,
  Drawer,
  Alert,
  AlertColor,
  Grid,
  AppBar as MuiAppBar,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'

import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material'

import { THEMES } from '../../constants'

import { setShowInstructions } from '../../redux/slices/notifications'

import { useUserManual } from '../../contexts/userManual'

import { Announcement } from '../../types'

import { getAnnouncements } from '../../services/announcementService'

import useClosedAnnouncements from '../../hooks/useClosedAnnouncements'

import NavbarNotificationsDropdown from './NavbarNotificationsDropdown'
import NavbarMessagesDropdown from './NavbarMessagesDropdown'
import NavbarLanguagesDropdown from './NavbarLanguagesDropdown'
import NavbarProfile from './NavbarProfile'
import NavbarSignOutButton from './NavbarSignOutButton'
import { InstructionForNewUser } from '../InstructionsForNewUser'
import ConfluencePageArticle from '../ConfluencePageArticle'

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`

type NavbarProps = {
  theme: Record<string, unknown>
  onDrawerToggle: React.MouseEventHandler<HTMLElement>
}

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = localStorage.getItem('theme')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The user manual.
   */
  const userManual = useUserManual()

  /**
   * The announcements.
   */
  const [announcements, setAnnouncements] = useState<Announcement[]>([])

  /**
   * The current announcements
   */
  const [currentAnnouncements, setCurrentAnnouncements] = useState<
    Announcement[]
  >([])

  /**
   * Indicates if the announcements are loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * Indicates if the user manual is open.
   */
  const [isUserManualOpen, setIsUserManualOpen] = useState<boolean>(false)

  useEffect(() => {
    loadAnnouncements()
  }, [])

  async function loadAnnouncements() {
    try {
      setIsLoading(true)

      const currentAnnouncements = await getAnnouncements({
        currentTime: new Date()
      })

      setCurrentAnnouncements(currentAnnouncements)

      const closedAnnouncements = useClosedAnnouncements()
      const listOfAnnouncementIds = closedAnnouncements.announcements ?? []

      const unreadAnnouncements = currentAnnouncements.filter(
        (announcement: Announcement) =>
          !listOfAnnouncementIds.includes(announcement.id)
      )

      setAnnouncements(unreadAnnouncements)
    } finally {
      setIsLoading(false)
    }
  }

  function handleCloseAnnouncement(id: string) {
    const closedAnnouncements = useClosedAnnouncements()
    const listOfAnnouncementIds = closedAnnouncements.announcements ?? []

    if (!listOfAnnouncementIds.includes(id)) {
      localStorage.setItem(
        'closedAnnouncements',
        JSON.stringify({
          announcements: [...listOfAnnouncementIds, id]
        })
      )

      const unreadAnnouncements = announcements.filter(
        (announcement: Announcement) => announcement.id !== id
      )

      setAnnouncements(unreadAnnouncements)
    }
  }

  /**
   * Handle theme change
   */
  function handleColorChange(): void {
    if (theme === THEMES.LIGHT) {
      localStorage.setItem('theme', THEMES.DARK)
      window.location.reload()
    } else {
      localStorage.setItem('theme', THEMES.LIGHT)
      window.location.reload()
    }
  }

  /**
   * Open the user manual.
   */
  function openUserManual(): void {
    setIsUserManualOpen(true)
  }

  /**
   * Close the user manual.
   */
  function closeUserManual(): void {
    setIsUserManualOpen(false)
  }

  return (
    <React.Fragment>
      <InstructionForNewUser />
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
            width="100%"
          >
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton color="inherit" size="large" onClick={onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
              width="100%"
            >
              {userManual !== null && (
                <>
                  <Tooltip title={t('userManual') as string}>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => openUserManual()}
                    >
                      <QuestionMarkIcon />
                    </IconButton>
                  </Tooltip>

                  <Drawer
                    anchor="right"
                    open={isUserManualOpen}
                    onClose={() => closeUserManual()}
                    PaperProps={{
                      sx: {
                        maxWidth: '525px',
                        width: '100%',
                        py: 6,
                        px: 3
                      }
                    }}
                  >
                    <Box>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack direction="column" gap={0}>
                          <Typography variant="h3">
                            {userManual.title}
                          </Typography>
                          <Typography
                            color={
                              theme !== null && theme === THEMES.DARK
                                ? 'rgba(255, 255, 255, 0.6)'
                                : 'rgba(0, 0, 0, 0.5)'
                            }
                          >
                            {t('userManual')}
                          </Typography>
                        </Stack>

                        <IconButton
                          onClick={() => closeUserManual()}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>

                      <Divider sx={{ mt: 6 }} />

                      <ConfluencePageArticle page={userManual} />

                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => closeUserManual()}
                          sx={{ mt: 6 }}
                        >
                          {t('closeUserManual')}
                        </Button>
                      </Box>
                    </Box>
                  </Drawer>
                </>
              )}

              {/*<Tooltip title={t('Navbar.help') as string}>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => dispatch(setShowInstructions(true))}
                >
                  <QuestionMarkIcon />
                </IconButton>
              </Tooltip>*/}

              <NavbarMessagesDropdown
                announcements={currentAnnouncements}
                lengthOfUnreadAnnouncements={announcements.length}
              />
              <NavbarNotificationsDropdown />

              {/*<Tooltip title={t('Navbar.switchTheme') as string}>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={handleColorChange}
                  color="inherit"
                >
                  {theme === THEMES.DARK ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness4Icon />
                  )}
                </IconButton>
              </Tooltip>*/}

              {/*<NavbarLanguagesDropdown />*/}
              <NavbarProfile />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      {!isLoading && (
        <>
          {announcements.map((announcement: Announcement) => (
            <Alert
              severity={announcement.severity as AlertColor}
              variant="filled"
              onClose={() => handleCloseAnnouncement(announcement.id)}
            >
              {announcement.message}{' '}
              {Object.keys(announcement.customData).length > 0 &&
              announcement.customData.url !== '' &&
              announcement.customData.urlName !== '' ? (
                <Button
                  color={announcement.severity as AlertColor}
                  style={{ color: 'black' }}
                  size="small"
                  onClick={() =>
                    (window.location.href =
                      announcement.customData.url.startsWith('http://') ||
                      announcement.customData.url.startsWith('https://') ||
                      announcement.customData.url.startsWith('www')
                        ? announcement.customData.url
                        : `https://${announcement.customData.url}`)
                  }
                  variant="contained"
                >
                  {announcement.customData.urlName}
                </Button>
              ) : null}
            </Alert>
          ))}
        </>
      )}
    </React.Fragment>
  )
}

export default withTheme(Navbar)
