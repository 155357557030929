import { useTranslation } from 'react-i18next'
import PageHeader from '../../components/PageHeader'
import { Reducer, useEffect, useReducer, useState } from 'react'
import { getDevice } from '../../redux/slices/devices'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth, useDevice } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { UserRole } from '../../enums/UserRole'
import {
  CardHeader as MuiCardHeader,
  Grid,
  Card as MuiCard,
  Typography,
  CardContent,
  Button,
  Breadcrumbs,
  Link
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../../redux/store'
import LoadingState from '../../components/LoadingState'
import Initial from '../../components/troubleshoot/Initial'
import Onomondo from '../../components/troubleshoot/Onomondo'
import DeviceReadings from '../../components/troubleshoot/DeviceReadings'
import DidWeSucceed from '../../components/troubleshoot/DidWeSucceed'
import RebootManually from '../../components/troubleshoot/RebootManually'
import ErrorCode from '../../components/troubleshoot/ErrorCode'
import ErrorTicket from '../../components/troubleshoot/ErrorTicket'
import CheckReadings from '../../components/troubleshoot/CheckReadings'
import SelectIncorrect, {
  IncorrectValues
} from '../../components/troubleshoot/SelectIncorrect'
import { NavLink } from 'react-router-dom'
import DeviceHeading from './DeviceHeading'

const Card = styled(MuiCard)(spacing)
const CardHeader = styled(MuiCardHeader)(spacing)

enum TroubleShootState {
  Initial = 'Initial',
  OnoMondoCheck = 'Onomondo check',
  ErrorCode = 'Error code',
  DeviceReadings = 'Device readings',
  CheckReadings = 'Check readings',
  SelectIncorrect = 'Select Incorrect',
  DidWeSucceed = 'Did we succeed',
  RebootDeviceManually = 'Reboot manually',
  SendErrorTicket = 'Send error ticket'
}

export enum TroubleshootEvent {
  NoErrorMessages = 'No error messages',
  SomeErrorMessage = 'Some error message',
  NoSensorReadings = 'No sensor readings',
  NoIncorrectReadings = 'No incorrect readings',
  SomeIncorrectReadings = 'Some incorrect readings',
  ResendConfiguration = 'Resend configuration',
  InvalidErrorCode = 'Invalid error code',
  SendErrorTicket = 'Send error ticket',
  Send = 'Send',
  ItDidNotHelp = 'It did not help',
  NothingWorked = 'Nothing worked',
  Skip = 'Skip',
  Continue = 'Continue'
}

export interface EditedValue {
  name: string
  oldValue?: number
  newValue: number
}

const troubleshootReducer: Reducer<TroubleShootState, TroubleshootEvent> = (
  state,
  event
) => {
  switch (state) {
    case TroubleShootState.Initial:
      if (event === TroubleshootEvent.Continue)
        return TroubleShootState.OnoMondoCheck
      break
    case TroubleShootState.OnoMondoCheck:
      if (event === TroubleshootEvent.NoErrorMessages)
        return TroubleShootState.DeviceReadings
      if (event === TroubleshootEvent.SomeErrorMessage)
        return TroubleShootState.ErrorCode
      break
    case TroubleShootState.DeviceReadings:
      if (event === TroubleshootEvent.NoSensorReadings)
        return TroubleShootState.RebootDeviceManually
      if (event === TroubleshootEvent.NoIncorrectReadings)
        return TroubleShootState.RebootDeviceManually
      if (event === TroubleshootEvent.SomeIncorrectReadings)
        return TroubleShootState.CheckReadings
      break
    case TroubleShootState.CheckReadings:
      if (event === TroubleshootEvent.SendErrorTicket)
        return TroubleShootState.SelectIncorrect
      if (event === TroubleshootEvent.ResendConfiguration)
        return TroubleShootState.DidWeSucceed
      break
    case TroubleShootState.SelectIncorrect:
      if (event === TroubleshootEvent.Send)
        return TroubleShootState.SendErrorTicket
      break
    case TroubleShootState.ErrorCode:
      if (
        event === TroubleshootEvent.ResendConfiguration ||
        event === TroubleshootEvent.Skip
      )
        return TroubleShootState.DidWeSucceed
      if (event === TroubleshootEvent.InvalidErrorCode)
        return TroubleShootState.RebootDeviceManually
      break
    case TroubleShootState.DidWeSucceed:
      if (event === TroubleshootEvent.ItDidNotHelp)
        return TroubleShootState.RebootDeviceManually
      break
    case TroubleShootState.RebootDeviceManually:
      if (
        event === TroubleshootEvent.NothingWorked ||
        event === TroubleshootEvent.Skip
      )
        return TroubleShootState.SendErrorTicket
  }
  return state
}

export default function TroubleshootDevice() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const device = useDevice()
  const [troubleshootState, troubleshootDispatch] = useReducer(
    troubleshootReducer,
    TroubleShootState.Initial
  )
  const [errorCode, setErrorCode] = useState(0)
  const [troubleshootSteps, setTroubleshootSteps] = useState<string[]>([])
  const [incorrectValues, setIncorrectValues] = useState<
    undefined | IncorrectValues
  >()
  const [updatedValues, setUpdatedValues] = useState<EditedValue[]>([])

  /**
   * Function to return back.
   */
  const endTroubleshoot = () => {
    navigate(-1)
  }

  const stateUpdate = (
    event: TroubleshootEvent,
    message?: string[] | string
  ) => {
    troubleshootDispatch(event)
    if (message) {
      const array = Array.isArray(message) ? message : [message]
      const newSteps = [...troubleshootSteps, ...array]
      setTroubleshootSteps(newSteps)
    }
  }

  const title = device?.asset?.name
    ? device.asset.name
    : device
    ? device.name
    : ''

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getDevice(id))
    }
  }, [currentUser, id])

  const isLoadingDevice = useSelector(
    (state: RootState) => state.devices.loading
  )

  if (isLoadingDevice || device === undefined) {
    return <LoadingState />
  }

  return (
    <>
      <PageHeader
        title={title}
        heading={<DeviceHeading device={device} />}
        breadcrumbs={
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              {t('frontpage')}
            </Link>

            {device?.asset?.project ? (
              <Link component={NavLink} to="/projects">
                {t('projects')}
              </Link>
            ) : (
              device && (
                <Link component={NavLink} to="/devices">
                  {t('devices')}
                </Link>
              )
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project.id}`}
              >
                {device?.asset?.project.name}
              </Link>
            )}

            {device?.asset?.project && (
              <Link
                component={NavLink}
                to={`/projects/${device?.asset?.project.id}#monitoring`}
              >
                {device?.asset?.project.name}
              </Link>
            )}

            {device ? (
              <Link component={NavLink} to={`/devices/${device?.name}`}>
                {device?.asset?.name ?? device.name}
              </Link>
            ) : (
              <Skeleton variant="text" width="100px" />
            )}

            <Typography>{t('troubleshoot.troubleshoot')}</Typography>
          </Breadcrumbs>
        }
      />
      <Card>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <CardHeader title={t('troubleshoot.title')} />
          </Grid>
          <Grid item xs={6}>
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button variant="contained" onClick={endTroubleshoot}>
                {t('cancel')}
              </Button>
            </Typography>
          </Grid>
        </Grid>
        <CardContent>
          {troubleshootState === TroubleShootState.Initial && (
            <Initial device={device} stateUpdate={stateUpdate} />
          )}
          {troubleshootState === TroubleShootState.OnoMondoCheck && (
            <Onomondo
              device={device}
              stateUpdate={stateUpdate}
              setErrorCode={setErrorCode}
            />
          )}
          {troubleshootState === TroubleShootState.DeviceReadings && (
            <DeviceReadings device={device} stateUpdate={stateUpdate} />
          )}
          {troubleshootState === TroubleShootState.DidWeSucceed && (
            <DidWeSucceed
              device={device}
              stateUpdate={stateUpdate}
              endTroubleshoot={endTroubleshoot}
            />
          )}
          {troubleshootState === TroubleShootState.RebootDeviceManually && (
            <RebootManually
              device={device}
              stateUpdate={stateUpdate}
              endTroubleshoot={endTroubleshoot}
            />
          )}
          {troubleshootState === TroubleShootState.ErrorCode && (
            <ErrorCode
              setUpdatedValues={setUpdatedValues}
              device={device}
              errorCode={errorCode}
              stateUpdate={stateUpdate}
            />
          )}
          {troubleshootState === TroubleShootState.SendErrorTicket && (
            <ErrorTicket
              troubleshootSteps={troubleshootSteps}
              device={device}
              incorrectValues={incorrectValues}
              updatedValues={updatedValues}
              endTroubleshoot={endTroubleshoot}
            />
          )}
          {troubleshootState === TroubleShootState.CheckReadings && (
            <CheckReadings
              device={device}
              stateUpdate={stateUpdate}
              setUpdatedValues={setUpdatedValues}
            />
          )}
          {troubleshootState === TroubleShootState.SelectIncorrect && (
            <SelectIncorrect
              device={device}
              stateUpdate={stateUpdate}
              setIncorrect={setIncorrectValues}
            />
          )}
        </CardContent>
      </Card>
    </>
  )
}
