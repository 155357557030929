import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Alert,
  Link,
  Table,
  TableRow,
  TableCell,
  Divider as MuiDivider,
  Typography,
  Stack
} from '@mui/material'
import * as Yup from 'yup'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { createOrderItem } from '../../redux/slices/orderItems'
import { OrderItemType } from '../../enums/OrderItemType'
import { NominalCurrentSelect100And250 } from '../NominalCurrentSelect'
import OrderItemTypeSelect from '../OrderItemTypeSelect'
import InfoIcon from '@mui/icons-material/Info'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { OrderErrors } from '../../enums/OrderErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { getCatalogProducts } from '../../redux/slices/catalog'
import { RootState } from '../../redux/store'
import { CatalogProduct, Product } from '../../types'
import LoadingState from '../LoadingState'
import { ProductStatus } from '../../enums/ProductStatus'
import { getOrder } from '../../redux/slices/orders'
import OrderItemInput from '../../types/OrderItemInput'
import { OrderItem } from '../../types'
import { getProducts } from '../../redux/slices/products'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'
import { CatalogProductType } from '../../enums/CatalogProductType'
import orderService from '../../services/orderService'
import NominalCurrent from '../../enums/NominalCurrent'
import { ProductType } from '../../enums'
import { ca } from 'date-fns/locale'
import { LoadingButton } from '@mui/lab'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const Button = styled(MuiButton)(spacing)

const Card = styled(MuiCard)(spacing)

const Grid = styled(MuiGrid)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const Divider = styled(MuiDivider)(spacing)

export default function CreateProductForm() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The route params.
   */
  const { id } = useParams()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The order.
   */
  const { order, isLoadingOrder } = useSelector((state: RootState) => ({
    order: state.orders.order,
    isLoadingOrder: state.orders.loading
  }))

  /**
   * The products.
   */
  const { products, isLoadingProducts } = useSelector((state: RootState) => ({
    products: state.products.products,
    isLoadingProducts: state.products.isLoadingProducts
  }))

  /**
   * The selected product IDs.
   */
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])

  /**
   * Indicates whether there are selected products from storage.
   */
  const hasSelectedProductsFromStorage = selectedProductIds.length > 0

  /**
   * The catalog products.
   */
  const { catalogProducts, isLoadingCatalogProducts } = useSelector(
    (state: RootState) => ({
      catalogProducts: state.catalogProducts.catalogProducts,
      isLoadingCatalogProducts: state.catalogProducts.loading
    })
  )

  /**
   * The electrical cabinets.
   */
  const electricalCabinets = catalogProducts.filter(
    (product: CatalogProduct) =>
      product.type === CatalogProductType.ELECTRICAL_CABINET
  )

  /**
   * The spine modules.
   */
  const spineModules = catalogProducts.filter(
    (product: CatalogProduct) =>
      product.type === CatalogProductType.SPINE_MODULE
  )

  /**
   * Indicates whether the clamp ratio input is visible.
   */
  const [isClampRatioInputVisible, setIsClampRatioInputVisible] =
    useState<boolean>(false)

  /**
   * Handles the change of visibility of the clamp ratio input.
   */
  const handleClampRatioInputVisibilityChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsClampRatioInputVisible(event.target.checked)
  }

  /**
   * Indicates whether the nominal current input is visible.
   */
  const [isNominalCurrentInputVisible, setIsNominalCurrentInputVisible] =
    useState<boolean>(false)

  /**
   * Handles the change of visibility of the nominal current input.
   */
  const handleNominalCurrentInputVisibilityChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsNominalCurrentInputVisible(event.target.checked)
  }

  /**
   * Indicates whether the clamp ratio instructions are open.
   */
  const [isClampRatioInstructionsOpen, setIsClampRatioInstructionsOpen] =
    useState<boolean>(false)

  /**
   * Open the clamp ratio instructions.
   */
  const openClampRatioInstructions = () => {
    setIsClampRatioInstructionsOpen(true)
  }

  /**
   * Close the clamp ratio instructions.
   */
  const closeClampRatioInstructions = () => {
    setIsClampRatioInstructionsOpen(false)
  }

  /**
   * The selected order item type.
   */
  const [type, setType] = useState<OrderItemType>(
    OrderItemType.INSTALLATION_KIT
  )

  /**
   * Indicates whether the selected type is an installation kit.
   */
  const isInstallationKit = type === OrderItemType.INSTALLATION_KIT

  /**
   * Indicates whether the selected type is a plug and play.
   */
  const isPlugAndPlay = type === OrderItemType.PLUG_AND_PLAY

  /**
   * Indicates whether the selected type is a smart electrical cabinet.
   */
  const isSmartElectricalCabinet =
    type === OrderItemType.SMART_ELECTRICAL_CABINET

  /**
   * Indicates whether the selected type is a spine module.
   */
  const isSpineModule = type === OrderItemType.SPINE_MODULE

  /**
   * Handle the change of the selected type.
   */
  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as OrderItemType)
  }

  /**
   * Reset the form when the type changes.
   */
  useEffect(() => {
    setNominalCurrent('')
    setCatalogProduct(undefined)
    setCatalogProductId(null)
    setProductCode('')
    setProductFilter('')
    setIsClampRatioInputVisible(false)
    dispatch(getProducts({ type, status: ProductStatus.STORED }))
  }, [type])

  /**
   * The quantity of items.
   */
  const [quantity, setQuantity] = useState<number>(1)

  /**
   * Handle the change of the quantity.
   */
  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(event.target.value))
  }

  /**
   * The nominal current.
   */
  const [nominalCurrent, setNominalCurrent] = useState<string>('')

  /**
   * The numeric nominal current.
   */
  const numericNominalCurrent = parseInt(nominalCurrent)

  /**
   * Handle the change of the nominal current.
   */
  const handleNominalCurrentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNominalCurrent(event.target.value)
  }

  /**
   * Changes the clamp ratio when the nominal current changes.
   */
  useEffect(() => {
    if (nominalCurrent === '' || numericNominalCurrent === 0) {
      setClampRatio('')
    } else if (numericNominalCurrent > 0 && numericNominalCurrent <= 100) {
      setClampRatio('1000')
    } else {
      setClampRatio('2500')
    }
  }, [nominalCurrent])

  /**
   * The clamp ratio.
   */
  const [clampRatio, setClampRatio] = useState<string>('')

  /**
   * Handles the change of the clamp ratio.
   */
  const handleClampRatioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClampRatio(event.target.value)
  }

  /**
   * Indicates whether the product is with power LED-bar.
   */
  const [withPowerLeds, setWithPowerLeds] = useState<boolean>(false)

  /**
   * Handles the change of the power LEDs.
   */
  const handlePowerLedsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWithPowerLeds(event.target.checked)
  }

  /**
   * The selected catalog product.
   */
  const [catalogProduct, setCatalogProduct] = useState<
    CatalogProduct | undefined
  >(undefined)

  /**
   * The ID of the selected catalog product.
   */
  const [catalogProductId, setCatalogProductId] = useState<string | null>(null)

  /**
   * The product code of the selected catalog product.
   */
  const [productCode, setProductCode] = useState('')

  /**
   * The search filter for the catalog products.
   */
  const [productFilter, setProductFilter] = useState<string>('')

  /**
   * Select a catalog product.
   */
  const selectCatalogProduct = (productCode: string | null) => {
    if (productCode === null) {
      setCatalogProduct(undefined)
      setCatalogProductId(null)
      setProductCode('')
      setProductFilter('')
      setNominalCurrent('')
    } else {
      const catalogProduct = catalogProducts.find((catalogProduct) => {
        return catalogProduct.manufacturerProductCode === productCode
      })

      if (catalogProduct !== undefined) {
        setCatalogProduct(catalogProduct)
        setCatalogProductId(catalogProduct.id)
        setProductCode(catalogProduct.manufacturerProductCode)
        setProductFilter(catalogProduct.manufacturerProductCode)
        setNominalCurrent(catalogProduct.customData.fuse.toString())
      }
    }
  }

  /**
   * Select a plug and play product.
   */
  const selectPlugAndPlayProduct = (productCode: string) => {
    setType(OrderItemType.SMART_ELECTRICAL_CABINET)
    selectCatalogProduct(productCode)
  }

  /**
   * Handle the change of the selected product from storage.
   */
  const handleSelectProductFromStorage = (
    event: React.ChangeEvent<HTMLInputElement>,
    productId: number
  ) => {
    const newSelectedProductIds = event.target.checked
      ? [...selectedProductIds, productId]
      : selectedProductIds.filter((id) => id !== productId)

    setSelectedProductIds(newSelectedProductIds)

    if (newSelectedProductIds.length > 0) {
      setNominalCurrent('0')
      setClampRatio('0')
      setQuantity(newSelectedProductIds.length)
    } else {
      setQuantity(1)
      setNominalCurrent('')
      setClampRatio('')
    }
  }

  /**
   * Loads the order when the ID changes.
   */
  useEffect(() => {
    if (id) {
      dispatch(getOrder(parseInt(id)))
    }
  }, [id])

  /**
   * Loads the catalog products when the component mounts.
   */
  useEffect(() => {
    dispatch(getCatalogProducts())
  }, [])

  /**
   * The initial values of the form.
   */
  const initialValues: OrderItemInput = {
    orderId: order?.id as number,
    type,
    quantity,
    withPowerLeds,
    nominalCurrent,
    clampRatio,
    catalogProduct: catalogProductId,
    status: ProductStatus.STORED,
    useStock: false,
    products: selectedProductIds
  }

  /**
   * The validation schema of the form.
   */
  const validationSchema = Yup.object().shape({
    nominalCurrent: Yup.string().required(
      t('isRequired', {
        field:
          isInstallationKit || isPlugAndPlay
            ? t('Manufacturing.currentTransformers').toLowerCase()
            : t('product').toLowerCase()
      })
    ),
    clampRatio: Yup.string().required(
      t('isRequired', {
        field: t('clampRatio').toLowerCase()
      })
    )
  })

  /**
   * The filtered products.
   */
  const filteredProducts = products.filter((product: Product) => {
    const regex = new RegExp(productFilter, 'i')

    return (
      productFilter.length === 0 ||
      Object.values(product).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.device ?? {}).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.catalogProduct ?? {}).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  /**
   * Maps the product codes.
   */
  const mapProductCodes = (catalogProducts: CatalogProduct[]) => {
    return catalogProducts.map(
      (catalogProduct: CatalogProduct) => catalogProduct.manufacturerProductCode
    )
  }

  /**
   * Handles the submission of the form.
   */
  const handleSubmit = async (
    values: OrderItemInput,
    helpers: FormikHelpers<OrderItemInput>
  ) => {
    try {
      if (values.products !== undefined && values.products.length > 0) {
        values.nominalCurrent = 0
        values.clampRatio = 0
        values.quantity = selectedProductIds.length
      } else {
        values.products = undefined
      }

      values.status = ProductStatus.PENDING

      const orderItem = await orderService.createOrderItem(values)
      dispatch(createOrderItem(orderItem))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('orderItemCreateSuccess')
        )
      )

      navigate(`/manufacturing/orders/${id}`)
    } catch (error) {
      dispatch(
        setError({
          type: OrderErrors.CREATE,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderErrors.CREATE,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      // TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(OrderErrors.CREATE))
    }
  }

  return (
    <React.Fragment>
      {isLoadingCatalogProducts || isLoadingOrder ? (
        <LoadingState />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            handleSubmit
          }) => (
            <>
              <Button
                onClick={() => navigate(`/manufacturing/orders/${id}`)}
                sx={{ mb: 3 }}
              >
                {t('Manufacturing.back')}
              </Button>

              <Card mb={6}>
                <CardHeader
                  title={t('Manufacturing.addProduct')}
                  titleTypographyProps={{ variant: 'h4' }}
                  action={
                    <Button
                      size="small"
                      onClick={() => navigate('/manufacturing/catalog')}
                    >
                      {t('manageCatalog')}
                    </Button>
                  }
                />
                <CardContent>
                  {isSubmitting ? (
                    <LoadingState />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6} order={1}>
                          <OrderItemTypeSelect
                            name="type"
                            value={values.type}
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                            disabled={
                              hasSelectedProductsFromStorage || isSubmitting
                            }
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleTypeChange}
                          />
                        </Grid>

                        {!hasSelectedProductsFromStorage && (
                          <Grid item xs={12} md={6} order={2}>
                            {isInstallationKit || isPlugAndPlay ? (
                              <NominalCurrentSelect100And250
                                name="nominalCurrent"
                                value={nominalCurrent}
                                error={Boolean(
                                  touched.nominalCurrent &&
                                    errors.nominalCurrent
                                )}
                                helperText={
                                  touched.nominalCurrent &&
                                  errors.nominalCurrent
                                }
                                disabled={isSubmitting}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleNominalCurrentChange}
                              />
                            ) : (
                              <>
                                <Autocomplete
                                  options={mapProductCodes(
                                    values.type ===
                                      OrderItemType.SMART_ELECTRICAL_CABINET
                                      ? electricalCabinets
                                      : spineModules
                                  )}
                                  value={productCode}
                                  disabled={isSubmitting}
                                  onChange={(
                                    event: SyntheticEvent,
                                    value: string | null
                                  ) => {
                                    selectCatalogProduct(value)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label={
                                        catalogProduct
                                          ? `${catalogProduct.manufacturer} / ${catalogProduct.name} (${catalogProduct.customData.fuse}A)`
                                          : t('Manufacturing.product')
                                      }
                                      error={Boolean(
                                        touched.nominalCurrent &&
                                          errors.nominalCurrent
                                      )}
                                      helperText={
                                        touched.nominalCurrent &&
                                        errors.nominalCurrent
                                      }
                                      {...params}
                                    />
                                  )}
                                />
                              </>
                            )}
                          </Grid>
                        )}

                        <Grid item xs={12} md={6} order={{ xs: 4, md: 3 }}>
                          <TextField
                            name="quantity"
                            label={t('Manufacturing.quantity')}
                            value={quantity}
                            error={Boolean(touched.quantity && errors.quantity)}
                            helperText={touched.quantity && errors.quantity}
                            disabled={
                              hasSelectedProductsFromStorage || isSubmitting
                            }
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleQuantityChange}
                          />

                          {!hasSelectedProductsFromStorage && (
                            <FormControlLabel
                              label={t('Manufacturing.addLedbar') as string}
                              sx={{ ml: 1, mt: 1 }}
                              control={
                                <Checkbox
                                  name="withPowerLeds"
                                  value={values.withPowerLeds}
                                  disabled={isSubmitting}
                                  onChange={handlePowerLedsChange}
                                />
                              }
                            />
                          )}
                        </Grid>

                        {!hasSelectedProductsFromStorage &&
                          nominalCurrent !== '' && (
                            <Grid item xs={12} md={6} order={{ xs: 3, md: 4 }}>
                              {/** @deprecated Nominal current should not be modifiable. */}
                              {/*{(isNominalCurrentInputVisible && (
                              <TextField
                                name="nominalCurrent"
                                label={t('Manufacturing.nominal')}
                                value={values.nominalCurrent}
                                error={Boolean(
                                  touched.nominalCurrent &&
                                    errors.nominalCurrent
                                )}
                                fullWidth
                                helperText={
                                  touched.nominalCurrent &&
                                  errors.nominalCurrent
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                              />
                            )}*/}

                              {/** @deprecated Nominal current should not be modifiable. */}
                              {/*<FormControlLabel
                                label={
                                  t('Manufacturing.modifyNominal') as string
                                }
                                control={
                                  <Checkbox
                                    name="other"
                                    onChange={handleNominalCurrentInputVisibilityChange}
                                  />
                                }
                              />*/}

                              {!isClampRatioInputVisible &&
                                numericNominalCurrent > 0 && (
                                  <TextField
                                    name="clampRatio"
                                    label={t('Manufacturing.clampRatio')}
                                    value={values.clampRatio}
                                    error={Boolean(
                                      touched.clampRatio && errors.clampRatio
                                    )}
                                    helperText={
                                      touched.clampRatio && errors.clampRatio
                                    }
                                    disabled
                                    fullWidth
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            openClampRatioInstructions()
                                          }
                                        >
                                          <InfoIcon fontSize="inherit" />
                                        </IconButton>
                                      )
                                    }}
                                  />
                                )}

                              {(isClampRatioInputVisible ||
                                nominalCurrent === NominalCurrent.OTHER) && (
                                <TextField
                                  name="clampRatio"
                                  label={t('Manufacturing.clampRatio')}
                                  value={values.clampRatio}
                                  error={Boolean(
                                    touched.clampRatio && errors.clampRatio
                                  )}
                                  helperText={
                                    touched.clampRatio && errors.clampRatio
                                  }
                                  fullWidth
                                  disabled={isSubmitting}
                                  onBlur={handleBlur}
                                  onChange={handleClampRatioChange}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          openClampRatioInstructions()
                                        }
                                      >
                                        <InfoIcon fontSize="inherit" />
                                      </IconButton>
                                    )
                                  }}
                                />
                              )}

                              {numericNominalCurrent > 0 && (
                                <FormControlLabel
                                  label={
                                    t(
                                      'Manufacturing.modifyClampratio'
                                    ) as string
                                  }
                                  control={
                                    <Checkbox
                                      name="modifyClampratio"
                                      onChange={
                                        handleClampRatioInputVisibilityChange
                                      }
                                      checked={isClampRatioInputVisible}
                                      disabled={isSubmitting}
                                    />
                                  }
                                  sx={{ ml: 1, mt: 1 }}
                                />
                              )}

                              <Dialog
                                open={isClampRatioInstructionsOpen}
                                onClose={closeClampRatioInstructions}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {t('Manufacturing.clampRatio')}
                                </DialogTitle>

                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {t('Manufacturing.setClampratioManually')}
                                  </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    variant="contained"
                                    onClick={closeClampRatioInstructions}
                                    autoFocus
                                  >
                                    {t('Manufacturing.close')}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Grid>
                          )}

                        {isPlugAndPlay && !hasSelectedProductsFromStorage && (
                          <Grid item xs={12} order={5}>
                            <Alert variant="outlined" severity="info">
                              {t('plugAndPlayInfo.partOne')} 16 A{' '}
                              <Link
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  selectPlugAndPlayProduct('501310005')
                                }
                                color={'darkblue'}
                              >
                                (501310005)
                              </Link>
                              , 32 A{' '}
                              <Link
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  selectPlugAndPlayProduct('501310004')
                                }
                                color={'darkblue'}
                              >
                                (501310004)
                              </Link>
                              , {t('plugAndPlayInfo.or')} 63 A{' '}
                              <Link
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  selectPlugAndPlayProduct('501310003')
                                }
                                color={'darkblue'}
                              >
                                (501310003)
                              </Link>{' '}
                              {t('plugAndPlayInfo.partTwo')}
                            </Alert>
                          </Grid>
                        )}

                        <Grid item xs={12} order={6}>
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={3}
                          >
                            <Button
                              onClick={() => {
                                navigate(`/manufacturing/orders/${id}`)
                              }}
                            >
                              {t('Manufacturing.cancel')}
                            </Button>

                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              loading={isSubmitting}
                            >
                              {t('Manufacturing.save')}
                            </LoadingButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </CardContent>
              </Card>
            </>
          )}
        </Formik>
      )}

      {order?.company !== null && (
        <Card>
          <CardHeader
            title={t('storage')}
            titleTypographyProps={{ variant: 'h4' }}
          />
          <Divider my={6} />
          <Grid xs={4} ml={4}>
            <TextField
              label={t('search')}
              fullWidth
              name="search"
              size="small"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setProductFilter(event.target.value)
              }}
            />
          </Grid>
          <CardContent>
            {isLoadingProducts ? (
              <LoadingState />
            ) : (
              <React.Fragment>
                {products ? (
                  <Table>
                    <TableRow>
                      <TableCell>{t('deviceName')}</TableCell>
                      <TableCell>{t('manufacturerProductCode')}</TableCell>
                      <TableCell>{t('type')}</TableCell>
                      <TableCell>{t('clampRatio')}</TableCell>
                      <TableCell>{t('nominalCurrent')}</TableCell>
                      <TableCell>{t('ledbar')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {filteredProducts.map((product: Product) => (
                      <TableRow>
                        <TableCell>
                          {product.device ? product.device.shortUUID : '-'}
                        </TableCell>
                        <TableCell>
                          {product.catalogProduct
                            ? product.catalogProduct.manufacturerProductCode
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {t(
                            translateProductOrOrderItemType(
                              product.type
                            ) as string
                          )}
                        </TableCell>
                        <TableCell>{product.clampRatio}</TableCell>
                        <TableCell>{product.nominalCurrent}</TableCell>
                        <TableCell>
                          {product.withPowerLeds ? t('yes') : t('no')}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            onChange={(event) =>
                              handleSelectProductFromStorage(event, product.id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                ) : (
                  <Typography align="center">{t('storageIsEmpty')}</Typography>
                )}
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  )
}
