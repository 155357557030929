import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider as MuiDivider,
  CardHeader,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  IconButton,
  Tooltip,
  CardContent
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { deleteOrder, getOrders } from '../../redux/slices/orders'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Add, Delete, Edit } from '@mui/icons-material'
import { OrderStatus } from '../../enums/OrderStatus'
import { Order } from '../../types'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import orderService from '../../services/orderService'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import { OrderErrors } from '../../enums/OrderErrors'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import LoadingState from '../LoadingState'
import { NavLink } from 'react-router-dom'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

export interface OrderTableProps {
  orders?: Order[]
}

function OrderTable({ orders }: OrderTableProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { currentUser } = useAuth()

  async function handleDelete(id: number) {
    try {
      await orderService.deleteOrder(id)
      dispatch(deleteOrder(id))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  const [open, setOpen] = useState(false)
  const [orderId, setOrderId] = useState(0)

  const handleClickOpen = (id: any) => {
    setOrderId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOrderId(0)
  }

  const translateOrderStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.PENDING:
        return t('Manufacturing.pending')
      case OrderStatus.ON_HOLD:
        return t('Manufacturing.onHold')
      case OrderStatus.IN_PROGRESS:
        return t('Manufacturing.inProgress')
      default:
        return '-'
    }
  }

  const [filter, setFilter] = useState<string>('')
  const [hasCompany, setHasCompany] = useState<boolean | string>('')
  const companyFilteredOrders =
    hasCompany !== ''
      ? hasCompany === false
        ? orders?.filter((item) => item.company === null)
        : orders?.filter((item) => item.company !== null)
      : orders

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    filter: string | boolean
  ) => {
    setHasCompany(filter)
  }
  const filteredOrders = companyFilteredOrders?.filter((order: Order) => {
    const regex = new RegExp(filter, 'i')
    return (
      filter.length === 0 ||
      Object.values(order).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon fontSize="inherit" />}
            component={NavLink}
            to="/manufacturing"
          >
            {t('Manufacturing.back')}
          </Button>

          <Divider my={6} />

          <Card mb={6}>
            <CardHeader
              title={t('Manufacturing.orders')}
              action={
                <Button
                  size="small"
                  component={NavLink}
                  to="/manufacturing/orders/create"
                  endIcon={<Add fontSize="inherit" />}
                >
                  {t('Manufacturing.newOrder')}
                </Button>
              }
            />
            <CardContent>
              {orders && (
                <>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    gap={6}
                  >
                    <TextField
                      name="search"
                      label={t('search')}
                      value={filter}
                      size="small"
                      fullWidth
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFilter(event.target.value)
                      }}
                      sx={{ maxWidth: '420px' }}
                    />

                    <ToggleButtonGroup
                      color="primary"
                      value={hasCompany}
                      exclusive
                      onChange={handleChange}
                    >
                      <ToggleButton value={false} size="small">
                        {t('storage')}
                      </ToggleButton>
                      <ToggleButton value={true} size="small">
                        {t('customers')}
                      </ToggleButton>
                      <ToggleButton value={''} size="small">
                        {t('all')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredOrders?.map((order: Order) => (
                          <TableRow key={order.id}>
                            <TableCell>
                              {order.company?.name ??
                                t('Manufacturing.storage')}
                              , {order.name},{' '}
                              {translateOrderStatus(order.status)}, DD:{' '}
                              {order.dueDate}
                            </TableCell>

                            <TableCell>
                              {order.company === null && (
                                <Tooltip title={t('storedOrder') as string}>
                                  <WarehouseIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </TableCell>

                            <TableCell>
                              <Tooltip
                                title={t('Manufacturing.edit') as string}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    navigate(
                                      `/manufacturing/orders/${order.id}/edit`
                                    )
                                  }
                                >
                                  <Edit fontSize="inherit">
                                    {t('Manufacturing.edit')}
                                  </Edit>
                                </IconButton>
                              </Tooltip>
                            </TableCell>

                            {order.status === OrderStatus.ON_HOLD ? (
                              <TableCell align="right">
                                <Tooltip
                                  title={t('Manufacturing.delete') as string}
                                >
                                  <IconButton
                                    color="error"
                                    onClick={() => handleClickOpen(order.id)}
                                  >
                                    <Delete fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>

                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {t('Manufacturing.confirmOrder')}
                                  </DialogTitle>

                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      {t('Manufacturing.warning')}
                                    </DialogContentText>
                                  </DialogContent>

                                  <DialogActions>
                                    <Button
                                      onClick={() => handleDelete(orderId)}
                                    >
                                      {t('Manufacturing.yes')}
                                    </Button>
                                    <Button onClick={handleClose} autoFocus>
                                      {t('Manufacturing.no')}
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                <Tooltip title={t('show') as string}>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      navigate(
                                        `/manufacturing/orders/${order.id}`
                                      )
                                    }
                                  >
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function OrderList() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The orders state.
   */
  const { orders, loading } = useSelector((state: RootState) => state.orders)

  useEffect(() => {
    dispatch(
      getOrders({
        status: [
          OrderStatus.PENDING,
          OrderStatus.IN_PROGRESS,
          OrderStatus.ON_HOLD
        ]
      })
    )
  }, [])

  return <>{loading ? <LoadingState /> : <OrderTable orders={orders} />}</>
}

export default OrderList
